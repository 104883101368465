import React from "react"
import { BlueGradientButton } from "@rentspree/component-v2/dist/button/gradient"
import { PLAN_NAMES } from "legacy/containers/pro/landing/constants"
import { BUTTON_TEXTS } from "./constants"

const ActionButton = ({
  name,
  hasTrialPlan,
  onClickTrial,
  onClickChoosePlanButton,
  onClickManagePlanButton,
  buttonFontSize = "14px",
  isActive,
  margin,
}) => {
  let text = BUTTON_TEXTS.UPGRADE_PLAN
  let onClick = onClickChoosePlanButton
  let Button = null
  switch (name) {
    case PLAN_NAMES.PRO:
      {
        if (isActive) {
          text = BUTTON_TEXTS.MANAGE_PLAN
          onClick = onClickManagePlanButton
        } else if (hasTrialPlan) {
          text = BUTTON_TEXTS.TRY_TRIAL
          onClick = onClickTrial
        }
        const buttonState = { text, onClick }

        Button = (
          <BlueGradientButton
            id={name}
            small
            text={text}
            semiBold
            type="submit"
            gradient="blue"
            fontSize={buttonFontSize}
            margin={margin}
            width="200px"
            mWidth="200px"
            {...buttonState}
          />
        )
      }
      break
    default:
      break
  }
  return Button
}

export { ActionButton }
