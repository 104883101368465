import { MAPPING_EXPLORE_FEATURE } from 'tracker/const'

import { BADGE_TYPE } from 'containers/badge/constants'
import { LISTING_MANAGEMENT_FEATURE_ID } from 'utils/feature-toggle/constants'

import tenantScreeningIcon from 'images/icons/explore/tenant-screening-icon.svg'
import rentEstimationIcon from 'images/icons/explore/rent-estimation-icon.svg'
import requestDocsIcon from 'images/icons/explore/request-docs-icon.svg'
import referenceChecksIcon from 'images/icons/explore/reference-checks-icon.svg'
import acceptDenyIcon from 'images/icons/explore/accept-or-deny-icon.svg'
import shareReportIcon from 'images/icons/explore/share-report-icon.svg'
import messagingIcon from 'images/icons/explore/messages-icon.svg'
import suggestFeatureIcon from 'images/icons/explore/suggest-feature-icon.svg'
import leaseAgreementIcon from 'images/icons/explore/esign-icon.svg'
import playBtnIcon from 'images/icons/explore/play-btn.svg'
import pdfBtnIcon from 'images/icons/explore/pdf-btn.svg'
import infoBtnIcon from 'images/icons/explore/info-btn.svg'
// import renterInsuranceIcon from "images/icons/explore/renter-insurance-icon.svg"
import suggestFeatureListingPage from 'images/post-login/illus-listing.svg'
import suggestFeatureRCM from 'images/post-login/illus-RCM.svg'
import suggestFeatureAgentProfile from 'images/post-login/illus-agentBranding.svg'
import { API_URL, MAIN_URL } from '../../env'

export const HEADER_TEXT = 'Explore RentSpree'
export const DESC_TEXT = 'Select an action to get started'

export const USER_PLAN_TYPE = {
  FREE: 'FREE',
  PRO: 'PRO',
}

export const SUBSCRIPTION_STATUS = {
  FREE: 'FREE',
  PRO: 'PRO',
  CANCELLED: 'CANCELLED',
  FREE_WITH_TRIAL: 'FREE_WITH_TRIAL',
}

export const SUBSCRIPTION_BUTTON_TEXT = {
  FREE: 'Upgrade to PRO',
  CANCELLED: 'Reactivate PRO',
  FREE_WITH_TRIAL: 'Start 30-Day Free Trial',
}
export const CARDS_USE_FEATURE_FLAG = {
  'esign-documents-card': 'rental_document',
  'renter-insurance-card': 'renters_insurance',
}

export const CARDS_LIST = [
  {
    id: 'rent-estimation-card',
    title: 'Rent Estimation',
    content: 'An estimate on the price of your listing',
    icon: { src: rentEstimationIcon, alt: 'Rent Estimation' },
    isPro: false,
    property: MAPPING_EXPLORE_FEATURE.RENT_ESTIMATE,
    buttons: [
      {
        id: 'watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Video',
        link: 'https://www.youtube.com/embed/i9ynYNsi6r4',
      },
      {
        id: 'sample-report-button',
        icon: pdfBtnIcon,
        text: 'Sample Report',
        href: 'https://cdn.rentspree.com/static-files/report/sample-rent-estimate-rentspree-report.PDF',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/rent-estimate-report',
      },
    ],
  },
  {
    id: 'tenant-screening-card',
    title: 'Tenant Screening',
    content: 'Collect rental applications and screening reports online',
    icon: { src: tenantScreeningIcon, alt: 'Tenant Screening' },
    isPro: false,
    property: MAPPING_EXPLORE_FEATURE.TENANT_SCREENING,
    buttons: [
      {
        id: 'watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Videos',
        link: 'https://www.youtube.com/embed/CMSegnMgzzA',
        link_caa: 'https://www.youtube.com/embed/HCCcEtbl-2k',
      },
      {
        id: 'sample-report-button',
        icon: pdfBtnIcon,
        text: 'Sample Report',
        href: 'https://cdn.rentspree.com/static-files/report/All-Report-Sample-04-25-22.pdf',
        href_caa: 'https://cdn.rentspree.com/static-files/report/CAA-Report-Sample-08-13-21.pdf',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/screening-new-applicants',
      },
    ],
  },
  {
    id: 'report-sharing-card',
    title: 'Report Sharing',
    content: 'Share reports securely with another agent or landlord',
    icon: { src: shareReportIcon, alt: 'Report Sharing' },
    isPro: false,
    property: MAPPING_EXPLORE_FEATURE.SHARE_REPORT,
    buttons: [
      {
        id: 'watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Video',
        link: 'https://www.youtube.com/embed/nV2UzG3C3nc',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/share-reports',
      },
    ],
  },
  {
    id: 'request-docs-card',
    title: 'Request Docs',
    content: 'Request extra documents from your applicants through RentSpree PRO',
    icon: { src: requestDocsIcon, alt: 'Request Docs' },
    isPro: true,
    property: MAPPING_EXPLORE_FEATURE.OTHER_DOCS,
    buttons: [
      {
        id: 'watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Video',
        link: 'https://www.youtube.com/embed/aSETxOitHVQ',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/how-to-access-uploaded-documents',
      },
    ],
  },
  {
    id: 'reference-checks-card',
    title: 'Reference Checks',
    content: 'Request reference verification through RentSpree PRO',
    icon: { src: referenceChecksIcon, alt: 'Reference Checks' },
    isPro: true,
    property: MAPPING_EXPLORE_FEATURE.REF_CHECK,
    buttons: [
      {
        id: 'watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Video',
        link: 'https://www.youtube.com/embed/hedE4j8oBAA',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/how-to-use-auto-reference-checks',
      },
    ],
  },
  {
    id: 'messaging-card',
    title: 'Messages',
    content: 'Converse with other agents, landlords, and applicants',
    icon: { src: messagingIcon, alt: 'Messaging' },
    isPro: false,
    property: MAPPING_EXPLORE_FEATURE.MESSAGING,
    buttons: [
      {
        id: 'messaging-watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Video',
        link: 'https://www.youtube.com/embed/wy9cu85-WBg',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/messages',
      },
    ],
  },
  {
    id: 'accept-deny-card',
    title: 'Accept/Deny',
    content: 'Send your decision with the click of a button',
    icon: { src: acceptDenyIcon, alt: 'Accept/Deny' },
    isPro: false,
    property: MAPPING_EXPLORE_FEATURE.ACCEPT_DENY,
    buttons: [
      {
        id: 'watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Video',
        link: 'https://www.youtube.com/embed/MiiWXVRYA8Y',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/accept-deny-applicants',
      },
    ],
  },
  {
    id: 'esign-documents-card',
    title: 'E-Sign Documents',
    content: 'Send and sign all rental documents.',
    icon: { src: leaseAgreementIcon, alt: 'Esign-document' },
    isPro: false,
    property: MAPPING_EXPLORE_FEATURE.ESIGN_DOCUMENTS,
    buttons: [
      {
        id: 'watch-video-button',
        icon: playBtnIcon,
        text: 'Watch Video',
        link: 'https://www.youtube.com/embed/VPug01WADsI',
        link_caa: 'https://www.youtube.com/embed/VPug01WADsI',
      },
      {
        id: 'read-more-button',
        icon: infoBtnIcon,
        text: 'Read More',
        href: 'https://support.rentspree.com/e-sign-documents',
      },
    ],
  },
  // hide renter insurance in constant of feature explore for old version
  // {
  //   id: "renter-insurance-card",
  //   title: "Renters Insurance",
  //   content: "Invite tenants to purchase renters insurance",
  //   icon: { src: renterInsuranceIcon, alt: "Renter Insurance" },
  //   isPro: false,
  //   property: MAPPING_EXPLORE_FEATURE.RENTER_INSURANCE,
  //   buttons: [
  //     {
  //       id: "messaging-watch-video-button",
  //       icon: playBtnIcon,
  //       text: "Watch Video",
  //       link: "https://www.youtube.com/embed/_csZ_vNypu4",
  //     },
  //     {
  //       id: "read-more-button",
  //       icon: infoBtnIcon,
  //       text: "Read More",
  //       href:
  //         "https://support.rentspree.com/en/how-to-request-renters-insurance",
  //     },
  //   ],
  // },
  {
    id: 'suggest-feature-card',
    title: 'Suggest a feature',
    content: 'Got an idea that you would like to share? Let us know',
    icon: { src: suggestFeatureIcon, alt: 'Suggest a feature' },
    buttons: [
      {
        id: 'suggest-feature-button',
        text: 'Suggest a feature',
        type: 'button',
      },
    ],
  },
]

export const TYPES = {
  SHOW_WATCH_VIDEO_MODAL: 'SHOW_WATCH_VIDEO_MODAL',
}

export const BUTTON_ID = {
  SAMPLE_REPORT: 'sample-report-button',
  READ_MORE: 'read-more-button',
}

export const SUGGEST_FEATURES = [
  {
    title: 'Listing Pages',
    description: 'Create property listing pages and share them with your network.',
    buttonName: 'Select a Property',
    buttonLink: '/listing-page/request/select-property?source=explore_rentspree',
    learnMoreLink: 'https://www.rentspree.com/listing-pages/',
    img: suggestFeatureListingPage,
    id: 'explore-listingPageFeature',
    featureName: 'listing pages',
    featureId: LISTING_MANAGEMENT_FEATURE_ID,
  },
  {
    title: 'Rental Client Manager',
    description: 'Organize contacts and stay connected with your clients all in one place.',
    buttonName: 'Add Contacts',
    buttonLink: '/client-manager',
    learnMoreLink: 'https://www.rentspree.com/rental-client-manager/',
    img: suggestFeatureRCM,
    id: 'explore-rcmFeature',
    featureName: 'rental client manager',
  },
  {
    title: 'Branding page',
    description: 'Build a profile to promote your business.',
    buttonName: 'Create Branding Page',
    buttonLink: `${MAIN_URL}/agent-tools`,
    learnMoreLink: 'https://www.rentspree.com/branding-page/',
    img: suggestFeatureAgentProfile,
    id: 'explore-agentProfileFeature',
    featureName: 'agent profile',
  },
]

export const MAIN_FEATURES = [
  {
    title: 'Tenant Screening',
    description: 'Collect rental applications and screening reports online.',
    buttonName: 'Start Screening',
    sampleReportLink:
      'https://cdn.rentspree.com/static-files/report/All-Report-Sample-04-18-23.pdf',
    sampleReportLinkCaa:
      'https://cdn.rentspree.com/static-files/report/CAA-Report-Sample-08-13-21.pdf',
    learnMoreLink: 'https://www.rentspree.com/tenant-screening/',
    featureLink: '/tenant-screening',
    badgeType: '',
    icon: { src: 'FeatureTenantScreening', alt: 'Tenant Screening' },
    featureName: 'tenant screening',
  },
]

export const MAIN_FEATURES_V2 = [
  {
    title: 'Online Rental Applications and Screening',
    buttonName: 'Start Screening Request',
    icon: { src: 'FeatureTenantScreening', alt: 'Tenant Screening' },
    featureLink: `${MAIN_URL}/dashboard/v2/tenant-screening/request`,
    featureName: 'tenant screening',
  },
]

export const REFERENCE_CHECKS_FEATURE_ID = 'reference_checks'
export const REQUEST_DOC_FEATURE_ID = 'request_docs'

export const FEATURES = [
  {
    title: 'Rent Estimate',
    description: 'An estimate on the price of your listing.',
    sampleReportLink:
      'https://cdn.rentspree.com/static-files/report/sample-rent-estimate-rentspree-report.PDF',
    learnMoreLink: 'https://www.rentspree.com/rent-estimate/',
    featureLink: '/rental-estimate',
    badgeType: '',
    icon: { src: 'FeatureRentEstimates', alt: 'Rent Estimate' },
    featureName: 'rent estimate',
    featureId: 'rent_estimate',
  },
  {
    title: 'Request Docs',
    description: 'Request extra documents from your applicants through RentSpree PRO.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/document-upload/',
    featureLink: '/rentspree-pro',
    badgeType: BADGE_TYPE.PRO_BADGE,
    icon: { src: 'FeatureRequestDocs', alt: 'Request Docs' },
    featureName: 'request docs',
    featureId: 'request_docs',
  },
  {
    title: 'Reference Checks',
    description: 'Request reference verification through RentSpree PRO.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/reference-checks/',
    featureLink: '/rentspree-pro',
    badgeType: BADGE_TYPE.PRO_BADGE,
    icon: { src: 'FeatureReferenceChecks', alt: 'Reference Checks' },
    featureName: 'reference checks',
    featureId: 'reference_checks',
  },
  {
    title: 'Rent Payment',
    description: 'Hassle-free online rent collection.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/rent-payment',
    featureLink: '/rent-payments',
    badgeType: '',
    icon: { src: 'menu-rent-collection', alt: 'Rent Payments' },
    featureName: 'rent payment',
    featureId: 'rent_payment',
  },
  {
    title: 'Messages',
    description: 'Converse with other agents, landlords, and applicants.',
    sampleReportLink: '',
    learnMoreLink: 'https://support.rentspree.com/messages/',
    featureLink: '/messaging',
    badgeType: '',
    icon: { src: 'MenuMessages', alt: 'Messages' },
    featureName: 'messages',
    featureId: 'messages',
  },
  {
    title: 'Renters Insurance',
    description: 'Invite tenants to purchase renters insurance.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/renters-insurance/',
    featureLink: '/renter-insurance',
    badgeType: '',
    icon: { src: 'FeatureRentersInsurance', alt: 'Renters Insurance' },
    featureName: 'renters insurance',
    featureId: 'renters_insurance',
  },
]

export const FEATURES_V2 = [
  {
    title: 'Rent Payment',
    description: 'Hassle-free online rent collection.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/rent-payment',
    featureLink: '/rent-payments?source=explore-page',
    badgeType: '',
    icon: { src: 'menu-rent-collection', alt: 'Rent Payments' },
    featureName: 'rent payment',
    featureId: 'rent_payment',
  },
  {
    title: 'Listing Pages',
    description: 'Create property listing pages and share them with your network.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/listing-pages/',
    featureLink: '/listing-page/request/select-property?source=explore_rentspree',
    badgeType: '',
    icon: { src: 'FeatureListingPage', alt: 'Renters Insurance' },
    featureName: 'listing pages',
    featureId: 'listing_management',
  },
  {
    title: 'Client Manager',
    description: 'Organize contacts and stay connected with your clients all in one place.',
    sampleReportLink: '',
    learnMoreLink: `https://www.rentspree.com/rental-client-manager`,
    featureLink: '/client-manager',
    badgeType: '',
    icon: { src: 'FeatureClientManager', alt: 'Client Manager' },
    featureName: 'rental client manager',
    featureId: 'client_manager',
  },
  {
    title: 'Branding Page',
    description: 'Build a branding page to elevate your business presence.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/agent-profile',
    featureLink: `${MAIN_URL}/agent-tools`,
    badgeType: '',
    icon: { src: 'FeatureAgentProfile', alt: 'Client Manager' },
    featureName: 'agent profile',
    featureId: 'agent_profile',
  },
  {
    title: 'Rent Estimate',
    description: 'An estimate on the price of your listing.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/rent-estimate/',
    featureLink: '/rental-estimate',
    badgeType: '',
    icon: { src: 'FeatureRentEstimates', alt: 'Rent Estimate' },
    featureName: 'rent estimate',
    featureId: 'rent_estimate',
  },
  {
    title: 'Renters Insurance',
    description: 'Invite tenants to purchase renters insurance.',
    sampleReportLink: '',
    learnMoreLink: 'https://www.rentspree.com/renters-insurance/',
    featureLink: '/renter-insurance',
    badgeType: '',
    icon: { src: 'FeatureRentersInsurance', alt: 'Renters Insurance' },
    featureName: 'renters insurance',
    featureId: 'renters_insurance',
  },
  {
    title: 'Messages',
    description: 'Converse with other agents, landlords, and applicants.',
    sampleReportLink: '',
    featureLink: '/messaging',
    badgeType: '',
    icon: { src: 'MenuMessages', alt: 'Messages' },
    featureName: 'messages',
    featureId: 'messages',
  },
]

export const PRO_DASHBOARD_URL = `${MAIN_URL}/dashboard/v2/rentspree-pro/`
export const CHECKOUT_URL = `${MAIN_URL}/account/profile/subscription/checkout`

export const SUGGEST_FEATURE = {
  title: 'Suggest a Feature',
  description: 'Got an idea that you would like to share? Let us know.',
  icon: { src: 'feature-suggest-feature', alt: 'Suggest a Feature' },
}

export const BUTTON_TYPE = {
  FEATURE: 'feature',
  LEARN_MORE: 'learnMore',
  SAMPLE_REPORT: 'sampleReport',
}

export const GET_USER_SUBSCRIPTION_API_URL = `${API_URL}/api/v2/subscriptions`

export const HELP_CENTER_URL = 'https://support.rentspree.com/en'
