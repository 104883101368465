import { object, bool } from 'yup'

import { validateDate, validateMaxEndDate } from 'legacy/constants/validations'

import {
  RECENT_CONTACT_RADIO_CHOICES,
  MIN_MONTHLY_INCOME_RANGE,
  MAX_MONTHLY_INCOME_RANGE,
} from '../../organisms/filter-form/constants'

import { isContaining } from './helpers'

export const initialValue = {
  role: {
    none: false,
    applicant: false,
    tenant: false,
    agent: false,
    pm: false,
    landlord: false,
    vendor: false,
  },
  source: {
    rentspree: false,
    application: false,
    'listing-syndication': false,
    'property-detail': false,
    'agent-profile': false,
    csv: false,
    'e-sign': false,
  },
  dateOfBirthRange: {
    start: '',
    end: '',
  },
  location: '',
  monthlyIncomeRange: {
    min: MIN_MONTHLY_INCOME_RANGE,
    max: MAX_MONTHLY_INCOME_RANGE,
  },
  recentContact: RECENT_CONTACT_RADIO_CHOICES[0].value,
}

export const schema = ({ filters }) => ({
  mapper: {
    role: {
      none: isContaining(filters?.role, 'none'),
      applicant: isContaining(filters?.role, 'applicant'),
      tenant: isContaining(filters?.role, 'tenant'),
      agent: isContaining(filters?.role, 'agent'),
      pm: isContaining(filters?.role, 'pm'),
      landlord: isContaining(filters?.role, 'landlord'),
      vendor: isContaining(filters?.role, 'vendor'),
    },
    source: {
      rentspree: isContaining(filters?.source, 'rentspree'),
      application: isContaining(filters?.source, 'application'),
      'listing-syndication': isContaining(filters?.source, 'listing-syndication'),
      'property-detail': isContaining(filters?.source, 'property-detail'),
      'agent-profile': isContaining(filters?.source, 'agent-profile'),
      csv: isContaining(filters?.source, 'csv'),
      'e-sign': isContaining(filters?.source, 'e-sign'),
    },
    dateOfBirthRange: {
      start: filters?.startBirthDate || '',
      end: filters?.endBirthDate || '',
    },
    location: filters?.state || '',
    monthlyIncomeRange: {
      min: parseInt(filters?.minIncome, 10) || MIN_MONTHLY_INCOME_RANGE,
      max: parseInt(filters?.maxIncome, 10) || MAX_MONTHLY_INCOME_RANGE,
    },
    recentContact: filters?.lastContact || RECENT_CONTACT_RADIO_CHOICES[0].value,
  },
  validation: object().shape({
    role: object().shape({
      none: bool(),
      applicant: bool(),
      tenant: bool(),
      agent: bool(),
      pm: bool(),
      landlord: bool(),
      vendor: bool(),
    }),
    dateOfBirthRange: object().shape({
      start: validateDate(false),
      end: validateMaxEndDate(false, 'start'),
    }),
  }),
})
