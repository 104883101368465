import { buildPath } from '@rentspree/path'
import moment from 'moment'

import { apiInstanceWithErrorHandler } from 'utils/api-interceptor'

import { CONFIRM_DRAFT_RENTAL_PAYMENT_API } from './constants'

export const confirmDraftRentalPaymentInstance = ({
  propertyId,
  rentalPaymentId,
  invitationDetail,
}) =>
  apiInstanceWithErrorHandler.post(
    buildPath(CONFIRM_DRAFT_RENTAL_PAYMENT_API, { propertyId, rentalPaymentId }),
    { timezone: moment.tz.guess(), invitationDetail },
  )
