import { Box } from '@mui/material'
import React, { useEffect } from 'react'

// import { AddLateFeeComponent } from 'v3/containers/overhaul-rent-payment/components/payment-forms/late-fee'
import { TellUsAboutPayment } from 'v3/containers/overhaul-rent-payment/components/payment-forms/tell-us-about-payment'
import { PaymentCategories } from 'v3/containers/overhaul-rent-payment/pages/payment-details/constants'
import { findPaymentCategory } from 'v3/containers/overhaul-rent-payment/pages/utils'

export const RentPaymentComponent = ({
  pageIndexes,
  _setDrawerOpen,
  _selectionOptions,
  _paymentSelections,
  _setPaymentSelections,
  payments,
  setPayments,
}) => {
  // assume for now we can only have one rent payment
  const [rentPaymentIndex, rentPayment] = findPaymentCategory(payments, PaymentCategories.RENT)
  const setRentPayment = newPayment => {
    const nextPayments = [...payments]
    if (rentPaymentIndex < 0) {
      nextPayments.push(newPayment)
    } else {
      nextPayments[rentPaymentIndex] = newPayment
    }
    setPayments(nextPayments)
  }

  useEffect(() => {
    // not ideal, but functional for now; prone to bugs, always causes 1 re-render (2+ if bugs), etc.
    if (rentPaymentIndex === -1) {
      setRentPayment(rentPayment)
    }
  }, [payments.length, rentPaymentIndex])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        textAlign: 'left',
      }}
    >
      <TellUsAboutPayment
        payment={rentPayment}
        setPayment={setRentPayment}
        pageIndexes={pageIndexes}
      />
      {/* <AddLateFeeComponent
        rentPayment={rentPayment}
        payments={payments}
        setPayments={setPayments}
        setDrawerOpen={_setDrawerOpen}
      /> */}
    </Box>
  )
}
