import { ACTIVITY_TEMPLATES } from 'containers/contacts/activities/constants'
import { TEMPLATES as TASK_TEMPLATES } from 'containers/contacts/tasks/constants'
export const withRP = event => `Rent Payment LL - ${event}`

export const EVENT_ACCEPT_DENY = {
  // TODO: move view report to EVENT_REPORT?
  viewReport: 'View report',
  openAcceptDenyModal: 'Click accept/deny button',
  clickAcceptMenu: 'Click accept menu',
  clickAcceptOnConditionMenu: 'Click accept with condition menu',
  clickDenyMenu: 'Click deny menu',
  submitAcceptCondition: 'Submit accept condition',
  submitDenyReason: 'Submit deny reason',
  sendWelcomeEmail: 'Send welcome email',
  sendConditionEmail: 'Send condition email',
  sendDenialEmail: 'Send denial email',
  agreeDisclaimer: 'Agree on disclaimer',
  closeAcceptDenyModal: 'Close accept/deny modal',
  reSendEmail: 'Re-send accept/deny email',
  changeStatus: 'Change accept/deny status',
  closeAcceptedModal: 'Close accept-deny email sent modal',
  CLICK_FROM: {
    ASSIGN_PROPERTY: 'assign property',
  },
}

export const EVENT_PEOPLE_CONCEPT = {
  ENTER_CONTACT_PAGE: 'Enter contacts page',
  EVENT_NAME: {
    CLICK_OPEN_FILTER: 'Contact - Click open filter',
    CLICK_CLOSE_FILTER: 'Contact - Close filter modal',
    CLICK_CLEAR_FILTER: 'Contact - Click clear filter',
    CLICK_APPLY_FILTER: 'Contact - Apply filter',
    CLICK_APPLY_UPCOMING_FILTER_EVENT_TYPE: 'apply upcoming event type filter',
    CLICK_APPLY_UPCOMING_FILTER_DATE: 'apply upcoming event date filter',
    CLICK_APPLY_UPCOMING_FILTER_EVENT_STARRED: 'apply upcoming event starred filter',
    ENTER_CONTACTS_MENU: 'Enter contacts menu',
    CLOSE_CREATED_CONTACT_MODAL: 'Contact - Close create contact modal',
    CREATE_CONTACT_SUCCESSFULLY: 'Contact - Create contact successfully',
    CLICK_CREATE_CONTACT: 'Contact - Click create contact',
    CLICK_TO_ADD_TAG_TO_CONTACT: 'Contact - Click to add tag to contact',
    ADDED_TAG_SUCCESS: 'Contact - Added tag success',
    FILTER_CONTACT_TAG: 'Contact - Filter contact tag',
    REMOVE_SINGLE_TAG_FROM_CONTACT: 'Contact - Remove single tag from contact',
    ENTER_CONTACT_DETAIL: 'Enter contact detail',
    CONTACT_DETAIL_NAME_SECTION: 'Contact detail - Name section',
    CONTACT_DETAIL_CONTACT_SECTION: 'Contact detail - Contact section',
    CONTACT_DETAIL_NOTE_ACTION: 'Contact detail - Note section',
    CONTACT_DETAIL_INFORMATION_ACTION: 'Contact detail - Information section',
    CONTACT_DETAIL_ADDRESS_SECTION: 'Contact detail - Address section',
    CONTACT_DETAIL_CAREER_SECTION: 'Contact detail - Career section',
    CONTACT_DETAIL_CLICK_DELETE_CONTACT: 'Contact detail - Click delete contact',
    CONTACT_DETAIL_CONFIRM_DELETE_CONTACT: 'Contact detail - Confirm delete contact',
    CONTACT_DETAIL_CANCEL_DELETE_CONTACT: 'Contact detail - Cancel delete contact',
    CONTACT_DETAIL_CLOSE_DELETE_CONTACT: 'Contact detail - Close delete contact modal',
    CONTACT_DETAIL_TASK_ACTION: 'Contact detail - Task section',
    CONTACT_DETAIL_DOCUMENT_SECTION: 'Contact detail - Document Section',
    VIEW_CONTACT_DASHBOARD: 'Contact - View contact dashboard',
    VIEW_EMPTY_CONTACT_DASHBOARD: 'Contact - View empty contact landing page',
    CLICK_VIEW_CONTACT_PROFILE: 'Contact - Click view contact profile',
    MARK_STAR_ON_CONTACT: 'Contact - Mark star on contact',
    UNSTARRED_CONTACT: 'Contact - Unstarred contact',
    GO_BACK_TO_ALL_CONTACTS_FROM_STARRED: 'Contact -  Go back to all contacts from starred',
    START_SEARCH_CONTACT: 'Contact - Start search contact',
    SEARCH_SUCCESS: 'Contact - Seach success',
    START_GETTING_NEW_CONTACT: 'Contact - Start getting new contact',
    CLICK_VIEW_CONTACT_GROUPS: 'Contact - Click view contact groups',
    BULK_DELETE_START: 'Contact - Start bulk delete',
    BULK_DELETE_CANCEL: 'Contact - Cancel bulk delete',
    BULK_DELETE_DESELECT_ALL: 'Contact - Deselect all selections',
    BULK_DELETE_SELECTED_CONTACT: 'Contact - Delete selected contacts',
    BULK_DELETE_CANCEL_CONFIRM_BOX: 'Contact - Cancel deletion',
    BULK_DELETE_CONFIRM_CONFIRMATION_BOX: 'Contact - Confirm bulk delete',
    BULK_DELETE_SUCCESS: 'Contact - Contacts deleted successfully',
    IMPORT_CONTACTS_FROM_SPREADSHEET: 'Contact - Import contacts from spreadsheet',
    START_SCREENING: 'Start screening',
    ENTER_IMPORT_CSV_PAGE: 'Contact - Enter import csv page',
    OPEN_CSV_GUIDE_BLOG: 'Contact - Open import contact csv guide blog',
    SELECT_CSV_FILE: 'Contact - Select contact csv file',
    ERROR_CSV_FILE: 'Contact - Error upload csv file',
    CSV_BACK_TO_DASHBOARD: 'Contact - Csv back to dashboard',
    REMOVE_CSV_FILE: 'Contact - Remove csv file',
    CLICK_IMPORT_CSV: 'Contact - Click import contact csv file',
    ENTER_MATCHING_CONTENT_TABLE_PAGE: 'Contact - Enter matching content table page',
    REMOVE_COLUMN: 'Contact - Remove column',
    UNDO_REMOVE_COLUMN: 'Contact - Undo remove column',
    SET_COLUMN_NAME: 'Contact - Select column name',
    REMOVE_ROW: 'Contact - Remove row',
    UNDO_REMOVE_ROW: 'Contact - Undo remove row',
    ERROR_MATCHING: 'Contact - Error email matching table panel',
    CONFIRM_IMPORT: 'Contact - Confirm import from csv',
    MINIMIZE_WIDGET: 'Contact - Minimize import progress widget',
    MAXIMIZE_WIDGET: 'Contact - Maximize import progress widget',
    CANCEL_IMPORT: 'Contact - Click cancel import from spreadsheeet widget',
    CONTINUE_IMPORT: 'Contact - Click continue import contact csv widget',
    CONFIRM_CANCEL_IMPORT: 'Contact - Confirm cancelling import contact csv widget',
    VIEW_ALL_CONTACTS: 'Contact - View all contacts widget',
    CLOSE_WIDGET: 'Contact - Close progress widget',
    IMPORT_SUCCESSFULLY: 'Contact - Import contacts from spreadsheet successfully',
    CLICK_ADDED_CONTACT_DROPDOWN_BUTTON_REPORT:
      'Contact - Click Added contact dropdown button on report',
    VIEW_EDIT_CONTACT_REPORT: 'Contact - Click View/Edit Contact on report',
    DONT_SAVE_THIS_PERSON: "Contact - Click Don't save this person on report",
    ADD_TO_CONTACT_REPORT: 'Contact - Click Add to Contacts on report',
    CLICK_EDIT_TAG: 'Contact - Click on edit tag',
    CLOSE_EDIT_TAG_MODAL: 'Contact - Close edit tag modal',
    CLICK_DELETE_TAG_ON_MODAL: 'Contact - Click delete tag on edit tag modal',
    CONFIRM_DELETE_TAG: 'Contact - Confirm to delete tag',
    CANCEL_DELETE_TAG: 'Contact - Cancel delete tag',
    CLOSE_DELETE_TAG_CONFIRMATION: 'Contact - Close delete tag confirmation',
    CLICK_SORT_COLUMN: 'Contact - Click sort column',
    BULK_ADDED_TAG_SUCCESS: 'Contact - bulk added tag success',
    BULK_ADD_TAG_START: 'Contact - Start bulk add tag',
    BULK_ADD_TAG_CANCEL: 'Contact - Cancel bulk add tag',
    BULK_REQUEST_APPLICATION: 'click multiple Send rental applications',
    CHOOSE_GIFT: 'choose gift',
    SEND_CONGRAT_MESSAGE: 'send congratulated message',
    CLICK_SUGGEST_GIFT: 'click suggest gift',
    SUBMIT_GIFT_SUGGESTION: 'submit gift suggestion',
    CANCEL_GIFT_SUGGESTION: 'cancel gift suggestion',
    CLOSE_GIFT_SELECTED_POP_UP: 'close gift selected pop-up',
    BACK_TO_GIFT_OPTIONS: 'back to gift options',
    START_SENDING_GIFT: 'start sending gift',
    START_SENDING_MESSAGE: 'start sending message',
    MARK_AS_SENT: 'mark as sent',
    CLICK_SEND_ANOTHER_GIFT: 'click send another gift',
    UNMARK_AS_SENT: 'unmark as sent',
    COPY_CONTACT_EMAIL: 'copy contact email',
    COPY_CONTACT_PHONE_NUMBER: 'copy contact phone number',
    CLICK_OPEN_AMAZON_LINK: 'Click open amazon link',
    READ_MORE_AFFILIATE_DISCLOSURE: 'read more affiliate disclosure',
    HIDE_AFFILIATE_DISCLOSURE: 'hide affiliate disclosure',
  },
  EVENT_PROPERTY: {
    CLICK_FROM: {
      CONTACTS_ALL: 'contacts_all',
      CONTACTS_ALL_MORE: 'contacts_all_more',
      CONTACTS_PROFILE: 'contacts_profile',
      CONTACTS_LANDING: 'contacts_landing',
      CONTACTS_CREATE_MODAL: 'contacts_createmodal',
      CONTACTS_STARRED: 'contacts_starred',
      CONTACTS_RECENT_ACTIVITY: 'contacts_recentactivity',
      CONTACTS_FROM_RENTSPREE: 'contacts_fromrentspree',
      CONTACTS_UPCOMING_EVENT: 'contacts_upcomingevent',
      CONTACTS_BULKADDTAG: 'contacts_bulkaddtag',
      CONTACT_DETAIL: 'contact_detail',
      CONTACT_DASHBOARD: 'contact_dashboard',
      UPCOMING_EVENTS: 'upcoming_events',
      GIFT_SELECTED_POPUP: 'gift_selected_popup',
    },
    SUCCESS_TAG_BY: {
      CREATE_NEW_TAG: 'create_newtag',
      CHOOSE_EXISTING_TAG: 'choose_exisitingtag',
    },
    ACTION: {
      CLICK_ADD_NAME: 'click_add_name',
      CONTACT_DETAIL_ADDED_NAME: 'contact_detail_added_name',
      CHANGE_ROLE: 'change_role',
      COPIES_EMAIL_ADDRESS: 'copied_email_address',
      CLICK_ADD_MOBILE_NUMBER: 'click_add_mobile_number',
      CLICK_ADD_OFFICE_NUMBER: 'click_add_office_number',
      CLICK_ADD_HOME_NUMBER: 'click_add_home_number',
      UPDATED_MOBILE_NUMBER: 'updated_mobile_number',
      ADDED_OFFICE_NUMBER: 'added_office_number',
      ADDED_HOME_NUMBER: 'added_home_number',
      COPIED_MOBILE_NUMBER: 'copied_mobile_number',
      COPIED_OFFICE_NUMBER: 'copied_office_number',
      COPIED_HOME_NUMBER: 'copied_home_number',
      COPIED_EMAIL_ADDRESS: 'copied_email_address',
      CLICK_NOTE_TAB: 'click_note_tab',
      CREATE_NEW_NOTE: 'created_new_note',
      CLICK_EDIT_NOTE: 'click_edit_note',
      EDITED_NOTE: 'edited_note',
      CANCEL_EDIT_NOTE: 'cancel_edit_note',
      CLICK_DELETE_NOTE: 'click_delete_note',
      DELETED_NOTE: 'deleted_note',
      CANCEL_DELETE_NOTE: 'cancel_delete_note',
      CLOSE_DELETE_MODAL: 'close_delete_modal',
      CLICK_NOTE: 'click_note',
      CLICK_TAG: 'click_tag',
      CLICK_CALL: 'click_call',
      CLICK_MESSAGE: 'click_message',
      CLICK_ADD_BIRTHDAY: 'click_add_birthday',
      CLICK_ADD_MONTHLY_INCOME: 'click_add_monthly_income',
      ADDED_BIRTHDAY: 'added_birthday',
      ADDED_MONTHLY_INCOME: 'added_monthly_income',
      CHANGE_WORK_STATUS: 'change_work_status',
      CLEARED_WORK_STATUS: 'cleared_work_status',
      EDITED_BIRTHDAY: 'edited_birthday',
      CLEARED_BIRTHDAY: 'cleared_birthday',
      EDITED_MONTHLY_INCOME: 'edited_monthly_income',
      CLEARED_MONTHLY_INCOME: 'cleared_monthly_income',
      CLICK_ADD_MOVEIN_DATE: 'click_add_movein_date',
      ADDED_MOVEIN_DATE: 'added_movein_date',
      EDITED_MOVEIN_DATE: 'edited_movein_date',
      CLEARED_MOVEIN_DATE: 'cleared_movein_date',
      CLICK_ADD_COMPANY_INFO: 'click_add_company_info',
      CLICK_EDIT_COMPANY_INFO: 'click_edit_company_info',
      ADDED_COMPANY_INFO: 'added_company_info',
      EDITED_COMPANY_INFO: 'edited_company_info',
      CLICK_ADD_RESIDENTIAL_INFO: 'click_add_residential_info',
      CLICK_EDIT_RESIDENTIAL_INFO: 'click_edit_residential_info',
      ADDED_RESIDENTIAL_INFO: 'added_residential_info',
      EDITED_RESIDENTIAL_INFO: 'edited_residential_info',
    },
    FROM_PAGE: {
      CONTACT: 'contact',
      CONTACT_CSV_UPLOAD: 'contact_csv_upload',
      CONTACT_CSV_TABLE: 'contact_csv_table',
    },
    CSV_WIDGET_STATUS: {
      CSV_CANCEL: 'csv_cancel',
      CSV_COMPLETE: 'csv_complete',
    },
    SORT_VALUE: {
      name: 'name',
      email: 'email',
      mobileNumber: 'mobile',
      lastContactedAt: 'latestcontact',
      role: 'role',
    },
    CATEGORY: {
      PEOPLE_CONCEPT: 'People Concept (Rental Client Manager)',
    },
    QUICK_FILTER: {
      ALL_EVENTS: 'all_events',
      BIRTHDAYS: 'birthdays',
      MOVE_IN_ANNIVERSARY: 'move_in_anniversary',
      MOVING_IN: 'moving_in',
      ALL_DATE: 'all_date',
      TODAY: 'today',
      SEVEN_DAYS: 'within_7_days',
      THIRTY_DAYS: 'within_30_days',
      STARRED: 'starred',
    },
    EVENT_TYPE: {
      BIRTHDAY: 'birthday',
      MOVE_IN_ANNIVERSARY: 'move_in_anniversary',
      MOVING_IN: 'move_in',
    },
    IGNORE_REDIRECT_LINK_TAB: false,
    SECTION: {
      CONTACT_SECTION: 'contact_section',
      ADDRESS_SECTION: 'address_section',
      CAREER_SECTION: 'career_section',
      CLIENT_INFORMATION: 'client_information',
      DOCUMENT_SECTION: 'document_section',
    },
  },
}

export const EVENT_TENANT_SCREENING = {
  EVENT_NAME: {
    ENTER_TENANT_SCREENING_MENU: 'Enter Tenant Screening Menu',
    ENTER_ASSIGN_PROPERTY_PAGE: 'Start create or select property to application',
    SELECT_PROPERTY: 'Tenant Screening - Select property',
    SWITCH_TO_ADD_NEW_PROPERTY: 'Tenant Screening - Switch to add new property',
    SWITCH_TO_SELECT_PROPERTY: 'Tenant Screening - Switch to select property',
    CREATE_NEW_PROPERTY: 'Tenant Screening - Save new listing',
    ADD_PROPERTY_LATER: 'Tenant Screening - Add property later',
    CLICK_GO_TO_LISTING_FROM_SCREENING_PAGE: 'click go to listing on options to screen page',
    SELECT_AGENT_TYPE: 'select property representative',
    START_SCREENING_REQUEST: 'start screening request',
    PROCEED_THROUGH_PROPERTY_SELECTION: 'proceed through property selection',
    ASSIGN_PROPERTY: 'Assign Property',
    SELECT_APPLICATION_PAYER: 'select application payer',
    SELECT_SCREENING_BUNDLE_TYPE: 'select screening bundle type',
    SELECT_APPLICATION_TYPE: 'select application type',
    CONFIRM_SCREENING_OPTION: 'confirm screening option',
    COMPARE_SCREENING_BUNDLE: 'compare screening bundle',
    UPDATE_PROPERTY_INFO: 'update property info',
    CLICK_TAKE_ACTION: 'click take action',
  },
  EVENT_PROPERTY: {
    CLICK_FROM: {
      MENU_SCREENING: 'menu_screening',
      MENU_SCREENING_TS: 'menu_screening_ts',
      MENU_SCREENING_LISTING: 'menu_screening_listing',
      TENANT_SCREENING_MENU: 'tenant screening menu',
      TENANT_SCREENING_WIDGET: 'ts_widget',
      CREATE_SCREENING_REQUEST_ROLE_SELECTION: 'create screening request - role selection',
      CREATE_SCREENING_REQUEST_PROPERTY_SELECTION: 'create screening request - property selection',
      HOME: 'home',
      START_VIEW_REPORT: 'start_view_report',
      EMAIL_APPLICATION_PACKAGE: 'email_application_package',
      PRO_REF_CHECK: 'pro_ref_check',
      PRO_DOC_UPLOAD: 'pro_doc_upload',
      HOME_UNREAD_LINK: 'home_unread_link',
      HOME_NEED_DECISION_LINK: 'home_need_decision_link',
      TO_DO_LIST: 'to_do_list',
      GET_STARTED: 'get_started',
      AGT_WELCOME_MODAL: 'agt_welcome_modal',
      SHARE_REPORT_MODAL: 'share_report_modal',
    },
    PROPERTY_REPRESENTATIVE_TYPE: {
      LANDLORD_OR_LISTING: 'Landlord or Listing',
      RENTER: 'Renter',
      BOTH: 'Both (Landlord or Listing and Renter)',
    },
    IS_DUPLICATE_ADDRESS: 'is_duplicate_address',
    PROPERTY_MODE: {
      SCREENING_WITHOUT_PROPERTY: 'screening without property',
      EXISTING_PROPERTY: 'existing property',
      NEW_PROPERTY: 'new property',
    },
    APPLICATION_PAYER: {
      APPLICANTS: 'applicants',
      AGENT: 'agent',
    },
    BUNDLE_TYPE: {
      PREMIUM: 'premium',
      STANDARD: 'standard',
      APPLICATION_ONLY: 'application_only',
    },
    APPLICATION_TYPE: {
      CAR_LRA: 'car_lra',
      STANDARD_RENTAL_APPLICATION: 'standard_rental_application',
      NO_APPLICATION: 'no_application',
    },
    REPRESENTING: 'representing',
    HAS_LANDLORD_EMAIL: 'has_landlord_email',
    VIEW_ON: {
      SCREENING_LANDING_PAGE: 'screening_landing_page',
    },
  },
}

export const EVENT_INCOME_VERIFICATION = {
  EVENT_NAME: {
    VIEW_INCOME_VERI_MODAL: 'view_income_veri_modal',
    CLICK_INCOME_VERI_MODAL: 'click_income_veri_modal',
  },
  EVENT_PROPERTY: {
    VIEW_SAMPLE_INCOME_REPORT: 'view_sample_income_report',
    GET_REPORT: 'get_report',
    CLOSE_MODAL: 'close_modal',
  },
}

export const EVENT_REPORT = {
  clickViewReport: 'Click view report',
  switchReportView: 'Switch report view',
  printReport: 'Print Report',
  saveReportPDF: 'Save Report as PDF',
  getCarRental: 'Get CAR rental app',
  exportCarRental: 'Export CAR rental app',
  submitReportSuccess: 'Submit Report Success',
  submitReportFail: 'Submit Report Fail',
  clickToViewApplicantInfo: 'click to view applicant information',
  clickEditReportSelection: 'click edit report selection',
  saveReportSelection: 'save report selection',
}
export const EVENT_PROPERTY = {
  EVENT_NAME: {
    SELECT_PROPERTY_TYPE: 'Successfully select a property type',
  },
  EVENT_PROPERTY: {
    PROPERTY_TYPE: 'property_type',
  },
}

export const EVENT_MAILING_ADDRESS = {
  enterMailingAddressPage: 'enter verify mailing address page',
  saveMailingAddress: 'Complete Mailing Address',
  viewModal: 'View mailing address modal',
  saveFromModal: 'Complete mailing address modal',
}

export const EVENT_REQUEST_SCREENING = {
  clickStartScreening: {
    name: 'Add New Property',
    properties: {
      popup: {
        click_from: 'popup',
      },
      leftMenu: {
        click_from: 'left_menu',
      },
      centerScreen: {
        click_from: 'center_screen',
      },
      mobileNavbar: {
        click_from: 'mobile_navbar',
      },
      howToPage: {
        click_from: 'how_to',
      },
      listingAction: {
        click_from: 'page_action',
      },
      getStarted: {
        click_from: 'get_started',
      },
    },
  },
  saveNewProperty: 'Save New Listing',
  completePropertyInformation: 'Complete Property Information',
  backToDashboardFromPropertyInformation: 'Back to Dashboard from Property Information',
  selectScreening: 'Select Screening Options',
  viewScreeningRestriction: 'view screening restriction',
  sendRequestEmail: {
    name: 'Send Request',
    properties: {
      email: {
        type: 'email',
      },
      textMessage: {
        type: 'text_message',
      },
    },
  },
  copyLeaseLink: 'Copy LeaseLink',
  printHandout: 'Print Screening Handout',
  updateHandoutMessage: 'change handout message',
  ENTER_SCREENING_MENU: 'Enter screening menu',
  CLICK_SCREENING_MENU: 'Click screening menu',
  SHARE_APPLY_LINK: 'share ApplyLink',
  EVENT_PROPERTY: {
    SHARING_METHOD: {
      HANDOUT: 'handout',
      COPY_LINK: 'copy link',
      SEND_EMAIL: 'send email',
      SEND_SMS: 'send sms',
    },
  },
  CLICK_FROM: {
    createScreening: 'create screening request - screening options',
  },
  customizeDocumentRequest: {
    name: 'view customize Document request',
    properties: ({ clickFrom }) => ({
      click_from: clickFrom,
    }),
    propValues: {
      clickFrom: {
        selectOptionPage: 'select_option_page',
      },
    },
  },
}

export const EVENT_PAY_REPORT = {
  viewPaymentModal: 'View payment modal',
  payReportSuccess: 'Pay report success',
  payReportFail: 'Pay report fail',
  viewOpenReportModal: 'View Open report modal',
  openReportSuccess: 'Open report success',
  openReportFail: 'Open report fail',
}

export const EVENT_DOWNLOAD_OTHER_DOCS = {
  DOWNLOAD_FULL_DOCS: {
    eventName: 'Download all other docs',
    properties: ({ email, rentalId }) => ({
      applicant: email,
      rental_id: rentalId,
    }),
  },
}

export const EVENT_RENTSPREE_PRO = {
  GET_RENTSPREE_PRO: {
    eventName: 'Get RentSpree Pro',
    properties: tab => ({
      click_from: tab,
    }),
  },
  REF_CHECK: {
    eventName: 'Request Ref Checks',
    properties: ({ email, rentalAppId, type }) => ({
      applicant: email,
      rental_id: rentalAppId,
      reference: type,
    }),
  },
  OTHER_DOCS: {
    eventName: {
      request: 'Request other docs',
      download: 'Download other docs',
    },
    properties: ({ email, rentalAppId, type }) => ({
      applicant: email,
      rental_id: rentalAppId,
      doc: type,
    }),
    mapping: {
      paystubs: 'proof_income',
      bank_statement: 'bank_statements',
      tax_return: 'tax_returns',
      w2s: 'w-2s',
      passport: 'government_issued_id',
      pets: 'photo_each_pet',
      pet_application: 'pet_application',
      other: 'others',
    },
  },
  REF_CHECK_TAB: 'Click ref checks tab',
  OTHER_DOCS_TAB: 'Click other docs tab',
}

export const INCOMING_FEATURE = {
  LISTING_SYNDICATION: 'listing syndication',
  LEASE_AGREEMENT: 'lease agreement',
  RENT_PAYMENT: 'rent collection',
}

export const EVENT_INCOMING_FEATURE = {
  NOTIFY_FEATURE: {
    name: 'Notified a feature',
    mapping: INCOMING_FEATURE,
  },
  UN_NOTIFY_FEATURE: {
    name: 'Notified cancelation a feature',
    mapping: INCOMING_FEATURE,
  },
  CLICK_UPCOMING_FEATURE: {
    name: 'Click upcoming feature',
    mapping: INCOMING_FEATURE,
  },
  EXPAND_SIDEBAR: {
    name: 'Expand main menu',
  },
  COLLAPSE_SIDEBAR: {
    name: 'Collapse main menu',
  },
  CLICK_SUGGEST_FEATURE: {
    name: 'Click suggest a feature',
  },
  SUBMIT_SUGGEST_FEATURE: {
    name: 'Submit suggest a feature',
  },
  CLICK_EXPLORE_PAGE: {
    name: 'Enter explore page',
  },
  CLICK_WATCH_VIDEO: {
    name: 'Watch video in explore page',
  },
  CLICK_READ_MORE: {
    name: 'Click read more in explore page',
  },
  CLICK_SAMPLE_REPORT: {
    name: 'See Sample report in explore page',
  },
}

export const GET_START_MODAL = {
  step: [
    {
      key: 'tenant_screening',
      name: 'Acknowledge tenant screening modal',
      title: 'Tenant Screening',
      property: 'tenant screening',
    },
    {
      key: 'rental_document',
      name: 'Acknowledge e-sign modal',
      title: 'E-Sign Documents',
      property: 'e-sign documents',
    },
    {
      key: 'rent_estimate',
      name: 'Acknowledge rent estimate modal',
      title: 'Rent Estimate',
      property: 'rent estimation',
    },
    {
      key: 'document_upload',
      name: 'Acknowledge other docs modal',
      title: 'Document Upload',
      property: 'other docs',
    },
    {
      key: 'ref_check',
      name: 'Acknowledge ref check modal',
      title: 'Auto reference checks',
      property: 'ref checks',
    },
    {
      key: 'accept_deny',
      name: 'Acknowledge accept/deny modal',
      title: 'Accept/Deny',
      property: 'accept/deny',
    },
  ],
  VIEW_ALL: {
    name: 'View all get start modal',
  },
  SKIP: {
    name: 'Skip get start modal',
  },
  CLICK_SUGGEST_FEATURE: {
    name: 'Click suggest a feature',
  },
  SUBMIT_SUGGEST_FEATURE: {
    name: 'Submit suggest a feature',
  },

  CLICK_EXPLORE_PAGE: {
    name: 'Enter explore page',
  },
  CLICK_WATCH_VIDEO: {
    name: 'Watch video in explore page',
  },
  CLICK_READ_MORE: {
    name: 'Click read more in explore page',
  },
  CLICK_SAMPLE_REPORT: {
    name: 'See Sample report in explore page',
  },
}

export const MAPPING_EXPLORE_FEATURE = {
  TENANT_SCREENING: 'tenant screening',
  RENT_ESTIMATE: 'rent estimation',
  OTHER_DOCS: 'other docs',
  REF_CHECK: 'ref checks',
  ACCEPT_DENY: 'accept/deny',
  MESSAGING: 'messaging',
  SHARE_REPORT: 'share report',
  ESIGN_DOCUMENTS: 'e-sign',
  RENTER_INSURANCE: 'renters insurance',
}

export const ZIPLOGIX_TRACKING = {
  SWITCH_CREATE_PROPERTY: 'Switch to create property',
  SWITCH_SELECT_PROPERTY: 'Switch to select property',
  FAIL_UPDATE_PROPERTY: 'Fail to update property',
  UPDATED_PROPERTY: 'Update property',
  SELECT_WRONG_VALIDATION: 'Select wrong validation property',
}

export const SHARE_MS_REPORT = {
  startShare: 'share report - MS applicant - view report',
  shareBeforePrint: 'share report - MS applicant - view report - print',
  shareBeforeDownload: 'share report - MS applicant - view report - save report as PDF download',
  printAnyway: 'print report - MS applicant',
  downloadAnyway: 'save report as PDF - MS applicant',
}

export const SHARE_REPORT = {
  startShare: 'Share Report - Agent start share report',
  clickNext: 'Share Report - Click next share report',
  shareSuccess: 'Share Report - Share report success',
  shareFail: 'Share Report - Share report fail',
  startShareViaAutoPrompt: 'Share Report - Auto-Prompt Modal',
  closeShareAutoPrompt: 'Share Report - Close Auto-Prompt Modal',
}

export const SHARE_REPORT_PROPERTIES = {
  rentalId: 'rental_id',
  clickFrom: 'click_from',
  position: 'position',
  landlordAddedToProperty: 'll_added_to_property',
}

export const FEATURE_NOTICE = {
  SKIP_FEATURE_NOTICE: {
    name: 'Skip feature notice modal',
    properties: ({ feature }) => ({
      feature,
    }),
    mapper: {
      'Accept/Deny': 'accept/deny',
      'Reference Checks [PRO]': 'ref checks',
      'Other Docs [PRO]': 'other docs',
    },
  },
  VIEW_ALL_FEATURE_NOTICE: 'View all feature notice',
  MAP_EVENT_NAME: {
    'Rent Estimate': 'Acknowledge rent estimate notice',
    'Explore RentSpree': 'Acknowledge explore rentspree notice',
    'Accept/Deny': 'Acknowledge accept/deny notice',
    'Reference Checks [PRO]': 'Acknowledge ref check notice',
    'Other Docs [PRO]': 'Acknowledge other docs notice',
    Messages: 'Acknowledge messaging tool notice',
    'Share Reports': 'Acknowledge share report notice',
    'E-Sign Documents': 'Acknowledge e-sign notice',
    'Drag & Drop': 'Acknowledge e-sign drag-drop notice',
    'Assign Recipient': 'Acknowledge e-sign assign recipient notice',
    'Renters Insurance': 'Acknowledge Renters Insurance Notice - Later',
    'Renters Insurance_insuranceCaseASidebar': 'Acknowledge Renters Insurance Notice - Go now',
    'Renters Insurance_insuranceCaseBListingMenu': 'Acknowledge Renters Insurance Notice - Go now',
    'Create Agent Profile': 'Acknowledge Agent Branding Notice',
    'Create your first listing_listingMenu': 'Click get started create listing flow',
    'Create your first listing': 'Skip listing creation flow from feature notice',
    'Contact auto-saved': 'Acknowledge add-to-contact notice',
  },
}

export const DELAY_CRIMINAL_MODAL = {
  SEE_CRIMINAL_DISCLAIMER: 'See Criminal Disclaimer',
  CONFIRM_CRIMINAL_DISCLAIMER: {
    name: 'Confirm criminal disclaimer',
    properties: ({ dontAskMe }) => ({
      dont_ask_me: dontAskMe,
    }),
  },
}

export const EVENT_MESSAGING_TOOL = {
  ENTER_MESSAGES_PAGE: 'Enter messages page',
  START_CONVERSATION: 'Start conversation',
  SEND_MESSAGE: 'Send message',
}

export const EVENT_ACTION_DROPDOWN = {
  SCREEN_FROM_CURRENT_PROPERTY: 'Screen from Current Property',
  // TODO: wow-1391 remove this ?
  DEACTIVATE_PROPERTY: 'Deactivate Property',
  REACTIVATE_PROPERTY: 'Reactivate Property',
}

export const EVENT_ACTION_DROPDOWN_CLICK_FROM = {
  APPLICATION_LIST: 'application_list',
  PROPERTY_LIST: 'property_list',
  APPLICATION_LIST_LANDING: 'application_list_landing',
}

export const ENVELOPE_UPLOAD_FILES = {
  SWITCH_FILE: 'Switch file order in lease doc',
  UPLOAD_FILES_SUCCESS: 'Upload file to lease doc',
  UPLOAD_FILES_FAILED: 'Upload file to lease doc failed',
  FINISH_UPDATE_FILES: 'Finish upload file to lease doc',
  REMOVE_ALL_FILES: 'Delete all files from uploaded page',
  REMOVE_SOME_FILES: 'Delete file by list from uploaded page',
  REPLACE_FILE: 'Click re-upload file in invalied file type ',
  CLICK_CHECK_ENCRYPT: 'Click check encrypt in upload page',
}

export const ENVELOPE_UPLOAD_ERROR_TYPE = {
  ENCRYPTED_FILE: 'encrypted_file',
  EDITABLE_FILE: 'editable_file',
}

export const ENVELOPE_ACCESS_PERMISSION = {
  ACCESS_DENIED: 'Enter accese denied page',
}

export const LEASE_AGREEMENTS = {
  CLICK_LEASE_MENU: 'Click lease menu',
  ENTER_PAGE: 'Enter lease menu',
  CREATE_LEASE: 'Start create lease',
  CREATE_LEASE_SUCCESS: 'Create lease success',
  VIEW_LEASE_DETAIL: 'View lease',
  EDIT_LEASE_SUCCESS: 'Edit lease success',
  CREATE_ENVELOPE: 'Start create lease doc',
  VOIDED_ENVELOPE: 'Void lease doc',
  PLACE_COMPONENT: 'Place component on lease doc',
  ASSIGN_SIGNER: 'Assign signer to component on lease doc',
  DELETE_COMPONENT: 'Delete component from lease doc',
  FINISH_PREPARE_ENVELOPE: 'Finish prepare lease doc',
  FINISH_ADD_RECIPIENTS: 'Finish add recipient to lease doc',
  REQUEST_SHARE_ENVELOPE: 'Send e-sign request',
  COMPONENT_TYPES: {
    TEXT_BOX: 'text',
    SIGNATURE_SIGNS: 'signature',
    INITIAL_SIGNS: 'initial',
    DATE_SIGNS: 'date_signed',
    CHECK_BOX: 'checkbox',
  },
  ACCEPT_CONCENT: 'Accept e-sign consent',
  RESEND_E_SIGN: 'Resend e-sign request',
  FINISH_EDITING_RECIPIENT: 'Finish edit recipient',
  CLICK_FROM: {
    VIEW_REPORT: 'view_report',
    STICKY_FOOTER_ACCEPTED: 'sticky_footer_accepted',
    TO_DO_LIST: 'to_do_list',
  },
  DELETE_RECIPIENT: 'Delete recipient in lease doc',
  CLICK_FIT_TO_WIDTH: 'Click Fit to width',
  ENTER_REQUEST_NEW_LINK_PAGE: 'Enter request new link page',
  CLICK_REQUEST_NEW_LINK: 'Click request new link',
  CLICK_EDIT_RENTAL_INFORMATION: 'Click edit rental information',
}

export const SKIP_TYPE = {
  USER_SKIP: 'user_skip',
  NO_SKIP: 'no_skip',
  AUTO_SKIP: 'auto_skip',
}

export const DOC_STATUS_PROPERTIES = {
  SIGNING: { doc_status: 'signing' },
  REVIEW: { doc_status: 'review' },
  COMPLETED: { doc_status: 'completed' },
}

export const SIGNATURE_TYPE_PROPERTIES = {
  TYPE: { signature_type: 'type' },
  DRAW: { signature_type: 'draw' },
}

export const EVENT_LEASE_DOC_REVIEW_AND_SIGN = {
  ENTER_LEASE_DOC_VIEW: 'Enter lease doc view',
  PRINT_LEASE_DOC: 'Print lease doc',
  SAVE_LEASE_DOC: 'Save lease doc as PDF',
  ADOPT_SIGNATURE: 'Adopt signature in lease doc',
  SIGNATURE_PLACE_ON_DOC: 'Signature placed on lease doc',
  EDIT_SIGNATURE: 'Edit signature in lease doc',
  REPLACE_SIGNATURE: 'Replace signature in lease doc',
  FINISH_ESIGN: 'Finish e-sign lease doc',
}

export const EVENT_ROUTE = {
  ENTER_DASHBOARD: 'Enter Dashboard',
}

export const EVENT_LISTING_OVERVIEW = {
  ENTER_PAGE: 'Enter listing overview',
  EVENT_NAME: {
    CLICK_ADD_LANDLORD_DETAILS: 'click add landlord details',
    CLICK_EDIT_LANDLORD_DETAILS: 'click edit landlord details',
    CLICK_SAVE_LANDLORD_DETAILS: 'click save landlord details',
  },
  EVENT_PROPERTY: {
    CLICK_FROM: {
      ADD: 'add',
      EDIT: 'edit',
    },
  },
}

export const RENT_ESTIMATE = {
  viewSampleReport: 'View an sample rent estimate report',
  enterPage: 'Enter rent estimate menu',
  submitSuccess: 'Submit rent estimate request',
  purchaseSuccess: 'Purchase rent estimate report',
  DownloadSuccess: 'Download rent estimate report',
  generateRentEstimateFail: 'Generate rent estimate report fail',
}

export const ID_VERIFICATION = {
  enterPage: 'Acknowledge One-Time Validation',
  clickStart: 'Start One-Time Validation',
  clickStartIDV: 'click start one time validation IDV',
  acknowledgeVerificationPending: 'Acknowledge Validation pending',
  refreshVerificationPendingPage: 'Refresh in Validation pending',
  backToDashboardFromVerificationPending: 'back to dashboard from validation pending',
  openOneTimeValidationModal: 'open one-time validation modal',
  closeOneTimeValidationModal: 'close one-time validation modal',
  openValidationPendingModal: 'open validation pending modal',
  closeValidationPendingModal: 'close validation pending modal',
  backToDashboardFromValidationPendingModal: 'back to dashboard from validation pending modal',
}

export const RENTER_INSURANCE = {
  EVENT_NAME: {
    CLICK_INSURANCE_MENU: 'Click Renters Insurance menu from dashboard',
    ENTER_PAGE: 'Enter renters insurance menu',
    ENTER_ONBOARDING_MENU: 'Enter Renters Insurance onboarding menu',
    START_REQUEST_RENTERS_INSURANCE: 'Start request renters insurance',
    REQUEST_RENTERS_INSURANCE: 'Request renters insurance',
    VIEW_RENTERS_INSURANCE: 'View renters insurance',
    VIEW_UPLOAD_RENTER_INSURANCE: 'View uploaded renter insurance',
    RENTER_MORE_INSURANCE: 'Renters Insurance - Request more insurance',
    CREATE_NEW_PROPERTY: 'Renters Insurance - Save new listing',
    SELECT_PROPERTY: 'Renters Insurance - Select property',
    SWITCH_TO_SELECT_PROPERTY: 'Renters Insurance - Switch to select property',
    SWITCH_TO_ADD_NEW_PROPERTY: 'Renters Insurance - Switch to add new property',
  },
  EVENT_PROPERTY: {
    REQUEST_RENTERS_INSURANCE: 'request_renters_insurance',
    RENTERS_INSURANCE_MENU: 'renters_insurance_menu',
    RENTERS_INSURANCE_ONBOARDING_MENU: 'renters_insurance_onboarding_menu',
    RENTERS_INSURANCE_ONBOARDING_TOP: 'renters_insurance_onboarding_menu_top',
    RENTERS_INSURANCE_ONBOARDING_BOTTOM: 'renters_insurance_onboarding_menu_bottom',
    RENTERS_INSURANCE_LANDING_PAGE: 'renters_insurance_landing_page',
  },
}

export const LR_INTEGRATION = {
  clickNotify: '(EXP69 MVP1) Click notify LR form',
  clickPreview: '(EXP69 MVP1) Preview LR form sample',
  submitInteresting: '(EXP69 MVP1) Submit interesting LR form',
  closeInteresting: '(EXP69 MVP1) Close interesting LR form',
}

export const LR_INTEGRATION_CLICK_FROM = {
  LEASE_DETAIL: 'lease_details',
  LEASE_UPLOAD: 'upload_esign',
}

export const AGREEMENT = {
  enterPage: 'Enter agreement page',
  acceptAgreement: 'Accept tenant screening service agreement',
  backToDashboard: 'Back to dashboard from agreement page',
  agreeToAccept: 'Checkbox acceptance agreement',
  scrollToEnd: 'Scroll to end of agreement',
}

export const TOGGLE_PROPERTY_FEATURE = {
  openTogglePropertyFeatureModal: 'See disable/enable modal',
  confirmTogglePropertyFeature: 'Submit disable/enable modal',
  viewReport: 'View report via drop-down action',
}

export const ARCHIVE_PROPERTY = {
  archiveListing: 'Archive listing',
  unarchiveListing: 'Unarchive listing',
  selectFilter: 'Select filter',
}

export const LEASE_DOC_TEMPLATE_EVENT = {
  EVENT_NAME: {
    SELECT_TEMPLATE_TO_LEASE_DOC: 'Select template to lease doc',
    PREVIEW_LEASE_DOC_TEMPLATE: 'Preview lease doc template sample',
    SEE_NRDS_ID_POP_UP: 'See NRDS ID popup',
    VALIDATE_NRDS_ID: 'Validate NRDS ID',
    LOOK_UP_NRDS_ID: 'Lookup NRDS ID',
  },
  EVENT_PROPERTY: {
    CLICK_FROM: {
      LR_FORM: 'lr_form',
      CUSTOM: 'custom',
      GDRIVE_IMPORT: 'gdrive_import',
      TEMPLATE_SELECTION: 'template_selection',
    },
  },
}

export const RENT_PAYMENT = {
  EVENT_NAME: {
    CLICK_PAYMENT_MENU: 'Click Rent Payment menu from dashboard',
    SWITCH_TO_ADD_NEW_PROPERTY: 'Rent Payments - Switch to add new property',
    CLICK_PAYMENT_NOTIFY: 'Click notify Rent Payment',
    CLICK_PAYMENT_UNNOTIFY: 'Click unnotify Rent Payment',
    CLICK_PAYMENT_SURVEY: 'Click take a survey',
    CLICK_TAKE_A_SURVEY: withRP('Click take a survey'),
    LAND_ON_LANDING_PAGE: withRP('Land on landing page'),
    START_COLLECTING_RENT: withRP('Start collecting rent'),
    CREATE_FIRST_PROPERTY: withRP('Create first property'),
    SELECT_EXISTING_PROPERTY: withRP('Select existing property'),
    ADD_NEW_PROPERTY: withRP('Add new property'),
    SUBMIT_TENANT_INFO: withRP('Submit tenant info'),
    SUBMIT_PAYMENT_DETAILS: withRP('Submit payment details'),
    START_ADD_RECEIVING_ACCOUNT: withRP('Start add receiving account'),
    RECEIVING_ACCOUNT_ADDED: withRP('Receiving account added'),
    FAILED_TO_ADD_RECEIVING_ACCOUNT: withRP('Failed to add receiving account'),
    START_VERIFY_BANK_ACCOUNT: withRP('Start verify bank account'),
    VERIFY_BANK_ACCOUNT: withRP('Verify bank account'),
    VERIFY_BANK_ACCOUNT_FAILED: withRP('Verify bank account failed'),
    START_VERIFY_IDENTITY: withRP('Start verify identity'),
    FINISH_VERIFY_IDENTITY: withRP('Finish verify identity'),
    FAILED_TO_VERIFY_IDENTITY: withRP('Failed to verify identity'),
    SUBMIT_RECEIVING_ACCOUNT: withRP('Submit receiving account'),
    EDIT_TENANT_INFO: withRP('Edit tenent info'),
    BACK_TO_EDIT_PAYMENT_DETAILS: withRP('Back to edit payment details'),
    BACK_TO_EDIT_RECEIVING_ACCOUNT: withRP('Back to edit receiving account'),
    CONFIRM_PAYMENT_REQUEST: withRP('Confirm payment request'),
    END_PAYMENT: withRP('End payment'),
    REINVITE_TENANT: withRP('Reinvite tenant'),
    EDIT_TENANT: withRP('Edit tenant'),
    ACCEPT_TERMS_START_COLLECTING_RENT: withRP('Accept terms start collecting rent'),
    ENTER_RENT_PAYMENT_MENU: withRP('Enter rent payment menu'),
    CLOSE_LEASE_DOC_FOOTER: 'Close footer panel on view lease doc',
    FIX_RECEIVING_ACCOUNT: 'fix receiving account',
    RETRY_DEPOSIT_MONEY: 'retry deposit money',
    CLOSE_RETRY_DEPOSIT_MONEY_MODAL: 'close retry deposit money modal',
    CLICK_OTHER_QUESTIONS: 'click other questions',
    INQUIRE_RENT_PAYMENT: 'inquire rent payment',
    CHANGE_RECEIVING_ACCOUNT: 'change receiving account',
    SELECT_PAYMENT_TYPE: withRP('Select payment type'),
    EDIT_ONE_TIME_PAYMENT_DASHBOARD: withRP('Edit one-time payment - Dashboard'),
    DELETE_ONE_TIME_PAYMENT: withRP('Delete one-time payment - Dashboard'),
    ADD_MULTIPLE_PAYMENT: withRP('Add multiple payment'),
    EDIT_MULTIPLE_PAYMENT: withRP('Edit multiple payment'),
    DELETE_MULTIPLE_PAYMENT: withRP('Delete multiple payment'),
    ANNOUNCEMENT_PANEL: withRP('Announcement Panel'),
    WATCH_TUTORIAL: withRP('Click watch tutorial'),

    // UX Improvement
    CLICK_VERIFY_ID: 'Click verify ID',
    CLICK_FINISH_VERIFICATION: 'Click finish verification',
    CLICK_ADD_RECEIVING_ACCOUNT: 'Click add receiving account',
    ADD_RECEIVING_ACCOUNT_SUCCESS: 'Add receiving account success',
    ADD_RECEIVING_ACCOUNT_FAIL: 'Add receiving account fail',
    CANCEL_ADD_RECEIVING_ACCOUNT: 'Cancel add receiving account',
    CLICK_SAVE_VERIFICATION: 'Click save verification',
    SAVE_PAYMENT_DETAIL: 'Save payment details',
    SAVE_EDIT_PAYMENT_DETAIL: 'Save edit payment details',
    CLICK_EDIT_PAYMENT_DETAILS: 'Click edit payment details',
    CANCEL_EDIT_PAYMENT_DETAILS: 'Cancel edit payment details',
    CLICK_DELETE_PAYMENT_DETAILS: 'Click delete payment details',
    CANCEL_DELETE_PAYMENT_DETAILS: 'Cancel delete payment details',
    CONFIRM_DELETE_PAYMENT_DETAILS: 'Confirm delete payment details',
    CONFIRM_PAYMENT_SETUP: 'Confirm payment setup',
    CLICK_ADD_NEW_PROPERTY: 'Click add new property',
    ADD_NEW_PROPERTY_SUCCESS: 'Add new property success',
    CANCEL_ADD_NEW_PROPERTY: 'Cancel add new property',
    CLICK_ADD_NEW_TENANT: 'Click add new tenant',
    ADD_NEW_TENANT_SUCCESS: 'Add new tenant success',
    CANCEL_ADD_NEW_TENANT: 'Cancel add new tenant',
    CLICK_SAVE_PROPERTY_AND_TENANT: 'Click save property and tenant',
    CLICK_SETUP_RENT_PAYMENT: 'Click setup rent payment',
    CLICK_ADD_ANOTHER_PAYMENT: 'Click add another payment',
    AGREE_TERM_OF_USE: 'Agree term of use',

    // Plaid to Stripe
    BACK_TO_SELECT_ACCOUNT_AFTER_ADD_MICRODEPOSIT_ACCOUNT:
      'back to select account after add microdeposit account',
    CLICK_START_VERIFY_MICRODEPOSIT_ACCOUNT: 'Click start verify microdeposit account',
    FAIL_TO_START_VERIFY_BANK_ACCOUNT: 'fail to start verify bank account',
    SEE_VERIFY_BANK_ACCOUNT_MODAL: 'see verify bank account modal',
    VERIFY_BANK_ACCOUNT_FAIL_ATTEMPT: 'verify bank account fail attempt',
    EXIT_VERIFY_MICRO_DEPOSIT_ACCOUNT: 'exit verify microdeposit account',

    // LL Optimization
    VIEW_POST_LOGIN_RENT_PAYMENT_LANDING_PAGE: 'View Post-login Rent Payment landing page ',
    VIEW_CONFIRM_PAYMENT_SETUP_PAGE: 'View confirm payment setup page',
    VIEW_TAX_INFORMATION_PAGE: 'View tax information page',
    AGREE_STRIPE_TERM_OF_USE: 'Agree Stripe term of use',
    SUBMIT_TAX_INFORMATION: 'Submit tax information',
    CLICK_BACK_TO_PAYMENT_SETUP: 'Click back to payment setup',
    CLICK_ADD_TAX_INFORMATION: 'Click add tax information',

    // IA redesign
    VIEW_PAYMENT_TRANSACTION_DETAILS: 'View payment transaction details',

    // Mark as paid
    CLICK_MARK_AS_PAID: 'Click mark as paid',
    CONFIRM_MARK_AS_PAID: 'Confirm mark as paid',

    // Notify new feature
    CLICK_NOTIFY_NEW_FEATURE: 'Click notify new rental payment feature',

    // Invite Landlord
    CLICK_SEND_INVITE_LANDLORD: 'Invite the landlord - Enter LL Information',
    INVITE_THE_LANDLORD_APPLICANT_ACCEPTED: 'Invite the landlord - Applicant Accepted',

    // Update Receiving Account
    CLICK_CHANGE_RECEIVING_ACCOUNT: 'Click change receiving account',
    CONFIRM_CHANGE_RECEIVING_ACCOUNT: 'Confirm change receiving account',
    CHANGE_RECEIVING_ACCOUNT_SUCCESS: 'Change receiving account success',
    FAIL_TO_CHANGE_RECEIVING_ACCOUNT: 'Fail to change receiving account',

    // Agent initiate draft payment
    EXIT_SET_UP_MOVE_IN_FEE_TO_EXISTING_FLOW: 'exit set up move-in fee to existing flow',
    CONFIRM_SET_UP_MOVE_IN_FEE: 'confirm set up move-in fee',
    START_SET_UP_MOVE_IN_FEE: 'start set up move-in fee',
    SEE_AGENT_SET_UP_MOVE_IN_FEE_ANNOUNCEMENT: 'see agent set up move-in fee announcement',
    VIEW_AGENT_SET_UP_MOVE_IN_FEE_DRAWER: 'view agent set up move-in fee drawer',
    CLOSE_SET_UP_MOVE_IN_FEE_ANNOUNCEMENT: 'close set up move-in fee announcement',
    VIEW_FINISH_SETUP_ACCOUNT: 'View finish set up account modal',
    VIEW_CONFIRM_SET_UP_MOVE_IN_FEE: 'view confirm set up move-in fee',

    // Stripe embedded
    CLICK_ENTER_BUSINESS_TYPE: 'Click enter business type',
  },
  EVENT_PROPERTY: {
    CLICK_FROM: {
      POST_LOGIN_LANDING_EMPTY_PAGE: 'post_login_landing_empty_page',
      ONBOARDING_PAGE: 'onboarding_page',
      LISTING_MENU: 'listing_menu_page',
      TRANSACTION_TAB: 'transaction_tab',
      PAYMENT_INFO_TAB: 'payment_info_tab',
      STICKY_FOOTER_ACCEPTED: 'sticky_footer_accepted',
      RENT_PAYMENT: 'rent_payment',
      START_COLLECTING_RENT_PAYMENT_PAGE: 'start_collecting_rent_payment_page',
      FIRST_TIME_SET_UP: 'first_time_set_up',
      ACCOUNT_RESTRICTED_EMAIL: 'account_restricted_email',
      TRANSACTION_PAGE_BANNER: 'transaction_page_banner',
      PAYMENT_INFO_PAGE: 'payment_info_page',
      SIDE_BAR: 'side_bar',
      SIDE_BAR_PROPERTY_PAGE: 'side_bar_property_page',
      PROPERTY_PAGE: 'property_page',
      EXPLORE_PAGE: 'explore_page',
      DIRECT_TO_FEATURE: 'direct_to_feature',
      DIRECT_URL: 'direct_url',
      EMAIL_LL_ADD_PROPERTY: 'email_ll_add_property',
      EMAIL_LL_APPLICANT_ACCEPTED: 'email_ll_applicant_accepted',
      ACCEPT_EMAIL_SENT_MODAL: 'accept_email_sent_modal',
      ACCEPT_WITH_CONDITION_EMAIL_SENT_MODAL: 'accept_with_condition_email_sent_modal',
      SELECT_PROPERTY_AND_TENANT_PAGE: 'select_property_and_tenant_page',
      CONFIRM_PAYMENT_SETUP_PAGE: 'confirm_payment_setup_page',
      PROPERTY_PAYMENT_INFO_PAGE: 'property_payment_info_page',
      HOME: 'home',
      WHAT_TO_EXPECT_NEXT_DRAWER: 'what_to_expect_next_drawer',
      TO_DO_LIST: 'to_do_list',
      PROPERTY_OVERVIEW: 'property_overview',
      PAYMENT_LANDING_PAGE_L1: 'payment_landing_page_l1',
      PAYMENT_LANDING_PAGE_L2: 'payment_landing_page_l2',
      AGENT_INITIATE_PAYMENT: 'agent_initiate_payment',
      DEPOSIT_COLLECTION_DRAWER: 'deposit_collection_drawer',
      SCREENING_LANDING_PAGE: 'screening_landing_page',
      GET_START: 'get_start',
      GENERIC_PAYMENT_SETUP: 'generic_payment_setup',
      FINISH_ACCOUNT_SETUP_MODAL: 'finish_account_setup_modal',
      PAYMENT_SETTING_PAGE: 'payment_setting_page',
      PAYMENT_WIDGET_LEARN_MORE: 'payment_widget_learn_more',
      INCENTIVE_DETAIL_PAGE: 'incentive_detail_page',
      INCENTIVE_REWARD_PAGE: 'incentive_reward_page',
    },
    PANEL_INTERACTION: {
      CLOSE: 'close',
      CLICKED_LINK: 'clicked link',
    },
    FAQ: {
      SECURE_PAYMENT: 'secure_payment',
      PROCESSING_FEE: 'processing_fee',
      PROCESSING_TIME: 'processing_time',
      PAYMENT_METHOD: 'payment_method',
      BANK_TYPE: 'receiving_bank_type',
      ONE_TIME_PAYMENT: 'one_time_payment',
    },
    MOBILE_BUTTON: {
      TOP: 'top',
      BOTTOM: 'bottom',
    },
    LANDING_PAGE: {
      MAIN_MENU: 'main_menu',
      LISTING_MENU: 'listing_menu',
    },
    START_FROM: {
      MAIN_MENU: 'main_menu',
      LISTING_MENU: 'listing_menu',
      LISTING_OVERVIEW: 'listing_overview',
      RECEIVING_ACCOUNT_STEP: 'receiving_account_step',
      CONFIRMATION_STEP: 'confirmation_step',
      PAYMENT_INFO_TAB: 'payment_info_tab',
      TRANSACTION_TAB: 'transaction_tab',
      STICKY_FOOTER_LEASE_DOC: 'sticky_footer_view_lease_doc',
    },
    ADDED_SOURCE: {
      PLAID_ONLINE: 'plaid_online',
      PLAID_ACCOUNT_NO: 'plaid_account_no',
      PLAID: 'plaid',
      STRIPE: 'stripe',
      STRIPE_INSTANT_AUTH: 'stripe_instant_auth',
      STRIPE_MICRODEPOSIT: 'stripe_microdeposit',
    },
    STATE: {
      EMPTY: 'empty',
      NOT_EMPTY: 'not-empty',
    },
    PAYMENT_TYPE: {
      MONTHLY: 'monthly',
      ONE_TIME: 'one-time',
      recurring: 'recurring_payment',
      oneTime: 'one_time_payment',
    },
    SECTION: {
      MONTHLY_PAYMENT: 'monthly_payment',
      ONE_TIME_PAYMENT: 'one-time_payment',
    },
    USER_TYPE: {
      LANDLORD: 'landlord',
      RENTER: 'renter',
    },
    VERIFY_TYPE: {
      INSTANT_AUTH: 'instant_auth',
      MICRODEPOSITS: 'microdeposits',
    },
    VERIFY_METHOD: {
      TRANSACTION_CODE: 'transaction_code',
      MICRODEPOSIT_AMOUNT: 'microdeposit_amount',
    },
    FAIL_REASON: {
      BANK_REJECT: 'bank_reject',
      VERIFY_EXPIRED: 'verify_expired',
    },
    DEPOSIT_AMOUNT_ORDER: {
      NONE: 'None',
      FIRST: '1st',
      SECOND: '2nd',
    },
    IDV_TESTING: {
      CURRENT: 'A (current)',
      NEW: 'B (new)',
    },
    TAX_INFO_STATUS: {
      NOT_VERIFIED: 'not_verified',
      VERIFYING: 'verifying',
      VERIFIED: 'verified',
      VERIFY_FAILED: 'verify_failed',
    },
    RECEIVING_ACCOUNT_STATUS: {
      NO_ACCOUNT_ADDED: 'no_account_added',
      ACCOUNT_VERIFIED: 'account_verified',
      ACCOUNT_UNVERIFIED: 'account_unverified',
    },
    FEATURE_NAME: {
      MULTIPLE_RECEIVING_ACCOUNT: 'multiple_receiving_account',
    },
    VIEW_ON: {
      SCREENING_LANDING_PAGE: 'screening_landing_page',
      HOME: 'home',
      PROPERTY_OVERVIEW: 'property_overview',
    },
    VIEW_TAB: {
      TRANSACTION: 'transaction',
      SETTING: 'setting',
      NONE: 'none',
      PAYMENT_PROPERTY_TAB: 'payment_property_tab',
    },
    VIEW_FROM: {
      PAYMENT_MENU: 'payment_menu',
      PROPERTY_PAGE: 'property_page',
    },
    // rp welcome modal LL
    VIEW_STAGE: {
      VERIFY_IDENTITY: 'verify_identity',
      ADD_BANK_ACCOUNT: 'add_bank_account',
      COLLECT_MONTHLY_RENT: 'collect_monthly_rent',
    },
    IDENTITY_VERIFICATION_STAGE: {
      BUSINESS_TYPE: 'business_type',
      PERSONAL_DETAILS: 'personal_details',
      UNKNOWN: 'unknown',
    },
    BUSINESS_TYPE: {
      INDIVIDUAL: 'individual',
      COMPANY: 'company',
    },
  },
  enterPage: 'Enter rent payment menu',
}

export const LRA_INTEGRATION = {
  ENTER_SCREENING_FEE_PAGE: 'Enter screening fee breakdown',
  COMPLETE_SCREENING_FEE: 'Complete screening fee breakdown',
  ENTER_DRE_LICENSE_PAGE: 'Enter DRE license',
  COMPLETE_DRE_LICENSE: 'Complete DRE license',
  SELECT_LRA: 'Select LRA',
  ENTER_APPLICATION_SIGN: 'Enter application sign',
  COMPLETE_APPLICATION_SIGN: 'Complete application sign',
}

export const LRA_INTEGRATION_PROPERTIES = {
  SCREENING_SELECTION: 'screening_selection',
}

export const SIGN_FLAG_EVENTS = {
  CLICK_START_SIGN_FLAG: 'Click start sign flag',
  CLICK_NEXT_SIGN_FLAG: 'Click next sign flag',
}

export const EMAIL_VERIFICATION = {
  EVENT_NAME: {
    CLICK_SEND_EMAIL_VERIFICATION: 'User send email verification',
  },
  enterPage: 'Enter email verification on dashboard',
}

export const CREATE_LISTING_EVENT = {
  EVENT_NAME: {
    ENTER_LISTING_CREATION_PAGE: isEdit => `Enter listing ${isEdit ? 'edit' : 'creation'} flow`,
    EXIT_LISTING_CREATION: isEdit => `Exit listing ${isEdit ? 'edit' : 'creation'} flow`,
    CLICK_PUBLISH_LISTING: 'Click publish listing',
    CLICK_SAVE_AND_NEXT_LISTING_INFORMATION_PAGE: 'click save and next',
    SELECT_OPTION_ON_IMPORT_LISTING: 'select option on import listing from mls page',
    LISTING_PUBLISHED: 'Listing published',
    CLOSE_CONFIRM_PROPERTY_ADDRESS_MODAL: 'Close Confirm property address modal',
    VIEW_RENT_ESTIMATE_REPORT: 'View Rent Estimate report on leasing information page',
    ENTER_PAGE: {
      'listing-info': 'Enter listing information page',
      'leasing-info': 'Enter leasing information page',
      'utilities-amenities': 'Enter utilities amenities page',
      description: 'enter listing description page',
      preview: 'Enter preview page',
    },
    CLICK_GENERATE_DESCRIPTION: 'click generate description',
    CLICK_TO_VIEW_PREVIOUS_GENERATED_DESCRIPTIONS: 'click to view previous generated descriptions',
  },
  PROPERTY_ROLE_SELECTION: 'property role selection',
}

export const MLS_ADOPTION_EVENT = {
  EVENT_NAME: {
    CLICK_CLAIM_LISTING: 'click claim listing',
  },
}

export const CREATE_PROPERTY_EVENT = {
  EVENT_NAME: {
    CLICK_CLOSE_MODAL: 'Close Confirm property address modal',
    CLICK_CONFIRM_MODAL: 'Confirm Property Address in confirmation modal',
    CLICK_EDIT_ADDRESS_MODAL: 'Edit Property Address in confirmation modal',
    CLICK_NEXT: 'Click next in create property page',
  },
}

export const LISTING_MANAGEMENT_EVENT = {
  EVENT_NAME: {
    VIEW_LISTING_PAGE_URL: 'View listing page URL',
    COPY_LISTING_PAGE_URL: 'Copy listing page URL',
    TENANT_SCREENING_SELECT: 'Tenant screening select',
    ENTER_EDIT_TENANT_SCREENING: 'Enter edit tenant screening',
    DISABLE_TENANT_SCREENING: 'Disable tenant screening',
    REENABLE_TENANT_SCREENING: 'Reenable tenant screening',
    CLOSE_LISTING_STATUS_BANNER: 'Close listing status banner',
    LEARN_MORE_ABOUT_LISTING_STATUS: 'Learn more about listing status',
    CLICK_RENEW_LISTING: 'Click renew listing',
    ENTER_EDIT_LISTING_FLOW: 'Click edit listing flow',
    ENTER_FINISH_LISTING_FLOW: 'Click finish listing flow',
    ENTER_LISTING_MANAGEMENT_PAGE: 'Enter Listing Management page',
    CLICK_CREATE_AGENT_PROFILE_ON_BANNER: 'click create agent profile on banner',
    CLOSE_CREATE_AGENT_PROFILE_BANNER: 'close create agent profile banner',
    SKIP_TENANT_SCREENING_OPTIONS: 'skip tenant screening options',
    DONT_SKIP_SCREENING_OPTIONS: 'don’t skip screening options',
    LEAVE_URL_SHARING_PAGE: 'leave URL sharing page',
    SHARE_LISTING_PAGE: 'share listing page',
    CLICK_MANAGE_LISTING: 'click manage listing',
    CLICK_MARKET_LISTING: 'click market listing'
  },
  EVENT_PROPERTY: {
    CLICK_FROM: 'click_from',
    SHARE_TO: 'share_to',
  },
  EVENT_VALUE: {
    APPLICATION_SCREENING: 'application_and_screening',
    SKIP_SCREENING: 'skip_screening_modal',
    LISTING_MANAGEMENT: 'listing_management',
    URL_SHARING: 'url_sharing_page',
    PUBLIC_PAGE: 'public_page',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    LINKEDIN: 'linkedin',
    EMAIL: 'email',
  },
}

export const LISTING_SYNDICATION_EVENT = {
  EVENT_NAME: {
    AUTO_SYNDICATION_SUCCESS: 'auto syndication success',
    AUTO_SYNDICATION_FAIL: 'auto syndication fail',
    TURN_ON_SYNDICATION_SUCCESS: 'turn on syndication success',
    TURN_ON_SYNDICATION_FAIL: 'turn on syndication fail',
    TURN_OFF_SYNDICATION_SUCCESS: 'turn off syndication success',
    TURN_OFF_SYNDICATION_FAIL: 'turn off syndication fail',
    OPEN_SYNDICATION_CONTACT_MODAL: 'open syndication contact modal',
    SAVE_SYNDICATION_CONTACT_SUCCESS: 'save syndication contact success',
    SAVE_SYNDICATION_CONTACT_FAIL: 'save syndication contact fail',
    CLOSE_SYNDICATION_CONTACT_MODAL: 'close syndication contact modal',
    OPEN_SYNDICATION_TOOLTIP: 'open syndication tooltip',
  },
}

export const START_FROM = {
  ACCEPT_EMAIL_SENT_MODAL: 'accept_email_sent_modal',
}

export const POST_LOGIN_EXPERIENCE_EVENT = {
  EVENT_NAME: {
    CLICK_LEARN_MORE: 'click learn more',
    CLICK_SAMPLE_REPORT_ON_EXPLORE_RENTSPREE: 'click sample report on explore rentspree',
    CLICK_SUGGEST_A_FEATURE_ON_EXPLORE_RENTSPREE: 'click suggest a feature on explore rentspree',
    CLICK_SUBMIT_ON_SUGGEST_A_FEATURE: 'click submit on suggest a feature',
    CLICK_DIRECT_TO_FEATURE: 'click direct to feature',
    CLICK_TO_CLOSE_MODAL: 'click close on ad modal after TS',
    CLICK_CHECKOUT: 'click checkout',
    CLICK_RENTSPREE_PRO_PAGE: 'click rentspree pro page',
    VISIT_RENTSPREE_HELP_CENTER: 'visit rentspree help center',
    START_SCREENING_REQUEST: 'start screening request',
  },
  EVENT_PROPERTY: {
    FEATURE_NAME: 'feature_name',
    CHECKED: 'checked',
    BUTTON_LOCATION: 'button_location',
    ACTION_NEEDED: 'action_needed',
    CLICK_FROM: 'click_from',
  },
  EVENT_VALUE: {
    ADS_MODAL: 'ad modal after TS',
    EXPLORE_RENTSPREE: 'explore_rentspree',
    EXPLORE_RENTSPREE_PAGE: 'explore rentspree page',
    EXPLORE_RENTSPREE_SHOW_ME_MORE: 'explore_rentspree_show_me_more',
    CHECKOUT_ACTIONS: {
      TRIAL: 'trial',
      UPGRADE: 'upgrade',
      REACTIVATE: 'reactivate',
    },
  },
}

export const RCM_TASK_AND_ACTIVITY_EVENT = {
  EVENT_NAME: {
    CLICK_CONTACT_DETAIL_MENU: 'click_contact_detail_menu',
    TASK_CLICK_DONE: 'task_click_done',
    TASK_CLICK_ACTION: 'task_click_action',
    TASK_DELETED: 'task_deleted',
    ACTIVITY_CLICK_LINK: 'activity_click_link',
    SEE_TASK: 'see_task',
    SEE_ACTIVITY: 'see_activity',
    TASK_UNMARK_DONE: 'task_unmark_done',
  },
  EVENT_PROPERTY: {
    CLICK_CONTACT_DETAIL_MENU: {
      ACTION: {
        CLICK_ACTIVITY_MENU: 'activity',
        CLICK_TASK_MENU: 'tasks',
        CLICK_NOTE_MENU: 'notes',
      },
    },
    SEE_FROM: {
      CONTACT_DETAILS: 'contact_details',
    },
    FROM: {
      CONTACT_DETAILS: 'contact_details',
    },
  },
}

export const TASK_TEMPLATE_TO_TRACK_EVENT_ACTION = {
  [TASK_TEMPLATES.CONTACT]: 'accept_deny',
  [TASK_TEMPLATES.APPLICATION_SUBMITTED]: 'accept_deny',
  [TASK_TEMPLATES.TENANT_SCREENING]: 'accept_deny',
  [TASK_TEMPLATES.EDOC]: 'request_esign',
  [TASK_TEMPLATES.SCREENING_REQUEST_ACCEPTED]: 'request_esign',
  [TASK_TEMPLATES.MANUAL]: 'manual',
  [TASK_TEMPLATES.BIRTHDAY_ANNIVERSARY]: 'send_gift',
  [TASK_TEMPLATES.MOVE_IN_ANNIVERSARY]: 'send_gift',
  [TASK_TEMPLATES.MOVING_IN]: 'send_gift',
  [TASK_TEMPLATES.MOVE_IN_FIRST_TIME]: 'send_gift',
  [TASK_TEMPLATES.SHARE_TO_LANDLORD]: 'share_with_landlord',
}

export const TASK_TEMPLATE_TO_TRACK_TASK_DELETED_EVENT_ACTION = {
  [TASK_TEMPLATES.CONTACT]: 'accept_deny',
  [TASK_TEMPLATES.APPLICATION_SUBMITTED]: 'accept_deny',
  [TASK_TEMPLATES.TENANT_SCREENING]: 'accept_deny',
  [TASK_TEMPLATES.EDOC]: 'request_esign',
  [TASK_TEMPLATES.SCREENING_REQUEST_ACCEPTED]: 'request_esign',
  [TASK_TEMPLATES.MANUAL]: 'manual',
  [TASK_TEMPLATES.BIRTHDAY_ANNIVERSARY]: 'send_gift',
  [TASK_TEMPLATES.MOVE_IN_ANNIVERSARY]: 'send_gift',
  [TASK_TEMPLATES.MOVING_IN]: 'send_gift',
  [TASK_TEMPLATES.MOVE_IN_FIRST_TIME]: 'send_gift',
  [TASK_TEMPLATES.SHARE_TO_LANDLORD]: 'share_with_landlord',
}

export const ACTIVITY_TEMPLATE_TO_TRACK_LINK_CLICK_ACTION = {
  [ACTIVITY_TEMPLATES.APPLICATION_SUBMITTED]: 'application',
  [ACTIVITY_TEMPLATES.SCREENING_REPORT_SUBMITTED]: 'application',
  [ACTIVITY_TEMPLATES.CONTACT_CREATED_FROM_AGENT_PROFILE]: 'agent_profile',
  [ACTIVITY_TEMPLATES.CONTACT_CREATED_LISTING_PAGE]: 'listing_page',
  [ACTIVITY_TEMPLATES.CONTACT_CREATED_RENTSPREE_LISTING_PAGE]: 'listing_page',
  [ACTIVITY_TEMPLATES.DOCUMENT_SIGNED]: 'document',
  [ACTIVITY_TEMPLATES.CONTACT_CREATED_FROM_E_SIGN]: 'document',
  [ACTIVITY_TEMPLATES.FIRST_INCOMING_MSG_FROM_AP]: 'message',
  [ACTIVITY_TEMPLATES.FIRST_INCOMING_MSG_FROM_LP]: 'message',
}

export const SEND_APPLY_LINK_EVENT = {
  SEND_APPLY_LINK: 'send applylink',
}

export const RENTSPREE_PRO_EVENT = {
  CLICK_RENTSPREE_PRO_PAGE: 'click rentspree pro page',
  BILLING_PERIOD_TOGGLE_BUTTON: 'click monthly yearly toggle',
  CLICK_CHECKOUT_PAGE: 'click checkout',
  CLICK_SUBSCRIPTION_MANAGEMENT: 'click subscription management',
  CLICK_SHOW_FAQ: 'click show faq',
  CLICK_HELP_CENTER: 'click help center',
  ACTION_SHOW_FAQ: 'show',
  ACTION_HIDE_FAQ: 'hide',
  CLICK_TO_EXPAND_FEATURE_DETAIL: 'click to expand feature detail',
}

export const EVENT_TASK_OVERVIEW = {
  EVENT_NAME: {
    VIEW_EMPTY_TASKS_PAGE: 'view empty tasks page',
    VIEW_TASK_DASHBOARD: 'view task dashboard',
    CLICK_SCREEN_TENANT: 'click screen tenant',
    SORT_TASK: 'sort task',
    APPLY_DUE_DATE_FILTER: 'apply due date filter',
    APPLY_ACTION_FILTER: 'apply action filter',
    CLICK_ASSOCIATED_CONTACT: 'click associated contact',
    CLICK_EDIT_TASK: 'click edit task',
    CLICK_SAVE_EDITED_TASK: 'click save edited task',
    CLICK_SAVE_DUE_DATE: 'click save due date',
    SAVE_EDITED_TASK: 'save edited task',
    SEARCH_TASK: 'search task',
    SEE_TASK: 'see_task',
    TASK_DELETED: 'task_deleted',
    TASK_CLICK_DONE: 'task_click_done',
    TASK_CLICK_ACTION: 'task_click_action',
    TASK_UNMARK_DONE: 'task_unmark_done',
    CLEAR_TASK_ACTION_FILTER: 'clear task action filter',
  },
  EVENT_PROPERTY: {
    PAGE_TYPE: 'page_type',
    SORT_BY: 'sort_by',
    DUE_DATE_TYPE: 'due_date_type',
    ACTION_TYPE: 'action_type',
    ACTION: 'action',
    EDIT_ACTION: 'edit_action',
    SEARCH_ITEM: 'search_item',
    NUMBER_OF_TOTAL_TASKS: 'number_of_total_tasks',
    TAB_MENU: 'tab_menu',
    NUMBER_OF_TASK_NOTI: 'number_of_task_noti',
    FROM: 'from',
    EDIT_PROPERTY: 'edit_property',
    TASK_NAME: 'task_name',
    CONTACT_ID: 'contact_id',
    CONTACT_EMAIL: 'contact_email',
    TASK_ID: 'task_id',
  },
  EVENT_VALUE: {
    EMPTY: 'empty',
    MAIN: 'main',
    TITLE: 'title',
    CONTACT: 'contact',
    PROPERTY: 'property',
    ACTION: 'action',
    DUE_DATE: 'due_date',
    ALL_DATE: 'all_date',
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    THIS_WEEK: 'this_week',
    THIS_MONTH: 'this_month',
    ACCEPT_DENY: 'accept_deny',
    REQUEST_ESIGN: 'request_esign',
    SHARE_WITH_REPORT: 'share_with_landlord',
    MARK_TASK: 'mark_task',
    EDIT_DUE_DATE: 'edit_due_date',
    ALL_TASKS: 'all_tasks',
    COMPLETED_TASK: 'completed_tasks',
    CONTACT_DETAILS: 'contact_details',
    TASK_OVERVIEW: 'task_overview',
    COMPLETED: 'completed',
    REMOVE_DUE_DATE: 'remove_due_date',
  },
}

export const FEATURE_BLOCK_EVENT = {
  VIEW_MODAL: 'view feature block modal',
  CLOSE_MODAL: 'close feature block modal',
  CLICK_LEARN_MORE: 'click learn more on feature block modal',
  CLICK_CHECKOUT: 'click checkout',
}

export const FEATURE_BLOCK_PROPERTY = {
  ACTION_NEEDED: 'action_needed',
  CLICK_FROM: 'click_from',
  SUBSCRIPTION_PERIOD: 'subscription_period',
}

export const FEATURE_BLOCK_AVAILABLE_ACTION = {
  TRIAL: 'trial',
  UPGRADE: 'upgrade',
}

// doc_upload, ref_check, e-sign, agent_review, custom_slug
export const BLOCKED_FEATURE_NAME = {
  E_SIGN_MANUALLY_UPLOAD: 'esign_manually_upload',
}

export const NAVBAR_CLICK_FROM = 'side_menu'

export const RENTSPREE_PRO_EXPAND_FEATURE_DETAIL_MAP = {
  'Online applications and screening': 'online_application_and_screening',
  'Client manager': 'client_manager',
  'Rental listings page': 'rental_listing',
  'Branding page': 'agent_profile',
  'Agent reviews and custom URL': 'agent_reviews_and_custom_URL',
  'Send and e-sign agreements': 'send_and_esign_agreements',
  'Secure file sharing': 'secure_file_sharing',
  'Auto-reference checks': 'auto_reference_check',
  'Priority phone support': 'priority_phone_support',
}

export const REFERRAL_EVENT = {
  SEND_REFERRAL_INVITE: 'send referral invite',
}

export const CHECK_ITEM_PERFORMED_BY = {
  USER: 'user',
  SYSTEM: 'system',
}

export const REVIEW_REPORT_EVENT = {
  EVENT_NAME: {
    REVIEW_REPORT: 'Review report',
    CONFIRM_INFORMATION: 'Confirm information',
    SUBMIT_REPORT: 'Submit report',
  },
}

export const REPORT_GENERATION_LIMIT_EVENT = {
  EVENT_NAME: {
    ENTER_TU_REPORT_GENERATION_LIMIT: 'enter tu report generation limit page',
  },
}

export const INCOME_VERIFICATION_EVENT = {
  EVENT_NAME: {
    CLICK_MANAGE_DOCUMENTS: 'click manage documents',
    VIEW_INCOME_REPORT_STATE: 'view_income_report_state',
    CLICK_TRY_AGAIN: 'click_try_again',
    CLICK_CONTACT_SUPPORT: 'click_contact_support',
  },
  SWITCH_TO_TAB: 'income_verification',
  CLICK_FROM: {
    INCOME_VERIFICATION_TAB: 'income_verification_tab',
    ALL_REPORT_TAB: 'all_report_tab',
  },
  VIEW_FROM: {
    INCOME_VERIFICATION_TAB: 'income_verification_tab',
    ALL_REPORT_TAB: 'all_report_tab',
  },
  INCOME_REPORT_STATE: {
    REPORT_AVAILABLE: 'report_available',
    REPORT_UNAVAILABLE: 'report_unavailable',
    REPORT_NOT_READY: 'report_not_ready',
    REPORT_ERROR: 'report_error',
    REPORT_EXPIRED: 'report_expired',
  },
}

export const TS_WIDGET_EVENT_NAME = {
  clickTakeTour: 'click take a tour',
  clickViewSampleReport: 'click view sample report',
  clickViewRequestWidget: 'click view request widget',
  clickViewReportWidget: 'click view report widget',
  clickViewApproveWidget: 'click approve report widget',
}

export const NEW_DASHBOARD_V3_EVENTS = {
  START_GUIDED_TOUR_EVENT: {
    EVENT_NAME: 'start guide tour',
    PROPERTY: {
      IS_FIRST_TIME: 'is_first_time',
      VERSION: 'version',
      LOCATION: 'location',
    },
  },
  EXIT_GUIDED_TOUR_EVENT: {
    EVENT_NAME: 'exit guide tour',
    PROPERTY: {
      STEP: 'step',
    },
  },
  COMPLETE_GUIDED_TOUR_EVENT: {
    EVENT_NAME: 'complete guide tour',
  },
  CLOSE_WHAT_TO_EXPECT_NEXT_WIDGET: {
    EVENT_NAME: 'close what to expect widget',
  },
  VIEW_WHAT_TO_EXPECT_DRAWER: {
    EVENT_NAME: 'view what to expect drawer',
    PROPERTY: {
      CLICK_FROM: 'click_from',
    },
    VALUE: {
      GET_STARTED_LIST: 'get_start_list',
      WHAT_TO_EXPECT_NEXT_WIDGET: 'what_to_expect_widget',
    },
  },
  SEE_NEW_HOMEPAGE_ANNOUNCEMENT: {
    EVENT_NAME: 'see new homepage announcement',
  },
  SKIP_GUIDED_TOUR: {
    EVENT_NAME: 'skip guide tour',
  },
  CONTINUE_GUIDED_TOUR: {
    EVENT_NAME: 'continue guide tour',
  },
  CLICK_LEARN_ABOUT_CHOOSING_TENANT: {
    EVENT_NAME: 'click learn about choosing tenant',
    PROPERTY: {
      CLICK_FROM: 'home',
    },
  },
  CLICK_LEARN_ABOUT_PAYMENT_SETUP: {
    EVENT_NAME: 'click learn about payment setup',
    PROPERTY: {
      CLICK_FROM: 'click_from',
    },
    VALUE: {
      GET_STARTED: 'get_started',
    },
  },
  GET_STARTED_WIDGET: {
    EVENT_NAME: 'get_started_widget',
    PROPERTY: {
      ACTION: 'action',
      USER_TYPE: 'user_type',
    },
    VALUE: {
      AGENT: 'agent',
      AGENT_INITIATED_LANDLORD: 'agent_initiated_landlord',
    },
  },
  TO_DO_LIST_WIDGET: {
    EVENT_NAME: 'to_do_list_widget',
    PROPERTY: {
      ACTION: 'action',
      USER_TYPE: 'user_type',
    },
    VALUE: {
      AGENT: 'agent',
      AGENT_INITIATED_LANDLORD: 'agent_initiated_landlord',
    },
  },
}

export const VERSION_REFRESH_EVENT = {
  SHOW_UI: 'show refresh required dialog',
  CLICK_ASK_ME_LATER: 'click ask me later refresh required',
  CLICK_REFRESH_NOW: 'click refresh now refresh required',
}

export const ONBOARDING_PARTNER_AGENT_EVENT = {
  EVENT_NAME: {
    REDIRECT_TO_ONBOARDING_AGENT: 'redirect to onboarding agent',
    OPEN_ONBOARDING_AGENT_MODAL: 'open onboarding agent modal',
    CLOSE_ONBOARDING_AGENT_MODAL: 'close onboarding agent modal',
    HIGHLIGHT_SCREEN_TENANT_MENU: 'highlight screen tenant menu',
    CLICK_PLAY_ONBOARDING_AGENT_VIDEO: 'click play onboarding agent video',
  },
}

export const AGENT_INCENTIVE_PROGRAM_PAYMENT_ADOPTION_EVENT = {
  EVENT_NAME: {
    SEE_INCENTIVE_ANNOUNCEMENT: 'see incentive announcement',
    ENTER_REWARD_PAGE: 'enter reward page',
    ENTER_INCENTIVE_DETAIL_PAGE: 'enter incentive detail page',
    ENTER_INCENTIVE_HOW_TO_PAGE: 'enter incentive howto page',
  },
  EVENT_PROPERTY: {
    VERSION: 'v4',
    CLICK_FROM: {
      INCENTIVE_ANNOUNCEMENT: 'incentive_announcement',
      NAV: 'nav',
      HOME: 'home',
      PAYMENT_L1: 'payment_l1',
      PROPERTY_L1: 'property_l1',
      SCREENING_L1: 'screening_l1',
      AUTO: 'auto',
      INCENTIVE_DETAIL_PAGE: 'incentive_detail_page',
      INCENTIVE_REWARD_PAGE: 'incentive_reward_page',
      INCENTIVE_HOWTO_PAGE: 'incentive_howto_page',
      BACK_BUTTON_REWARD_SHEET: 'back_button_your_rewards_sheet',
      BACK_BUTTON_REFERRALS_SHEET: 'back_button_how_referrals_work_sheet'
    },
  }
}
