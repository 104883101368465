import get from 'lodash/get'
import { createSelector } from 'reselect'

import { BUSINESS_TYPE } from 'v3/containers/identity-verification/constants'
import {
  identityVerificationInitialState,
  KEY,
  API_STATUS,
} from 'v3/containers/identity-verification/reducers'

export const selectIdentityVerificationState = state =>
  get(state, KEY.IDENTITY_VERIFICATION, identityVerificationInitialState)

// AccountSession
export const makeSelectAccountSession = () =>
  createSelector(selectIdentityVerificationState, state => get(state, 'accountSession'))

export const makeSelectAccountSessionData = () =>
  createSelector(makeSelectAccountSession(), state => get(state, 'data'))

export const makeSelectAccountSessionStatus = () =>
  createSelector(makeSelectAccountSession(), state => get(state, 'status', API_STATUS.NONE))

export const makeSelectAccountSessionErrors = () =>
  createSelector(makeSelectAccountSession(), state => get(state, 'errors'))

// Fetch Account information
export const makeSelectAccountInformation = () =>
  createSelector(selectIdentityVerificationState, state => get(state, 'accountInformation'))

export const makeSelectAccountInformationData = () =>
  createSelector(makeSelectAccountInformation(), state => get(state, 'data'))

export const makeSelectAccountInformationStatus = () =>
  createSelector(makeSelectAccountInformation(), state => get(state, 'status', API_STATUS.NONE))

export const makeSelectAccountBusinessType = () =>
  createSelector(makeSelectAccountInformationData(), state =>
    get(state, 'businessType', BUSINESS_TYPE.UNKNOWN),
  )
export const makeSelectAccountIdentityVerification = () =>
  createSelector(makeSelectAccountInformationData(), state =>
    get(state, 'identityVerification', {}),
  )

// Update Account information
export const makeSelectUpdateAccountInformation = () =>
  createSelector(selectIdentityVerificationState, state => get(state, 'updateAccountInformation'))

export const makeSelectUpdateAccountInformationStatus = () =>
  createSelector(makeSelectUpdateAccountInformation(), state =>
    get(state, 'status', API_STATUS.NONE),
  )
export const makeSelectUpdateAccountInformationError = () =>
  createSelector(makeSelectUpdateAccountInformation(), state => get(state, 'error'))
