import WATCH_TUTORIAL_ICON from "../../images/icons/getting-started/watch-tutorial.svg"
import SAMPLE_APP_ICON from "../../images/icons/getting-started/sample-application.svg"
import APP_PROCESS_ICON from "../../images/icons/getting-started/applicant-process.svg"

export const BEFORE_START_LIST = [
  {
    id: "screeningTutorial",
    icon: { src: WATCH_TUTORIAL_ICON, alt: "watch tutorial" },
    title: "Tenant screening tutorial",
    content:
      "Watch this brief tutorial to see how RentSpree's tenant verification process and online rental application works.",
    button: {
      id: "watchTutorialBtn",
      text: "Watch Tutorial",
      icon: "fas fa-play",
    },
  },
  {
    id: "seeSampleApp",
    icon: { src: SAMPLE_APP_ICON, alt: "see a sample" },
    title: "Sample screening package",
    content:
      "You’ll get a full tenant screening package, including an online rental application and more.See exactly what you’ll receive back when you screen.",
    button: { id: "seeSampleBtn", text: "See a Sample" },
  },
  {
    id: "applicationProcess",
    icon: { src: APP_PROCESS_ICON, alt: "view process" },
    title: "Applicant process",
    content:
      "Each applicant applies online in minutes.The process is simple and straightforward.  See exactly what each applicant does to apply.",
    button: { id: "viewProcessBtn", text: "View Process", icon: "fas fa-play" },
  },
]

export const GETTING_STARTED_VIDEO = "https://www.youtube.com/embed/W7lvl2vEmuc"
export const WATCH_TUTORIAL_VIDEO = "https://www.youtube.com/embed/rsDBL5AFz8k"
export const URL_SIMPLE_APPLICATION =
  "https://cdn.rentspree.com/static-files/report/All-Report-Sample-6-7-19_opt.pdf"
export const VIEW_PROCESS_VIDEO = "https://www.youtube.com/embed/QHSnM5VIJgo"
export const GETTING_STARTED_VIDEO_ID = "W7lvl2vEmuc"

export const WATCH_TUTORIAL_VIDEO_FOR_CAA =
  "https://www.youtube.com/embed/WRPvYIISM00"
export const GETTING_STARTED_VIDEO_ID_FOR_CAA = "HCCcEtbl-2k"
export const VIEW_PROCESS_VIDEO_FOR_CAA =
  "https://www.youtube.com/embed/OVzsBtPcH38"
export const URL_SIMPLE_APPLICATION_FOR_CAA =
  "https://cdn.rentspree.com/static-files/report/CAA-Report-Sample-6-7-19.pdf"
