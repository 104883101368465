import PaginationV2 from '@rentspree/component-v2/dist/pagination'
import React from 'react'
import styled from 'styled-components'

import { UpcomingEventTableHeader } from 'components/contacts/molecules/upcoming-event-table-header'
import UpcomingEventListDesktop from 'components/contacts/organisms/upcoming-event-list/list-desktop'
import UpcomingEventListMobile from 'components/contacts/organisms/upcoming-event-list/list-mobile'
import Pagination from 'components/filter/pagination'
import { getContactName } from 'containers/contacts/helpers'
import { breakpoints } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import { contactDataMapper } from '../contact-dashboard-template/helpers'

const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  ${breakpoints.mobile(`
    table-layout: fixed;
  `)};
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const TableWrapper = styled.div`
  flex-grow: 1;
`

export const PaginationContainer = styled(Pagination)`
  position: inherit;
  ${breakpoints.mobile(`
    width: 100%;
    height: 80px;
    padding: 20px 0;
`)};
`

const PaginationV2Wrapper = styled.div`
  margin: 20px 0;
  padding-left: 5px;
  ${breakpoints.mobile(`
    margin: 30px 0 120px;
    padding-left: 0;
  `)};
`

const PaginationV2Container = styled(PaginationV2)`
  @media (max-width: 1199px) {
    height: auto;
    flex-direction: column;
    align-items: center;

    > div:nth-child(1) {
      position: relative;
      margin-bottom: 10px;
    }

    > div:nth-child(2) {
      height: 40px;
    }

    > div:nth-child(3) {
      height: 40px;
      margin-left: 0;
      margin-top: 10px;
    }
  }
`

export const UpcomingEventTemplate = ({
  allTags = [],
  bulkSelected = [],
  data = [],
  pagination,
  isLoadingTag,
  handleGoToContactDetail,
  handleChangePage,
  onCreateAndTagContact,
  onClickToggleSelectedBulk,
  onClickAddSelectedAllBulk,
  onTagContact,
  onUntagContact,
  onStarContact,
  bulkState,
  onSortContact,
  currentSortContactValue,
  onFocusAddTag,
  onMarkAsGiftSent,
  onUnmarkGiftSent,
  onOpenContactPanel,
  setSelectedContact,
}) => (
  <FlexWrapper>
    <TableWrapper>
      <Table>
        <UpcomingEventTableHeader
          bulkSelected={bulkSelected}
          data={data}
          onClickAddSelectedAllBulk={onClickAddSelectedAllBulk}
          bulkState={bulkState}
          onSortContact={onSortContact}
          currentSortContactValue={currentSortContactValue}
        />
        {data.map(contact => (
          <>
            <UpcomingEventListDesktop
              {...contactDataMapper(contact)}
              contactId={contact?._id}
              allTags={allTags}
              bulkSelected={bulkSelected}
              onClickContactRow={() =>
                handleGoToContactDetail(
                  contact?._id,
                  contact?.daysTillBirthday,
                  contact?.daysTillMoveInAnniversary,
                  contact?.daysTillMovingIn,
                )
              }
              onCreateAndTagContact={name =>
                onCreateAndTagContact({
                  contactId: contact?._id,
                  name,
                })
              }
              onTagContact={tagId =>
                onTagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                })
              }
              onUntagContact={tagId =>
                onUntagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                })
              }
              isLoadingTag={isLoadingTag}
              onStarContact={event => {
                onStarContact({
                  contactId: contact?._id,
                  starredAt: contact?.starredAt,
                })
                event.stopPropagation()
              }}
              onToggleSelectedBulk={event => {
                onClickToggleSelectedBulk(contact?._id)
                event.stopPropagation()
              }}
              bulkState={bulkState}
              onFocusAddTag={() => onFocusAddTag(contact?.email)}
              onMarkAsGiftSent={event => {
                tracker.trackEvent(EVENT_NAME.MARK_AS_SENT, {
                  contact_fullname: getContactName(contact),
                  click_from: EVENT_PROPERTY.CLICK_FROM.CONTACT_DASHBOARD,
                  always_mark_sent: false,
                  category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
                })
                onMarkAsGiftSent({
                  contactId: contact?._id,
                  eventType: contact?.event,
                })
                event.stopPropagation()
              }}
              onUnmarkGiftSent={event => {
                tracker.trackEvent(EVENT_NAME.UNMARK_AS_SENT, {
                  contact_fullname: getContactName(contact),
                  category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
                })
                onUnmarkGiftSent({
                  contactId: contact?._id,
                  eventType: contact?.event,
                })
                event.stopPropagation()
              }}
              onOpenContactPanel={onOpenContactPanel}
              setSelectedContact={() => setSelectedContact(contact)}
            />
            <UpcomingEventListMobile
              {...contactDataMapper(contact)}
              contactId={contact?._id}
              allTags={allTags}
              onClickContactRow={() =>
                handleGoToContactDetail(
                  contact?._id,
                  contact?.daysTillBirthday,
                  contact?.daysTillMoveInAnniversary,
                  contact?.daysTillMovingIn,
                )
              }
              onCreateAndTagContact={name =>
                onCreateAndTagContact({
                  contactId: contact?._id,
                  name,
                })
              }
              onTagContact={tagId =>
                onTagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                })
              }
              onUntagContact={tagId =>
                onUntagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                })
              }
              isLoadingTag={isLoadingTag}
              onStarContact={event => {
                onStarContact({
                  contactId: contact?._id,
                  starredAt: contact?.starredAt,
                })
                event.stopPropagation()
              }}
              onToggleSelectedBulk={event => {
                onClickToggleSelectedBulk(contact?._id)
                event.stopPropagation()
              }}
              bulkSelected={bulkSelected}
              bulkState={bulkState}
              onFocusAddTag={onFocusAddTag}
              onMarkAsGiftSent={event => {
                tracker.trackEvent(EVENT_NAME.MARK_AS_SENT, {
                  contact_fullname: getContactName(contact),
                  click_from: EVENT_PROPERTY.CLICK_FROM.CONTACT_DASHBOARD,
                  always_mark_sent: false,
                  category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
                })
                onMarkAsGiftSent({
                  contactId: contact?._id,
                  eventType: contact?.event,
                })
                event.stopPropagation()
              }}
              onUnmarkGiftSent={event => {
                tracker.trackEvent(EVENT_NAME.UNMARK_AS_SENT, {
                  contact_fullname: getContactName(contact),
                  category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
                })
                onUnmarkGiftSent({
                  contactId: contact?._id,
                  eventType: contact?.event,
                })
                event.stopPropagation()
              }}
              onOpenContactPanel={onOpenContactPanel}
              setSelectedContact={() => setSelectedContact(contact)}
            />
          </>
        ))}
      </Table>
    </TableWrapper>
    <PaginationV2Wrapper>
      <PaginationV2Container
        isShowStatus
        isShowInput
        total={pagination?.total}
        page={pagination?.page}
        perPage={pagination?.perPage}
        onChangePage={handleChangePage}
      />
    </PaginationV2Wrapper>
  </FlexWrapper>
)

UpcomingEventTemplate.defaultProps = {
  currentSortContactValue: {},
}

export default UpcomingEventTemplate
