import { takeLatest, put, call } from 'redux-saga/effects'

import {
  FETCH_ACCOUNT_INFORMATION,
  fetchAccountInformationAPI,
} from 'v3/containers/identity-verification/actions'
import { fetchAccountInformationInstance } from 'v3/containers/identity-verification/api-instances'

export function* fetchAccountInformationSaga() {
  yield put(fetchAccountInformationAPI.request())
  try {
    const response = yield call(fetchAccountInformationInstance)
    yield put(fetchAccountInformationAPI.success(response))
  } catch (err) {
    yield put(fetchAccountInformationAPI.failure(err))
  }
}

export function* watchFetchAccountInformation() {
  yield takeLatest(FETCH_ACCOUNT_INFORMATION, fetchAccountInformationSaga)
}
