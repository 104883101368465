import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'
import { getMySeenAnnouncement } from 'v3/services/announcement'

/**
 * useGetSeenAnnouncements a hook that will call to getMySeenAnnouncement api
 *
 * @param {import('@tanstack/react-query').UseQueryOptions<Awaited<ReturnType<typeof getMySeenAnnouncement>>} options configurations for useQuery
 */
export const useQuerySeenAnnouncements = options => {
  const userProfile = useSelector(selectProfile)

  return useQuery({
    queryKey: ['getMySeenAnnouncement', userProfile?.id],
    queryFn: getMySeenAnnouncement,
    staleTime: 1000,
    refetchOnWindowFocus: false,
    ...options,
  })
}
