import React from 'react'

import { EmptyContact } from '../../organisms/empty-contact-landing-component/empty-contact'

import { EMPTY_CONTACT_GROUP_MENU_LIST } from './constants'

export const EmptyContactComponent = ({ currentGroupPath, filter, onClickActions = {} }) => {
  const groupInfo = EMPTY_CONTACT_GROUP_MENU_LIST(currentGroupPath, filter)
  return (
    <EmptyContact
      title={groupInfo?.title}
      description={groupInfo?.description}
      imgSrc={groupInfo?.imgSrc}
      buttonName={groupInfo?.buttonName}
      buttonIcon={groupInfo?.buttonIcon}
      onClick={onClickActions[groupInfo?.buttonActionName]}
    />
  )
}

export default EmptyContactComponent
