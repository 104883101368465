import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import ContactPhoto from 'components/contacts/atoms/contact-photo'
import Star from 'components/contacts/atoms/star'
import { breakpoints } from 'styles/settings'

const Container = styled.div`
  max-width: 100%;
  min-width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 15px 25px 15px 5px;
  box-sizing: border-box;
  ${breakpoints.mobile(`
    padding: 15px 10px 0px 5px;
    border-bottom: none;
  `)};
`

const AvatarContainer = styled.div`
  padding: 0 14px 0 8px;
  > div {
    width: 36px;
    height: 34px;
  }
`

const NameContainer = styled.div`
  width: 0;
  flex: 1;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  ${breakpoints.mobile(`
    font-size: 18px;
`)};
`

const NameText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UpcomingEventIdentityCell = ({
  imagePlaceholder,
  image,
  firstName,
  middleName,
  lastName,
  onStarContact,
  starredAt,
}) => (
  <Container>
    <Star isActive={!!starredAt} onClick={onStarContact} />
    <AvatarContainer>
      <ContactPhoto image={image} imagePlaceholder={imagePlaceholder} isDashboard />
    </AvatarContainer>
    <NameContainer>
      <NameText>
        {firstName} {middleName} {lastName}
      </NameText>
    </NameContainer>
  </Container>
)

UpcomingEventIdentityCell.propTypes = {
  imagePlaceholder: PropTypes.string,
  image: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
}

export default UpcomingEventIdentityCell
