import produce from 'immer'

import { OPEN_FILTER_MODAL, CLOSE_FILTER_MODAL } from './constants'

export const initialState = {
  isOpen: false,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type }) =>
  produce(state, draft => {
    switch (type) {
      case OPEN_FILTER_MODAL:
        draft.isOpen = true
        break
      case CLOSE_FILTER_MODAL:
        draft.isOpen = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
