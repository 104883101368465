import { Divider } from '@mui/material'
import Step from '@rentspree/component-2023.components.organisms.step'
import StepLabel from '@rentspree/component-2023.components.organisms.step-label'
import Stepper from '@rentspree/component-2023.components.organisms.stepper'
import React from 'react'

export const RentPaymentStepper = ({ stepTitles, pageIndex, handleClick }) => {
  /*
   * Generates a Rent Payment flow specific stepper, letting the caller specify the titles so that as pages
   * might change, this component can react dynamically.
   *
   * The parent page's state needs to be coordinated between this Stepper & the footer's next/back/etc. buttons,
   * if the stepper is going to allow for navigation at all. Leaving the state variable & setter to the parent scope.
   */

  return (
    <Divider
      sx={{
        position: 'relative',
        width: 'calc(100% - 50px)',
        margin: '25px',
        overflowX: 'auto',
      }}
    >
      <Stepper activeStep={pageIndex} sx={{ overflowX: 'auto' }}>
        {stepTitles.map((step, i) => (
          <Step key={step}>
            <StepLabel
              // 'icon' prop can be customized, but not set blank atm?
              onClick={e => {
                // classList is a DOMTokenList, which uses .contains() vs an Array.includes()
                const isDisabled = e.currentTarget.classList.contains('Mui-disabled')
                if (handleClick !== undefined && !isDisabled) {
                  // stepper should always be changing the top-level/L1 index
                  handleClick(i)
                }
              }}
            >
              {step}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Divider>
  )
}
