import get from 'lodash/get'
import { createSelector } from 'reselect'

import { initialState } from './reducer'
import { countFilter, mapQueryStringToFilters } from './utils'

export const getContactList = state => get(state, 'contacts.list', initialState)

export const selectData = createSelector(getContactList, list =>
  get(list, 'data', initialState.data),
)

export const selectContactSettings = createSelector(getContactList, list =>
  get(list, 'settings', initialState.settings),
)

export const selectIsLoading = createSelector(getContactList, list =>
  get(list, 'isLoading', initialState.isLoading),
)

export const selectIsLoadingDone = createSelector(getContactList, list =>
  get(list, 'isLoadingDone', initialState.isLoadingDone),
)

export const selectIsError = createSelector(getContactList, list =>
  get(list, 'isError', initialState.isError),
)

export const selectIsInitial = createSelector(getContactList, list =>
  get(list, 'isInitial', initialState.isInitial),
)

export const selectCurrentFilterCount = createSelector(
  state => get(state, 'router.location.search'),
  search => countFilter(mapQueryStringToFilters(search)),
)

export const selectHasQueryOption = createSelector(
  state => get(state, 'router.location.search'),
  search => {
    const { page, perPage, ...filters } = mapQueryStringToFilters(search)
    return Object.keys(filters).length > 0
  },
)

export const selectPagination = createSelector(getContactList, list =>
  get(list, 'pagination', initialState.pagination),
)

export const selectIsLoadingTag = createSelector(getContactList, list =>
  get(list, 'isLoadingTag', initialState.isLoadingTag),
)

export const selectSelectedTag = createSelector(
  state => get(state, 'router.location.search'),
  search => mapQueryStringToFilters(search)?.tagIds?.split('_'),
)

export const selectCounts = createSelector(getContactList, list =>
  get(list, 'counts', initialState.counts),
)

export const selectIsLoadingCount = createSelector(getContactList, list =>
  get(list, 'counts.isLoading', initialState.counts.isLoading),
)

export const selectBulkSelected = createSelector(getContactList, list =>
  get(list, 'bulkSelected', initialState.bulkSelected),
)

export const selectBulkContactsSelected = createSelector(getContactList, list => {
  const selectedContacts = get(list, 'bulkSelected', [])
  const contacts = get(list, 'data', [])
  // eslint-disable-next-line no-underscore-dangle
  return contacts.filter(contact => selectedContacts.includes(contact?._id))
})

export const selectBulkState = createSelector(getContactList, list =>
  get(list, 'bulkState', initialState.bulkState),
)

export const selectIsBulkLoading = createSelector(getContactList, list =>
  get(list, 'isBulkLoading', initialState.isBulkLoading),
)

export const selectIsLoadingSendMessage = createSelector(getContactList, list =>
  get(list, 'isLoadingSendMessage', initialState.isLoadingSendMessage),
)
