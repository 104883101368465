// TODO: Should not adding a new route in this file so those routes have been moved
// to ฺBoilerplate structure so that we should use route in that place
import { MAIN_URL, API_URL, USER_API_URL, PRINT_PDF_URL, FILES_URL } from '../../env'

export const ROOT = '/'
export const DASHBOARD = process.env.BASE_NAME || '/dashboard'
export const RENTAL = '/rental-submission'
export const RENTAL_SUBMISSION = '/rental-submissions'
export const GETTING_STARTED = '/getting-started'
export const HOW_TO = '/how-to'
export const PROPERTY = '/properties'
export const APPLICATION_LIST = '/properties/:propertyId'
export const APPLICATION = '/rental'
export const NOT_FOUND = '/not-found'
export const REQUEST = '/request'
export const REQUEST_UPDATE_INFO = '/request/update-info'
export const REQUEST_UPDATE_PROPERTY = '/request/update-property'
export const PERMALINK_ACTIONS = '/request/application'
export const PERMALINK_ACTIONS_ID = '/request/application/:propertyId'
export const PERMALINK_ACTIONS_SCREENING = '/request/application/:propertyId/actions'
export const SUPPORT_URL = process.env.SUPPORT_URL || 'https://support.rentspree.com'
export const SUPPORT_PROMO_CODE_URL = `${SUPPORT_URL}/how-to-apply-coupon`
export const SUPPORT_REFUND_URL = `${SUPPORT_URL}/rentspree-refund-policy`
export const SUPPORT_TERMS_URL = `${MAIN_URL}/rentspree-supplemental-terms-rentspree-pro`
export const SUPPORT_HELP_PRO_URL = `${SUPPORT_URL}/for-agents-owners-managers#rentspree-pro`
export const SUPPORT_HELP_REQUEST_DOCS_URL = `${SUPPORT_URL}/what-is-document-upload`
export const SUPPORT_PARTICIPATING_PARTNERS_URL = `${SUPPORT_URL}/rentspree-pro-participating-partners`
export const SUPPORT_FAQ_URL = `${SUPPORT_URL}/en/refer-earn-referral-program-terms-and-conditions`
export const SUPPORT_TERM_AND_CONDITION_URL = `${SUPPORT_URL}/rentspree-referral-terms`
export const SUPPORT_LINK_ZIPFORM_ACCOUNT_URL = `${SUPPORT_URL}/link-rentspree-zipform-plus`
export const REFERRAL_FORM_PAGE = '/referral-form'
export const PRO_PAGE = '/rentspree-pro'
export const REQUEST_DOCS_PAGE = `${PRO_PAGE}/request-docs`
export const REF_CHECKS_PAGE = `${PRO_PAGE}/reference-checks`
export const PAYMENT_PAGE = '/payment'
export const SUBSCRIPTION_PAYMENT = `${PRO_PAGE}${PAYMENT_PAGE}`
export const WEB_CONTACT_PAGE = `${process.env.MAIN_URL}/contact-us`
export const VERSION_CHECK_API = `${process.env.BASE_NAME}/version`
export const VERSION_META_CHECK_API = `${process.env.BASE_NAME}/htmlversion`
export const RENT_ESTIMATE_PAGE = '/rental-estimate'
export const LEASE = '/esign-documents'

export const SINGLE_PROPERTY = `${PROPERTY}/:propertyId`
export const SINGLE_PROPERTY_WITH_TYPE = `${SINGLE_PROPERTY}/:actionType(rental|rent-estimate|esign-documents|overview|renter-insurance|rent-payments|listing)`
export const SINGLE_PROPERTY_APPLICATION_LIST = `${SINGLE_PROPERTY}/rental`
export const SINGLE_APPLICATION = `${SINGLE_PROPERTY_APPLICATION_LIST}/:rentalAppId`
export const SINGLE_APPLICATION_REGEXP = new RegExp(`${PROPERTY}/[^/]*${APPLICATION}/[^/]*`)
export const RENT_ESTIMATE_PROPERTY = `${PROPERTY}/:propertyId/rent-estimate`
export const DOWNLOAD = '/download'
export const DOCUMENT = `${DOWNLOAD}/rental/:rentalAppId/documents/:documentId/download`
export const DOCUMENT_FULL = `${DASHBOARD}${DOCUMENT}`
export const REPORT_TYPE = `${DOWNLOAD}/:reportType`
export const EXPLORE_PAGE = '/explore'

// PRINT REPORTS PAGE
export const IFRAME_PATH = '/iframe/:userType(landlord|renter)'
export const OLD_PRINT_REPORTS_PAGE = `${RENTAL}/:rentalAppId`
export const OLD_PRINT_REPORTS_IFRAME_PAGE = `${IFRAME_PATH}${RENTAL}/:rentalAppId`
export const PRINT_REPORTS_PAGE = `/reports${SINGLE_PROPERTY}/rental-submissions/:rentalAppId`
export const PRINT_REPORTS_IFRAME_PAGE = `${IFRAME_PATH}${PRINT_REPORTS_PAGE}`
export const PRINT_RENTAL_SUBMISSION = `${DASHBOARD}${PRINT_REPORTS_PAGE}/:type`

// OUTSIDE PATH
export const UPLOAD_DOCUMENT_VIDEO = 'https://www.youtube.com/embed/aSETxOitHVQ?rel=0&showinfo=0'
export const HOW_TO_REQUEST_DOC_VIDEO = 'https://www.youtube.com/embed/K2E-zKC3-KM?rel=0&showinfo=0'
// API PATH

// Agent Property API
export const PROPERTY_API_V2 = `${API_URL}/api/v2/agent/properties`
export const PROPERTY_DETAIL_V2 = `${PROPERTY_API_V2}/:propertyId`
export const APPLICATION_LIST_API = `${PROPERTY_API_V2}/:propertyId/rental-submissions`
export const REACTIVATE_PROPERTY_API = `${PROPERTY_API_V2}/:propertyId/deactivate`
export const GET_SUBMISSION_PARTICIPANT = `${APPLICATION_LIST_API}/:rentalId`

// Portals API
export const ZIPLOGIX_PROPERTY_API = `${API_URL}/api/portals/ziplogix/property/:transactionId`
export const PROPERTY_DROPDOWN_API = `${API_URL}/api/portals/properties`

// Rental Submission API
export const RENTAL_SUBMISSION_API_V2 = `${API_URL}/api/v2/rental-submissions/:rentalId`
export const FORM_AVAILABLE_API = `${RENTAL_SUBMISSION_API_V2}/available`
export const REFERENCES_API = `${RENTAL_SUBMISSION_API_V2}/reference-checks`
export const RENTAL_DOCUMENT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/documents`
export const RENTAL_DOCUMENT_TOKEN_V2 = `${RENTAL_DOCUMENT_API_V2}/:documentId`
export const PAYMENT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/credit-report/payment`
// RentEstimate
export const RENT_ESTIMATE_API = `${API_URL}/api/v2/rent-estimate`
// Application API
export const APPLICATION_V2_DETAIL_API = `${RENTAL_SUBMISSION_API_V2}/application`
// Accept Deny
export const ACCEPT_DENY_API = `${RENTAL_SUBMISSION_API_V2}/applicant/:type`

// Credit Report API
export const OPEN_CREDIT_REPORT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/credit-report/open`
export const GET_CREDIT_REPORT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/credit-report/full-report`

// Generate PDF API
export const GENERATE_REPORT_API = `${PRINT_PDF_URL}/:reportType`

// Get LRA PDF API
export const LRA_PDF_API = `${PRINT_PDF_URL}/rental-submission/:rentalId/lra`

// Screening Plan API
export const SCREENING_PLAN_API = `${API_URL}/api/v2/screening-plans`

// User API
export const USER_API = `${USER_API_URL}/api/v2/user`
export const USER_ME_API = `${USER_API}/me`
export const USER_INTEGRATION_API = `${USER_ME_API}/integration`
export const USER_PREFERENCE_API = `${USER_ME_API}/preference`

// LRA APU
export const LRA_FORM_API = `${API_URL}/api/v2/rental-submissions/:rentalSubmissionId/forms`

export const DOWNLOAD_PDF_API = `${FILES_URL}/rental-submission/:rentalSubmissionId/full-documents`

// Full Document Zip API
export const FULL_DOC_ZIP_API = `${FILES_URL}/rental-submission/:rentalId/documents`

// MORE PAGE
export const MORE_PAGE = `${PROPERTY}/:propertyId/more`

// OVERVIEW PAGE
export const PROPERTY_OVERVIEW_PAGE = `${SINGLE_PROPERTY}/overview`
// Lease Agreement Page
export const LEASE_AGREEMENT_PAGE = `${PROPERTY}/:propertyId${LEASE}`
export const LEASE_AGREEMENT_REQUEST = `${LEASE_AGREEMENT_PAGE}/request`
export const LEASE_AGREEMENT_DETAILS = `${LEASE_AGREEMENT_PAGE}/:id`

// RENTER INSURANCE
export const RENTER_INSURANCE = '/renter-insurance'
export const RENTER_INSURANCE_PAGE = `${SINGLE_PROPERTY}${RENTER_INSURANCE}`
export const RENTER_INSURANCE_DETAIL = `${SINGLE_PROPERTY}${RENTER_INSURANCE}/:rentersInsuranceId`

// RENT PAYMENT
export const RENT_PAYMENT = '/rent-payments'
export const RENT_PAYMENT_PAGE = `${SINGLE_PROPERTY}${RENT_PAYMENT}`
export const RENT_PAYMENT_ID_PAGE = `${RENT_PAYMENT_PAGE}/:rentPaymentId`

// POLLING SERVICE
export const POLLING_SERVICE = `${API_URL}/api/v2/files`
export const GENERATE_REPORT_FILES = `${POLLING_SERVICE}/reports`
export const POLLING_REPORT_FILES_STATUS = `${POLLING_SERVICE}/reports/:transactionId/status`
export const GENERATE_REPORTS_PAGE = '/generate/:reportType'
