import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const defaultButtonStyles = {
  width: 'fit-content',
  minWidth: '48px',
  textTransform: 'none',
  borderRadius: '8px',
  fontFamily: 'Inter',
  fontWeight: 500,
  textAlign: 'left',
  fontSize: '2rem!important',
}

export const BackButton = styled(MuiButton)(() => ({
  ...defaultButtonStyles,

  color: 'black',
  '&:hover': {
    color: '#606060',
    textDecoration: 'underline',
  },
  textDecoration: 'underline',
}))

export const NextButton = styled(MuiButton)(() => ({
  ...defaultButtonStyles,

  color: 'white',
  backgroundColor: 'black',
  '&:hover': {
    color: 'white',
    backgroundColor: '#606060',
  },
}))
