import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import get from 'lodash/get'
import React from 'react'
import styled from 'styled-components'

import ChatIcon from 'components/contacts/atoms/chat-icon'
import Dropdown from 'components/contacts/atoms/dropdown-v2'
import SmallButton from 'components/contacts/atoms/small-button'
import LabelWithImage from 'components/contacts/molecules/label-with-image'
import UpcomingEventIdentityCell from 'components/contacts/molecules/upcoming-event-identity-cell'
import { UpcomingEventTypeCell } from 'components/contacts/molecules/upcoming-event-type-cell'
import { UPCOMING_EVENT_TYPES } from 'components/contacts/molecules/upcoming-event-type-cell/constants'
import {
  CHAT_PANEL,
  CHOOSE_GIFT_PANEL,
} from 'components/contacts/templates/contact-dashboard-template/constants'
import { ROLE_OPTIONS } from 'containers/contacts/constants'
import { convert } from 'helpers/convert'
import CheckIcon from 'images/icons/contacts/check.svg'
import { breakpoints, COLOR } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT
const { CATEGORY } = EVENT_PEOPLE_CONCEPT.EVENT_PROPERTY

const DataContainer = styled.tr`
  height: 60px;
  cursor: pointer;
  &:hover {
    background-color: ${COLOR.bgGrey};
    transition: 0.2s;
  }

  background: ${({ background }) => background};
  ${breakpoints.mobile(`
    display: none;
  `)};
`

const Td = styled.td`
  padding-right: 25px;
  border-bottom: 1px solid ${COLOR.grayLv6};
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  box-sizing: border-box;
  ${breakpoints.mobile(`
    border-bottom: none;
  `)};
`

const CheckboxContainer = styled.td`
  padding-left: 5px;
`

const DateCell = styled(Td)`
  font-weight: 600;
`

const ActionCell = styled(Td)`
  .open {
    position: absolute;
  }
  > div {
    display: inline-block;
  }
  white-space: initial;
  text-overflow: unset;
`

const ChatButton = styled.div`
  cursor: pointer;
`

const StyledDropdownWrapper = styled(Dropdown)`
  top: initial;
  right: initial;
  margin: -17.5px 0;

  .dropdown-menu {
    min-width: initial;
    width: initial;
    margin-top: 5px;
  }

  a[role='menuitem'] {
    padding: 10px 20px;
  }
`

const UpcomingEventListDesktop = ({
  _id,
  firstName,
  lastName,
  middleName,
  email,
  imagePlaceholder,
  role,
  onClickContactRow,
  moveInDate,
  birthDate,
  daysTillMoveInAnniversary,
  daysTillMovingIn,
  daysTillBirthday,
  bulkSelected,
  onToggleSelectedBulk,
  onStarContact,
  starredAt,
  bulkState,
  isUnseen,
  event = UPCOMING_EVENT_TYPES.BIRTHDAY,
  isGiftSent,
  onMarkAsGiftSent,
  onUnmarkGiftSent,
  onOpenContactPanel,
  setSelectedContact,
}) => {
  const handleOpenGiftPanel = e => {
    e.stopPropagation()
    tracker.trackEvent(EVENT_NAME.CLICK_SEND_ANOTHER_GIFT, {
      category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
    })
    onOpenContactPanel({
      contentType: CHOOSE_GIFT_PANEL,
      data: { firstName, lastName, middleName, email, isGiftSent },
    })
  }

  const menuItems = isGiftSent
    ? [
        {
          label: 'Unmark As Sent',
          onClick: onUnmarkGiftSent,
        },
        {
          label: 'Send Another',
          onClick: handleOpenGiftPanel,
        },
      ]
    : [
        {
          label: 'Mark As Sent',
          onClick: onMarkAsGiftSent,
        },
      ]

  return (
    <DataContainer onClick={onClickContactRow} background={isUnseen ? COLOR.blueLv7 : COLOR.white}>
      {bulkState && (
        <CheckboxContainer>
          <Checkbox
            id="contact_checkbox"
            simple
            isSelected={bulkSelected.includes(_id)}
            bgSimpleTransparent
            onClick={onToggleSelectedBulk}
          />
        </CheckboxContainer>
      )}
      <Td>
        <UpcomingEventIdentityCell
          imagePlaceholder={imagePlaceholder}
          firstName={firstName}
          lastName={lastName}
          moveInDate={moveInDate}
          birthDate={birthDate}
          daysTillMoveInAnniversary={daysTillMoveInAnniversary}
          daysTillMovingIn={daysTillMovingIn}
          daysTillBirthday={daysTillBirthday}
          onStarContact={onStarContact}
          starredAt={starredAt}
        />
      </Td>
      <UpcomingEventTypeCell type={event} />
      <DateCell>
        {event === UPCOMING_EVENT_TYPES.BIRTHDAY
          ? convert(birthDate, 'dateOfUTC', 'DD MMM')
          : convert(moveInDate, 'dateOfUTC', 'DD MMM')}
      </DateCell>
      <Td>
        {role ===
        get(
          ROLE_OPTIONS.find(({ value }) => value === 'none'),
          'label',
        )
          ? '-'
          : role}
      </Td>
      <ActionCell>
        {isGiftSent ? (
          <LabelWithImage icon={CheckIcon} color={COLOR.cyanGreen} label="Gift Sent" />
        ) : (
          <SmallButton
            text="Send Gift"
            onClick={e => {
              e.stopPropagation()
              tracker.trackEvent(EVENT_NAME.START_SENDING_GIFT, {
                category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
              })
              onOpenContactPanel({
                contentType: CHOOSE_GIFT_PANEL,
                data: {
                  contactId: _id,
                  eventType: event,
                  firstName,
                  lastName,
                  middleName,
                  email,
                  isGiftSent,
                },
              })
            }}
          />
        )}
      </ActionCell>
      <ActionCell>
        <ChatButton
          onClick={e => {
            e.stopPropagation()
            setSelectedContact()
            tracker.trackEvent(EVENT_NAME.START_SENDING_MESSAGE, {
              click_from: EVENT_PROPERTY.CLICK_FROM.UPCOMING_EVENTS,
              category: CATEGORY.PEOPLE_CONCEPT,
            })
            onOpenContactPanel({
              contentType: CHAT_PANEL,
              data: {
                firstName,
                middleName,
                lastName,
                email,
                imagePlaceholder,
              },
            })
          }}
        >
          <ChatIcon />
        </ChatButton>
      </ActionCell>
      <ActionCell>
        <StyledDropdownWrapper menuItems={menuItems} />
      </ActionCell>
    </DataContainer>
  )
}

export default UpcomingEventListDesktop
