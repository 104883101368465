import { query } from '@rentspree/path'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash/isNil'
import pick from 'lodash/pick'
import moment from 'moment'

import { RECENT_CONTACT_RADIO_CHOICES } from 'components/contacts/organisms/filter-form/constants'
import { CONTACT_GROUP } from 'constants/route'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import { MAX_UPCOMING_DAYS } from '../constants'

const { CLICK_FROM } = EVENT_PEOPLE_CONCEPT.EVENT_PROPERTY

export const mapFiltersToPayload = ({ group = 'all', page = 1, sort = {}, filters = {} }) => ({
  group,
  page,
  sort,
  ...filters,
})

export const mapQueryStringToFilters = (queryString = '') => ({
  ...pick(query.parse(queryString), [
    'page',
    'perPage',
    'role',
    'source',
    'startBirthDate',
    'endBirthDate',
    'city',
    'state',
    'workStatus',
    'minIncome',
    'maxIncome',
    'lastContact',
    'tagIds',
    'search',
    'sort',
    'eventType',
    'dateType',
    'starred',
  ]),
})

export const countFilter = (filters = {}) => {
  let count = 0

  if (filters.role) count += 1
  if (filters.source) count += 1
  if (filters.state) count += 1
  if (filters.startBirthDate || filters.endBirthDate) count += 1
  if (filters.minIncome || filters.maxIncome) count += 1
  if (filters.lastContact && filters.lastContact !== RECENT_CONTACT_RADIO_CHOICES[0].value) {
    count += 1
  }
  return count
}

export const appendArrayValue = (currentValue = '', addingValue) => {
  const arrayValue = currentValue.split('_')
  arrayValue.push(addingValue)
  const filtered = [...new Set(arrayValue.filter(value => !isEmpty(value)))]
  return filtered.join('_')
}

export const removeArrayValue = (currentValue = '', removingValue) => {
  const arrayValue = currentValue.split('_')
  return arrayValue.filter(value => value !== removingValue).join('_')
}

export const isTagSelected = (tagId, selectedTag = []) => selectedTag.includes(tagId)

export const updateBulkSelectedAll = (selected, contacts) => {
  const isAllSelected = contacts.every(contact => selected.includes(contact?._id))
  contacts.forEach(contact => {
    if (isAllSelected) {
      const index = selected.indexOf(contact?._id)
      selected.splice(index, 1)
    } else {
      const index = selected.indexOf(contact?._id)
      if (index === -1) {
        selected.push(contact?._id)
      }
    }
  })

  return selected
}

export const getDefaultSortedKey = (pathname = '') => {
  const contactPathname = pathname.replace(/\/client-manager\//gm, '')
  switch (contactPathname) {
    case CONTACT_GROUP.RECENT_ACTIVITY:
      return {}
    case CONTACT_GROUP.UPCOMING_EVENT:
      return {}
    default:
      return { lastContactedAt: -1 }
  }
}
export const mapPathToEventProperty = path => {
  switch (path) {
    case CONTACT_GROUP.STARRED:
      return CLICK_FROM.CONTACTS_STARRED
    case CONTACT_GROUP.RENTSPREE_APPLICATION:
      return CLICK_FROM.CONTACTS_FROM_RENTSPREE
    case CONTACT_GROUP.RECENT_ACTIVITY:
      return CLICK_FROM.CONTACTS_RECENT_ACTIVITY
    case CONTACT_GROUP.UPCOMING_EVENT:
      return CLICK_FROM.CONTACTS_UPCOMING_EVENT
    default:
      return CLICK_FROM.CONTACTS_ALL
  }
}

export const isUpcomingDate = daysTill => !isNil(daysTill) && daysTill <= MAX_UPCOMING_DAYS

export const calDaysUntilNextAnniversary = date => {
  const today = moment.utc().format('YYYY-MM-DD')
  const year = moment(today).diff(date, 'years')

  let nextAnniversaryDate = moment(date).add(year, 'years')
  if (nextAnniversaryDate.isSame(today)) return 0

  if (nextAnniversaryDate.diff(today, 'days') < 0) {
    nextAnniversaryDate = moment(date).add(year + 1, 'years')
  }
  return nextAnniversaryDate.diff(today, 'days')
}
