import get from 'lodash/get'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getFilterModal = state => get(state, 'contacts.filterModal', initialState)

export const selectIsOpen = createSelector(getFilterModal, modal =>
  get(modal, 'isOpen', initialState.isOpen),
)
