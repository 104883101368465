import React from "react"
import { PanelGroup, Panel } from "react-bootstrap"
import { RouterLink, Link } from "../buttons/buttons"
import * as PATH from "../../constants/route-consts"
import { P, Ul, Ol } from "../typography"
import { PanelHeading } from "./panel-box"

export const SubscriptionFaq = ({ clickPanel, headerWithIcon }) => (
  <PanelGroup accordion id="subscriptionFaqAccordion">
    <Panel eventKey={1} onClick={() => clickPanel("clickFaqWhatIsPro")}>
      <PanelHeading>{headerWithIcon("What is RentSpree PRO?")}</PanelHeading>
      <Panel.Body collapsible>
        <P>
          Regular RentSpree allows you to access a completed rental application
          and a TransUnion screening package, which includes a full credit
          report, criminal background check and eviction history.
        </P>
        <div>
          <Ul bullet>
            <li>
              <P>
                Document Collection: Receive a truly complete application
                package by allowing renters to upload supplemental documents.
                View pay stubs, bank statements, driver license copies, and more
                to verify applicant information.&nbsp;
                <RouterLink
                  id="faqLinkToRequestDocsPage"
                  inlineLink
                  to={PATH.REQUEST_DOCS_PAGE}
                  text="Learn more"
                />
              </P>
            </li>
            <li>
              <P>
                Auto Reference Checks: Want to hear from applicants’ previous
                landlords, employers, and references? We will reach out for you
                to gather crucial information on prospective tenants.&nbsp;
                <RouterLink
                  id="faqLinkToRefChecksPage"
                  inlineLink
                  to={PATH.REF_CHECKS_PAGE}
                  text="Learn more"
                />
              </P>
            </li>
          </Ul>
        </div>
      </Panel.Body>
    </Panel>
    <Panel eventKey={2} onClick={() => clickPanel("clickFaqHowAccessPro")}>
      <PanelHeading>
        {headerWithIcon("How can I access RentSpree PRO features?")}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          RentSpree PRO is available at no cost to members of RentSpree’s
          clients. If your REALTOR{"\u00AE"} Association, brokerage, or property
          management group is a RentSpree affiliate or client, you can receive a
          coupon code to unlock RentSpree PRO. Please&nbsp;
          <Link
            id="supportParticipatingPartnersLink"
            inlineLink
            text="click here"
            href={PATH.SUPPORT_PARTICIPATING_PARTNERS_URL}
            target="_blank"
          />{" "}
          to see if your organization has provided you with access.
        </P>
        <P>
          If your organization is not affiliated with RentSpree and you would
          still like to use RentSpree PRO, you can unlock RentSpree PRO through
          making referrals. For each property representative you refer to
          RentSpree who receives a rental application, you will get unlimited
          access to RentSpree PRO for 6 months.
        </P>
        <P>
          In addition, anyone who would like to receive access to RentSpree PRO
          can have unlimited use for a 1 month trial. Thereafter, you can opt
          into a monthly or annual subscription by adding&nbsp;
          <RouterLink
            id="goToPaymentPageLink1"
            to={PATH.SUBSCRIPTION_PAYMENT}
            inlineLink
            text="payment information"
          />
          .
        </P>
      </Panel.Body>
    </Panel>
    <Panel eventKey={3} onClick={() => clickPanel("clickFaqNeedCreditCard")}>
      <PanelHeading>
        {headerWithIcon("Do I need a credit card to sign up?")}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          You do not need to provide a credit card to get unlimited access to
          RentSpree PRO for 1 month. Simply sign up and start using!
        </P>
      </Panel.Body>
    </Panel>
    <Panel eventKey={4} onClick={() => clickPanel("clickFaqTrialEnds")}>
      <PanelHeading>
        {headerWithIcon("What happens when my trial ends?")}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          When your RentSpree PRO trial ends, your access to RentSpree PRO
          features and to any uploaded documents will be suspended until you
          reactivate your subscription.
        </P>
        <P>You have a few options to reactivate:</P>
        <Ul bullet>
          <li>
            <P>
              <strong>Add payment information.</strong> You will gain access to
              all RentSpree PRO services immediately. To learn how to reactivate
              your subscription, click &nbsp;
              <RouterLink
                id="goToPaymentPageLink2"
                to={PATH.SUBSCRIPTION_PAYMENT}
                inlineLink
                text="payment information"
              />
              .
            </P>
          </li>
          <li>
            <P>
              <strong>Get a promotional code from our clients.</strong> If you
              are a member of one of our partner organizations, you are provided
              access to RentSpree PRO for free. To see a list of&nbsp;
              <Link
                id="gotoSupportArticle1"
                href={PATH.SUPPORT_PARTICIPATING_PARTNERS_URL}
                inlineLink
                text="participating partners"
                target="_blank"
              />{" "}
              or to learn how to obtain an access code,&nbsp;
              <Link
                id="gotoSupportArticle2"
                href={PATH.SUPPORT_PROMO_CODE_URL}
                inlineLink
                text="click here"
                target="_blank"
              />
              .
            </P>
          </li>
          <li>
            <P>
              <strong>Refer new RentSpree users.</strong> For each new user you
              refer to RentSpree who receives a rental application, you will get
              unlimited access to RentSpree PRO for 6 months.
            </P>
          </li>
        </Ul>
      </Panel.Body>
    </Panel>
    <Panel eventKey={5} onClick={() => clickPanel("clickFaqHowUseCoupon")}>
      <PanelHeading>
        {headerWithIcon("How do I use a coupon code?")}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          <Ol size="16px">
            <li>
              When you are on the payment page, you will see a{" "}
              <strong>coupon section</strong> at the top.
            </li>
            <li>Enter your code</li>
            <li>
              Click <strong>Redeem</strong>
            </li>
          </Ol>
        </P>
      </Panel.Body>
    </Panel>
    <Panel eventKey={6} onClick={() => clickPanel("clickFaqCancelMember")}>
      <PanelHeading>
        {headerWithIcon("What if I want to cancel my membership?")}
      </PanelHeading>
      <Panel.Body collapsible>
        <P>
          You can cancel your RentSpree PRO membership at any time. Refunds and credits aren't issued for partial billing periods. Learn more about our
          &nbsp;
          <Link
            id="supportRefundPolicyLink"
            href={PATH.SUPPORT_REFUND_URL}
            inlineLink
            text="refund policy"
            target="_blank"
          />
          .
        </P>
      </Panel.Body>
    </Panel>
  </PanelGroup>
)
