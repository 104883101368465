import get from "lodash/get"
import {
  takeLatest,
  put,
  call,
  all,
  delay,
  select,
  fork,
} from "redux-saga/effects"

import {
  ESIGN_TEMPLATE_LIST_API,
  USER_LINK_ACCOUNT_API,
  AGENT_PROFILE_API,
} from "constants/route"
import tracker from "tracker"
import {
  LEASE_DOC_TEMPLATE_EVENT,
  LRA_INTEGRATION_PROPERTIES,
} from "tracker/const"
import { getLinkAccountData } from "containers/request/step-screening-options/saga"
import { SCREENING_REQUEST } from "containers/request/step-screening-options/constants"

import {
  apiInstanceWithErrorHandler,
  UserApiInstance,
  apiInstance,
} from "../../../utils/api-interceptor"
import {
  eSignTemplateListApi,
  closeErrorInModal,
  linkingAccountWithoutSSOApi,
  setCarModalState,
} from "./actions"
import {
  GET_ESIGN_TEMPLATE_LIST_CALL,
  ERROR_MESSAGE,
  LINKING_ACCOUNT_WITHOUT_SSO_CALL,
} from "./constants"
import { makeSelectPrevTemplateData } from "./selectors"
import {
  updateEnvelopeByTemplate,
  getUserIntegrationsSuccess,
} from "../actions"
import { USER_INTEGRATION_API } from "../../../legacy/constants/route-consts"

export const callTemplateAPI = () =>
  apiInstanceWithErrorHandler.get(ESIGN_TEMPLATE_LIST_API)

export function* getESignTemplateListSaga() {
  yield put(eSignTemplateListApi.request())
  try {
    const response = yield call(callTemplateAPI)
    yield put(eSignTemplateListApi.success(response))
  } catch (error) {
    yield put(eSignTemplateListApi.failure(error))
  }
}

export const getUserIntegrationsAPI = () =>
  UserApiInstance.get(USER_INTEGRATION_API)

export const selectPrevTemplateData = makeSelectPrevTemplateData()

export const linkingAccountAPI = payload =>
  UserApiInstance.put(USER_LINK_ACCOUNT_API, payload.data)

export const updateAgentProfileInfo = async brokerage => {
  try {
    await apiInstance.put(AGENT_PROFILE_API, {
      contactInformation: { brokerage },
    })
  } catch (error) {
    console.error(error)
  }
}

export function* delayCloseModal() {
  yield delay(1500)
  yield put(setCarModalState(false))
}

export function* linkingPartnerAccount(action) {
  yield put(closeErrorInModal())
  yield put(linkingAccountWithoutSSOApi.request())
  try {
    const { payload: linkAccountData } = action
    const { linkingFrom } = linkAccountData
    yield call(linkingAccountAPI, { data: linkAccountData })
    yield call(updateAgentProfileInfo, linkAccountData.brokerInfo)
    const userIntegration = yield call(getUserIntegrationsAPI)
    yield put(getUserIntegrationsSuccess(userIntegration.result || []))
    yield put(linkingAccountWithoutSSOApi.success())

    let clickFromEventProperty =
      LEASE_DOC_TEMPLATE_EVENT.EVENT_PROPERTY.CLICK_FROM.TEMPLATE_SELECTION

    if (linkingFrom === SCREENING_REQUEST) {
      yield call(getLinkAccountData)
      clickFromEventProperty = LRA_INTEGRATION_PROPERTIES.SCREENING_SELECTION
    }

    yield call(
      [tracker, "trackEvent"],
      LEASE_DOC_TEMPLATE_EVENT.EVENT_NAME.VALIDATE_NRDS_ID,
      {
        click_from: clickFromEventProperty,
      },
    )
    yield fork(delayCloseModal)

    const templateData = yield select(selectPrevTemplateData)
    const { envelopeId, payload, nextPath, digitalDocTemplate } =
      templateData || {}
    if (envelopeId && payload && nextPath && digitalDocTemplate) {
      yield put(
        updateEnvelopeByTemplate(
          envelopeId,
          payload,
          nextPath,
          digitalDocTemplate,
          true,
        ),
      )
    }
  } catch (err) {
    const status = get(err, "status")
    let message = ""
    switch (status) {
      case 404:
        message = ERROR_MESSAGE.NOT_FOUND_AND_INVALID
        break
      case 409:
        message = ERROR_MESSAGE.NRDS_ID_ALREADY_LINKED
        break
      case 500:
      default:
        message = ERROR_MESSAGE.GENERAL
        break
    }
    yield put(
      linkingAccountWithoutSSOApi.failure({
        message,
      }),
    )
  }
}

export function* watchApiCall() {
  yield takeLatest(GET_ESIGN_TEMPLATE_LIST_CALL, getESignTemplateListSaga)
}

export function* watchLinkingPartnerAccount() {
  yield takeLatest(LINKING_ACCOUNT_WITHOUT_SSO_CALL, linkingPartnerAccount)
}

export function* rootSaga() {
  yield all([watchApiCall(), watchLinkingPartnerAccount()])
}

export default rootSaga
