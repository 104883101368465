import React, { useState } from "react"
import { PanelGroup, Panel } from "react-bootstrap"
import { RENT_PAYMENT } from "tracker/const"
import { P } from "../typography"
import { PanelHeading } from "./panel-box"
import { RENT_PAYMENT_FAQ } from "./wording"
import { RENT_PAYMENT_FAQ_PANEL_EVENT_PROPERTY } from "./constants"

export const handleToggle = ({ clickPanel, eventKey }) =>
  eventKey &&
  clickPanel(RENT_PAYMENT.EVENT_NAME.INQUIRE_RENT_PAYMENT, {
    click_from: RENT_PAYMENT_FAQ_PANEL_EVENT_PROPERTY[eventKey],
  })

export const RentPaymentFaq = ({ clickPanel, headerWithIcon }) => {
  const [activePanel, setActivePanel] = useState()

  return (
    <PanelGroup
      accordion
      onSelect={e => {
        setActivePanel(e)
        handleToggle({ clickPanel, eventKey: e })
      }}
      activeKey={activePanel}>
      <Panel id="securePayment" eventKey={1}>
        <PanelHeading>
          {headerWithIcon({
            text: RENT_PAYMENT_FAQ.panels[0].header.title,
            collapsed: activePanel === 1,
          })}
        </PanelHeading>
        <Panel.Body collapsible>
          <P size='14px'>{RENT_PAYMENT_FAQ.panels[0].paragraph}</P>
        </Panel.Body>
      </Panel>
      <Panel id="paymentMethod" eventKey={2}>
        <PanelHeading>
          {headerWithIcon({
            text: RENT_PAYMENT_FAQ.panels[1].header.title,
            collapsed: activePanel === 2,
          })}
        </PanelHeading>
        <Panel.Body collapsible>
          <P size='14px'>{RENT_PAYMENT_FAQ.panels[1].paragraph}</P>
        </Panel.Body>
      </Panel>
      <Panel id="processingFee" eventKey={3}>
        <PanelHeading>
          {headerWithIcon({
            text: RENT_PAYMENT_FAQ.panels[2].header.title,
            collapsed: activePanel === 3,
          })}
        </PanelHeading>
        <Panel.Body collapsible>
          <P size='14px'>{RENT_PAYMENT_FAQ.panels[2].paragraph}</P>
        </Panel.Body>
      </Panel>
      <Panel id="processingTime" eventKey={4}>
        <PanelHeading>
          {headerWithIcon({
            text: RENT_PAYMENT_FAQ.panels[3].header.title,
            collapsed: activePanel === 4,
          })}
        </PanelHeading>
        <Panel.Body collapsible>
          <P size='14px'>{RENT_PAYMENT_FAQ.panels[3].paragraph}</P>
        </Panel.Body>
      </Panel>
      <Panel id="oneTimePayment" eventKey={5}>
        <PanelHeading>
          {headerWithIcon({
            text: RENT_PAYMENT_FAQ.panels[4].header.title,
            collapsed: activePanel === 5,
          })}
        </PanelHeading>
        <Panel.Body collapsible>
          <P size='14px'>{RENT_PAYMENT_FAQ.panels[4].paragraph}</P>
        </Panel.Body>
      </Panel>
      <Panel id="businessBank" eventKey={6}>
        <PanelHeading>
          {headerWithIcon({
            text: RENT_PAYMENT_FAQ.panels[5].header.title,
            collapsed: activePanel === 6,
          })}
        </PanelHeading>
        <Panel.Body collapsible>
          <P size='14px'>{RENT_PAYMENT_FAQ.panels[5].paragraph}</P>
        </Panel.Body>
      </Panel>
      <Panel id="agentSetupForClient" eventKey={7}>
        <PanelHeading>
          {headerWithIcon({
            text: RENT_PAYMENT_FAQ.panels[6].header.title,
            collapsed: activePanel === 6,
          })}
        </PanelHeading>
        <Panel.Body collapsible>
          <P size='14px'>{RENT_PAYMENT_FAQ.panels[6].paragraph}</P>
        </Panel.Body>
      </Panel>
    </PanelGroup>
  )
}
