import SelectDropdown from '@rentspree/component-v2/dist/dropdown/select'
import RadioButton from '@rentspree/component-v2/dist/input/radio-button'
import Slider from '@rentspree/component-v2/dist/input/slider'
import S18 from '@rentspree/component-v2/dist/typography/s18'
import React from 'react'
import { Row as BootstrapRow, Col as BootstrapCol } from 'react-bootstrap'
import styled from 'styled-components'

import { Checkbox } from 'components/atoms/checkbox'
import { US_STATES } from 'containers/contacts/constants'
import ScrollToError from 'legacy/components/scroll/scroll-to-error'
import { COLOR, breakpoints } from 'styles/settings'

import {
  RECENT_CONTACT_RADIO_CHOICES,
  ROLE_CHECKBOX,
  LOCATION_DROPDOWN_PLACEHOLDER,
  MIN_MONTHLY_INCOME_RANGE,
  MAX_MONTHLY_INCOME_RANGE,
  MIN_DISTANCE_MONTHLY_INCOME_RANGE,
  SOURCE_CHECKBOX,
} from './constants'
import { groupToRow } from './helpers'

const Container = styled.div`
  padding: 0 25px;
  overflow: auto;
`

const Col = styled(BootstrapCol)`
  padding: 0px;
`

const Row = styled(BootstrapRow)`
  margin: 5px 0px;
  ${breakpoints.mobile(`
   margin: 0px;
  `)};
`

const Thumb = styled.div`
  top: -5px;
  position: absolute;
  z-index: 100;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  border: 6px solid ${COLOR.newDeepBlue};
  border-radius: 100%;
  background: ${COLOR.white};
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  box-sizing: border-box;
  line-height: 50px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const Track = styled.div`
  top: 1px;
  bottom: 0;
  margin-top: ${({ index }) => (index === 1 ? '0' : '2px')};
  height: ${({ index }) => (index === 1 ? '5px' : '1px')};
  border-radius: 999px;
  background: ${COLOR.borderGrey};
`

const MonthlyLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FilterForm = props => {
  const { values = {}, handleBlur, setFieldValue } = props

  const setRoleCheckbox = field => {
    setFieldValue(`role.${field}`, !values?.role[field])
  }

  const setSourceCheckbox = field => {
    setFieldValue(`source.${field}`, !values?.source[field])
  }

  const roleChoices = groupToRow(ROLE_CHECKBOX, 3)
  const sourceChoices = groupToRow(SOURCE_CHECKBOX, 3)
  const recentContactChoices = groupToRow(RECENT_CONTACT_RADIO_CHOICES, 3)

  const setMonthlyIncomeRange = value => {
    setFieldValue('monthlyIncomeRange.min', value[0])
    setFieldValue('monthlyIncomeRange.max', value[1])
  }

  return (
    <Container>
      <ScrollToError containerError {...props} />
      <S18>Role</S18>
      {roleChoices.map(row => (
        <Row>
          {row.map(item => (
            <Col md={4}>
              <Checkbox
                id={`filter-role-${item.value}-checkbox`}
                value={values?.role?.[item.value]}
                label={item.label}
                onClick={() => setRoleCheckbox(item.value)}
              />
            </Col>
          ))}
        </Row>
      ))}
      <S18>Source</S18>
      {sourceChoices.map(source => (
        <Row>
          {source.map(item => (
            <Col md={4}>
              <Checkbox
                id={`filter-source-${item.value}-checkbox`}
                value={values?.source?.[item.value]}
                label={item.label}
                onClick={() => setSourceCheckbox(item.value)}
              />
            </Col>
          ))}
        </Row>
      ))}
      <S18>Location</S18>
      <Row>
        <Col md={12}>
          <SelectDropdown
            placeholder={LOCATION_DROPDOWN_PLACEHOLDER}
            id="filter-location-dropdown"
            options={US_STATES}
            name="location"
            onChange={val => setFieldValue('location', val)}
            onBlur={handleBlur}
            value={values.location}
          />
        </Col>
      </Row>
      <S18>Monthly Income Range</S18>
      <Slider
        id="filter-monthly-income-slider"
        height="24px"
        StyledThumb={Thumb}
        StyledTrack={Track}
        isShowValue={false}
        min={MIN_MONTHLY_INCOME_RANGE}
        max={MAX_MONTHLY_INCOME_RANGE}
        minDistance={MIN_DISTANCE_MONTHLY_INCOME_RANGE}
        value={[values?.monthlyIncomeRange?.min, values?.monthlyIncomeRange?.max]}
        onChange={setMonthlyIncomeRange}
      />
      <MonthlyLabelContainer>
        <span>${values?.monthlyIncomeRange?.min.toLocaleString('en')}</span>
        <span>
          ${values?.monthlyIncomeRange?.max.toLocaleString('en')}
          {values?.monthlyIncomeRange?.max === MAX_MONTHLY_INCOME_RANGE && '+'}
        </span>
      </MonthlyLabelContainer>
      <S18>Recent Contact</S18>
      {recentContactChoices.map(row => (
        <Row>
          {row.map(item => {
            const id = `filter-recent-contact-${item.value}-radio`
            return (
              <Col md={4}>
                <RadioButton
                  id={id}
                  key={id}
                  name="recent-contact"
                  choice={{ ...item, id }}
                  checked={values?.recentContact === item.value}
                  onChange={() => setFieldValue('recentContact', item.value)}
                />
              </Col>
            )
          })}
        </Row>
      ))}
    </Container>
  )
}

export default FilterForm
