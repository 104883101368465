import { BlueGradientButton } from '@rentspree/component-v2'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const Container = styled.div`
  position: fixed;
  bottom: 135px;
  left: 33px;
  z-index: 14;
`

const StyledGradientButtonWrapper = styled.div`
  > button {
    width: 150px;
    margin-bottom: 10px;
    padding: 0 23.5px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }
`

export const WhiteTransparentButton = styled.button`
  width: 150px;
  height: 40px;
  padding: 0 23.5px;
  border-radius: 100px;
  border: 1px solid ${COLOR.white};
  background: transparent;
  color: ${COLOR.white};
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
`

const ActionMenus = ({ onClickCreateContact, onClickImportContact }) => (
  <Container>
    <StyledGradientButtonWrapper>
      <BlueGradientButton text="Import Contacts" onClick={onClickImportContact} />
    </StyledGradientButtonWrapper>
    <WhiteTransparentButton onClick={onClickCreateContact}>Add Contact</WhiteTransparentButton>
  </Container>
)

export default ActionMenus
