import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Tag from 'components/contacts/atoms/tag'
import TagInput from 'components/contacts/atoms/tag-input'
import { TAG_INPUT_TYPE } from 'components/contacts/atoms/tag-input/constants'

import { CreateTagButton } from '../tags-dropdown/dropdown-menus'

export const AddingTagContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 124px;
  overflow-y: scroll;
`

const CreateTagText = styled.span`
  width: 100%;

  overflow-wrap: break-word;
`

const AddTagSection = ({
  tags,
  allTags,
  isLoading,
  onCreateAndTagContact,
  handleTagContact,
  onFocus,
  disabledButton,
  setDisplayedTags,
}) => {
  const [isShowInput, setIsShowInput] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const handleInputFocus = event => {
    setIsShowInput(true)
    event.stopPropagation()
  }

  const handleTagsDisplay = () => {
    const startWith = allTags.filter(tag =>
      tag.name?.toLowerCase().startsWith(inputValue.toLowerCase()),
    )
    const includes = allTags.filter(tag =>
      tag.name?.toLowerCase().includes(inputValue.toLowerCase()),
    )
    const mergeArray = [...startWith, ...includes]
    const nameArray = mergeArray.map(({ name }) => name)
    const sortedAllTags = [...startWith, ...includes].filter(
      (value, index) => nameArray.indexOf(value.name) === index,
    )
    return sortedAllTags.filter(tag => !tags.map(mappedTag => mappedTag.name).includes(tag.name))
  }

  const onCloseInput = () => {
    setIsShowInput(false)
    setInputValue('')
  }

  const handleInputBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onCloseInput()
    }
  }

  const handleCreateAndTagContact = event => {
    if (inputValue && !tags.map(tag => tag.name.toLowerCase()).includes(inputValue.toLowerCase())) {
      onCreateAndTagContact(inputValue, {
        successCallback: () => setDisplayedTags({ name: inputValue }),
      })
      onCloseInput()
    }
    if (event) event.stopPropagation()
  }

  return (
    <AddingTagContainer onBlur={handleInputBlur}>
      <TagInput
        margin="0px 15px 15px 15px"
        inputType={TAG_INPUT_TYPE.ROUND}
        isLoading={isLoading}
        isShowInput={isShowInput}
        onChangeValue={setInputValue}
        onFocusInput={handleInputFocus}
        onEnter={handleCreateAndTagContact}
        onFocus={onFocus}
        sortedAllTags={allTags}
        disabledButton={disabledButton}
      />
      {isShowInput &&
        handleTagsDisplay().length > 0 &&
        handleTagsDisplay().map(displayedTag => (
          <Tag
            id={displayedTag?._id}
            key={displayedTag?._id}
            name={displayedTag?.name}
            color={displayedTag?.color}
            onClick={event => {
              onCloseInput()
              handleTagContact(displayedTag)
              setDisplayedTags(displayedTag)
              event.stopPropagation()
            }}
            iconName="adding"
            maxWidth="fit-content"
            margin="0 5px 15px 15px"
            iconMargin="0px 5px 0px 0px"
          />
        ))}
      {inputValue && !tags.map(tag => tag.name.toLowerCase()).includes(inputValue.toLowerCase()) ? (
        <CreateTagButton
          padding="0px 20px 10px 20px"
          tabIndex="0"
          type="button"
          onClick={handleCreateAndTagContact}
        >
          <CreateTagText>+ Create tag for {inputValue && `'${inputValue}'`}</CreateTagText>
        </CreateTagButton>
      ) : (
        inputValue && (
          <CreateTagButton tabIndex="0" padding="0px 20px 10px 20px">
            <CreateTagText>You already added this tag</CreateTagText>
          </CreateTagButton>
        )
      )}
    </AddingTagContainer>
  )
}

export default AddTagSection

AddTagSection.propTypes = {
  tags: PropTypes.array,
}
