import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import React from 'react'
import styled from 'styled-components'

import ContactCard from 'components/contacts/molecules/contact-card'
import { UPCOMING_EVENT_TYPES } from 'components/contacts/molecules/upcoming-event-type-cell/constants'
import { breakpoints, COLOR } from 'styles/settings'

const Container = styled.div`
  width: 100%;
  display: none;
  border-bottom: 1px solid ${COLOR.lineGrey};
  &:last-child {
    border-bottom: none;
  }
  background: ${({ background }) => background};
  ${breakpoints.mobile(`
    display: inline-flex;
    &:last-child {
      border-bottom: 1px solid ${COLOR.lineGrey};
    }
  `)};
`

const CheckBoxContainer = styled.div`
  width: 40px;
  padding-top: 26px;
  padding-left: 4px;
`

const DataContainer = styled.div`
  width: ${({ bulkState }) => (bulkState ? 'calc(100% - 35px);' : '100%;')};
`

const ListMobile = ({
  _id,
  contactId,
  firstName,
  middleName,
  lastName,
  email,
  imagePlaceholder,
  role,
  onClickContactRow,
  moveInDate,
  birthDate,
  daysTillMoveInAnniversary,
  daysTillMovingIn,
  daysTillBirthday,
  starredAt,
  onStarContact,
  bulkSelected,
  onToggleSelectedBulk,
  bulkState,
  isUnseen,
  isGiftSent,
  event = UPCOMING_EVENT_TYPES.BIRTHDAY,
  onMarkAsGiftSent,
  onUnmarkGiftSent,
  onOpenContactPanel,
  setSelectedContact,
}) => (
  <Container onClick={onClickContactRow} background={isUnseen ? COLOR.blueLv7 : COLOR.white}>
    {bulkState && (
      <CheckBoxContainer>
        <Checkbox
          id="contact_checkbox"
          simple
          isSelected={bulkSelected.includes(_id)}
          bgSimpleTransparent
          onClick={onToggleSelectedBulk}
          margin="0"
        />
      </CheckBoxContainer>
    )}
    <DataContainer bulkState={bulkState}>
      <ContactCard
        imagePlaceholder={imagePlaceholder}
        firstName={firstName}
        middleName={middleName}
        lastName={lastName}
        email={email}
        role={role}
        contactId={contactId}
        moveInDate={moveInDate}
        birthDate={birthDate}
        daysTillMoveInAnniversary={daysTillMoveInAnniversary}
        daysTillMovingIn={daysTillMovingIn}
        daysTillBirthday={daysTillBirthday}
        starredAt={starredAt}
        onStarContact={onStarContact}
        isGiftSent={isGiftSent}
        type={event}
        onMarkAsGiftSent={onMarkAsGiftSent}
        onUnmarkGiftSent={onUnmarkGiftSent}
        onOpenContactPanel={onOpenContactPanel}
        setSelectedContact={setSelectedContact}
      />
    </DataContainer>
  </Container>
)

export default ListMobile
