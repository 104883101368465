import { FormControl } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

const nameFieldValidate = name => typeof name === 'string' && name.length > 1
const emailFieldValidate = val =>
  typeof val === 'string' &&
  // email regex from: https://stackoverflow.com/a/46181
  val.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )

const validateClientInputs = inputs => {
  const { firstName, lastName, email } = inputs

  return nameFieldValidate(firstName) && nameFieldValidate(lastName) && emailFieldValidate(email)
}

export const ClientDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, SUBTITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL, EMAIL_LABEL } =
    RECIPIENT_DETAILS.CLIENT_DETAILS
  const [localInputs, setLocalInputs] = useState({
    firstName: renterInfo?.firstName || null,
    lastName: renterInfo?.lastName || null,
    email: renterInfo?.email || null,
    fieldsAreValid: false,
  })
  const updateLocalInputs = inputObj => {
    setLocalInputs({ ...localInputs, ...inputObj })
  }

  useEffect(() => {
    const fieldsAreValid = validateClientInputs(localInputs)
    updateLocalInputs({ fieldsAreValid })
    if (fieldsAreValid) {
      const { firstName, lastName, email } = localInputs
      setRenterInfoPiece({ firstName, lastName, email })
    }
  }, [localInputs?.firstName, localInputs?.lastName, localInputs?.email])

  return (
    <>
      <TitleText>{TITLE}</TitleText>
      <p>{SUBTITLE}</p>
      <FormControl fullWidth required>
        <StyledTextField
          id="client-firstName"
          label={FIRST_NAME_LABEL}
          placeholder="James"
          variant="outlined"
          defaultValue={renterInfo?.firstName}
          error={
            localInputs?.firstName !== null ? !nameFieldValidate(localInputs?.firstName) : false
          }
          size="normal"
          onBlur={e => updateLocalInputs({ firstName: e.target.value })}
          required
        />
        <StyledTextField
          id="client-lastName"
          label={LAST_NAME_LABEL}
          placeholder="Johnson"
          variant="outlined"
          defaultValue={renterInfo?.lastName}
          error={localInputs?.lastName !== null ? !nameFieldValidate(localInputs?.lastName) : false}
          size="normal"
          onBlur={e => updateLocalInputs({ lastName: e.target.value })}
          required
        />
        {/* TODO: remove email if user navigates back to "Me or my business" option */}
        <StyledTextField
          id="client-email"
          label={EMAIL_LABEL}
          placeholder="jj@email.com"
          variant="outlined"
          defaultValue={renterInfo.email}
          error={localInputs?.email !== null ? !emailFieldValidate(localInputs?.email) : false}
          size="normal"
          onBlur={e => updateLocalInputs({ email: e.target.value })}
          required={false}
        />
      </FormControl>
      <DrawerFooter
        buttonLayout={buttonLayoutChoices.NEXT_BACK}
        textOverrides={{ back: 'Cancel', next: 'Save' }}
        nextEnabled={localInputs?.fieldsAreValid}
      />
    </>
  )
}
