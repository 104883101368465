export const RENT_PAYMENT_FAQ = {
  panels: [
    {
      header: {
        title: 'Is rent payment with RentSpree secure?',
      },
      paragraph: `Yes. All rent payments will be processed through Stripe, a PCI Service Level Provider 1, which is the highest grade of payment processing security. RentSpree never stores your sensitive information or shares with anyone, even your tenants. Stripe will also require identity verification in order to prevent fraudulent activity on our site. Lastly, RentSpree utilizes SSL (128 bit encryption through Secure Socket Layers) for communication between our servers and user devices.`,
    },
    {
      header: {
        title: 'What payment methods do you accept?',
      },
      paragraph:
        'We make it convenient for renters by offering payment via bank account transfer, credit or debit card.',
    },
    {
      header: {
        title: 'Is there a fee?',
      },
      paragraph: `We charge a small processing fee: $3 for recurring ACH payments and 3% for card payments. You can pass these fees to your renters, or cover them yourself.`,
    },
    {
      header: {
        title: 'When are payments deposited into my bank account?',
      },
      paragraph: `Typically, it takes up to 3 - 5 business days for a bank transfer to be processed and 2 business days for a credit card payment. The landlord and renter will be notified on 2 occasions: Once, when the renter initiates payment and finally once the payment has been processed by the landlord’s bank.`,
    },
    {
      header: {
        title: 'Can I charge the tenant for a one-time payment?',
      },
      paragraph: `Yes, you can collect various fees with one-time payments, including tenant security deposit, late fees, move-in fees, pet fees, and more.`,
    },
    {
      header: {
        title: 'Can I use a business bank account to receive funds from the tenant?',
      },
      paragraph: `Yes, we support both personal and business accounts. Just enter your business information during the verification process. This information will be securely shared with Stripe and help ensure that payments you collect are correctly reported.`,
    },
    {
      header: {
        title: 'I’m an agent, can I set up payments for my client?',
      },
      paragraph: `Yes, you can initiate security deposit and move-in fee collection on the landlord's behalf to streamline the payment process through RentSpree. Once you send the payment request, we’ll email the landlord to add their bank account at RentSpree. You’ll get notified as payments progress.`,
    },
  ],
  rentPaymentEventName: [
    'clickFaqIsRentPaymentWithRentSpreeSecure',
    'clickFaqHowWhatPaymentMethodsDoYouAccept',
    'clickFaqWhatIsTheProcessingFee',
    'clickFaqHowLongDoesItTakeForARentPaymentToBeProcessed',
    'clickFaqCanIChargeTenantForOneTimePayment',
    'clickFaqCanIUseABusinessBankAccount',
    'clickFaqCanAgentSetupForClient'
  ],
}
