import { call } from 'redux-saga-test-plan/matchers'
import { put, takeLatest } from 'redux-saga/effects'

import {
  updateAccountInformationAPI,
  UPDATE_ACCOUNT_INFORMATION,
} from 'v3/containers/identity-verification/actions'
import { updateAccountInformationInstance } from 'v3/containers/identity-verification/api-instances'

export function* updateAccountInformationSaga({ payload }) {
  yield put(updateAccountInformationAPI.request())
  const { data } = payload
  try {
    yield call(updateAccountInformationInstance, data)
    yield put(updateAccountInformationAPI.success())
  } catch (err) {
    yield put(updateAccountInformationAPI.failure(err))
  }
}

export function* watchUpdateAccountInformation() {
  yield takeLatest(UPDATE_ACCOUNT_INFORMATION, updateAccountInformationSaga)
}
