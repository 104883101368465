import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isDashboard }) => (isDashboard ? '30px' : '60px')};
  height: ${({ isDashboard }) => (isDashboard ? '30px' : '60px')};
  ${({ margin }) => `margin: ${margin};`}
  background-color: ${({ colorImagePlaceholder }) => colorImagePlaceholder || COLOR.steelBluePale};
  border-radius: 50%;
  overflow: hidden;

  .img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const LabelContainer = styled.span`
  font-size: ${({ isDashboard, fontSize }) => (isDashboard ? fontSize || '12px' : '24px')};
  font-weight: 600;
  color: ${({ colorText }) => colorText || COLOR.imageGrey};
`

const ContactPhoto = ({
  className,
  margin,
  imagePlaceholder,
  image,
  isDashboard,
  colorImagePlaceholder,
  fontStyle = {},
}) => (
  <Wrapper
    className={className}
    margin={margin}
    isDashboard={isDashboard}
    colorImagePlaceholder={colorImagePlaceholder}
  >
    {image ? (
      <img src={image} alt={imagePlaceholder} />
    ) : (
      <LabelContainer
        isDashboard={isDashboard}
        colorText={fontStyle.colorText}
        fontSize={fontStyle.size}
      >
        {imagePlaceholder}
      </LabelContainer>
    )}
  </Wrapper>
)

ContactPhoto.propTypes = {
  imagePlaceholder: PropTypes.string,
  image: PropTypes.string,
}

export default ContactPhoto
