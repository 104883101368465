import styled from "styled-components"
import { COLOR } from "styles/settings"
import { B12 } from "components/typography"

export const SwitchToggleButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 188px;
  height: 40px;
  padding: 1px;
  background: ${COLOR.white};
  border: 1px solid ${COLOR.borderGrey};
  border-radius: 23px;
  z-index: 1;
`
export const ToggleInput = styled.input`
  display: none;
  & + label {
    cursor: pointer;
    &:after {
      background: ${COLOR.quiteLightBlue};
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
      z-index: -1;
      border-radius: 23px;
    }
  }

  &.first-toggle + label {
    &:after {
      margin-left: 4px;
      left: 100%;
    }
  }
  &.second-toggle + label {
    &:after {
      margin-left: -4px;
      left: -100%;
    }
  }
  &:checked + label {
    h4,
    p {
      color: ${COLOR.white} !important;
      transition: color 200ms;
    }
    cursor: default;
    &:after {
      margin-left: 0;
      left: 0;
    }
  }
`
export const ToggleButton = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR.textBlack};
  width: 90px;
  height: 100%;
  transition: color 600ms ease;
`
export const BillingPeriodButtonSubtitle = styled(B12)`
  position: inherit;
  top: -3px;
`
