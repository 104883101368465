import React from 'react'
import { P } from 'legacy/components/typography'
import { Link } from 'legacy/components/buttons/buttons'
import * as PATH from 'legacy/constants/route-consts'
import { RENT_PAYMENT } from 'tracker/const'

export const SUBSCRIPTION_FAQ = [
  {
    id: 'free_trial',
    event: {
      name: 'free_trial',
    },
    header: 'Is there a PRO free trial?',
    description: (
      <P>Yes! We offer a 30-day free trial and send you a reminder before your first renewal.</P>
    ),
  },
  {
    id: 'cc_wall',
    event: {
      name: 'cc_wall',
    },
    header: 'Do I need a credit card to sign up?',
    description: (
      <P>
        Yes, a credit or debit card is required to sign up for your 30-day free trial. We’ll send
        you a courtesy email 3 days before the trial ends.
      </P>
    ),
  },
  {
    id: 'trial_ends',
    event: {
      name: 'trial_ends',
    },
    header: 'What happens when my free trial ends?',
    description: (
      <P>
        Your free trial expires 30 days after it’s redeemed. If you don’t cancel your subscription
        before the trial ends, you will automatically be charged the subscription fee until you
        cancel. Full terms are available &nbsp;
        <Link
          id="supportRefundPolicyLink"
          href={PATH.SUPPORT_TERMS_URL}
          inlineLink
          text="here."
          target="_blank"
        />
      </P>
    ),
  },
  {
    id: 'use_coupon',
    event: {
      name: 'use_coupon',
    },
    header: 'How do I use a coupon code?',
    description: (
      <P>Continue with the payment, select Add Coupon, enter the code, and select Apply.</P>
    ),
  },
  {
    id: 'cancel_pro',
    event: {
      name: 'cancel_pro',
    },
    header: 'What if I want to cancel PRO?',
    description: (
      <>
        <P>
          You can cancel PRO at any time and use it until the end of your free trial or billing
          period. After that, you’ll be limited to the features in our Basic plan.
        </P>
        <P>
          Refunds and credits aren’t issued for partial billing periods. RentSpree does not offer
          refunds.
        </P>
      </>
    ),
  },
]

export const RENT_PAYMENT_FAQ_PANEL_EVENT_PROPERTY = {
  1: RENT_PAYMENT.EVENT_PROPERTY.FAQ.SECURE_PAYMENT,
  2: RENT_PAYMENT.EVENT_PROPERTY.FAQ.PAYMENT_METHOD,
  3: RENT_PAYMENT.EVENT_PROPERTY.FAQ.PROCESSING_FEE,
  4: RENT_PAYMENT.EVENT_PROPERTY.FAQ.PROCESSING_TIME,
  5: RENT_PAYMENT.EVENT_PROPERTY.FAQ.ONE_TIME_PAYMENT,
  6: RENT_PAYMENT.EVENT_PROPERTY.FAQ.BANK_TYPE,
}
