let proc
if (typeof window !== 'undefined' && window.process && window.process.NODE_ENV === 'production') {
  proc = window.process
} else {
  proc = process.env
}

export const { MAIN_URL = 'https://www.rentspree.com' } = proc
export const { API_URL } = proc
export const { FILES_URL = 'files.ladprao21.com' } = proc
export const { USER_API_URL } = proc
export const { URL_PRINT_PDF } = proc
export const { NODE_ENV } = proc
export const { DOMAIN_NAME } = proc
export const { PRINT_PDF_URL } = proc
export const { DASHBOARD_V1_URL } = proc
export const { CREATED_AT_USER } = proc
export const { DISABLE_LOG } = proc
export const { SAVE_PDF_TIMEOUT } = proc
export const { REFRESH_TOKEN_DELAY } = proc
export const { AUTH_WEB_SIGNUP } = proc
export const { AUTH_WEB_LOGIN } = proc
export const { AUTH_WEB_VERIFICATION } = proc
export const { AUTH_WEB_VERIFICATION_V2 } = proc
export const MAIL_SUPPORT = 'support@rentspree.com'
export const IDLE_TIMEOUT_MS = parseInt(proc.IDLE_TIMEOUT_MS, 10)
export const { CI_COMMIT_SHA } = proc
export const { SENTRY_DSN } = proc
export const { ENVIRONMENT } = proc
export const { BASE_NAME } = proc
export const { AMPLITUDE_API_KEY } = proc
export const { AMPLITUDE_COOKIE_DOMAIN } = proc
export const { CDN_URL } = proc
export const { RENT_PAYMENT_SURVEY_MVP_URL } = proc
export const { RENT_PAYMENT_SURVEY_URL } = proc
export const { ENABLE_FEATURE_INTEGRATION_ESIGN } = proc
export const { SURVICATE_SURVEY_ID } = proc
export const { STATSIG_SDK_KEY } = proc
export const { STATSIG_ENVIRONMENT } = proc
export const { STATSIG_API } = proc
export const { STATSIG_PREMIUM_SCREENING_GATE } = proc
export const { STATSIG_LEGACY_SCREENING_GATE } = proc
export const { STATSIG_PREMIUM_SCREENING_EXP } = proc

export const { STRIPE_PUBLIC_KEY } = proc
export const IMPORT_CONTACT_CHUNK_SIZE = parseInt(proc.IMPORT_CONTACT_CHUNK_SIZE, 10)

export const { GOOGLE_MAP_KEY } = proc
export const { GOOGLE_PLACE_API_KEY } = proc

export const {
  IMAGE_PROXY_URL = 'https://storage.googleapis.com',
  LISTING_BUCKET = 'rentspree-listing-dev',
  IMAGE_PROXY_HOST = 'https://image-proxy.ladprao21.com',
  IMAGE_PROXY_RESIZE_HEIGHT = '800',
  IMAGE_PROXY_RESIZE_WIDTH = '1200',
} = proc

export const IMPORT_LISTING_POLLING_TRIALS = parseInt(proc.IMPORT_LISTING_POLLING_TRIALS, 10)

// Polling service
export const POLLING_MAX_COUNT = parseInt(proc.POLLING_MAX_COUNT || '37', 10)
export const POLLING_SHIFT_COUNT = parseInt(proc.POLLING_SHIFT_COUNT || '12', 10)
export const POLLING_DELAY = parseInt(proc.POLLING_DELAY || '5000', 10)

export const { TOGGLE_BUTTON_YEARLY_SUBTITLE } = proc
export const { ALWAYS_SHOW_STRIKETHROUGH_SUBSCRIPTION_PRICE } = proc

export const AGENT_REVIEW_API_BASE_URL = proc.AGENT_REVIEW_API_BASE_URL

export const { SURVICATE_SDK_PROVIDER_KEY } = proc

export const { SMARTY_AUTOCOMPLETE_API_KEY } = proc

export const IS_SHOW_CRIMINAL_STATES_RESTRICTION =
  proc.IS_SHOW_CRIMINAL_STATES_RESTRICTION || 'true'

export const { GOOGLE_TAG_MANAGER_CONTAINER_ID } = proc

export const { ONBOARDING_LANDLORD_ITR3_LAUNCH_DATE } = proc

// DataDog
export const { DATADOG_APPLICATION_ID } = proc
export const { DATADOG_CLIENT_TOKEN } = proc
export const { DATADOG_SITE } = proc
export const DATADOG_SAMPLE_RATE = parseInt(proc.DATADOG_SAMPLE_RATE, 10)
export const DATADOG_REPLAY_SAMPLE_RATE = parseInt(proc.DATADOG_REPLAY_SAMPLE_RATE, 10)
export const DATADOG_TRACE_SAMPLE_RATE = parseInt(proc.DATADOG_TRACE_SAMPLE_RATE, 10)

export const { TU_CONTACT_KB_URL } = proc
export const { CAR_LEASE_ALLOWED_SUBDOMAIN } = proc

export const { START_POTENTIAL_FRAUD_USER_CREATED_AT = '2024-09-15T00:00:00.000Z' } = proc
