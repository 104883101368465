import { FormControl } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { DrawerFooter } from 'v3/containers/overhaul-rent-payment/components/footer/drawer-footer'
import { buttonLayoutChoices } from 'v3/containers/overhaul-rent-payment/components/footer/utils'
import { StyledTextField } from 'v3/containers/overhaul-rent-payment/components/text/text-field'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

const nameFieldValidate = name => typeof name === 'string' && name.length > 1

const validateSelfInputs = inputs => {
  const { firstName, lastName } = inputs

  return nameFieldValidate(firstName) && nameFieldValidate(lastName)
}

export const SelfDetailsDrawer = ({ renterInfo, setRenterInfoPiece }) => {
  const { TITLE, FIRST_NAME_LABEL, LAST_NAME_LABEL } = RECIPIENT_DETAILS.SELF_DETAILS
  const [localInputs, setLocalInputs] = useState({
    firstName: renterInfo?.firstName || null,
    lastName: renterInfo?.lastName || null,
    fieldsAreValid: false,
  })
  const updateLocalInputs = inputObj => {
    setLocalInputs({ ...localInputs, ...inputObj })
  }

  useEffect(() => {
    const fieldsAreValid = validateSelfInputs(localInputs)
    if (fieldsAreValid) {
      const { firstName, lastName } = localInputs
      setRenterInfoPiece({ firstName, lastName, email: null })
    }

    updateLocalInputs({ fieldsAreValid })
  }, [localInputs?.firstName, localInputs?.lastName, localInputs?.email])

  return (
    <>
      <TitleText>{TITLE}</TitleText>
      {/* TODO: skip if details can be found elsewhere? */}
      <FormControl defaultValue="" required>
        <StyledTextField
          id="client-firstName"
          label={FIRST_NAME_LABEL}
          placeholder="James"
          variant="outlined"
          defaultValue={renterInfo?.firstName}
          error={
            localInputs?.firstName !== null ? !nameFieldValidate(localInputs?.firstName) : false
          }
          size="normal"
          onBlur={e => updateLocalInputs({ firstName: e.target.value })}
          required
        />
        <StyledTextField
          id="client-lastName"
          label={LAST_NAME_LABEL}
          placeholder="Johnson"
          variant="outlined"
          defaultValue={renterInfo?.lastName}
          error={localInputs?.lastName !== null ? !nameFieldValidate(localInputs?.lastName) : false}
          size="normal"
          onBlur={e => updateLocalInputs({ lastName: e.target.value })}
          required
        />
      </FormControl>
      <DrawerFooter
        buttonLayout={buttonLayoutChoices.NEXT_BACK}
        textOverrides={{ back: 'Cancel', next: 'Save' }}
        nextEnabled={localInputs?.fieldsAreValid}
      />
    </>
  )
}
