import { buildPath } from '@rentspree/path'
import get from 'lodash/get'
import { CREATE_SCREENING_REQUEST_API } from 'constants/route'
import checkStatus from './handlers/check-status'
import fetch from '../helpers/fetch-utils'
import { SCREENING_PLAN_API } from '../constants/route-consts'

export const getScreeningPlanApi =
  (state = 'all', zip) =>
  bearer =>
    fetch(buildPath(SCREENING_PLAN_API, {}, { state, zipCode: zip }), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearer.accessToken}`,
      },
    }).then(checkStatus)

export const createScreeningRequestApi = (propertyId, data) => bearer => {
  const requestData = {
    ...get(data, 'selectedScreeningOption'),
    proOption: get(data, 'proOption'),
    payerType: get(data, 'payerType'),
    applicationType: get(data, 'applicationType'),
    source: data?.source,
    agentType: get(data, 'agentType'),
  }
  return fetch(buildPath(CREATE_SCREENING_REQUEST_API, { propertyId }), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  }).then(checkStatus)
}
