import React, { useCallback, useState } from 'react'
import { withFormik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { TextInput, FormattedInput, USStateDropdown, S16 } from '@rentspree/component-v2'

import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { InputWrapper } from 'components/shared/form'
import { ButtonSet } from 'components/buttons/button-set'
import { Section } from 'legacy/components/request/heading'
import { FlexBox } from 'legacy/components/layout/section'

import {
  GoogleAddressAutoComplete,
} from 'components/organisms/address-input-v2'
import styled from 'styled-components'
import SubtitleWithHr from 'components/molecules/subtitle-with-hr'
import { MAILING_ADDRESS_HEADER } from './const'
import { schema } from './schema'

const { title, subtitle } = MAILING_ADDRESS_HEADER

export const StyledS16 = styled(S16)`
  margin: ${props => props.margin};
  line-height: 22px;
  font-family: Source Sans Pro;
  @media (max-width: 991px) {
    margin: ${props => props.mMargin};
  }
`

const SubHeaderSection = styled.div`
  width: 100%;
`

const StyledInformationSection = styled.div`
  margin: 0px 10px 0px 10px;
  @media (max-width: 991px) {
    margin: 0px;
  }
`

export const MailingAddress = props => {
  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
    handleBackToDashboard,
    nextBtnTitle,
    validateForm,
  } = props
  const [selectedAddress, setSelectedAddress] = useState()
  const autofillAddress = useCallback(
    address =>
      new Promise(resolve => {
        const { streetLine, secondary, city, state, zipcode, entries } = address
        const isSingleEntire = !isEmpty(secondary) && entries === 1
        const secondaryLine = isSingleEntire ? secondary : ''
        setFieldValue('street', streetLine)
        setFieldValue('unitNumber', secondaryLine)
        setFieldValue('city', city)
        setFieldValue('state', state)
        setFieldValue('zip', zipcode)

        resolve()
      }).then(() => {
        if (validateForm) {
          validateForm()
        }
        setSelectedAddress(address)
      }),
    [setFieldValue, validateForm, setSelectedAddress],
  )

  return (
    <>
      <TitleWithSubtitle
        titleWidth="530px"
        mTitleWidth="100%"
        title={title}
        subtitle={subtitle}
        mb="30"
        titleMargin="0 0 18px 0"
        tMargin="0 0 20px 0"
        fontWeight={700}
        mTitleLineHeight="32px"
        subTitleLineHeight="22px"
        mSubTitleLineHeight="22px"
        dataTestKey="mailing-address-page"
      />
      <SubtitleWithHr hrMargin="0px 0px 10px" mHrMargin="0px 0px 10px" text="Your Information" />
      <StyledS16 weight="400" margin="0px 10px 18px 10px" mMargin="0px 0px 20px 0px">
        The applicant will see your name and phone number when they receive the ApplyLink.
      </StyledS16>
      <Section mb={-10} mMargin="0 0 -10px 0">
        <StyledInformationSection>
          <FlexBox>
            <InputWrapper width="230px" mb="5" mMarginBottom="5" bgTransparent>
              <TextInput
                name="firstName"
                value={values.firstName}
                label="First Name"
                isRequired
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && errors.firstName}
              />
            </InputWrapper>
            <InputWrapper ml="20" mb="5" mMarginBottom="5" width="230px" bgTransparent>
              <TextInput
                isRequired
                name="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && errors.lastName}
              />
            </InputWrapper>
            <InputWrapper mb="5" mMarginBottom="5" bgTransparent>
              <FormattedInput
                formatType="phone"
                id="phoneNumberInput"
                name="phone"
                value={values.phone}
                label="Phone Number"
                isRequired
                onValueChange={vals => {
                  setFieldValue('phone', vals.value)
                }}
                onBlur={handleBlur}
                error={touched.phone && errors.phone}
              />
            </InputWrapper>
          </FlexBox>
        </StyledInformationSection>
        <SubHeaderSection>
          <SubtitleWithHr
            textMargin="15px 0 8px 0"
            mTextMargin="15px 0 8px 0"
            hrMargin="0px 0px 10px"
            mHrMargin="0px 0px 13px"
            text="Your Home Address"
          />
          <StyledS16 weight="400" margin="0px 10px 20px 10px" mMargin="0px 0px 22px 0px">
            The applicant will not see your address.
          </StyledS16>
        </SubHeaderSection>
        <StyledInformationSection>
          <FlexBox>
            <InputWrapper mb="5" mMarginBottom="5" bgTransparent>
              <GoogleAddressAutoComplete
                id="googleAddressAutoComplete"
                className="newPropertyInput"
                name="street"
                label="Address"
                value={values.street}
                debouncingTime="500"
                onValueChange={val => {
                  if (!isEmpty(selectedAddress)) setSelectedAddress(undefined)
                  setFieldValue('street', val)
                }}
                onBlur={() => setFieldTouched('street', true)}
                onSelectOption={autofillAddress}
                error={touched.street && errors.street}
                isRequired
              />
            </InputWrapper>
            <InputWrapper mb="5" mMarginBottom="5" bgTransparent>
              <TextInput
                name="unitNumber"
                label="Apartment, unit, suite, or floor #"
                value={values.unitNumber}
                onChange={e => {
                  if (!isEmpty(selectedAddress)) setSelectedAddress(undefined)
                  handleChange(e)
                }}
                onBlur={handleBlur}
                error={touched.unitNumber && errors.unitNumber}
              />
            </InputWrapper>
            <InputWrapper mb="5" mMarginBottom="5" width="146px" bgTransparent>
              <TextInput
                isRequired
                name="city"
                label="City"
                value={values.city}
                onChange={e => {
                  if (!isEmpty(selectedAddress)) setSelectedAddress(undefined)
                  handleChange(e)
                }}
                onBlur={handleBlur}
                error={touched.city && errors.city}
              />
            </InputWrapper>
            <InputWrapper mb="5" ml="20" mMarginBottom="5" width="148px" bgTransparent>
              <USStateDropdown
                name="state"
                id="stateInput"
                onChange={val => {
                  if (!isEmpty(selectedAddress)) setSelectedAddress(undefined)
                  setFieldValue('state', val)
                }}
                onBlur={() => setFieldTouched('state', true)}
                value={values.state}
                error={touched.state && errors.state}
                label="State"
                isRequired
              />
            </InputWrapper>
            <InputWrapper mb="5" ml="20" mMarginBottom="5" width="146px" bgTransparent>
              <FormattedInput
                formatType="zipcode"
                id="zipCodeInput"
                name="zip"
                label="Zip/Postal Code"
                value={values.zip}
                onValueChange={vals => {
                  if (!isEmpty(selectedAddress)) setSelectedAddress(undefined)
                  setFieldValue('zip', vals.value)
                }}
                onBlur={handleBlur}
                error={touched.zip && errors.zip}
                isRequired
              />
            </InputWrapper>
          </FlexBox>
        </StyledInformationSection>
      </Section>

      <ButtonSet
        id="mailing-address-button-set"
        disabledNextButton={isEmpty(touched) || !isEmpty(errors)}
        margin="45px 10px 20px 10px"
        mMargin="73px 0px 10px 0px"
        prevText="Back"
        continueText={nextBtnTitle || 'Next'}
        onClickPrevBtn={handleBackToDashboard}
        onSaveAndNext={handleSubmit}
        hideBackButton
        hasIconNextBtn
        withSystemLayout
        onBorderDesktop
        onBorder
      />
    </>
  )
}

export const MailingAddressForm = withFormik({
  mapPropsToValues: props => schema(props).mapper,
  validationSchema: props => schema(props).validation,
  handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
    onSubmit(values)
    setSubmitting(false)
  },
  enableReinitialize: true,
  displayName: 'MailingAddressForm',
})(MailingAddress)
