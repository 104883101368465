import React from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { compose } from 'redux'

import { MainContent } from 'components/contacts/layout'
import * as PATH from 'constants/route'

import { withSaga as withContactSaga } from './connect'
import CreateContactModal from './create-contact-modal'
import DeleteTagModal from './delete-tag-modal'
import ContactDetailPage from './detail/loadable'
import ContactsList from './list' // TODO: use loadable
import { getAllTags } from './tags/actions'
import { withReducer as withTagsReducer, withSaga as withTagsSaga } from './tags/connect'

export const ContactsRoute = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getAllTags())
  }, [])

  return (
    <MainContent>
      <CreateContactModal />
      <DeleteTagModal />
      <Switch>
        <Route path={[PATH.CONTACTS_LIST, PATH.CONTACTS]} component={ContactsList} exact />
        <Route
          path={PATH.CONTACT_DETAIL}
          render={renderProps => <ContactDetailPage {...renderProps} />}
        />
      </Switch>
    </MainContent>
  )
}

export default compose(withTagsSaga, withTagsReducer, withContactSaga)(ContactsRoute)
