import React, { useEffect, useRef } from 'react'
import SelectDropdown from '@rentspree/component-v3.components.atoms.select-dropdown'
import { ButtonSetV2 } from 'components/buttons/button-set'
import styled from 'styled-components'
import { Div } from 'components/layout/main'
import isEmpty from 'lodash/isEmpty'
import { COLOR } from '../../../styles/settings'
import { CREATE_DROPDOWN_VALUE, PAGE_NAME } from './const'
import { UserRoleForPropertyForm } from '../user-role-for-property-form'


export const StyledP = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin: ${props => props.margin || "0px 0 50px"};
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const TextButton = styled.button`
  color: ${COLOR.activeBlue};
  background: transparent;
  font-weight: 600;
  border: none;
  &:hover,
  &:focus {
    color: ${COLOR.blueHover};
  }
  @media (max-width: 480px) {
    margin-top: 5px;
    padding: 0 0 0 0;
  }
`

export const SelectPropertyFormV2 = props => {
  const {
    touched,
    errors,
    selectedPropertyId,
    propertyList,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isLoading,
    onClickBackToDashboard,
    onClickCreateNewProperty,
    onChangeProperty,
    onClickAddPropertyLater = () => { },
    propertyMap,
    values,
    resetForm,
  } = props

  useEffect(() => {
    if (selectedPropertyId) {
      setFieldValue('selectedPropertyId', selectedPropertyId)
    }
  }, [selectedPropertyId])

  const addressList = [
    { value: CREATE_DROPDOWN_VALUE, label: '+ Add New Property' },
    ...propertyList,
  ]

  const userRoleForPropertyFormRef = useRef(null)
  const handleOnChangeDropdown = value => {
    resetForm({ errors: {} })

    if (value === CREATE_DROPDOWN_VALUE) {
      onChangeProperty(selectedPropertyId || '')
      onClickCreateNewProperty()
    } else {
      onChangeProperty(value)
    }
    if (userRoleForPropertyFormRef.current && value !== selectedPropertyId) {
      setFieldValue('userRoleForProperty', undefined)
      userRoleForPropertyFormRef.current.resetSelectedUserRoleForProperty()
    }
  }

  const handleUserRoleDropdownChange = (value) => {
    if (value !== values.userRoleForProperty) {
      resetForm({ values: { selectedPropertyId, userRoleForProperty: value }, errors: {} })
    }
  }

  const selectedPropertyIdUnion = selectedPropertyId || values.selectedPropertyId
  const isSelectedPropertyHasRole = !!propertyMap?.[selectedPropertyIdUnion]?.userRoleForProperty
  const disableNextButtonPropertyHadRole = !selectedPropertyIdUnion || !isEmpty(errors)
  const disableNextButtonPropertyNoRole = selectedPropertyIdUnion && !isSelectedPropertyHasRole && !values.userRoleForProperty
  const disabledNextButton = disableNextButtonPropertyNoRole || disableNextButtonPropertyHadRole
  const shouldShowUserRoleForPropertyForm =  !isSelectedPropertyHasRole && selectedPropertyIdUnion

  return (
    <>
      <Div margin="0 10px 20px 10px" mMargin="0 0 20px 0">
        <SelectDropdown
          isRequired
          id="dropDownSelectProperty"
          aria-label="dropDownSelectProperty"
          options={addressList}
          label="Select existing or add new property "
          name="selectedPropertyId"
          data-testid={`${PAGE_NAME}-select-dropdown`}
          onChange={handleOnChangeDropdown}
          onBlur={() => {
            setFieldTouched('selectedPropertyId', true)
          }}
          optionSize={10}
          value={selectedPropertyId}
          error={touched.selectedPropertyId && errors.selectedPropertyId}
          placeholder="Select one"
        />
        <StyledP margin={shouldShowUserRoleForPropertyForm ? "0px 0 30px" : "0px 0 50px"}>
          <div>Unsure about the property?</div>
          <div>
            <TextButton
              id="screenWithoutProperty"
              data-testid={`${PAGE_NAME}-screen-without-property-text-button`}
              onClick={onClickAddPropertyLater}
            >
              Screen without property
            </TextButton>
          </div>
        </StyledP>
        {shouldShowUserRoleForPropertyForm &&
          <UserRoleForPropertyForm
            onSelectDropdownChange={handleUserRoleDropdownChange}
            ref={userRoleForPropertyFormRef}
            wrapperWidth="480px"
            {...props}
          />
        }
        <ButtonSetV2
          id="selectPropertyButtonSet"
          minWidth="100"
          margin="0 0"
          continueBtnType="submit"
          prevText="Back"
          continueText="Next"
          btnHeight="40px"
          onBorder
          onBorderDesktop
          hideBackButton
          hideIconPrevButton
          onClickPrevBtn={onClickBackToDashboard}
          onSaveAndNext={handleSubmit}
          isLoading={isLoading}
          disabledNextButton={disabledNextButton}
          dataTestKey={PAGE_NAME}
        />
      </Div>
    </>
  )
}

export default SelectPropertyFormV2
