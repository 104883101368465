import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import { withFormik } from 'formik'
import isEqual from 'lodash/isEqual'
import React from 'react'
import styled from 'styled-components'

import FilterForm from 'components/contacts/organisms/filter-form'
import { breakpoints } from 'styles/settings'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import tracker from '../../../../tracker'

import { initialValue, schema } from './schema'

const { CLICK_CLEAR_FILTER } = EVENT_PEOPLE_CONCEPT.EVENT_NAME

const StyledContainer = styled.div`
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Source Sans Pro, sans-serif;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ButtonContainer = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  padding: 0 25px;
  ${breakpoints.mobile(`
    flex-direction: column;
  `)};
`

export const CancelFilterButton = styled(Button)`
  margin-right: auto;
  ${breakpoints.mobile(`
    order: 2;
    margin 10px 0;
  `)};
`

export const ClearFilterButton = styled(Button)`
  height: 40px;
  margin: 0 10px 0 0;
  ${breakpoints.mobile(`
    order: 1;
    margin: 0;
  `)};
`

export const SubmitButton = styled(BlueGradientButton)`
  ${breakpoints.mobile(`
    order: 3;
  `)};
`

export const FilterModalForm = ({
  handleSubmit,
  handleCloseModal,
  handleClearFilter,
  ...formikProp
}) => {
  const isDefaultFilter = isEqual(formikProp.values, initialValue)

  const handleResetForm = () => {
    formikProp.resetForm({ values: initialValue })
    tracker.trackEvent(CLICK_CLEAR_FILTER)
  }

  return (
    <StyledContainer>
      <S24 size="28px" margin="0 25px 10px 25px">
        Advanced filter
      </S24>
      <StyledForm id="filter-contact-form" onSubmit={handleSubmit}>
        <FilterForm {...formikProp} />
        <ButtonContainer>
          <CancelFilterButton
            type="button"
            small
            google
            text="Cancel"
            minWidth="150"
            onClick={handleCloseModal}
          />
          <ClearFilterButton
            type="button"
            small
            transparent
            blueText={!isDefaultFilter}
            blackText={isDefaultFilter}
            disabled={isDefaultFilter}
            text="Clear filter"
            minWidth="150"
            margin="0 10px 0 0"
            mMargin="10px 0"
            onClick={handleResetForm}
          />
          <SubmitButton small text="Submit" minWidth="150" type="submit" />
        </ButtonContainer>
      </StyledForm>
    </StyledContainer>
  )
}

const FilterModalFormTemplate = withFormik({
  mapPropsToValues: props => schema(props).mapper,
  validationSchema: props => schema(props).validation,
  handleSubmit: async (values, { props }) => {
    props.handleApplyFilter(values)
  },
  values: {},
  enableReinitialize: true,
  displayName: 'FilterModalForm',
})(FilterModalForm)

export default FilterModalFormTemplate
