import { buildPath } from '@rentspree/path'

import { AGENT_OPEN_REPORT_EXECUTION_API } from 'constants/route'

import fetch from '../helpers/fetch-utils'
import checkStatus from './handlers/check-status'
import {
  APPLICATION_V2_DETAIL_API,
  FORM_AVAILABLE_API,
  RENTAL_SUBMISSION_API_V2,
} from '../constants/route-consts'

export const getRentalSubmissionById = rentalId => bearer => {
  const uri = buildPath(RENTAL_SUBMISSION_API_V2, { rentalId })
  return fetch(uri, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}

export const getApplicationDetail =
  (rentalId = '') =>
  bearer =>
    fetch(buildPath(APPLICATION_V2_DETAIL_API, { rentalId }), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearer.accessToken}`,
      },
    }).then(checkStatus)

export const getFormAvailable =
  (rentalId = '') =>
  bearer =>
    fetch(buildPath(FORM_AVAILABLE_API, { rentalId }), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearer.accessToken}`,
      },
    }).then(checkStatus)

export const agentOpenReportExecution =
  (propertyId = '', rentalId = '') =>
  bearer =>
    fetch(buildPath(AGENT_OPEN_REPORT_EXECUTION_API, { rentalId }), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearer.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ propertyId }),
    }).then(checkStatus)
