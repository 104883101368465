import { ROLE_OPTIONS, SOURCE_OPTIONS } from 'containers/contacts/constants'

const [noneRole, ...otherRoles] = ROLE_OPTIONS
export const ROLE_CHECKBOX = [...otherRoles, { ...noneRole, label: 'None' }]
export const SOURCE_CHECKBOX = SOURCE_OPTIONS

export const LOCATION_DROPDOWN_PLACEHOLDER = {
  label: 'State',
  value: '',
}

export const RECENT_CONTACT_RADIO_CHOICES = [
  { label: 'All-time', id: 'filter-contact-all-time-radio', value: 'all' },
  { label: 'Past 7 days', id: 'filter-contact-7-days-radio', value: '7_days' },
  {
    label: 'Past 30 days',
    id: 'filter-contact-30-days-radio',
    value: '30_days',
  },
  {
    label: 'Past 60 days',
    id: 'filter-contact-60-days-radio',
    value: '60_days',
  },
  {
    label: 'Past 3 months',
    id: 'filter-contact-3-months-radio',
    value: '3_months',
  },
  {
    label: 'Past 6 months',
    id: 'filter-contact-6-months-radio',
    value: '6_months',
  },
  {
    label: 'Past 12 months',
    id: 'filter-contact-12-months-radio',
    value: '12_months',
  },
  {
    label: 'Over 2 years',
    id: 'filter-contact-2-years-radio',
    value: '2_years',
  },
]

export const MIN_MONTHLY_INCOME_RANGE = 0
export const MAX_MONTHLY_INCOME_RANGE = 300000
export const MIN_DISTANCE_MONTHLY_INCOME_RANGE = 100
