export const BUSINESS_TYPE = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
}

export const BUSINESS_STRUCTURE = {
  MULTIPLE_LLC: 'multi_member_llc',
  SINGLE_LLC: 'single_member_llc',
  PRIVATE_CORPORATION: 'private_corporation',
  PRIVATE_PARTNERSHIP: 'private_partnership',
  SOLE_PROPRIETORSHIP: 'sole_proprietorship',
}

export const BUSINESS_RADIO_BUTTON = {
  INDIVIDUAL: {
    subtitle: 'Individual',
    toolsTipText:
      'You own your rental property as a personal investment and don’t separate related income from any other personal income you have.',
  },
  COMPANY: {
    subtitle: 'Company',
    toolsTipText:
      'Your rental property is owned by a separate business entity and the rental income is kept separate from your personal income.',
  },
}

export const BUSINESS_STRUCTURE_OPTIONS = [
  { value: BUSINESS_STRUCTURE.SINGLE_LLC, label: 'Single-member  LLC' },
  { value: BUSINESS_STRUCTURE.SOLE_PROPRIETORSHIP, label: 'Sole proprietorship' },
  { value: BUSINESS_STRUCTURE.MULTIPLE_LLC, label: 'Multi-member LLC' },
  { value: BUSINESS_STRUCTURE.PRIVATE_PARTNERSHIP, label: 'Private partnership' },
  { value: BUSINESS_STRUCTURE.PRIVATE_CORPORATION, label: 'Private corporation' },
]
