import React from 'react'
import styled from 'styled-components'

import DebounceInput from 'components/atoms/debounce-input/debounce-input'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
`

export const Input = styled(DebounceInput)`
  #searchContactInput {
    padding: 0px 15px;
  }
`

const trackStartSearch = () => {
  tracker.trackEvent(EVENT_PEOPLE_CONCEPT.EVENT_NAME.START_SEARCH_CONTACT)
}

const Search = ({ initialValue, disabled, onSearch }) => (
  <Container>
    <Input
      id="searchContactInput"
      type="text"
      name="search"
      placeholder="Search Contacts (name or email)"
      value={initialValue}
      disabled={disabled}
      onChange={onSearch}
      debounceTimeout={700}
      isShowClearButton
      onFocus={trackStartSearch}
    />
  </Container>
)

export default Search
