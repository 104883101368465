import createContactImg from 'images/contacts/create-contact.svg'
import importCsvImg from 'images/contacts/import-csv.svg'

export const BUTTON_TEXT = 'Screen Tenants'

export const MAIN_CONTENT = {
  title: 'Client Manager',
  description:
    'Organize contact data and keep track of important milestones that will enable you to stay connected with your clients.',
}

export const BENEFIT_CARDS = [
  {
    image: importCsvImg,
    title: 'Import from Spreadsheet',
    subTitle: 'Choose spreadsheet (.csv) and import your contact right away',
    buttonText: 'Upload File',
    action: 'importCsv', // add action name for import
  },
  {
    image: createContactImg,
    title: 'Create contact',
    subTitle: 'Add a person on your own',
    buttonText: 'Create',
    action: 'openCreateContactModal',
  },
]
