import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import React from 'react'
import styled from 'styled-components'

import { SortableHeader } from 'components/atoms/sortable-header'
import { contactSortMapper } from 'components/contacts/templates/contact-dashboard-template/helpers'
import { breakpoints, COLOR } from 'styles/settings'

import { TABLE_HEADER } from './constants'

const HeaderContainer = styled.tr`
  font-family: 'Lato';
  height: 40px;
  font-size: 14px;
  border-bottom: 2px solid ${COLOR.grayFade};
  & th {
    font-weight: bold;
  }
  ${breakpoints.mobile(`
    display: none;
  `)};
`

const Th = styled.th`
  width: ${({ width }) => width || 'initial'};
`

const NameTh = styled.th`
  width: ${({ width }) => width || 'initial'};
  padding-left: 20px;
`

const CheckboxTh = styled.th`
  padding-left: 5px;
`

export const UpcomingEventTableHeader = ({
  bulkSelected = [],
  data = [],
  onClickAddSelectedAllBulk,
  bulkState,
  onSortContact,
  currentSortContactValue,
}) => (
  <HeaderContainer>
    {bulkState && (
      <CheckboxTh width="39px">
        <Checkbox
          id="header_checkbox"
          simple
          isSelected={data.every(contact => bulkSelected.includes(contact?._id)) && data.length > 0}
          bgSimpleTransparent
          onClick={onClickAddSelectedAllBulk}
        />
      </CheckboxTh>
    )}
    {TABLE_HEADER.map((header, index) => {
      const sortedVal = currentSortContactValue[header.name]
      return index === 0 ? (
        <NameTh key={header.name} width={header.width}>
          <SortableHeader
            id={`sort-${header.name}-btn`}
            text={header.text}
            sorting={contactSortMapper(sortedVal)}
            onClick={() =>
              header.isSortable &&
              onSortContact({
                sortedKey: header.name,
                sortedVal,
              })
            }
          />
        </NameTh>
      ) : (
        <Th key={header.name} width={header.width}>
          <SortableHeader
            id={`sort-${header.name}-btn`}
            text={header.text}
            sorting={contactSortMapper(sortedVal)}
            onClick={() =>
              header.isSortable &&
              onSortContact({
                sortedKey: header.name,
                sortedVal,
              })
            }
          />
        </Th>
      )
    })}
  </HeaderContainer>
)

UpcomingEventTableHeader.defaultProps = {
  currentSortContactValue: {},
}
