import { buildPath } from "@rentspree/path"
import fetch from "../helpers/fetch-utils"
import checkStatus from "./handlers/check-status"
import { APPLICATION_LIST_API } from "../constants/route-consts"

export const getAppList = (propertyId, options = {}) => bearer => {
  const uri = buildPath(APPLICATION_LIST_API, { propertyId }, options)
  return fetch(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}
