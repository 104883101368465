import { buildPath } from '@rentspree/path'
import Amplitude from 'amplitude-js'
import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import * as PATH from 'constants/route'
import { STATSIG, useFeatureFlag } from 'hooks/use-statsig-feature-flag'
import { OVERHAUL_RENT_PAYMENT } from 'utils/feature-flag/constants'
import { OverhaulBankSetupRouter } from 'v3/containers/overhaul-bank-setup/router'
import { OverhaulRentPaymentRouter } from 'v3/containers/overhaul-rent-payment/router'
import RentPaymentLandingPages from 'v3/containers/rent-payment/landing-page'
import RentPaymentPropertyLanding from 'v3/containers/rent-payment/landing-page/property-landing'
import { RentPaymentSetupRoutes } from 'v3/containers/rent-payment/setup-page/routes'
import { AgentInitPaymentSuccessPage } from 'v3/containers/rent-payment/setup-page/set-up-for-landlord/success-page/success-page'
import { API_STATUS } from 'v3/containers/rent-payment/shared/redux/constants'
import RentPaymentTransaction from 'v3/containers/rent-payment/transactions'

import { usePayeeRiskLevel } from '../../../containers/rent-payment/hooks'

export const RentPaymentRouter = props => {
  const { result: riskLevel, status: payeeRiskLevelAPIStatus } = usePayeeRiskLevel()

  const { isEnabled: isOverhaulRentPaymentEnabled } = useFeatureFlag({
    statsigFeatureFlagKey: OVERHAUL_RENT_PAYMENT,
    featureFlagProvider: STATSIG,
  })

  const rentPaymentRequestVariation = () => {
    if (isOverhaulRentPaymentEnabled) {
      return OverhaulRentPaymentRouter
    }
    return RentPaymentSetupRoutes
  }

  useEffect(() => {
    if (payeeRiskLevelAPIStatus === API_STATUS.SUCCESS) {
      Amplitude.getInstance().setUserProperties({ user_risk_level: riskLevel })
    }
  }, [payeeRiskLevelAPIStatus])

  return (
    <Switch>
      <Route
        path={PATH.RENT_PAYMENT_REQUEST_AGENT_INITIATED_SUCCESS_PAGE}
        component={AgentInitPaymentSuccessPage}
        {...props}
      />
      {isOverhaulRentPaymentEnabled && (
        <Route
          exact
          path={PATH.RENT_PAYMENT_REQUEST_BANK_SETUP}
          component={OverhaulBankSetupRouter}
          {...props}
        />
      )}
      <Route
        path={PATH.RENT_PAYMENT_REQUEST_BANK_SETUP}
        component={OverhaulBankSetupRouter}
        {...props}
      />
      <Route
        path={PATH.RENT_PAYMENT_REQUEST_PAGE}
        component={rentPaymentRequestVariation()}
        {...props}
      />
      {/* legacy routes compatibility */}
      <Route
        exact
        path={[
          PATH.RENT_PAYMENT_ONBOARDING_REDIRECT,
          PATH.RENT_PAYMENT_VERIFY_TAX_WITH_STRIPE_EMBEDDED,
          PATH.RENT_PAYMENT_VERIFY_TAX,
        ]}
        component={({ match: { params } }) => (
          <Redirect
            to={{ pathname: buildPath(PATH.IA_SETTING_TAX_INFORMATION_TAKEOVER, params) }}
          />
        )}
      />
      <Route
        exact
        path={PATH.RENT_PAYMENT_SETUP_REQUEST_PAGE}
        render={({ match: { params } }) => (
          <Redirect to={{ pathname: buildPath(PATH.RENT_PAYMENT_REQUEST_PAGE, params) }} />
        )}
      />

      <Switch>
        {/* landing page -> transaction list & settings tabs */}
        <Route
          exact
          path={[
            PATH.RENT_PAYMENT,
            PATH.RENT_PAYMENT_SETTINGS,
            PATH.IA_SETTING_TAX_INFORMATION_TAKEOVER,
            PATH.IA_SETTING_TAX_INFORMATION_TAKEOVER_BUSINESS_TYPE,
            PATH.IA_SETTING_TAX_INFORMATION_TAKEOVER_PERSON_DETAIL,
            PATH.IA_SUCCESS_TAKEOVER_RENT_PAYMENT_LANDING,
            PATH.RENT_PAYMENT_SELECT_PAYMENT_RECEIVER,
          ]}
          render={() => <RentPaymentLandingPages padding="0px" />}
        />

        {/* transaction detail */}
        <Route
          path={PATH.IA_RENT_PAYMENT_ID_PAGE}
          render={() => <RentPaymentTransaction padding="0px" />}
        />
        {/* property landing page */}
        <Route
          exact
          path={PATH.RENT_PAYMENT_PROPERTY_PAGE}
          render={() => <RentPaymentPropertyLanding margin="0px" mMargin="0px" />}
        />
        {/* property transaction detail */}
        <Route
          exact
          path={[PATH.RENT_PAYMENT_ID_PAGE, PATH.IA_SUCCESS_TAKEOVER_RENT_PAYMENT_ID_PAGE]}
          render={() => <RentPaymentTransaction margin="0px" padding="0px" showBreadcrumb />}
        />
        {/* legacy routes compatibility */}
        <Route
          exact
          path={PATH.RENT_PAYMENT_ID_TRANSACTION_PAGE}
          render={({ match: { params } }) => (
            <Redirect to={{ pathname: buildPath(PATH.RENT_PAYMENT_ID_PAGE, params) }} />
          )}
        />
        <Route
          exact
          path={PATH.RENT_PAYMENT_ID_HISTORY_PAGE}
          render={({ match: { params } }) => (
            <Redirect to={{ pathname: buildPath(PATH.RENT_PAYMENT_ID_PAGE, params) }} />
          )}
        />
        <Route
          exact
          path={PATH.RENT_PAYMENT_ID_INFO_PAGE}
          render={({ match: { params } }) => (
            <Redirect to={{ pathname: buildPath(PATH.RENT_PAYMENT_ID_PAGE, params) }} />
          )}
        />
      </Switch>
    </Switch>
  )
}
