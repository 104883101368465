import { Box, MenuItem } from '@mui/material'
import Select from '@rentspree/component-2023.components.atoms.select'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setupCreateProperty } from 'v3/containers/create-property-form/actions'
import { useCreatePropertyHook } from 'v3/containers/create-property-form/hooks'
import {
  createPropertyString,
  isEmptyOrNotObject,
} from 'v3/containers/overhaul-rent-payment/pages/utils'
import { PROP_AND_TENANT } from 'v3/containers/overhaul-rent-payment/text-constants'
import { useListPropertiesQuery } from 'v3/containers/rent-payment/apis/property'

export const PropertySelectAndForm = ({ selectedProperty, setProperty }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const selectedPropertyStr =
    typeof selectedProperty === 'string' && selectedProperty.length > 0
      ? createPropertyString(selectedProperty)
      : undefined

  const [properties, setProperties] = useState([])
  const getAllProperties = useCallback(() => {
    return properties.filter(el => !isEmptyOrNotObject(el))
  }, [properties])

  const listPropertiesQuery = useListPropertiesQuery()

  /*
   * const propertyOptions = useMemo(
   *   () => generatePropertyOptions(listPropertiesQuery.data?.data),
   *   [listPropertiesQuery.data],
   * )
   */

  useEffect(() => {
    if (listPropertiesQuery.isSuccess && !listPropertiesQuery.isLoading) {
      setProperties(listPropertiesQuery.data?.data || [])
    }
  }, [listPropertiesQuery.data, listPropertiesQuery.isSuccess, listPropertiesQuery.isLoading])

  // TODO: skip landlord/agent/etc. page
  const { open: openPropertyForm, close: closePropertyForm } = useCreatePropertyHook()
  useEffect(() => {
    if (isOpen) {
      const options = {
        onSuccessCallback: async ({ property }) => {
          await listPropertiesQuery.refetch()
          setProperty(property)
          setIsOpen(false)
        },
      }
      dispatch(setupCreateProperty({ options }))
      openPropertyForm()
    } else {
      closePropertyForm()
    }
  }, [isOpen, dispatch])

  return (
    <Box style={{ margin: 'auto', marginBottom: '20px', width: '100%' }}>
      <Select
        label="Property"
        value={selectedProperty?._id || ''}
        style={{ fontSize: '1.75rem', textAlign: 'left' }}
        required
      >
        <MenuItem
          key="new"
          onClick={() => {
            setProperty(undefined)
            setIsOpen(true)
          }}
        >
          {PROP_AND_TENANT.ADD_NEW_PROPERTY}
        </MenuItem>
        {getAllProperties().map(val => {
          const propString = createPropertyString(val)
          return (
            <MenuItem
              key={val._id}
              onClick={() => setProperty(val)}
              value={val._id}
              selected={selectedPropertyStr === propString}
            >
              {propString}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}
