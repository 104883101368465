import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { buildPath } from '@rentspree/path'
import { generateAddress } from '@rentspree/helper'
import { withRouter } from 'react-router-dom'
import tracker from 'tracker'
import { EVENT_TENANT_SCREENING } from 'tracker/const'
import { CenterContent, Loading } from 'components/layout/main'
import { FullModalCreateProperty } from 'components/templates/create-property-full-page-modal/modal'
import ExistingSubmissionErrorModal from 'components/templates/tenant-screening/existing-submission-error-modal'
import SelectPropertyComponent from 'components/templates/tenant-screening/select-property-before-request'
import {
  SINGLE_APPLICATION,
  TENANT_SCREENING,
  PERMALINK_ACTIONS_ID,
  SELECT_AGENT_TYPE,
} from 'constants/route'

import withScreenTenantRequirement from 'containers/request/with-screen-tenant-requirement'
import { useMutateUpdateUserRoleForProperty } from 'hooks/use-mutate-update-user-role-for-property'
import { USER_ROLE_FOR_PROPERTY } from 'containers/request/step-create-property/constants'
import { saveNewListingTrackerWithUserRoleMapper } from 'tracker/tracker-mapper'
import StandardizedAddressModal from 'containers/address-verification/standardized-address-modal'
import {
  withAddressVerificationReducer,
  withAddressVerificationSaga,
} from 'containers/address-verification/connect'
import isEqual from 'lodash/isEqual'
import InvalidAddressConfirmModal from 'containers/address-verification/invalid-address-confirm-modal'
import ValidAddressConfirmModal from 'containers/address-verification/valid-address-confirm-modal'
import { useCreatePropertyHook } from 'v3/containers/create-property-form/hooks'
import { get } from 'lodash'
import SelectPropertyConnect from './connect'
import { withSaga } from '../../assign-property/connect'
import { TENANT_SCREENING_FLOW_ORIGIN } from '../constants'

export const SelectProperty = ({
  actions,
  isLoading,
  applicant,
  history,
  propertyList,
  selectedPropertyId,
  createPropertyModal,
  existingSubmissionErrorModal,
  addressVerification,
  isVerifyingPropertyAddress,
}) => {
  const [sessionToken, setSessionToken] = useState('')
  const [createdPropertyId, setCreatedPropertyId] = useState(null)
  const [propertyInput, setPropertyInput] = useState({})
  const [isAutoFill, setIsAutoFill] = useState(false)
  const [showValidConfirmModal, setShowValidConfirmModal] = useState(false)
  const [showInvalidConfirmModal, setShowInvalidConfirmModal] = useState(false)
  const [showStandardizedAddressModal, setShowStandardizedAddressModal] = useState(false)

  const { isVerified, inputAddress, standardizedAddress } = addressVerification
  const generatedAddress = generateAddress(propertyInput)

  const { open: openPropertyCreateFormPanel, setupCreateProperty } = useCreatePropertyHook()

  const { EVENT_NAME, EVENT_PROPERTY } = EVENT_TENANT_SCREENING
  const { CLICK_FROM, PROPERTY_MODE } = EVENT_PROPERTY

  const propertyMap = propertyList.reduce(
    (map, property) => ({ ...map, [property.value]: property.data }),
    {},
  )

  const mutateUserRoleForProperty = useMutateUpdateUserRoleForProperty()

  useEffect(() => {
    actions.getAssignPropertyList(1)
  }, [])

  useEffect(() => {
    if (createPropertyModal.isSuccess) {
      setCreatedPropertyId(createPropertyModal.createdPropertyId)
    }
  }, [createPropertyModal.isSuccess])

  useEffect(() => {
    if (standardizedAddress && !isEqual(standardizedAddress, inputAddress)) {
      setShowStandardizedAddressModal(true)
      return
    }
    if (isVerified === true) {
      setShowValidConfirmModal(true)
      return
    }
    if (isVerified === false) {
      setShowInvalidConfirmModal(true)
    }
  }, [addressVerification])

  const handleSetIsAutoFill = ({ isAutoFillValue }) => {
    setIsAutoFill(isAutoFillValue)
  }

  const handleOpenCreatePropertyModal = () => {
    tracker.trackEvent(EVENT_NAME.SWITCH_TO_ADD_NEW_PROPERTY)

    const buttonLocation = 'address dropdown'
    tracker.trackButtonClick({
      location: buttonLocation,
      click_text: 'Add new property',
    })
    openPropertyCreateFormPanel()
    const options = {
      location: buttonLocation,
      isLandlordInfoRequired: false,
      onSuccessCallback: ({ property }) => {
        actions.appendAssignPropertyList(property)
        actions.autoSelectProperty(get(property, '_id'))
      },
    }
    setupCreateProperty(options)
  }

  const handleCloseCreatePropertyModal = () => {
    tracker.trackEvent(EVENT_NAME.SWITCH_TO_SELECT_PROPERTY)
    actions.closeCreatePropertyModal()
  }

  const handleCloseValidAddressModal = () => {
    setShowValidConfirmModal(false)
    actions.resetAddressVerification()
  }

  const handleCloseInValidAddressModal = () => {
    setShowInvalidConfirmModal(false)
    actions.resetAddressVerification()
  }

  const handleCloseStandardizedAddressModal = () => {
    setShowStandardizedAddressModal(false)
    actions.resetAddressVerification()
  }

  const handleSubmitProperty = (property, isAddressVerified) => {
    actions.createAndSelectProperty({
      property: { ...property, isAddressVerified: isAutoFill || isAddressVerified },
    })
    setShowValidConfirmModal(false)
    setShowInvalidConfirmModal(false)
    actions.resetAddressVerification()
  }

  const handleVerifyAddress = ({ property }) => {
    setPropertyInput({ ...property, zip: property.zipcode })
    const { street, unitNumber, city, state, zipcode: zip } = property
    const address = { street, unitNumber, city, state, zip }
    actions.verifyPropertyAddress({ address, sessionToken })
    setSessionToken('')
  }

  const submitPropertyHandler = ({ property }) => {
    if (isAutoFill) {
      setPropertyInput({ ...property, zip: property.zipcode })
      setShowValidConfirmModal(true)
      return
    }

    handleVerifyAddress({ property })
  }

  const handleUseSelectedAddress = (property, propertyAddress, isAddressVerified) => {
    actions.createAndSelectProperty({
      property: { ...property, ...propertyAddress, isAddressVerified },
    })
    setShowStandardizedAddressModal(false)
    actions.resetAddressVerification()
  }

  const handleClickNext = async values => {
    const isNewPropertySelected = createdPropertyId === selectedPropertyId
    tracker.trackEvent(EVENT_NAME.PROCEED_THROUGH_PROPERTY_SELECTION, {
      click_from: CLICK_FROM.CREATE_SCREENING_REQUEST_PROPERTY_SELECTION,
      property_mode: isNewPropertySelected
        ? PROPERTY_MODE.NEW_PROPERTY
        : PROPERTY_MODE.EXISTING_PROPERTY,
      property_id: selectedPropertyId,
    })

    const {
      selectedPropertyId: propertyIdForUseMutate,
      userRoleForProperty,
      firstName,
      middleName,
      lastName,
      email,
      mobileNumber,
    } = values
    const landlordProfile = { firstName, middleName, lastName, email, mobileNumber }
    const representing = saveNewListingTrackerWithUserRoleMapper(userRoleForProperty)
    let hasLandlordEmail = 'none'
    if (userRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT) {
      hasLandlordEmail = !!landlordProfile && !!landlordProfile.email
    }
    tracker.trackEvent(EVENT_NAME.UPDATE_PROPERTY_INFO, {
      representing,
      has_landlord_email: hasLandlordEmail,
    })

    const redirectPath = buildPath(
      PERMALINK_ACTIONS_ID,
      { propertyId: selectedPropertyId },
      { origin: TENANT_SCREENING_FLOW_ORIGIN.SELECT_PROPERTY },
    )
    await mutateUserRoleForProperty.mutateAsync({
      propertyId: propertyIdForUseMutate,
      userRoleForProperty,
      landlordProfile,
    })

    history.push(redirectPath)
  }

  const handleClickBack = () => {
    history.push(TENANT_SCREENING)
  }

  const handleClickAddPropertyLater = () => {
    tracker.trackEvent(EVENT_NAME.PROCEED_THROUGH_PROPERTY_SELECTION, {
      click_from: CLICK_FROM.CREATE_SCREENING_REQUEST_PROPERTY_SELECTION,
      property_mode: PROPERTY_MODE.SCREENING_WITHOUT_PROPERTY,
    })

    history.push(SELECT_AGENT_TYPE)
  }

  if (isLoading) {
    return (
      <CenterContent>
        <Loading />
      </CenterContent>
    )
  }

  return (
    <>
      <ExistingSubmissionErrorModal
        isOpen={existingSubmissionErrorModal.isOpen}
        errorPayload={existingSubmissionErrorModal.errorPayload}
        onClickConfirm={() => {
          history.push(
            buildPath(SINGLE_APPLICATION, {
              propertyId: existingSubmissionErrorModal.errorPayload.propertyId,
              rentalAppId: existingSubmissionErrorModal.errorPayload.rentalSubmissionId,
            }),
          )
          actions.openExistingSubmissionErrorModal(false, {})
        }}
        onClose={() => actions.openExistingSubmissionErrorModal(false, {})}
      />
      <FullModalCreateProperty
        isOpen={createPropertyModal.isOpen}
        handleCloseModal={handleCloseCreatePropertyModal}
        handleSubmit={submitPropertyHandler}
        isError={createPropertyModal.isError}
        handleCloseError={actions.clearCreatePropertyModalError}
        isCreatingProperty={createPropertyModal.isSubmitting}
        isCreatePropertySuccess={createPropertyModal.isSuccess}
        isScreeningWithoutProperty
        isVerifyingPropertyAddress={isVerifyingPropertyAddress}
        onHandleAutoFilledUsedTracker={handleSetIsAutoFill}
        sessionToken={sessionToken}
        setSessionToken={setSessionToken}
      />
      <ValidAddressConfirmModal
        isOpen={showValidConfirmModal}
        onClose={handleCloseValidAddressModal}
        handleSubmitProperty={() => handleSubmitProperty(propertyInput, isVerified)}
        handleEditAddress={handleCloseValidAddressModal}
        generatedAddress={generatedAddress}
      />
      <InvalidAddressConfirmModal
        isOpen={showInvalidConfirmModal}
        onClose={handleCloseInValidAddressModal}
        handleSubmitProperty={() => handleSubmitProperty(propertyInput, isVerified)}
        handleEditInvalidAddress={handleCloseInValidAddressModal}
        generatedAddress={generatedAddress}
      />
      <StandardizedAddressModal
        isOpen={showStandardizedAddressModal}
        onClose={handleCloseStandardizedAddressModal}
        handleSubmitProperty={(propertyAddress, isAddressVerified) =>
          handleUseSelectedAddress(propertyInput, propertyAddress, isAddressVerified)
        }
        enteredAddress={inputAddress}
        standardizedAddress={standardizedAddress}
        isAddressVerified={isVerified}
      />
      <SelectPropertyComponent
        propertyList={propertyList}
        selectedPropertyId={selectedPropertyId}
        applicant={applicant}
        onSubmitProperty={handleClickNext}
        onChangeProperty={actions.selectProperty}
        onClickBackToDashboard={handleClickBack}
        isLoading={isLoading}
        onClickCreateNewProperty={handleOpenCreatePropertyModal}
        onClickAddPropertyLater={handleClickAddPropertyLater}
        isScreeningWithoutProperty
        propertyMap={propertyMap}
      />
    </>
  )
}

export default compose(
  withRouter,
  withSaga,
  withAddressVerificationReducer,
  withAddressVerificationSaga,
  SelectPropertyConnect,
  withScreenTenantRequirement,
)(SelectProperty)
