import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const buttonDisabledStyled = `
  background-color: ${COLOR.bgGrey};
  color: ${COLOR.placeholderGrey};
`

export const Button = styled.button`
  height: 40px;
  width: 65px;
  border: 1px solid ${COLOR.placeholderGrey};
  background-color: ${COLOR.white};
  color: ${COLOR.textBlack};
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
  ${({ disabled }) => disabled && buttonDisabledStyled};
`
export const Count = styled.div`
  position: absolute;
  top: 8px;
  right: 3px;
  height: 13px;
  width: 13px;
  background-color: ${COLOR.newDeepBlue};
  border-radius: 50%;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: ${COLOR.white};
`

const Container = styled.div`
  position: relative;
`

const FilterButton = ({ onClick, currentFilterCount, disabled }) => (
  <Container>
    <Button onClick={onClick} disabled={disabled}>
      Filter
    </Button>
    {!!currentFilterCount && <Count>{currentFilterCount}</Count>}
  </Container>
)

export default FilterButton
