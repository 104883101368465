export const REVIEW = {
  TITLE: 'Review and send payment request',
  DESCRIPTION:
    'Make sure everything looks good. We’ll notify the tenant once you send the request.',
}
export const TENANT_AND_PROPERTY = 'Tenant and property'
export const PAYMENT_DETAILS = {
  title: 'Payment details',
  description: 'Funds are deposited within 2-5 business days after the tenant makes the payment.',
}
export const MONTHLY_PAYMENTS = 'Monthly payments'
export const ONE_TIME_PAYMENTS = 'One-time payments'
export const RECIPIENT_DETAILS = 'Recipient details'
export const INVITE_BY_TEXT = 'inviteByText'

// Add these payment type constants
export const PAYMENT_TYPES = Object.freeze({
  RECURRING: 'recurring',
  ONE_TIME: 'oneTime',
})
// Add these recipient info field constants
export const RECIPIENT_INFO_FIELDS = Object.freeze({
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  RECIPIENT_INFO: 'recipient-info',
})
export const pageOptions = Object.freeze({
  RECIPIENT_DETAILS: 'recipient-details',
  PROPERTY_AND_TENANT: 'property-and-tenant',
  PAYMENT_DETAILS: 'payment-details',
  REVIEW: 'review',
})

export const recipientDetailsOptions = Object.freeze({
  ME: 'me',
  CLIENT: 'client',
})
