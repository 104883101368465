import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import TagsDropdown from 'components/contacts/molecules/tags-dropdown'
import { breakpoints, COLOR } from 'styles/settings'

import MultipleTag from '../multiple-tags'

const Cell = styled.td`
  width: 100%;
  border-bottom: 1px solid ${COLOR.lineGrey};
  box-sizing: border-box;
  ${breakpoints.mobile(`
    display: block;
    border-bottom: none;
  `)};
`
const TagBoxWrapper = styled.div`
  ${breakpoints.mobile(`
    margin-left: 31px;
  `)};
`
const FlexWrapper = styled.div`
  display: flex;
  ${breakpoints.mobile(`
    position: relative;
  `)};
`

const AddTagButton = styled(TagsDropdown)`
  margin: 0;
`

const ContactTagCell = ({
  contactId,
  tags,
  allTags,
  onCreateAndTagContact,
  onTagContact,
  onShowMoreTag,
  onUntagContact,
  isLoadingTag,
  onFocusAddTag,
}) => (
  <Cell>
    <TagBoxWrapper>
      {tags?.length > 0 ? (
        <FlexWrapper>
          <MultipleTag
            onUntagContact={onUntagContact}
            number={tags?.length - 1}
            tags={tags}
            isLoading={isLoadingTag}
            contactId={contactId}
            allTags={allTags}
            onTagContact={tagId => onTagContact(tagId)}
            onShowMoreTag={onShowMoreTag}
            onFocus={onFocusAddTag}
            onCreateAndTagContact={onCreateAndTagContact}
          />
        </FlexWrapper>
      ) : (
        <AddTagButton
          maxWidth="150px"
          margin="0 0 5px 0px"
          mMargin="0 0 5px 0px"
          isLoading={isLoadingTag}
          contactId={contactId}
          sortedAllTags={allTags}
          onTagContact={tagId => onTagContact(tagId)}
          onFocus={onFocusAddTag}
          onCreateAndTagContact={onCreateAndTagContact}
        />
      )}
    </TagBoxWrapper>
  </Cell>
)

export default ContactTagCell

ContactTagCell.defaultProps = {
  tags: [],
}

ContactTagCell.propTypes = {
  tags: PropTypes.array,
  onCreateAndTagContact: PropTypes.func,
  onFocusAddTag: PropTypes.func,
}
