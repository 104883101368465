import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import HubspotForm, { FormWrapper } from 'components/form/hubspot-form'
import { selectProfile } from 'containers/user/selectors'
import { COLOR } from 'styles/settings'

const HubspotFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > ${FormWrapper} > div > div.form > div.form-content-hubspot > .submitted-message {
    display: none;
  }

  > ${FormWrapper}, > ${FormWrapper} > div,
  > ${FormWrapper} > div > div.form,
  > ${FormWrapper} > div > div.form > div.form-content-hubspot,
  > ${FormWrapper} > div > div.form > div.form-content-hubspot > form,
  > ${FormWrapper} > div > div.form > div.form-content-hubspot > form > div.hs-fieldtype-textarea,
  > ${FormWrapper}
    > div
    > div.form
    > div.form-content-hubspot
    > form
    > div.hs-fieldtype-textarea
    > div.input {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  > ${FormWrapper} > div > div.form > div.form-content-hubspot > form {
    justify-content: space-between;
    margin: unset;
    > .hs_error_rollup {
      display: none;
    }
    > div.hs-fieldtype-textarea > div.input > textarea {
      flex: 1;
      border-radius: unset;
      resize: none;
    }
    > div.hs-fieldtype-textarea > .hs-error-msgs {
      display: none !important;
    }
    > div.hs-submit > div.actions {
      display: flex;
      justify-content: flex-end;
      > input[type='submit'] {
        flex: 0.25;
        margin: unset;
        margin-bottom: 15px;
        border: unset;
        background: linear-gradient(90deg, ${COLOR.blueLv2} 7.19%, ${COLOR.newDeepBlue} 107%);
      }
    }
    &.is-error {
      > div.hs-fieldtype-textarea > div.input > textarea {
        border: 1px solid ${COLOR.redLv2};
        background: ${COLOR.redLv5};
      }
      > div.hs-fieldtype-textarea > .hs-error-msgs {
        display: unset !important;
        margin-bottom: 0;
        > li {
          &::after {
            content: 'Required';
            position: absolute;
            color: ${COLOR.redLv2};
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
          }
          > label {
            display: none;
          }
        }
      }
    }
  }
`

const Content = ({ onFormSubmitted }) => {
  const ref = React.useRef()
  const profile = useSelector(selectProfile)

  const handleFormAction = React.useCallback(
    event => {
      if (ref?.current && ['click', 'input', 'blur'].includes(event?.type)) {
        const { value } = ref.current.querySelector('textarea')
        if (['click', 'blur'].includes(event.type) && value === '') {
          ref.current.querySelector('form').classList.add('is-error')
        } else if (value === '') {
          ref.current.querySelector('form').classList.remove('is-error')
        } else if (value?.trim() === '') {
          ref.current.querySelector('form').classList.add('is-error')
        } else {
          ref.current.querySelector('form').classList.remove('is-error')
        }
      }
    },
    [ref],
  )

  const onFormReady = () => {
    if (ref?.current) {
      const link = ref.current.querySelector('a')
      if (link) {
        link.click()
      }
      ref.current.querySelector("input[name='email']").value = profile.email
      ref.current
        .querySelector("input[type='submit']")
        .addEventListener('click', handleFormAction, true)
      ref.current.querySelector('textarea').addEventListener('input', handleFormAction, true)
      ref.current.querySelector('textarea').addEventListener('blur', handleFormAction, true)
    }
  }

  return (
    <HubspotFormWrapper ref={ref}>
      <HubspotForm
        portalId="3028281"
        formId="cbf565df-e0a2-4c44-b57f-c64a16682cdb"
        id="RCMSuggestGiftForm"
        onFormSubmitted={onFormSubmitted}
        onFormReady={onFormReady}
      />
    </HubspotFormWrapper>
  )
}

export default Content
