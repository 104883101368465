export const UPCOMING_EVENT_TYPES = {
  BIRTHDAY: 'birthday',
  MOVE_IN_ANNIVERSARY: 'move-in-anniversary',
  MOVING_IN: 'moving-in',
}

export const GIFT_TYPES = {
  BIRTHDAY: 'birthday',
  MOVE_IN_ANNIVERSARY: 'move-in',
  MOVING_IN: 'moving-in',
}

export const UPCOMING_EVENT_TO_GIFT_TYPES = {
  [UPCOMING_EVENT_TYPES.BIRTHDAY]: GIFT_TYPES.BIRTHDAY,
  [UPCOMING_EVENT_TYPES.MOVE_IN_ANNIVERSARY]: GIFT_TYPES.MOVE_IN_ANNIVERSARY,
  [UPCOMING_EVENT_TYPES.MOVING_IN]: GIFT_TYPES.MOVING_IN,
}
