import { createInstance } from "@rentspree/feature-toggle"
import { DOMAIN_NAME, CDN_URL } from "env"

const BASE_URL =
  DOMAIN_NAME === "localhost"
    ? `https://partner-admin.ladprao21.com`
    : `https://partner-admin.${DOMAIN_NAME}`

const disableFeature = createInstance({
  BASE_URL,
  CDN_URL,
  timeout: 5000,
})

export default disableFeature
