import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

const { EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT

export const EVENT_TYPE_KEYS = {
  ALL: 'ALL',
  BIRTHDAY: 'BIRTHDAY',
  MOVE_IN_ANNIVERSARY: 'MOVE_IN_ANNIVERSARY',
  MOVING_IN: 'MOVING_IN',
}

export const DATE_TYPE_KEYS = {
  ALL: 'ALL',
  TODAY: 'TODAY',
  SEVEN_DAYS: 'SEVEN_DAYS',
  THIRTY_DAYS: 'THIRTY_DAYS',
}

export const UPCOMING_EVENT_OPTION = [
  {
    label: 'All Events',
    value: EVENT_TYPE_KEYS.ALL,
    trackName: EVENT_PROPERTY.QUICK_FILTER.ALL_EVENTS,
  },
  {
    label: 'Birthdays',
    value: EVENT_TYPE_KEYS.BIRTHDAY,
    trackName: EVENT_PROPERTY.QUICK_FILTER.BIRTHDAYS,
  },
  {
    label: 'Move-in',
    value: EVENT_TYPE_KEYS.MOVING_IN,
    trackName: EVENT_PROPERTY.QUICK_FILTER.MOVING_IN,
  },
  {
    label: 'Move-in Anniversary',
    value: EVENT_TYPE_KEYS.MOVE_IN_ANNIVERSARY,
    trackName: EVENT_PROPERTY.QUICK_FILTER.MOVE_IN_ANNIVERSARY,
  },
]

export const UPCOMING_DATE_OPTION = [
  {
    label: 'All Dates',
    value: DATE_TYPE_KEYS.ALL,
    trackName: EVENT_PROPERTY.QUICK_FILTER.ALL_DATE,
  },
  {
    label: 'Today',
    value: DATE_TYPE_KEYS.TODAY,
    trackName: EVENT_PROPERTY.QUICK_FILTER.TODAY,
  },
  {
    label: 'Within 7 days',
    value: DATE_TYPE_KEYS.SEVEN_DAYS,
    trackName: EVENT_PROPERTY.QUICK_FILTER.SEVEN_DAYS,
  },
  {
    label: 'Within 30 days',
    value: DATE_TYPE_KEYS.THIRTY_DAYS,
    trackName: EVENT_PROPERTY.QUICK_FILTER.THIRTY_DAYS,
  },
]
export const APPLY_QUICK_FILTER = 'APPLY_QUICK_FILTER'
