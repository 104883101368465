import { API_URL } from 'env'

export const GET_GIFTS_API = `${API_URL}/api/v2/people/gifts`
export const GENERATE_GIFT_LINK_API = `${API_URL}/api/v2/people/gift-trackings/generate-link`

export const GET_GIFTS_CALL = 'GET_GIFTS_CALL'
export const GET_GIFTS_REQUEST = 'GET_GIFTS_REQUEST'
export const GET_GIFTS_SUCCESS = 'GET_GIFTS_SUCCESS'
export const GET_GIFTS_FAILURE = 'GET_GIFTS_FAILURE'

export const GENERATE_GIFT_LINK_CALL = 'GENERATE_GIFT_LINK_CALL'
export const GENERATE_GIFT_LINK_REQUEST = 'GENERATE_GIFT_LINK_REQUEST'
export const GENERATE_GIFT_LINK_SUCCESS = 'GENERATE_GIFT_LINK_SUCCESS'
export const GENERATE_GIFT_LINK_FAILURE = 'GENERATE_GIFT_LINK_FAILURE'

export const SET_SELECTED_GIFT = 'SET_SELECTED_GIFT'

export const TOAST_BODY_MESSAGE = 'Something went wrong, please try again later'
