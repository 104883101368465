import { Box, Grid } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React, { useState } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

import { RENT_PAYMENT } from '../../../../../constants/route'
import { TAX_INFORMATION_STEPS } from '../../../identity-verification/constants'
import { PersonalDetailsStep } from '../../../identity-verification/personal-details-step/personal-details-step'

const mobileGridSize = 12
const desktopGridSize = 6

export const handlePersonalDetailsProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  _pageField,
  _curPaymentInfo,
) => {
  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }

  // eslint-disable-next-line no-console
  console.log('Progression handler is handling progression...')

  // shortcut invalid scenarios
  if (increment === decrement) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Recipient Details page progression request')
  } else {
    if (increment) {
      indexChanges.pageL1Index += 1
    }
    if (decrement) {
      indexChanges.pageL1Index -= 1
    }
  }

  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  setPageIndexes(indexChanges)
}

export const PersonalDetailsPage = ({ history }) => {
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const gridSize = isMobile ? mobileGridSize : desktopGridSize

  // eslint-disable-next-line no-unused-vars
  const [_, setCurrentStep] = useState(TAX_INFORMATION_STEPS.BUSINESS_TYPE)
  // eslint-disable-next-line no-unused-vars
  const [__, setHiddenBottomBar] = useState(true)

  const textStyle = {
    textAlign: 'left',
  }

  const onExit = () => {}

  return (
    <Grid item xs={gridSize}>
      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="title" sx={textStyle}>
          Last step: Verify your identity
        </Typography>
      </Box>
      <PersonalDetailsStep
        continuePath={RENT_PAYMENT}
        history={history}
        location={{}}
        onExit={onExit}
        setCurrentStep={setCurrentStep}
        setHiddenBottomBar={setHiddenBottomBar}
        setWelcomeModalOnContinue={false}
      />
    </Grid>
  )
}
