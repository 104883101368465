/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
import { addLocaleData } from 'react-intl'
import enLocaleData from "react-intl/locale-data/en"
import deLocaleData from "react-intl/locale-data/de"

import enTranslationMessages from "./translations/en.json"
import deTranslationMessages from "./translations/de.json"

addLocaleData(enLocaleData)
addLocaleData(deLocaleData)

const DEFAULT_LOCALE = "en"

const appLocales = [
  'en',
  'de',
]

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {}
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }
  return Object.keys(messages).reduce(flattenFormattedMessages, {})
}

const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  de: formatTranslationMessages("de", deTranslationMessages),
}

export {
  appLocales,
  formatTranslationMessages,
  translationMessages,
  DEFAULT_LOCALE

}
