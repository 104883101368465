import { B16, Hr, RedText, S20 } from '@rentspree/component-v2'
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import SelectDropdownV3 from '@rentspree/component-v3.components.atoms.select-dropdown'
import { USER_ROLE_FOR_PROPERTY } from 'containers/request/step-create-property/constants'
import { LandlordBasicInfoForm } from '../landlord-information-form'
import { FormWrapper, UserRoleForPropertyFormWrapper } from './styled-index'
import {
  REPRESENTING_ROLES,
  USER_ROLE_FOR_PROPERTY_FORM_TITLE,
  EXPERIMENTAL_LANDLORD_FORM_DESCRIPTION
} from './constants'

export const UserRoleForPropertyForm = forwardRef(({
  choices = REPRESENTING_ROLES,
  dropDownMargin = "0px 0px 30px",
  dropDownWidth = "480px",
  landlordProfileWidth,
  onSelectDropdownChange,
  wrapperWidth = "500px",
  error,
  ...props
}, ref) => {
  const [selectedUserRoleForProperty, setSelectedUserRoleForProperty] = useState(null)
  // NOTE: this is needed for resetting the dropdown
  const [dropDownKey, setDropDownKey] = useState(undefined)

  const handleSelectDropdownChange = (value) => {
    onSelectDropdownChange(value)
    setSelectedUserRoleForProperty(value)
    setDropDownKey(value)
  }

  useImperativeHandle(ref, () => ({
    resetSelectedUserRoleForProperty: () => {
      setSelectedUserRoleForProperty(null)
      setDropDownKey(null)
    }
  }))

  return (
    <UserRoleForPropertyFormWrapper width={wrapperWidth}>
      <S20>{USER_ROLE_FOR_PROPERTY_FORM_TITLE}<RedText> *</RedText></S20>
      <Hr margin="0 0 8px 0" />
      <FormWrapper width={dropDownWidth} margin={dropDownMargin}>
        <SelectDropdownV3
          key={dropDownKey}
          isRequired
          id="dropDownSelectUserRoleForProperty"
          options={choices}
          aria-label="dropDownSelectUserRoleForProperty"
          noLabel
          name="selectedUserRoleForProperty"
          placeholder="Select one"
          onChange={handleSelectDropdownChange}
          value={selectedUserRoleForProperty}
          error={error}
        />
        {
          selectedUserRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT && (
            <>
              <B16 margin="20px 0 10px">{EXPERIMENTAL_LANDLORD_FORM_DESCRIPTION}</B16>
              <LandlordBasicInfoForm
                id="landlord-info-form"
                margin="0"
                width={landlordProfileWidth}
                {...props}
              />
            </>
          )
        }
      </FormWrapper>
    </UserRoleForPropertyFormWrapper>
  )
})
