import React from 'react'
import { compose } from 'redux'
import { withLegacyScreeningFeatureGate } from 'utils/feature-flag/features/premium-screening'
import { withSubscriptionData } from 'utils/subscription/with-subscription-data'
import { RequestWrapper } from 'legacy/components/request/request-main-style'
import { withSaga, withConnect, withReducer } from './connect'

import RequestWithOutPropertyStepOptions from '../request-v2.2'
import { RequestStepOptions } from './request-step-options'
import { withUserPreferenceData } from '../../../utils/tenant-screening/with-user-preference-data'
import withSelectScreeningOptionsPage from '../with-select-screening-option-page'

const withRequestWrapper = Component => props =>
  (
    <RequestWrapper>
      <Component {...props} />
    </RequestWrapper>
  )

export default compose(
  withConnect,
  withSaga,
  withReducer,
  withSubscriptionData,
  withUserPreferenceData,
  withLegacyScreeningFeatureGate,
  withRequestWrapper,
  withSelectScreeningOptionsPage,
)(RequestWithOutPropertyStepOptions, RequestStepOptions)
