import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { breakpoints, COLOR } from 'styles/settings'

const Cell = styled.td`
  padding-right: 25px;
  border-bottom: 1px solid ${COLOR.lineGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  ${breakpoints.mobile(`
     border-bottom: none;
  `)};
`
const ContactCell = ({ text }) => <Cell>{text}</Cell>

ContactCell.prototype = {
  text: PropTypes.string,
}

export default ContactCell
