import styled from "styled-components"

export const BackButtonContainer = styled.div`
  order: 1;
  padding: 11.5px 30px;
  #BackToDashBoardBtn {
    height: 22px;
  }
  @media (max-width: 991px) {
    order: 1;
    padding: 11.5px 20px;
    #BackToDashBoardBtn {
      height: 22px;
    }
  }
`

export const StickyStyled = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 1;
`
