import find from 'lodash/find'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { breakpoints, COLOR } from 'styles/settings'

export const FilterHeader = styled.button`
  height: auto;
  width: 100%;
  background: transparent;
  border: none;
  text-overflow: ellipsis;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 8px;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
  position: relative;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  color: ${COLOR.newDeepBlueGradientMid};
`
const FilterText = styled.p`
  width: 90%;
  margin: auto 0;
  overflow: hidden;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Select = styled.select`
  width: 90%;
  height: 28px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
  appearance: none;
  border-radius: 4px;
  background: transparent;
  text-overflow: ellipsis;
  color: ${COLOR.newDeepBlueGradientMid};
`

export const FilterButton = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  width: 100%;
  cursor: pointer;
  height: auto;
  font-weight: 600;
  position: relative;
  border-radius: 4px;
  font-style: normal;
  text-rendering: auto;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  color: ${COLOR.newDeepBlueGradientMid};
  border: 1px solid
    ${props => (props.isSelected || props.isFocus ? COLOR.newDeepBlueGradientMid : COLOR.grayLv3)};
  background: ${props => (props.isSelected ? COLOR.lightBlueHEX : COLOR.grayLight)};

  i {
    top: 8px;
    right: 10px;
    position: absolute;
    pointer-events: none;
  }
`

export const FilterListContainer = styled.div`
  padding: 0;
  z-index: 1000;
  font-size: 14px;
  margin-top: 2px;
  font-weight: 600;
  min-width: 153px;
  max-height: 35vh;
  line-height: 20px;
  position: absolute;
  font-style: normal;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${COLOR.grayLight};
  color: ${COLOR.newDeepBlueGradientMid};
  width: ${props => props.width || '100%'};
  border: 1px solid ${COLOR.newDeepBlueGradientMid};
`

export const ListItem = styled.li`
  cursor: pointer;
  list-style: none;
  padding: 6px 12px;
  &:first-child {
    &:hover {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: ${COLOR.grayLv6};
    }
  }
  &:hover {
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background: ${COLOR.grayLv6};
  }
  ::-webkit-scrollbar {
    display: none;
  }
  &:last-child {
    &:hover {
      background: ${COLOR.grayLv6};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`

export const Option = styled.option``

export const Section = styled.section`
  font-family: Source Sans Pro, sans-serif;
  width: ${props => props.width || '100%'};
`

export const MobilePanel = styled.div`
  display: none;
  ${breakpoints.mobile(`
  display: block;`)};
`

export const Panel = styled.div`
  ${breakpoints.mobile(`
  display: none;`)};
`

export const Filter = ({ options, callback, icon, width, defaultOption, previousSelected }) => {
  const previousOption = previousSelected && find(options, item => item.value === previousSelected)
  const [isOpen, setIsOpen] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [selectedOption, setSelectedOption] = useState(previousOption || defaultOption)

  useEffect(() => {
    const isSelectOption = selectedOption.value !== defaultOption.value
    setIsSelected(isSelectOption)
  }, [selectedOption])

  const handleSetOption = option => {
    setSelectedOption(option)
    callback(option)
    setIsOpen(false)
  }

  const onOptionChange = option => e => {
    const value = option || JSON.parse(e.target.value)
    handleSetOption(value)
  }

  return (
    <Section width={width}>
      <Panel>
        <FilterButton isSelected={isSelected} width={width} isFocus={isFocus}>
          <FilterHeader
            value={selectedOption.label}
            onClick={() => setIsOpen(!isOpen)}
            onFocus={() => setIsFocus(true)}
            onBlur={() => {
              setIsOpen(false)
              setIsFocus(false)
            }}
          >
            <FilterText>{selectedOption.label}</FilterText>
            <i className={icon || 'fas fa-caret-down'} />
          </FilterHeader>
        </FilterButton>
        {isOpen && (
          <FilterListContainer width={width}>
            {options.map(option => (
              <ListItem key={`option-${option.label}`} onMouseDown={onOptionChange(option)}>
                {option.label}
              </ListItem>
            ))}
          </FilterListContainer>
        )}
      </Panel>
      <MobilePanel>
        <FilterButton isSelected={isSelected}>
          <i className={icon || 'fas fa-caret-down'} />
          <Select value={JSON.stringify(selectedOption)} onChange={e => onOptionChange()(e)}>
            {options.map(option => (
              <Option key={`option-${option.label}`} value={JSON.stringify(option)}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FilterButton>
      </MobilePanel>
    </Section>
  )
}
const optionType = {
  label: PropTypes.string,
  value: PropTypes.any,
  trackName: PropTypes.string,
}
Filter.propTypes = {
  icon: PropTypes.string, // support only awesome icons
  width: PropTypes.string,
  callback: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.exact(optionType)).isRequired,
  defaultOption: PropTypes.exact(optionType).isRequired,
  previousSelected: PropTypes.exact(optionType),
}

export default Filter
