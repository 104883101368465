import DropdownMenuWithCircleHover from '@rentspree/component-v2/dist/menu-dropdown/dropdown-menu-with-circle-hover'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const DotsContainer = styled.div`
  span {
    display: block;
    width: ${({ dotSize }) => dotSize || '4px'};
    height: ${({ dotSize }) => dotSize || '4px'};
    margin: 2px auto;
    background-color: ${COLOR.textBlack};
    border-radius: 50%;
  }
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 8px;

  ul {
    margin-right: -2px;
  }

  .dropdown-menu {
    position: absolute;
    right: 0;
    min-width: 180px;
    width: 180px;
    margin-top: 15px;
    padding: 5px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);

    // triangle
    &:after {
      position: absolute;
      top: -11px;
      right: 8px;
      content: '';
      width: 0;
      height: 0;
      border-bottom: 12px solid ${COLOR.white};
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      filter: drop-shadow(0px -4px 2px rgba(175, 175, 175, 0.5));
    }
  }

  a[role='menuitem'] {
    padding: 5px 20px;
    font-size: 16px;
  }
`

const Dots = ({ dotSize }) => (
  <DotsContainer dotSize={dotSize}>
    <span />
    <span />
    <span />
  </DotsContainer>
)

const Dropdown = ({ className, menuItems, disabled, dotSize }) => (
  <DropdownContainer className={className}>
    <DropdownMenuWithCircleHover
      ToggleComponent={() => <Dots dotSize={dotSize} />}
      menuItems={menuItems}
      disabled={disabled}
    />
  </DropdownContainer>
)

export default Dropdown
