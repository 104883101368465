import B14 from '@rentspree/component-v2/dist/typography/b14'
import React from 'react'
import styled from 'styled-components'

import { breakpoints, COLOR } from 'styles/settings'

const GiftContainer = styled.div`
  width: 33.333%;
  padding: 7.5px;
  ${breakpoints.mobile(`
    padding: 5px;
  `)};
`
const Gift = styled.div`
  padding: 20px 2px 0px 2px;
  border: 1px solid #aaaaaa;
  border-radius: 9px;
  height: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${COLOR.grayLv7};
  }
`

const GiftImage = styled.img`
  height: 60px;
  width: 60px;
`

const GiftName = styled(B14)`
  padding-top: 5px;
  text-align: center;
  color: ${COLOR.grayLv1};
`

const GiftBox = ({ name, imageUrl, onClick }) => (
  <GiftContainer>
    <Gift onClick={onClick}>
      <GiftImage src={imageUrl} />
      <GiftName weight={600}>{name}</GiftName>
    </Gift>
  </GiftContainer>
)

export default GiftBox
