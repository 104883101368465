import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { WhiteWrapper } from 'components/layout/main'
import FaqAccordionV2 from 'components/panel/faq-accordion-v2'
import { COLOR } from 'styles/settings'
import { SUPPORT_HELP_RENTSPREE_PRO_URL } from 'constants/route'
import { P, S28 } from 'components/typography'
import { useWindowDimensions } from 'containers/contacts/hooks'

import circleTickIconBlue from 'images/circle-with-tick-inside.svg'
import circleXIconGrey from 'images/circle-xmark-inside.svg'
import { PricePlanCard } from 'components/pro/landing/price-plan-card'
import {
  FeatureAccordion,
  FeatureMobileAccordion,
} from 'components/pro/landing/price-plan-card/utils'
import {
  FeatureHeaderMobileWrapper,
  StyledFeatureIcon,
} from 'legacy/components/pro/landing/price-plan-card/styled-index'

import LoadingOverlay from 'components/contacts/molecules/loading-overlay'
import ProLandingHeaderImage from 'images/rentspree-pro/pro-landing-header-v2.svg'
import LeftDecorator from 'images/rentspree-pro/pro-landing-body-decoration-left.svg'
import RightDecorator from 'images/rentspree-pro/pro-landing-body-decoration-right.svg'
import tracker from 'tracker'
import { getSubscriptionPeriodFromPriceName } from 'tracker/tracker-mapper'
import { RENTSPREE_PRO_EVENT, RENTSPREE_PRO_EXPAND_FEATURE_DETAIL_MAP } from 'tracker/const'
import { useQueryStrings } from 'hooks'
import {
  BackgroundDecoratorLeft,
  BackgroundDecoratorRight,
  BlueRadialGradientBackground,
  SupportLink,
  SourceSansProActionButton,
} from 'legacy/containers/pro/landing/landing-styled'

import { withConnect, withReducer, withSaga } from 'legacy/containers/pro/landing/connect'
import { getCurrentPlan } from 'legacy/containers/pro/landing/utils'
import { BillingPeriodToggleButton } from 'legacy/components/pro/landing/billing-period-toggle-button/billing-period-toggle-button'
import flatten from 'lodash/flatten'
import {
  LANDING_HEADER_TEXTS,
  FAQ_HEADER,
  PLAN_NAMES,
  PLAN_FEATURES,
  PRO_PLAN_VARIANTS,
  RECOMMENDED_PLAN,
  PLAN_TITLE_MAP,
  SUBSCRIPTION_PROFILE_CHECKOUT_URL,
  FREE_QUOTA,
  PRO_QUOTA,
  SUBSCRIPTION_PROFILE_MANAGEMENT_URL,
  PRO_PAGE_FEATURE_NAME,
  CHOOSE_PLAN_ACTION,
  FEATURE_DESCRIPTIONS,
} from './constants'

import { ProLandingHeader } from './pro-landing-header'
import {
  BillingPeriodToggleButtonWrapper,
  FeatureCellWrapper,
  FeatureRowWrapper,
  PlanCardsWrapper,
  SourceSansProContainer,
  Wrapper,
} from './landing-styled'

const generateAccordionToggleHandler = featureName => expanded => {
  if (expanded) {
    tracker.trackEvent(RENTSPREE_PRO_EVENT.CLICK_TO_EXPAND_FEATURE_DETAIL, {
      feature_name: RENTSPREE_PRO_EXPAND_FEATURE_DETAIL_MAP[featureName],
    })
  }
}

const getFeatureAvailabilityIcon = (plan, feature) => {
  const QUOTA = plan === PLAN_NAMES.FREE ? FREE_QUOTA : PRO_QUOTA
  const available = QUOTA[feature.name]
  const src = available ? circleTickIconBlue : circleXIconGrey
  return <StyledFeatureIcon src={src} alt="featureAvailabilityIcon" size="18px" />
}

const FeatureRenderer = ({ plan, feature }) => {
  const Icon = getFeatureAvailabilityIcon(plan, feature)
  return <FeatureAccordion icon={Icon} />
}

const FeatureMobileRenderer = ({ plan, feature }) => {
  const Icon = getFeatureAvailabilityIcon(plan, feature)

  const toggleHandler = generateAccordionToggleHandler(feature.name)
  return (
    <FeatureMobileAccordion
      featureName={feature.name}
      featureNameColor={COLOR.textBlack}
      textColor={COLOR.textBlack}
      icon={Icon}
      onToggle={toggleHandler}
      expandableText={FEATURE_DESCRIPTIONS[feature.name]}
    />
  )
}

const configFeatures = {
  [PLAN_NAMES.FREE]: PLAN_FEATURES.map(feature => (
    <FeatureMobileRenderer plan={PLAN_NAMES.FREE} feature={feature} />
  )),
  [PLAN_NAMES.PRO]: PLAN_FEATURES.map(feature => (
    <FeatureMobileRenderer plan={PLAN_NAMES.PRO} feature={feature} />
  )),
}

const mapProductsWithConfigFeatures = ({ products, userProduct }) =>
  products.map(product => {
    const isMatch = !!product.variants.find(
      variant => variant.stripePriceId === userProduct.productId,
    )
    const productWithFeatures = {
      ...product,
      features: configFeatures[product.name],
      isActive: isMatch,
    }
    return productWithFeatures
  })

const configProducts = PLAN_FEATURES.reduce((res, feature) => {
  res[feature.name] = [
    <FeatureCellWrapper isFeatureColumn>
      <FeatureAccordion
        text={feature.name}
        expandableText={FEATURE_DESCRIPTIONS[feature.name]}
        onToggle={generateAccordionToggleHandler(feature.name)}
        isFeatureColumn
      />
    </FeatureCellWrapper>,
    ...Object.values(PLAN_NAMES).map(plan => (
      <FeatureCellWrapper>
        <FeatureRenderer plan={plan} feature={feature} key={`${feature.name}-${plan}`} />
      </FeatureCellWrapper>
    )),
  ]
  return res
}, {})

export const Landing = ({ subscription, products, selectedPriceName, actions }) => {
  const [mappedProducts, setMappedProducts] = useState([])
  const [currentPlan, setCurrentPlan] = useState({})
  const { featureName, redirect } = useQueryStrings()
  let priceName

  useEffect(() => {
    if (!products.loaded && !products.loading) {
      actions.getSubscriptionProduct()
    }
    if (!subscription.loaded && !subscription.loading) {
      actions.getUserSubscription()
    }
  }, [])

  useEffect(() => {
    if (subscription.loaded && products.loaded) {
      const {
        products: userProduct,
        trialEnd,
        trialStart,
        cancelAtPeriodEnd,
        currentEndPeriod,
      } = subscription.data
      const plan = getCurrentPlan({
        products: products.data,
        userProduct: userProduct[0],
      })
      setCurrentPlan({
        name: plan,
        trialEnd,
        trialStart,
        cancelAtPeriodEnd,
        currentEndPeriod,
      })
      const mapped = mapProductsWithConfigFeatures({
        products: products.data,
        userProduct: userProduct[0],
      })
      setMappedProducts(mapped)
    }
  }, [subscription?.loaded, products?.loaded])

  if (subscription.loaded && products.loaded) {
    const userVariant = flatten(products.data.map(product => product.variants)).find(
      variant => variant.stripePriceId === subscription.data.products[0].productId,
    )

    if (selectedPriceName !== PRO_PLAN_VARIANTS.YEARLY) {
      if (
        !subscription.data.cancelAtPeriodEnd &&
        [PRO_PLAN_VARIANTS.MONTHLY, PRO_PLAN_VARIANTS.MONTHLY_PRO_EARLY_ADOPTER_DISCOUNT].includes(
          userVariant?.name,
        )
      ) {
        priceName = userVariant?.name
      } else {
        priceName = PRO_PLAN_VARIANTS.MONTHLY
      }
    } else {
      priceName = PRO_PLAN_VARIANTS.YEARLY
    }
  }

  const onClickChoosePlan = (clickFrom, action) => {
    tracker.trackEvent(RENTSPREE_PRO_EVENT.CLICK_CHECKOUT_PAGE, {
      action_needed: action,
      click_from: featureName || clickFrom,
      subscription_period: getSubscriptionPeriodFromPriceName(priceName.toLowerCase()),
    })

    if (featureName && redirect)
      window.location.assign(
        `${SUBSCRIPTION_PROFILE_CHECKOUT_URL}?featureName=${featureName}&redirect=${redirect}`,
      )
    else if (featureName)
      window.location.assign(`${SUBSCRIPTION_PROFILE_CHECKOUT_URL}?featureName=${featureName}`)
    else window.location.assign(`${SUBSCRIPTION_PROFILE_CHECKOUT_URL}?featureName=${clickFrom}`)
  }

  const onClickManagePlanButton = clickFrom => {
    tracker.trackEvent(RENTSPREE_PRO_EVENT.CLICK_SUBSCRIPTION_MANAGEMENT, {
      click_from: clickFrom,
    })
    window.location.assign(SUBSCRIPTION_PROFILE_MANAGEMENT_URL)
  }

  const handleGoToSupport = () => {
    tracker.trackEvent(RENTSPREE_PRO_EVENT.CLICK_HELP_CENTER)
    window.open(SUPPORT_HELP_RENTSPREE_PRO_URL, '_blank')
  }

  const ctaButton = (
    <SourceSansProActionButton
      name={PLAN_NAMES.PRO}
      hasTrialPlan={!currentPlan.trialStart && !currentPlan.trialEnd}
      onClickTrial={() =>
        onClickChoosePlan(PRO_PAGE_FEATURE_NAME.HEADLINE, CHOOSE_PLAN_ACTION.TRIAL)
      }
      onClickChoosePlanButton={() =>
        onClickChoosePlan(PRO_PAGE_FEATURE_NAME.HEADLINE, CHOOSE_PLAN_ACTION.UPGRADE)
      }
      onClickManagePlanButton={() => onClickManagePlanButton(PRO_PAGE_FEATURE_NAME.HEADLINE)}
      isActive={currentPlan.name === PLAN_NAMES.PRO}
      margin="0 0 20px 0"
    />
  )

  const loaded = subscription.loaded && products.loaded && mappedProducts.length > 0

  const planFeatures = PLAN_FEATURES.map(feature => (
    <FeatureAccordion
      text={feature.name}
      onToggle={generateAccordionToggleHandler(feature.name)}
      isFeatureColumn
    />
  ))
  const isYearly = priceName === PRO_PLAN_VARIANTS.YEARLY
  const { isDesktop } = useWindowDimensions()

  return (
    <SourceSansProContainer data-testid="landing-container">
      {!loaded && <LoadingOverlay />}
      <BlueRadialGradientBackground>
        <ProLandingHeader
          title={LANDING_HEADER_TEXTS.TITLE}
          descriptionLine1={LANDING_HEADER_TEXTS.DESCRIPTION_LINE1}
          descriptionLine2={LANDING_HEADER_TEXTS.DESCRIPTION_LINE2}
          descriptionLine3={LANDING_HEADER_TEXTS.DESCRIPTION_LINE3}
          priceTagWording={{
            prefix: 'Just',
            price: '$19.99',
            duration: '/month',
          }}
          ctaButton={ctaButton}
          image={ProLandingHeaderImage}
        />
      </BlueRadialGradientBackground>
      <WhiteWrapper flexCenter justifyContext="flex-start" noMargin>
        <BackgroundDecoratorLeft src={LeftDecorator} />
        <BackgroundDecoratorRight src={RightDecorator} />
        <S28 color={COLOR.grayLv1} margin="50px 0 15px 0" mMargin="0 0 20px 0">
          Compare Plans
        </S28>
        <BillingPeriodToggleButtonWrapper>
          <BillingPeriodToggleButton
            selectedPriceName={selectedPriceName}
            setSelectedPriceName={actions.setSelectedPriceName}
          />
        </BillingPeriodToggleButtonWrapper>
        {loaded && (
          <>
            <PlanCardsWrapper>
              <PricePlanCard
                key="pricePlanCard"
                featureList={planFeatures}
                selectedPriceName={priceName}
                isFeatureColumn
                isYearly={isYearly}
                isDesktop={isDesktop}
              />
              {Object.values(PLAN_NAMES).map(name => {
                const product = mappedProducts.find(element => element.name === name)
                // always recommend plan even the plan is subscribed
                const isRecommended = product.name === RECOMMENDED_PLAN
                const { title, subtitle } = PLAN_TITLE_MAP[product.name]
                const card = (
                  <PricePlanCard
                    priceId={product.stripePriceId}
                    key={product.name}
                    name={product.name}
                    title={title}
                    description={subtitle}
                    price={product.variants}
                    isActive={product.isActive}
                    isRecommended={isRecommended}
                    featureList={product.features}
                    onClickTrial={() =>
                      onClickChoosePlan(
                        PRO_PAGE_FEATURE_NAME.FEATURE_LIST,
                        CHOOSE_PLAN_ACTION.TRIAL,
                      )
                    }
                    onClickChoosePlan={() =>
                      onClickChoosePlan(
                        PRO_PAGE_FEATURE_NAME.FEATURE_LIST,
                        CHOOSE_PLAN_ACTION.UPGRADE,
                      )
                    }
                    onClickManagePlanButton={() =>
                      onClickManagePlanButton(PRO_PAGE_FEATURE_NAME.FEATURE_LIST)
                    }
                    hasTrialPlan={!currentPlan.trialStart && !currentPlan.trialEnd}
                    isYearly={isYearly}
                    selectedPriceName={priceName}
                  />
                )
                return card
              })}
              <FeatureHeaderMobileWrapper />
            </PlanCardsWrapper>
            {isDesktop &&
              PLAN_FEATURES.map(feature => (
                <FeatureRowWrapper>{configProducts[feature.name]}</FeatureRowWrapper>
              ))}
          </>
        )}
        <Wrapper
          width="100%"
          maxWidth="1300"
          margin="50px 0 0 0"
          padding="0 85px"
          mPadding="0"
          mMargin="0 20px 0 20px"
        >
          <S28 weight={600} margin="0 0 30px 0" center color={COLOR.black}>
            {FAQ_HEADER}
          </S28>
          <FaqAccordionV2 name="subscriptionFAQ" />
          <P>
            <SupportLink
              id="goToSupportProLink"
              inlineLink
              onClick={handleGoToSupport}
              text="RentSpree’s Help Center"
            />
          </P>
        </Wrapper>
      </WhiteWrapper>
    </SourceSansProContainer>
  )
}

export default compose(withConnect, withSaga, withReducer)(Landing)
