import { push } from 'connected-react-router'
import { takeLatest, all, put, select } from 'redux-saga/effects'

import { removeEmptyValue } from 'helpers/object'

import { updateQueryString } from '../helpers'
import { selectLocation } from '../selectors'

import { APPLY_QUICK_FILTER } from './constants'

export const parseQuickFilterValues = values => ({
  eventType: values?.eventType,
  dateType: values?.dateType,
  starred: values?.starred,
})

export function* applyFilterSaga({ payloads }) {
  let applyingFilter = parseQuickFilterValues(payloads)
  applyingFilter = removeEmptyValue(applyingFilter)
  const currentLocation = yield select(selectLocation)
  const newSearch = updateQueryString(currentLocation.search, applyingFilter)
  yield put(push({ pathname: currentLocation.pathname, search: newSearch }))
}

export function* watchApplyFilter() {
  yield takeLatest(APPLY_QUICK_FILTER, applyFilterSaga)
}

export function* rootSaga() {
  yield all([watchApplyFilter()])
}

export default rootSaga
