import React from "react"
import { Grid } from "react-bootstrap"
import { withRouter } from "react-router-dom"
import { buildPath, query } from "@rentspree/path"
import { GENERATE_REPORT_API, LRA_PDF_API } from "../../constants/route-consts"
import { LoadingPDF } from "../../components/download-pdf"
import tracker from "../../tracker"
import { EVENT_REPORT } from "../../tracker/const"
import mapWordingTrack from "../../helpers/map-wording-track"

@withRouter
export default class ReportDownload extends React.Component {
  componentDidMount() {
    const url = this.genUrlPDF()
    setTimeout(() => {
      window.location.replace(url)
    }, 500)
  }

  genUrlPDF() {
    const { location, match } = this.props
    const { reportType } = match.params
    const queryParams = location.search
    const params = query.parse(queryParams)
    const applicant = decodeURI(params.applicant)
    let url
    switch (reportType) {
      case "all-report":
      case "credit-report":
      case "application-report":
      case "eviction-report":
      case "criminal-report":
      case "reference-report":
        url = buildPath(GENERATE_REPORT_API, { reportType })
        url += queryParams
        tracker.trackEvent(EVENT_REPORT.saveReportPDF, {
          rental_id: params.rentalSubmissionId,
          applicant,
          click_from: params.click_from,
          report_name: mapWordingTrack(reportType),
          page: params.page,
        })
        break
      case "lra":
        // url = buildPath(LRA_PDF_API, { rentalId: "5bc72ac16173a30eea2f343c" }) // mock rentalID
        url = buildPath(LRA_PDF_API, { rentalId: params.rentalSubmissionId })
        url += queryParams
        break
      default:
        url = "/not-found"
        break
    }
    return url
  }

  render() {
    return (
      <Grid>
        <div>
          <LoadingPDF />
        </div>
      </Grid>
    )
  }
}
