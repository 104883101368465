import { Grid } from '@mui/material'
import { buildPath } from '@rentspree/path'
import React, { useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useListPropertiesQuery } from 'v3/containers/rent-payment/apis/property'
import {
  selectProperty,
  selectRenterInfo,
} from 'v3/containers/rent-payment/setup-page/set-up-for-myself/redux/actions'
import {
  useConfirmDraftRentalPayment,
  useGetOrCreateDraftRentalPayment,
  useUpdateDraftQuotations,
  useGetOrCreateRenterInfo,
} from 'v3/containers/rent-payment/shared/hooks'
import { useSearchRenterInfo } from 'v3/containers/rent-payment/shared/hooks/use-search-renter-info'
import { API_STATUS } from 'v3/containers/rent-payment/shared/redux/constants'
import { useMutateAgentInitiatePayment } from 'v3/hooks/use-mutate-agent-initiate-payment'
import { useUpdateLandlordProfileInPropertyUser } from 'v3/hooks/use-update-landlord-profile-in-property-user'

import { PaymentInfoContext, HistoryContext } from '../../context'
import { BackButton, NextButton } from '../buttons/footer-buttons'

/*
 *Redefining constants here because of circular dependency issue.
 *Need to refactor constants definition and importing for the whole payment overhaul module.
 */
export const buttonLayoutChoices = Object.freeze({
  // an enum to sync button layout options between CreateButtons & the footer's parent renderers
  NEXT: 'next',
  NEXT_BACK: 'next-back',
})
const TENANT_FIELD = 'tenant'
const PAYMENTS_FIELD = 'payments'
const PROPERTY_FIELD = 'property'
const RECIPIENT_INFO_FIELD = 'recipient-info'
const RENT_PAYMENT_PATH = '/rent-payments'
const pageNames = ['recipient-details', 'property-and-tenant', 'payment-details', 'review']
const pageOptions = Object.freeze({
  RECIPIENT_DETAILS: 'recipient-details',
  PROPERTY_AND_TENANT: 'property-and-tenant',
  PAYMENT_DETAILS: 'payment-details',
  REVIEW: 'review',
})
const recipientDetailsOptions = Object.freeze({
  ME: 'me',
  CLIENT: 'client',
})

/*
 * CamelCasing it because,
 * 1. It is a proper component returning jsx
 * 2. we need to use hooks from within this component, and without this pre-commit fails.
 *
 * NOTE: Need to discuss and refactor
 */
export const CreateButtons = (
  pageIndexes,
  progressionHandler,
  buttonLayout,
  textOverrides = {},
  nextEnabled = true,
  onBack = () => {},
  onNext = () => {},
) => {
  /* A dynamic handler for all of the Rental Payment page button configurations we might need. */
  // while this section defines the the layout style, the user might want different text
  const dispatch = useDispatch()
  const { back: backstepText = 'Back', next: forwardText = 'Next' } = textOverrides
  const [paymentInfo] = useContext(PaymentInfoContext)
  const historyObj = useContext(HistoryContext)
  const { confirmDraftRentalPayment, status: confirmDraftRentalPaymentStatus } =
    useConfirmDraftRentalPayment()
  const {
    data: quotations,
    updateDraftQuotations,
    status: updateDraftQuotationsStatus,
  } = useUpdateDraftQuotations()
  const { pageL1Index } = pageIndexes
  const { data: renterInfoSearchList, status: searchRenterInfoStatus } = useSearchRenterInfo()
  const listPropertiesQuery = useListPropertiesQuery()
  const { getOrCreateRenterInfo, status: getOrCreateRenterInfoStatus } = useGetOrCreateRenterInfo()
  const {
    getOrCreateDraftRentalPayment,
    data: draftRentalPayment,
    status: getOrCreateDraftRentalPaymentStatus,
  } = useGetOrCreateDraftRentalPayment()
  const initiateDraftPaymentMutate = useMutateAgentInitiatePayment({})

  const {
    [pageOptions.PROPERTY_AND_TENANT]: propertyAndTenantInfo = {},
    [pageOptions.RECIPIENT_DETAILS]: recipientDetailsInfo = {},
    [pageOptions.PAYMENT_DETAILS]: paymentDetailsInfo = {},
    [pageOptions.REVIEW]: reviewInfo = {},
  } = paymentInfo || {}
  const { [PROPERTY_FIELD]: propertyInfo = {}, [TENANT_FIELD]: tenantInfo = {} } =
    propertyAndTenantInfo
  const recipientInfo = recipientDetailsInfo?.[RECIPIENT_INFO_FIELD] || {}
  const quotes = paymentDetailsInfo?.[PAYMENTS_FIELD] || []
  const mutateLandlordProfile = useUpdateLandlordProfileInPropertyUser(
    propertyInfo._id,
    recipientInfo,
  )

  const savePropertyAndTenantData = async () => {
    const { id: tenantId } = tenantInfo
    const { _id: propertyId } = propertyInfo
    const renterInfoSearch = renterInfoSearchList.find(({ id }) => id === tenantId)
    const property = listPropertiesQuery.data?.data?.find(({ _id }) => _id === propertyId)

    if (renterInfoSearch.renterInfoId) {
      await getOrCreateDraftRentalPayment({
        propertyId,
        renterInfoId: renterInfoSearch.renterInfoId,
      })
      dispatch(selectRenterInfo(renterInfoSearch))
    } else {
      const renterInfo = await getOrCreateRenterInfo(renterInfoSearch)
      await getOrCreateDraftRentalPayment({
        propertyId: property._id,
        renterInfoId: renterInfo.id,
      })
      dispatch(selectRenterInfo(renterInfo))
    }

    dispatch(selectProperty(property))
  }

  const savePaymentDetailsData = async () => {
    await updateDraftQuotations({
      rentalPaymentId: draftRentalPayment.id,
      quotations: quotes,
    })
  }

  const saveReviewData = async () => {
    const { phone } = tenantInfo
    const { _id: propertyId } = propertyInfo
    const { inviteByText = false } = reviewInfo

    if (!propertyId) {
      console.error('property id not found')
    }

    if (quotations.length === 0) {
      console.error('payment details not found')
    }

    await confirmDraftRentalPayment({
      rentalPaymentId: draftRentalPayment?.id,
      propertyId,
      rentInfo: quotations,
      invitationDetail: {
        sms: inviteByText,
        ...(inviteByText && phone ? { phone } : {}),
      },
    })

    historyObj.push(RENT_PAYMENT_PATH)
  }

  const saveAgentInitiatedFlowData = async () => {
    const { _id: propertyId } = propertyInfo
    const { firstName: tenantFirstName, lastName: tenantLastName, email: tenantEmail } = tenantInfo
    const {
      firstName: recipientFirstName,
      lastName: recipientLastName,
      email: recipientEmail,
    } = recipientInfo
    const data = {
      propertyId,
      renterInfo: {
        firstName: tenantFirstName,
        lastName: tenantLastName,
        email: tenantEmail,
      },
      landlordInfo: {
        firstName: recipientFirstName,
        lastName: recipientLastName,
        email: recipientEmail,
      },
      quotations: quotes,
    }

    await mutateLandlordProfile()
    await initiateDraftPaymentMutate.mutateAsync(data)
    historyObj.push(
      buildPath(
        RENT_PAYMENT_PATH,
        {},
        {
          paymentSetupSuccess: true,
        },
      ),
    )
  }

  const savePageDataHandler = useMemo(
    () => ({
      [pageOptions.PROPERTY_AND_TENANT]: savePropertyAndTenantData,
      [pageOptions.PAYMENT_DETAILS]: savePaymentDetailsData,
      [pageOptions.REVIEW]: saveReviewData,
    }),
    [savePropertyAndTenantData, savePaymentDetailsData, saveReviewData],
  )

  const saveAndProgress = async action => {
    try {
      if (await progressionHandler(action)) {
        /*
         * save page level data if the user is going through the landlord flow.
         * save all data at once at the end of the flow if the user is going through the agent flow.
         */
        if (recipientDetailsInfo.recipient === recipientDetailsOptions.ME) {
          const savePageData = savePageDataHandler[pageNames[pageL1Index]]
          if (savePageData) {
            await savePageData()
          } else {
            console.error('Should not reach this state: ', pageNames[pageL1Index])
          }
        } else if (pageNames[pageL1Index] === pageOptions.REVIEW) {
          await saveAgentInitiatedFlowData()
        }
      }
    } catch (error) {
      console.error('Something went wrong', error)
    }
  }

  const backOnClick = () => {
    onBack()
    saveAndProgress({ decrement: true })
  }

  const nextOnClick = () => {
    onNext()
    saveAndProgress({ increment: true })
  }

  const isNextButtonDisabled = useMemo(() => {
    return (
      !nextEnabled ||
      confirmDraftRentalPaymentStatus === API_STATUS.UPDATING ||
      updateDraftQuotationsStatus === API_STATUS.UPDATING ||
      searchRenterInfoStatus === API_STATUS.FETCHING ||
      getOrCreateRenterInfoStatus === API_STATUS.FETCHING ||
      getOrCreateDraftRentalPaymentStatus === API_STATUS.FETCHING
    )
  }, [
    nextEnabled,
    confirmDraftRentalPaymentStatus,
    updateDraftQuotationsStatus,
    searchRenterInfoStatus,
    getOrCreateRenterInfoStatus,
    getOrCreateDraftRentalPaymentStatus,
  ])

  // default error state
  let renderedButtons = (
    <Grid item xs={4}>
      <p>Button configuration error?</p>
    </Grid>
  )

  // quick & bulky definition for now, we can refactor later
  if (buttonLayout === buttonLayoutChoices.NEXT) {
    renderedButtons = (
      <>
        {/* This grid offset only helps for the desktop view */}
        <Grid item xs={9} />
        <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <NextButton disabled={!nextEnabled} onClick={nextOnClick} variant="contained">
            {forwardText}
          </NextButton>
        </Grid>
        <Grid item xs={1} />
      </>
    )
  } else if (buttonLayout === buttonLayoutChoices.NEXT_BACK) {
    renderedButtons = (
      <>
        {/* right: 0 isn't working for BackButton, so an offset Grid
         * is pushing the button away from the edge
         */}

        <Grid item xs={1} />
        <Grid item xs={8}>
          <BackButton onClick={backOnClick} variant="text">
            {backstepText}
          </BackButton>
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row-reverse' }} xs={3}>
          <NextButton
            disabled={isNextButtonDisabled}
            onClick={() => nextOnClick()}
            variant="contained"
          >
            {forwardText}
          </NextButton>
        </Grid>
        <Grid item xs={1} />
      </>
    )
  } else {
    console.error(`No button configuration for this state! Layout choice '${buttonLayout}'?`)
  }

  return renderedButtons
}
