import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'
import { getCleanUserMetadata } from 'v3/services/announcement'

/**
 * useGetCleanUserMeta get if user is invited and if has property or screening from cookies first
 * then collects to user's cookies.
 * @param {import('@tanstack/react-query').UseQueryOptions<Awaited<ReturnType<typeof getCleanUserMetadata>>>} options
 */
export const useGetCleanUserMeta = (options = {}) => {
  const userProfile = useSelector(selectProfile)
  return useQuery({
    queryKey: ['getCleanUserMeta', userProfile?.id],
    // 30 mins
    staleTime: 1800000,
    queryFn: () => getCleanUserMetadata(),
    refetchOnWindowFocus: false,
    ...options,
  })
}
