import React from "react"
import CollapsedToggle from "images/icons/rent-payment/collapsed-toggle.svg"
import CollapseToggle from "images/icons/rent-payment/collapse-toggle.svg"
import tracker from "tracker"
import { Text } from "../typography"
import * as Styled from "./accordion-style"
import { RentPaymentFaq } from "./rent-payment-faq"
import { SubscriptionFaq } from "./subscription-faq"

export default class FaqCollapseList extends React.Component {
  headerWithIcon = ({ text, collapsed }) => (
    <Styled.PanelHeader>
      <Text className={collapsed ? "active" : ""}>{text}</Text>
      <img
        src={collapsed ? CollapsedToggle : CollapseToggle}
        alt={`collapse${collapsed && "d"} toggle`}
      />
    </Styled.PanelHeader>
  )

  clickPanel = (name, properties) => {
    tracker.trackEvent(name, properties)
  }

  selectFaq = name => {
    switch (name) {
      case "subscriptionFAQ":
        return (
          <SubscriptionFaq
            clickPanel={this.clickPanel}
            headerWithIcon={this.headerWithIcon}
          />
        )
      case "rentPaymentFaq":
      default:
        return (
          <RentPaymentFaq
            clickPanel={this.clickPanel}
            headerWithIcon={this.headerWithIcon}
          />
        )
    }
  }

  render() {
    const { name, maxWidth, bottomContent } = this.props
    return (
      <Styled.AccordionWrapper maxWidth={maxWidth}>
        {this.selectFaq(name)}
        {bottomContent}
      </Styled.AccordionWrapper>
    )
  }
}
