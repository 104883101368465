import { T24 } from "@rentspree/component-v2"
import { Panel } from "react-bootstrap"
import styled from "styled-components"
import { COLOR } from "styles/settings"
import { SourceSansProS16 } from "components/typography"

export const HighLightedTag = styled.div`
  margin: -1px -1px 0px -1px;
  color: ${COLOR.blue90};
  display: flex;
  justify-content: center;
  height: 25px;
  @media (max-width: 991px) {
    height: 30px;
  }
  background-color: ${COLOR.tagOrangeLv3}
`

export const BorderWrapper = styled.div`
  flex: 1;
  ${props => !props.isFeatureColumn && `flex-basis: 0;`}
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.isRecommended
      ? `linear-gradient(to right, ${COLOR.newDeepBlueGradient}, ${
          COLOR.newDeepBlue
        })`
      : `white`};
  :not(:first-of-type) {
    @media (max-width: 991px) {
      margin-left: 0px;
      margin-bottom: ${props => (props.isRecommended ? "10px" : "50px")};
    }
  }
  min-width: 253px;
  @media (max-width: 991px) {
    ${props => props.isFeatureColumn && `display: none`};
    width: 100%;
    max-width: none;
    margin-bottom: 30px;
    ${props => props.isRecommended ? `
      border-width: 0px 1px 0px 1px;
    ` : `
      border-width: 1px 1px 0px 1px;
    `}
    border-color: ${COLOR.placeholderGrey};
    border-style: solid;
  }
  @media (min-width: 991px) {
    ${props => props.isFeatureColumn ? `
      border: 1px solid ${COLOR.placeholderGrey};
    ` : `
      border-width: 1px 1px 1px 0px;
      border-color: ${COLOR.placeholderGrey};
      border-style: solid;
    `}
  }
`

export const PlanHeaderWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 173px;
  padding: ${props =>
    props.hasButton || props.showCurrentPlanTag
      ? "20px 36px 0"
      : "38px 36px 0"};
  transition: height 0.5s;
  @media (max-width: 991px) {
    height: auto;
    padding: ${props =>
      props.hasButton ? "15px 48px 20px" : "25px 48px 20px"};
  }
`

export const SourceSansProT24 = styled(T24)`
  font-family: 'Source Sans Pro'!important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${COLOR.textBlack}
`

export const WhiteBackground = styled.div`
  display: flex;
  flex: 1;
  background: white;
  box-sizing: border-box;
  flex-direction: column;
  ${props =>
    !props.isRecommended && "padding-top: 23px"};
  justify-content: space-evenly;
  @media (max-width: 991px) {
    padding: 0;
    ${FeatureLineMobileWrapper}:last-child {
      border-bottom: 0px;
    }
  }
`

export const FeatureCellInnerWrapper = styled(Panel)`
  flex-grow: 1;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  justify-content: ${props =>
    props.isFeatureColumn ? `space-between` : `start`};
  border: 1px solid white;
  margin: 0;
  padding: ${props => (props.isFeatureColumn ? `20px 24px` : `20px`)};
  align-items: ${props => (props.isFeatureColumn ? `center` : `flex-start`)};
  width: 100%;
  height: 100%;
  min-width: 62px;
  @media (max-width: 991px) {
    display: none;
  }
  flex-direction: row;
  box-shadow: 0px;
  ${props => props.isFeatureColumn ? `
    ${SourceSansProS16} {
      text-align: start;
    }` : `
    ${AccordionHeadingWrapper} {
      align-items: center;
    }`
  }
`

export const FeatureLineMobileWrapper = styled(Panel)`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    border-width: 0px 0px 1px 0px;
    border-color: ${COLOR.placeholderGrey};
    border-style: solid;
    border-radius: 0px;
    margin: 0px;
    align-items: baseline;
    width: 100%;
    min-height: 62px;
    justify-content: space-between;
    padding: 20px 20px 20px 22px;
  }
`

export const FeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.isFeatureColumn ? `80%` : `20%`)};
  justify-content: ${props =>
    props.isFeatureColumn ? `flex-start` : `center`};
`

export const AccordionHeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  ${SourceSansProS16} {
    flex: 1;
  }
  @media (min-width: 991px) {
    img {
      margin: 3px 0 0 0;
    }
    h4 {
      padding: 0 20px 0 0;
    }
  }
`

export const AccordionHeadingGroup = styled.div`
  display: flex;
  flex-direction: row;
  img {
    margin-top: 2px;
    margin-right: 13px;
  }
  @media (max-width: 991px) {
    h4 {
      padding-right: 20px;
    }
  }
`

export const AccordionToggleButtonWrapper = styled(Panel.Toggle)`
  display: flex;
  justify-content: center;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 991px) {
    padding-top: 6px;
  }
  color: ${COLOR.textBlack}
  ${props => props.expanded && `
    svg {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  `}
`

export const AccordionBodyWrapper = styled(Panel.Body)`
  padding: 15px 0px;
  text-align: start;
  ${SourceSansProS16} {
    font-weight: 400;
  }
  @media (max-width: 991px) {
    h4 {
      padding-right: 20px;
    }
  }
`