import React, { useEffect } from "react"
import { SelectDropdown } from "@rentspree/component-v2"
import { ButtonSet } from "components/buttons/button-set"
import styled from "styled-components"
import { Div } from "components/layout/main"
import { COLOR } from "../../../styles/settings"

export const StyledP = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin: 0px 0 32px;
  display: flex;
  justify-content: space-between;
`
const CREATE_PROPERTY_TEXT = "create a new property"
const ADD_PROERTY_LATER = "add property later"

export const TextButton = styled.button`
  color: ${COLOR.activeBlue};
  background: transparent;
  border: none;
  &:hover,
  &:focus {
    color: ${COLOR.blueHover};
  }
`

export const SelectPropertyForm = props => {
  const {
    touched,
    errors,
    selectedPropertyId,
    propertyList,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isLoading,
    onClickBackToDashboard,
    onClickCreateNewProperty,
    onChangeProperty,
    addPropertyLater = false,
    onClickAddPropertyLater = () => {},
  } = props

  const addressList = [{ value: "", label: " " }, ...propertyList]
  useEffect(() => {
    setFieldValue("selectedPropertyId", selectedPropertyId || undefined)
  }, [selectedPropertyId])

  return (
    <>
      <Div margin="0 10px 20px 10px" mMargin="0 0 20px 0">
        <SelectDropdown
          isRequired={!addPropertyLater}
          id="dropDownSelectProperty"
          options={addressList}
          label="Property Address"
          name="selectedPropertyId"
          onChange={value => {
            onChangeProperty(value)
          }}
          onBlur={() => setFieldTouched("selectedPropertyId", true)}
          value={selectedPropertyId}
          error={
            !addPropertyLater &&
            touched.selectedPropertyId &&
            errors.selectedPropertyId
          }
        />

        <StyledP>
          <div>
            {"Or "}
            <TextButton
              id="createNewPropertyBtn"
              onClick={onClickCreateNewProperty}>
              {CREATE_PROPERTY_TEXT}
            </TextButton>
          </div>
          {addPropertyLater && (
            <TextButton id="addPropertyLater" onClick={onClickAddPropertyLater}>
              {ADD_PROERTY_LATER}
            </TextButton>
          )}
        </StyledP>
        <ButtonSet
          id="selectPropertyButtonSet"
          minWidth="100"
          margin="0 0"
          continueBtnType="submit"
          continueText="Next"
          btnHeight="40px"
          hidePrevButton
          onClickBackToDashboardBtn={onClickBackToDashboard}
          onSaveAndNext={handleSubmit}
          isLoading={isLoading}
          disabledNextButton={!selectedPropertyId}
        />
      </Div>
    </>
  )
}

export default SelectPropertyForm
