import { Icon } from '@rentspree/component-v2'
import React, { useState } from "react"
import { SourceSansProS16 } from "components/typography"
import {
  FeatureLineMobileWrapper,
  AccordionToggleButtonWrapper,
  AccordionHeadingWrapper,
  FeatureCellInnerWrapper,
  AccordionBodyWrapper,
  AccordionHeadingGroup,
} from "./styled-index"

const FeatureAccordion = ({ text, textColor, expandableText, icon, height, onToggle, isFeatureColumn }) => {
  const [expanded, setExpanded] = useState(false)
  const combinedOnToggle = (isExpanded) => {
    setExpanded(isExpanded)
    onToggle(isExpanded)
  }
  return isFeatureColumn 
  ? (
      <FeatureCellInnerWrapper onToggle={combinedOnToggle} isFeatureColumn={isFeatureColumn}>
        <AccordionHeadingWrapper expanded={expanded}>
          <SourceSansProS16 margin="0" lineHeight="22px" color={textColor}>
            {text}
          </SourceSansProS16>
          <AccordionBodyWrapper collapsible>
            <SourceSansProS16 margin="0" lineHeight="22px" color={textColor}>
              {expandableText}
            </SourceSansProS16>
          </AccordionBodyWrapper>
        </AccordionHeadingWrapper>
        <AccordionToggleButtonWrapper expanded={expanded}>
          <Icon name="chevron-down"/>
        </AccordionToggleButtonWrapper>
      </FeatureCellInnerWrapper>
  ) : (
    <FeatureCellInnerWrapper isFeatureColumn={isFeatureColumn} height={height}>
      <AccordionHeadingWrapper>
        {icon}
      </AccordionHeadingWrapper>
    </FeatureCellInnerWrapper>
  )
}

const FeatureMobileAccordion = ({
  featureName,
  featureNameColor,
  expandableText,
  textColor,
  icon,
  onToggle
}) => {
  const [expanded, setExpanded] = useState(false)
  const combinedOnToggle = (isExpanded) => {
    setExpanded(isExpanded)
    onToggle(isExpanded)
  }
  return (
    <FeatureLineMobileWrapper onToggle={combinedOnToggle}>
      <AccordionHeadingWrapper expanded={expanded}>
        <AccordionHeadingGroup>
          {icon}
          <SourceSansProS16 margin="0" lineHeight="22px" color={featureNameColor}>
            {featureName}
          </SourceSansProS16>
        </AccordionHeadingGroup>
        <AccordionBodyWrapper collapsible>
          <SourceSansProS16 margin="0" lineHeight="22px" color={textColor}>
            {expandableText}
          </SourceSansProS16>
        </AccordionBodyWrapper>
      </AccordionHeadingWrapper>
      <AccordionToggleButtonWrapper expanded={expanded} >
        <Icon name="chevron-down"/>
      </AccordionToggleButtonWrapper>
    </FeatureLineMobileWrapper>
  )
}

export { FeatureAccordion, FeatureMobileAccordion }