import { Checkbox, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import React from 'react'

import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { PAYMENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

const SelectionCard = styled(Card)(() => ({
  width: '90%',
  maxWidth: '500px',
  margin: '15px auto',
  padding: '10px 0!important',
  display: 'flex',
  flexDirection: 'row!important',
  justifyContent: 'space-between',
  border: 'ipx solid #2222221F',
}))

export const LandingPageComponent = ({
  selectionOptions,
  paymentSelections,
  setPaymentSelections,
}) => {
  return (
    <>
      <TitleText>{PAYMENT_DETAILS.LANDING.TITLE}</TitleText>
      {Object.keys(selectionOptions).map(key => {
        const val = selectionOptions[key]
        const selected = paymentSelections?.[key] || false
        return (
          <SelectionCard
            flex
            key={key}
            sx={{ margin: '15px 0px' }}
            variant="outlined"
            size="normal"
            onClick={() => {
              setPaymentSelections({
                ...paymentSelections,
                [key]: !selected,
              })
            }}
          >
            <CardElement sx={{ alignContent: 'center', justifyContent: 'left', flex: 'grow' }}>
              <Typography variant="h5" sx={{ fontSize: '2rem', textAlign: 'left' }}>
                {val}
              </Typography>
            </CardElement>
            <CardElement sx={{ width: 'fit-content' }}>
              <Checkbox
                size="large"
                checked={selected}
                sx={{
                  '&.Mui-checked svg': {
                    fill: 'black',
                  },
                }}
              />
            </CardElement>
          </SelectionCard>
        )
      })}
    </>
  )
}
