import { SourceSansProB16, SourceSansProB18, SourceSansProT36 } from "components/typography"
import { B16_STYLES, B18_STYLES, DesktopWrapper, HeaderContainer, MobileWrapper, TextContainer } from "legacy/containers/pro/landing/pro-landing-header-styled"
import React from "react"
import { COLOR } from "styles/settings"
import { ProContentImage, StyledT24WithGradientColored } from "./pro-landing-header-styled"

const ProLandingHeader = ({
  title,
  descriptionLine1,
  descriptionLine2,
  descriptionLine3,
  ctaButton,
  image,
}) => (
  <>
    <HeaderContainer>
      <TextContainer>
        <DesktopWrapper>
          <SourceSansProT36 margin="0 0 20px 0" color={COLOR.grayLv1} lineHeight="45.25px">
            {title}
          </SourceSansProT36>
          <SourceSansProB18 margin="0" {...B18_STYLES}>
            {descriptionLine1}
          </SourceSansProB18>
          <SourceSansProB18 margin="0" {...B18_STYLES}>
            {descriptionLine2}
          </SourceSansProB18>
          <SourceSansProB18 margin="0 0 30px 0" {...B18_STYLES}>
            {descriptionLine3}
          </SourceSansProB18> 
        </DesktopWrapper>
        <MobileWrapper>
          <StyledT24WithGradientColored
            weight="700"
            margin="27px auto 10px auto"
            lineHeight="32px"
            align="center">
            {title}
          </StyledT24WithGradientColored>
          <SourceSansProB16 margin="0" {...B16_STYLES}>
            {descriptionLine1}
          </SourceSansProB16>
          <SourceSansProB16 margin="0" {...B16_STYLES}>
            {descriptionLine2}
          </SourceSansProB16>
          <SourceSansProB16 margin="0 0 30px 0" {...B16_STYLES}>
            {descriptionLine3}
          </SourceSansProB16>
        </MobileWrapper>
        {ctaButton}
      </TextContainer>
      <ProContentImage src={image} alt="placeholder" />
    </HeaderContainer>
  </>
)

export { ProLandingHeader }