import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const StyledGiftBoxIcon = styled.i`
  justify-content: center !important;
  margin-right: 5px;
  align-items: center !important;
  &::before {
    background-color: ${COLOR.purpleLv2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${props => props.isShadow && 'text-shadow: 0px 4px 8px rgba(59, 153, 252, 0.3)'};
  }
`

const GiftBoxIcon = ({ isShadow }) => (
  <StyledGiftBoxIcon isShadow={isShadow} className="fa fa-gift" />
)

GiftBoxIcon.defaultProps = {
  isShadow: true,
}

export default GiftBoxIcon
