import FileSaver from "file-saver"
import { buildPath } from "@rentspree/path"
import * as Types from "../constants/action-types"
import createAction from "../apis/utils/create-action"
import * as api from "../apis/rental-submission.api"
import { ALERT_PRESET } from "../libs/sweet-alert/sweet-alert"
import * as ERROR from "../constants/error-messages"
import { isIOS } from "../components/helper/ua-parser-js"
import { FULL_DOC_ZIP_API } from "../constants/route-consts"

export const pdfDocs = createAction(
  api.pdfAPI,
  [
    Types.GET_DOC_PDF_REQUEST,
    Types.GET_DOC_PDF_SUCCESS,
    Types.GET_DOC_PDF_FAILED,
  ],
  (res = {}) => res,
)

export const onShowPdfSuccess = () => dispatch =>
  dispatch({
    type: Types.SHOW_DOC_PDF_SUCCESS,
  })

export const getDownloadFullDocZip = createAction(
  api.downloadFullDocumentZip,
  [
    Types.DOWNLOAD_FULL_DOC_ZIP_REQUEST,
    Types.DOWNLOAD_FULL_DOC_ZIP_SUCCESS,
    Types.DOWNLOAD_FULL_DOC_ZIP_FAILED,
  ],
  response => ({
    otherDocDownloadData: response.blob,
    fileName: response.fileName,
  }),
)

export const downloadFullDocument = (rentalId, profile) => (
  dispatch,
  getState,
) => {
  if (isIOS()) {
    const queryString = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      accessToken: getState().persistState.auth.accessToken,
    }
    const url = buildPath(FULL_DOC_ZIP_API, { rentalId }, queryString)
    window.location.href = url
    return Promise.resolve(url)
  }
  return new Promise(resolve =>
    getDownloadFullDocZip(rentalId, profile)(dispatch, getState).then(data => {
      if (data && data.otherDocDownloadData) {
        FileSaver.saveAs(data.otherDocDownloadData, data.fileName)
      } else {
        dispatch({
          type: Types.OPEN_SWEET_ALERT,
          preset: ALERT_PRESET.ERROR,
          option: {
            title: ERROR.OTHER_DOCUMENTS.DOWNLOAD_DOCUMENT.TITLE,
            text: ERROR.OTHER_DOCUMENTS.DOWNLOAD_DOCUMENT.MESSAGE,
            button: "OK",
            className: "rentspree-sweetalert",
          },
        })
      }
      resolve()
    }),
  )
}
