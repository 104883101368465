/* eslint-disable react/jsx-filename-extension */
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Button from '@rentspree/component-2023.components.atoms.button'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import Level1Template from '@rentspree/component-2023.components.templates.level-1-template'
import { T36, B16, S16 } from '@rentspree/component-v2'
import { useFeatureGate } from '@statsig/react-bindings'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import BenefitCard from 'components/contacts/atoms/benefit-card'
import { CONTACT_IMPORT_CSV, TENANT_SCREENING } from 'constants/route'
import Badge from 'containers/badge/badge-component'
import { BADGE_COMPONENT_TYPES } from 'containers/badge/constants'
import useMobile from 'hooks/use-mobile'
import { breakpoints, COLOR } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'
import { MOBILE_THRESHOLD } from 'utils/constants'
import { ZERO_STATE_CONTACTS } from 'utils/feature-flag/constants'

import { MAIN_CONTENT, BENEFIT_CARDS } from './constants'

const LandingContainer = styled.div`
  text-align: center;
  width: 100%;
  background-color: ${COLOR.white};
  padding-top: 61px;
  min-height: 100%;
  @media (max-width: 991px) {
    padding-bottom: 82px;
  }
`

const Title = styled(T36)`
  margin: 20px 0;
  @media (max-width: 991px) {
    margin: 10px 0;
  }
`

const BenefitCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: center;
  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
  }
`
const BottomTextContainer = styled(S16)`
  margin-top: 50px;
  @media (max-width: 991px) {
    display: none;
  }
`
const SubTitle = styled(B16)`
  display: inline-block;
  max-width: 600px;
  padding: 0 30px;
`
export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BetaBadge = styled(Badge)`
  font-size: 20px;
  margin: 0 0 0 10px;
  padding: 3px 5px;
  border-radius: 7px;
  ${breakpoints.mobile(`
    font-size: 14px;
    padding: 3px;
    border-radius: 5px;
  `)};
`

const trackClickToTenantScreening = () => {
  tracker.trackEvent(EVENT_PEOPLE_CONCEPT.EVENT_NAME.START_SCREENING, {
    click_from: EVENT_PEOPLE_CONCEPT.EVENT_PROPERTY.CLICK_FROM.CONTACTS_LANDING,
  })
}

export const OldLanding = ({ actions = {} }) => (
  <LandingContainer noMargin flexCenter>
    <TitleContainer>
      <Title>{MAIN_CONTENT.title}</Title>
      <BetaBadge
        activeId="rcmMenuEmptyLandingPage"
        badgeComponentType={BADGE_COMPONENT_TYPES.BETA_BADGE}
      />
    </TitleContainer>
    <SubTitle>{MAIN_CONTENT.description}</SubTitle>
    <BenefitCardsContainer>
      {BENEFIT_CARDS.map(item => (
        <BenefitCard {...item} buttonAction={actions[item.action]} />
      ))}
    </BenefitCardsContainer>
    <BottomTextContainer>
      Contact details collected in the rental application will show up here.{' '}
      <Link to={TENANT_SCREENING} onClick={trackClickToTenantScreening}>
        Screen tenant
      </Link>
    </BottomTextContainer>
  </LandingContainer>
)

const ZERO_STATE_CONTACTS_IMAGE_URL =
  'https://cdn.rentspree.com/static-files/zero-state/contact-page-empty-state.svg'

export const NewLanding = ({ actions = {} }) => {
  const isMobile = useMobile(MOBILE_THRESHOLD)

  const handleImportContactListClick = () => {
    const url = CONTACT_IMPORT_CSV
    tracker.trackButtonClick({
      click_text: 'Import contact list',
      click_url: url,
      location: 'empty_state',
    })
    if (actions.importCsv) actions.importCsv()
  }

  const handleAddContactClick = () => {
    tracker.trackButtonClick({
      click_text: 'Add contact',
      location: 'empty_state',
    })
    if (actions.openCreateContactModal) actions.openCreateContactModal()
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: `${COLOR.whiteContainer}`,
        }}
      >
        <Level1Template title="Contacts" isMobile={isMobile} />
      </Box>
      <Box
        sx={{
          backgroundColor: `${COLOR.whiteContainer}`,
          height: '100%',
          display: 'flex',
          flexGrow: 1,
          alignItems: 'start',
        }}
      >
        <EmptyState
          title="Stay connected"
          subTitle="Build better relationships with your clients by keeping track of key milestones."
          image={<img src={ZERO_STATE_CONTACTS_IMAGE_URL} alt="empty-state-contacts" />}
          cta={
            <Button
              data-testid="ContactsZeroStateLanding-primary-button"
              onClick={handleImportContactListClick}
              variant="contained"
              color="secondary"
              size="small"
            >
              Import contact list
            </Button>
          }
          secondaryCTA={
            <Button
              data-testid="ContactsZeroStateLanding-secondary-button"
              onClick={handleAddContactClick}
              variant="text"
              color="secondary"
              size="small"
              className="underline"
              startIcon={<AddIcon />}
            >
              Add contact
            </Button>
          }
        />
      </Box>
    </>
  )
}

export const ContactsZeroStateLanding = ({ actions = {} }) => {
  const { value: isNewZeroStateContactsEnabled } = useFeatureGate(ZERO_STATE_CONTACTS)

  if (isNewZeroStateContactsEnabled) {
    return <NewLanding actions={actions} />
  }

  return <OldLanding actions={actions} />
}

export default ContactsZeroStateLanding
