import { Button } from '@rentspree/component-v2/dist'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import React from 'react'
import styled from 'styled-components'

import GiftSent from 'images/contacts/gift-sent.svg'

const ContentContainer = styled.div`
  text-align: center;

  > div > div {
    justify-content: center;
    margin-top: 24px;
  }
`

const GiftImage = styled.img`
  margin-top: 18px;
`

const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin-top: 25px;
`

const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 14px;
`

const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  > button {
    margin: 0 10px;
  }
`
const SubmitButton = styled(BlueGradientButton)`
  font-size: 16px;
`
const CancelButton = styled(Button)`
  font-size: 16px;
`

const MarkAsGiftSentModal = ({ onMarkAsGiftSent, ...props }) => {
  const modalProps = {
    ...props,
    onClose: props.onCloseModal || props.onClose,
  }
  return (
    <RentSpreeBaseModal
      {...modalProps}
      padding="30px"
      closeButton={false}
      shouldCloseOnOverlayClick
    >
      <ContentContainer>
        <GiftImage src={GiftSent} alt="gift-sent" />
        <Title>Opening Amazon in new tab</Title>
        <Text>
          You can continue choosing a gift, after that mark out this task as sent and track your
          gift activity on RentSpree
        </Text>
        <div />
        <ButtonGroupWrapper>
          <CancelButton google small id="markAsGiftSentCancel" onClick={modalProps.onClose}>
            Cancel
          </CancelButton>
          <SubmitButton
            small
            semiBold
            id="markAsGiftSent"
            onClick={() => {
              onMarkAsGiftSent()
              modalProps.onClose()
            }}
          >
            Mark As Sent
          </SubmitButton>
        </ButtonGroupWrapper>
      </ContentContainer>
    </RentSpreeBaseModal>
  )
}

export default MarkAsGiftSentModal
