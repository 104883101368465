import React from "react"
import { SourceSansProS16 } from "components/typography"
import { PLAN_NAMES, PLAN_TITLE_MAP, PRO_PLAN_VARIANTS } from "containers/pro/landing/constants"
import { CURRENT_PLAN } from "legacy/components/pro/landing/price-plan-card/constants"
import { PlanHeaderWrapper } from "legacy/components/pro/landing/price-plan-card/styled-index"
import { Price } from "legacy/components/pro/landing/price-plan-card/utils"
import { COLOR } from "styles/settings"
import { SourceSansProT24 } from "./styled-index"

export const PlanHeader = ({
  title,
  price,
  actionButton,
  hasButton,
  isActive,
  isYearly,
  selectedPriceName,
}) => {
  const productPrice =
    price.find(variant => variant.name === selectedPriceName)?.price ||
    price[0].price
  const monthlyPrice =
    price.find(variant => variant.name === PRO_PLAN_VARIANTS.MONTHLY)?.price ||
    0
  const showCurrentPlanTag =
    isActive && title === PLAN_TITLE_MAP[PLAN_NAMES.FREE].title
  return (
    <PlanHeaderWrapper
      showCurrentPlanTag={showCurrentPlanTag}
      hasButton={hasButton}>
      <SourceSansProT24 margin="0 0 5px 0" lineHeight="32px">
        {title}
      </SourceSansProT24>
      <Price
        price={productPrice}
        monthlyPrice={monthlyPrice}
        hasButton={hasButton}
        isYearly={isYearly}
      />
      {actionButton}
      {/* only show current plan tag on FREE plan column */}
      {showCurrentPlanTag && (
        <SourceSansProS16
          margin="0"
          lineHeight="22px"
          color={COLOR.steelBlueDark}>
          {CURRENT_PLAN}
        </SourceSansProS16>
      )}
    </PlanHeaderWrapper>
  )
}
