import React from "react"
import { COLOR } from "styles/settings"
import { PLAN_NAMES } from "containers/pro/landing/constants"
import { SourceSansProB14 } from "components/typography"
import { SourceSansProActionButton } from "legacy/containers/pro/landing/landing-styled"
import { FeatureHeaderWrapper } from "legacy/components/pro/landing/price-plan-card/styled-index"
import { BorderWrapper, HighLightedTag, WhiteBackground } from "./styled-index"
import { PlanHeader } from "./card-header"

const PricePlanCard = ({
  name,
  title,
  price,
  featureList,
  isActive,
  isRecommended,
  onClickTrial,
  onClickChoosePlan,
  onClickManagePlanButton,
  hasTrialPlan,
  isFeatureColumn,
  selectedPriceName,
  isYearly,
  isDesktop,
}) => {
  const actionButton = (
    <SourceSansProActionButton
      name={name}
      hasTrialPlan={hasTrialPlan}
      onClickTrial={onClickTrial}
      onClickChoosePlanButton={onClickChoosePlan}
      onClickManagePlanButton={onClickManagePlanButton}
      isActive={isActive}
    />
  )
  const hasButton = name !== PLAN_NAMES.FREE
  return (
    <BorderWrapper
      isRecommended={isRecommended}
      isFeatureColumn={isFeatureColumn}>
      {isRecommended && (
        <HighLightedTag>
          <SourceSansProB14 color={COLOR.blue90} weight={600} margin="auto">
            Recommended
          </SourceSansProB14>
        </HighLightedTag>
      )}
      <WhiteBackground
        isActive={isActive}
        isRecommended={isRecommended}
        isFeatureColumn={isFeatureColumn}>
        {isFeatureColumn ? (
          <FeatureHeaderWrapper/>
        ) : (
          <PlanHeader
            title={title}
            price={price}
            actionButton={actionButton}
            hasButton={hasButton}
            isActive={isActive}
            isYearly={isYearly}
            selectedPriceName={selectedPriceName}
          />
        )}
        {!isDesktop && featureList}
      </WhiteBackground>
    </BorderWrapper>
  )
}
export { PricePlanCard }