import { CONTACT_GROUP } from 'constants/route'

export const CONTACT_GROUP_MENU_LIST = [
  {
    key: 'all',
    label: 'All Contacts',
    path: '',
    isSubMenu: false,
  },
  {
    key: CONTACT_GROUP.STARRED,
    label: 'Starred',
    path: CONTACT_GROUP.STARRED,
    isSubMenu: true,
  },
  {
    key: CONTACT_GROUP.RENTSPREE_APPLICATION,
    label: 'From RentSpree',
    path: CONTACT_GROUP.RENTSPREE_APPLICATION,
    isSubMenu: true,
  },
  {
    key: CONTACT_GROUP.RECENT_ACTIVITY,
    label: 'Recent Activity',
    path: CONTACT_GROUP.RECENT_ACTIVITY,
    isSubMenu: true,
  },
  {
    key: CONTACT_GROUP.UPCOMING_EVENT,
    label: 'Upcoming Event',
    path: CONTACT_GROUP.UPCOMING_EVENT,
    isSubMenu: false,
  },
]
