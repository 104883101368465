import { buildPath } from "@rentspree/path"
import checkStatus from "./handlers/check-status"
import fetch from "../helpers/fetch-utils"
import {
  PROPERTY_API_V2,
  PROPERTY_DROPDOWN_API,
} from "../constants/route-consts"

export const fetchPropertyList = (options = {}) => bearer => {
  const uri = buildPath(PROPERTY_API_V2, null, options)
  return fetch(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}

export const fetchPropertyDropdown = () => bearer =>
  fetch(PROPERTY_DROPDOWN_API, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
