import React from 'react'
import { buildPath } from '@rentspree/path'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { CenterContent, Loading, WhiteWrapper } from 'components/layout/main'
import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import { PRO_PLAN_VARIANTS } from 'legacy/containers/pro/landing/constants'
import { getSubscriptionProductVariant } from 'legacy/containers/pro/landing/utils'
import * as ERROR from 'legacy/constants/error-messages'
import { referenceType } from 'legacy/constants/ref-check-consts'
import { TrackerInstance as Tracker } from 'legacy/helpers/tracker'
import { AbilityContext } from 'containers/reports/with-pro-report-available/ability-context'
import {
  PREMIUM_CAN_ACTION,
  PREMIUM_CAN_SUBJECT,
  PRO_CAN_ACTION,
  PRO_CAN_SUBJECT,
  TRIAL_CAN_ACTION,
  TRIAL_CAN_SUBJECT,
} from 'containers/reports/with-pro-report-available/constants'
import { FeatureBlockModal, FEATURE_BLOCK_NAME } from '@rentspree/component-v2'
import { REFERENCE_CHECKS_ABSOLUTE_PATH, TENANT_SCREENING_WITH_RENTAL_ID } from 'constants/route'
import { RefChecksTemplate } from 'components/templates/tenant-screening/reports/ref-checks'
import styled from 'styled-components'
import { EVENT_TENANT_SCREENING } from 'tracker/const'
import RefChecksReportContainerWrapper from './report'
import { SHARED_REPORT_TYPES, PARTICIPANT_TYPE } from '../constants'
import { withConnect } from './connect'
import withProReportAvailable from '../with-pro-report-available'
import { BtnOverlay } from '../btn-overlay'
import { WrapperHideMobile } from '../../../components/reports/wrapper'
import AmplitudeTracker from '../../../legacy/tracker'
import {
  EVENT_FEATURE_BLOCK_MODAL,
  EVENT_RENTSPREE_PRO,
  FEATURE_BLOCK_ACTION,
  REF_CHECK,
  RENTSPREE_PRO_FEATURE_NAME,
  SUBSCRIPTION_PERIOD,
} from '../../../legacy/tracker/const'

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TemplateWrapper = styled.div`
  padding-bottom: 20px;
`

export class RefChecksContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFeatureBlockModal: false,
      hasStartTrial: false,
      showErrorText: false,
      innerWidth: window.innerWidth,
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateInnerWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateInnerWidth)
  }

  updateWindowDimensions() {
    this.setState({ innerWidth: window.innerWidth })
  }

  onCheckAll = () => {
    const isCheckedAll = !(this.countChecked() > 0)
    this.props.selectedRefCheckAll(isCheckedAll)
    if (isCheckedAll) {
      this.trackEventCheck('all')
    }
    this.setState({
      showErrorText: false,
    })
  }

  onClickRow = (index, event) => {
    const { refCheck, participantType } = this.props
    const { list } = refCheck
    event.preventDefault()
    if (list[index].isDisabled || participantType === PARTICIPANT_TYPE.PARTICIPANT) {
      return
    }

    this.props.selectedRefCheckRow(index)
    if (list[index].isChecked) {
      this.trackEventCheck(list[index].type)
    }
    this.setState({
      showErrorText: false,
    })
  }

  selectedReference = references => {
    const data = filter(references, ['isChecked', true])
    return data.map(ref =>
      ref.type === referenceType.personal
        ? { type: ref.type, arrayIndex: ref.arrayIndex }
        : { type: ref.type },
    )
  }

  onRequestRefCheck = refChecks => () => {
    const {
      match: { params },
    } = this.props
    const { rentalAppId } = params
    const references = this.selectedReference(refChecks)

    Tracker.trackEvent('clickSendRequestsReference')
    if (isEmpty(references)) {
      this.setState({
        showErrorText: true,
      })
      return
    }

    this.trackSendRequestRentSpreePro()
    this.props.requestReferenceChecks(rentalAppId, { references })
  }

  countChecked() {
    const { list } = this.props.refCheck
    let refCheckLists = 0
    return reduce(
      list,
      (sum, ref) => {
        refCheckLists = sum
        refCheckLists += ref.isChecked ? 1 : 0
        return refCheckLists
      },
      0,
    )
  }

  trackEventCheck(type) {
    switch (type) {
      case referenceType.current_employer:
        Tracker.trackEvent('checkCurrentEmployer')
        break
      case referenceType.former_employer:
        Tracker.trackEvent('checkFormerEmployer')
        break
      case referenceType.current_landlord:
        Tracker.trackEvent('checkCurrentLandlord')
        break
      case referenceType.previous_landlord:
        Tracker.trackEvent('checkPreviousLandlord')
        break
      case referenceType.professional:
        Tracker.trackEvent('checkProfessional')
        break
      case referenceType.personal:
        Tracker.trackEvent('checkPersonal')
        break
      case 'all':
        Tracker.trackEvent('checkAllReference')
        break
      default:
        break
    }
  }

  isRefChecksDisabled() {
    const { refCheck, participantType } = this.props
    if (participantType === PARTICIPANT_TYPE.PARTICIPANT) return true
    return refCheck.list.every(ref => ref.isDisabled)
  }

  checkIsBlur(participantType, ref) {
    return ref.isBlur
      ? ref.isBlur
      : participantType === PARTICIPANT_TYPE.PARTICIPANT && isEmpty(ref.status)
  }

  getParticipantText(participantType) {
    return participantType === PARTICIPANT_TYPE.PARTICIPANT
      ? 'Reference(s)'
      : 'Select reference(s) you want to verify'
  }

  hasStartedTrial = () => !this.context.can(TRIAL_CAN_ACTION, TRIAL_CAN_SUBJECT)

  shouldShowFeatureBlockModal = shouldShow => {
    if (shouldShow) {
      this.trackSendRequestRentSpreePro()
    }
    this.setState({
      showFeatureBlockModal: shouldShow,
      hasStartTrial: this.hasStartedTrial(),
    })
  }

  withSubscriptionCheck = func =>
    this.context.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT) ||
    this.context.can(PREMIUM_CAN_ACTION, PREMIUM_CAN_SUBJECT)
      ? func
      : () => this.shouldShowFeatureBlockModal(true)

  getActionNeeded() {
    return this.hasStartedTrial() ? 'upgrade' : 'trial'
  }

  trackModalEvent = type => {
    let eventType
    const eventProperties = {
      action: this.getActionNeeded(),
      feature: RENTSPREE_PRO_FEATURE_NAME.REF_CHECK,
    }
    switch (type) {
      case FEATURE_BLOCK_ACTION.OPEN:
        eventType = EVENT_FEATURE_BLOCK_MODAL.VIEW_FEATURE_BLOCK_MODAL
        break
      case FEATURE_BLOCK_ACTION.CLOSE:
        eventType = EVENT_FEATURE_BLOCK_MODAL.CLOSE_FEATURE_BLOCK_MODAL
        break
      case FEATURE_BLOCK_ACTION.LEARN_MORE:
        eventType = EVENT_FEATURE_BLOCK_MODAL.CLICK_LEARN_MORE_ON_MODAL
        break
      case FEATURE_BLOCK_ACTION.CHECKOUT:
        eventType = EVENT_FEATURE_BLOCK_MODAL.GO_TO_CHECK_OUT_PAGE
        eventProperties.subscriptionPeriod = SUBSCRIPTION_PERIOD.MONTHLY
        break
      default:
        return
    }
    AmplitudeTracker.trackEvent(eventType.eventName, eventType.properties(eventProperties))
  }

  trackSendRequestRentSpreePro = () => {
    const isPro = this.context.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
    const { eventName, properties } = EVENT_RENTSPREE_PRO.REF_CHECK
    const { CLICK_FROM } = REF_CHECK.EVENT_PROPERTY
    const { PREMIUM, STANDARD } = EVENT_TENANT_SCREENING.EVENT_PROPERTY.BUNDLE_TYPE
    const {
      match: { params },
      refCheck,
      rentalDetail,
      screeningOption,
    } = this.props
    const { rentalAppId } = params
    const { email } = rentalDetail
    const references = this.selectedReference(refCheck.list)
    map(references, item => {
      const { type, arrayIndex } = item
      const selectType =
        arrayIndex !== null && type === referenceType.personal
          ? `${type}_reference_${arrayIndex + 1}`
          : type
      AmplitudeTracker.trackEvent(
        eventName,
        properties({
          email,
          rentalAppId,
          type: selectType,
          isPro,
          clickFrom: CLICK_FROM.VIEW_REPORT,
          bundle: screeningOption.premium ? PREMIUM : STANDARD,
        }),
      )
    })
  }

  render() {
    const { showFeatureBlockModal, hasStartTrial, showErrorText, innerWidth } = this.state
    const { rentalDetail, refCheck, participantType } = this.props
    const { rentalAppId, propertyId } = this.props.match.params

    const selectedCount = this.countChecked()
    const redirectUrl = propertyId
      ? buildPath(REFERENCE_CHECKS_ABSOLUTE_PATH, {
          propertyId,
          rentalAppId,
        })
      : buildPath(`${TENANT_SCREENING_WITH_RENTAL_ID}/reference-checks`, { rentalAppId })

    const monthlyPrice = getSubscriptionProductVariant(this.props.products?.data, {
      name: PRO_PLAN_VARIANTS.MONTHLY,
    })?.price
    const totalPrice = getSubscriptionProductVariant(this.props.products?.data, {
      name: PRO_PLAN_VARIANTS.MONTHLY,
    })?.price

    if (refCheck.errorReference) {
      return (
        <FailedToFetch
          withBreadcrumb
          withFilter
          title={ERROR.APPLICATION_DETAIL.REFERENCE_CHECK.FETCH_REFERENCE.TITLE}
          text={ERROR.APPLICATION_DETAIL.REFERENCE_CHECK.FETCH_REFERENCE.MESSAGE}
        />
      )
    }

    return (
      <WhiteWrapper withReportTabs noMargin extraPx={7}>
        {refCheck.isFetching && (
          <CenterContent overlay withBreadcrumb withFilter>
            <Loading />
          </CenterContent>
        )}
        <FeatureBlockModal
          id={`${FEATURE_BLOCK_NAME.REFERENCE_CHECKS}FeatureBlockModal`}
          feature={FEATURE_BLOCK_NAME.REFERENCE_CHECKS}
          isOpen={showFeatureBlockModal}
          hasStartTrial={hasStartTrial}
          onClose={() => this.shouldShowFeatureBlockModal(false)}
          redirectUrl={redirectUrl}
          onAfterOpen={() => this.trackModalEvent(FEATURE_BLOCK_ACTION.OPEN)}
          onAfterClose={() => this.trackModalEvent(FEATURE_BLOCK_ACTION.CLOSE)}
          onMoreInfoClick={() => this.trackModalEvent(FEATURE_BLOCK_ACTION.LEARN_MORE)}
          onCheckoutClick={() => this.trackModalEvent(FEATURE_BLOCK_ACTION.CHECKOUT)}
          fullPrice={monthlyPrice}
          totalPrice={totalPrice}
        />
        <ContentWrapper>
          <RefChecksTemplate
            application={rentalDetail}
            refChecks={refCheck.list}
            selectedCount={selectedCount}
            requestDisabled={this.isRefChecksDisabled}
            onCheckAll={this.onCheckAll}
            onClickSendRequest={this.withSubscriptionCheck}
            onRequestRefs={this.onRequestRefCheck}
            participantType={participantType}
            onClickRow={this.onClickRow}
            showError={showErrorText}
            checkIsBlur={this.checkIsBlur}
            isMobile={innerWidth < 991}
          />
        </ContentWrapper>
        <TemplateWrapper>
          <RefChecksReportContainerWrapper match={this.props.match} location={this.props.location} />
        </TemplateWrapper>
        <WrapperHideMobile>
          <BtnOverlay type="reference-checks" />
        </WrapperHideMobile>
      </WhiteWrapper>
    )
  }
}

// TODO remove this and use hook useAbility when we refactor to functional component
RefChecksContainer.contextType = AbilityContext

export default compose(
  withProReportAvailable(SHARED_REPORT_TYPES.REFERENCE_CHECK),
  withRouter,
  withConnect,
)(RefChecksContainer)
