import Button from '@rentspree/component-2023.components.atoms.button'
import { Level1Template } from '@rentspree/component-2023.components.templates.level-1-template'
import B16 from '@rentspree/component-v2/dist/typography/b16'
import { buildPath, urlJoin } from '@rentspree/path'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import { UPCOMING_EVENT_TO_GIFT_TYPES } from 'components/contacts/molecules/upcoming-event-type-cell/constants'
import ContactDashboardTemplate from 'components/contacts/templates/contact-dashboard-template'
import FullLandingTemplate from 'components/contacts/templates/full-landing-template'
import { CenterLoading } from 'components/organisms/center-loading'
import ConfirmationModal from 'components/organisms/confirmation-modal'
import { CONFIRM_TYPE } from 'components/organisms/confirmation-modal/constants'
import {
  DASHBOARD,
  SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT,
  CONTACTS,
  CONTACT_GROUP,
  CONTACT_DETAIL,
  CONTACT_IMPORT_CSV,
  CONTACT_REDIRECT,
} from 'constants/route'
import useMobile from 'hooks/use-mobile'
import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import tracker from 'tracker'
import { EVENT_ACTION_DROPDOWN, EVENT_PEOPLE_CONCEPT } from 'tracker/const'
import { generateTrackingValueByGiftName } from 'tracker/tracker-mapper'
import { MOBILE_THRESHOLD } from 'utils/constants'

import LoadingOverlay from '../../../components/contacts/molecules/loading-overlay'
import { contactContact } from '../actions'
import { openCreateContactModal } from '../create-contact-modal/actions'
import { openDeleteTagModal } from '../delete-tag-modal/actions'
import FilterModal from '../filter-modal'
import { openFilterModal } from '../filter-modal/actions'
import { getGifts, generateGiftLink, setSelectedGift } from '../gifts/actions'
import { selectGifts, selectSelectedGift } from '../gifts/selectors'
import { updateQueryString, getTagByTagId, removeKeysFromQueryString } from '../helpers'
import { IMPORT_PROGRESS_STATUS } from '../import-csv/constants'
import { selectCreateProgress } from '../import-csv/selectors'
import QuickFilterContact from '../quick-filter-contact'
import { selectAllTags, selectIsLoadingTags } from '../tags/selectors'

import {
  getContactList,
  createAndTagContact,
  tagContact,
  unTagContact,
  starContact,
  getContactCount,
  setBulkState,
  toggleSelectedBulk,
  addSelectedAllBulk,
  bulkDelete,
  bulkAddTag,
  applySortContact,
  createAndBulkAddTag,
  deselectAllBulk,
  unsetIsLoadingDone,
  markAsGiftSent,
  unmarkAsGiftSent,
  getContactSettings,
  updateContactSettings,
  sendMessage,
} from './actions'
import { withReducer, withSaga, withGiftSaga, withGiftReducer } from './connect'
import {
  ERROR_MESSAGE,
  BULK_DELETE_MODEL_TITLE,
  BULK_DELETE_MODEL_DESCRIPTION,
  BULK_DELETE_SELECT_STATE,
  BULK_REQUEST_APPLICATION_SELECT_STATE,
  BULK_DELETE_CONFIRM_STATE,
  BULK_ADD_TAG_SELECT_STATE,
  BULK_DELETE,
} from './constants'
import {
  selectData,
  selectContactSettings,
  selectIsLoading,
  selectIsError,
  selectIsInitial,
  selectCurrentFilterCount,
  selectHasQueryOption,
  selectPagination,
  selectIsLoadingTag,
  selectSelectedTag,
  selectCounts,
  selectIsLoadingCount,
  selectBulkSelected,
  selectBulkContactsSelected,
  selectBulkState,
  selectIsBulkLoading,
  selectIsLoadingDone,
  selectIsLoadingSendMessage,
} from './selectors'
import {
  mapFiltersToPayload,
  mapQueryStringToFilters,
  appendArrayValue,
  removeArrayValue,
  isTagSelected,
  getDefaultSortedKey,
  mapPathToEventProperty,
  isUpcomingDate,
} from './utils'

const { SUCCESS, PARTIALLY_SUCCESS } = IMPORT_PROGRESS_STATUS
const {
  EVENT_NAME: {
    VIEW_CONTACT_DASHBOARD,
    VIEW_EMPTY_CONTACT_DASHBOARD,
    CLICK_VIEW_CONTACT_PROFILE,
    CLICK_TO_ADD_TAG_TO_CONTACT,
    REMOVE_SINGLE_TAG_FROM_CONTACT,
    FILTER_CONTACT_TAG,
    CLICK_OPEN_FILTER,
    IMPORT_CONTACTS_FROM_SPREADSHEET,
    START_SCREENING,
    CLICK_CREATE_CONTACT,
    MARK_STAR_ON_CONTACT,
    UNSTARRED_CONTACT,
    GO_BACK_TO_ALL_CONTACTS_FROM_STARRED,
    BULK_DELETE_CANCEL,
    BULK_DELETE_DESELECT_ALL,
    BULK_DELETE_CONFIRM_CONFIRMATION_BOX,
    BULK_DELETE_CANCEL_CONFIRM_BOX,
    BULK_DELETE_SELECTED_CONTACT,
    BULK_DELETE_START,
    CLICK_EDIT_TAG,
    CLICK_SORT_COLUMN,
    BULK_ADD_TAG_CANCEL,
    BULK_ADD_TAG_START,
    BULK_REQUEST_APPLICATION,
    CHOOSE_GIFT,
    CLICK_VIEW_ALL_TAG,
  },
  EVENT_PROPERTY: {
    CLICK_FROM: {
      CONTACTS_ALL,
      CONTACTS_LANDING,
      CONTACTS_FROM_RENTSPREE,
      CONTACTS_BULKADDTAG,
      CONTACTS_ALL_MORE,
    },
    SORT_VALUE,
    CATEGORY: { PEOPLE_CONCEPT },
  },
} = EVENT_PEOPLE_CONCEPT

const { SCREEN_FROM_CURRENT_PROPERTY } = EVENT_ACTION_DROPDOWN

const ContactButton = ({ onClickCreateContact, onClickImportContact, isMobile }) => {
  const handleClickNewContact = () => {
    tracker.trackButtonClick({
      click_text: 'Add contact',
      location: 'title_cta',
    })
    onClickCreateContact()
  }

  const handleClickImportContacts = () => {
    tracker.trackButtonClick({
      click_text: 'Import contacts',
      click_url: CONTACT_IMPORT_CSV,
      location: 'title_cta',
    })
    onClickImportContact()
  }

  if (isMobile) {
    return (
      <Button size="small" variant="outlined" color="secondary" onClick={handleClickNewContact}>
        Add contact
      </Button>
    )
  }

  return (
    <>
      <Button size="small" variant="outlined" color="secondary" onClick={handleClickNewContact}>
        + Add contact
      </Button>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        onClick={handleClickImportContacts}
      >
        Import contacts
      </Button>
    </>
  )
}

export const ContactsList = ({ match, history, location }) => {
  const dispatch = useDispatch()
  const isLoadingList = useSelector(selectIsLoading)
  const isLoadingDone = useSelector(selectIsLoadingDone)
  const isLoadingTag = useSelector(selectIsLoadingTag)
  const isLoadingSendMessage = useSelector(selectIsLoadingSendMessage)
  const isError = useSelector(selectIsError)
  const isInitial = useSelector(selectIsInitial)
  const data = useSelector(selectData)
  const groupCount = useSelector(selectCounts)
  const isLoadingCount = useSelector(selectIsLoadingCount)
  const isLoadingTags = useSelector(selectIsLoadingTags)
  const isBulkLoading = useSelector(selectIsBulkLoading)
  const contactSettings = useSelector(selectContactSettings)
  const {
    page: currentPage = 1,
    sort: contactSort = getDefaultSortedKey(location?.pathname),
    ...filters
  } = mapQueryStringToFilters(location?.search)
  const dataPagination = useSelector(selectPagination)
  const currentGroupPath = match?.params?.contactGroup || ''
  const currentFilterCount = useSelector(selectCurrentFilterCount)
  const hasQueryOption = useSelector(selectHasQueryOption)
  const createProgress = useSelector(selectCreateProgress)
  const selectedTags = useSelector(selectSelectedTag)
  const allTags = useSelector(selectAllTags)
  const bulkSelected = useSelector(selectBulkSelected)
  const bulkContactSelected = useSelector(selectBulkContactsSelected)
  const bulkState = useSelector(selectBulkState)
  const gifts = useSelector(selectGifts)
  const selectedGift = useSelector(selectSelectedGift)
  const [isMarkAsGiftSentModalOpen, setIsMarkAsGiftSentModalOpen] = React.useState()
  const [isContactPanelOpen, setIsContactPanelOpen] = React.useState(false)
  const [isClosingContactPanel, setIsClosingContactPanel] = React.useState(false)
  const [contactPanelContent, setContactPanelContent] = React.useState({})

  const currentQueryString = removeKeysFromQueryString(location?.search, ['sort'])

  const hasQuery = Object.values(CONTACT_GROUP).includes(currentGroupPath) || hasQueryOption

  const [selectedEvent, setSelectedEvent] = React.useState(null)

  React.useEffect(() => {
    dispatch(getContactSettings())
    return () => {
      dispatch(unsetIsLoadingDone())
      dispatch(setSelectedGift())
    }
  }, [])

  React.useEffect(() => {
    const payload = mapFiltersToPayload({
      group: currentGroupPath,
      page: currentPage,
      sort: contactSort,
      filters,
    })
    dispatch(getContactList(payload))
    dispatch(getContactCount(payload))
  }, [currentGroupPath, currentPage, location?.search])

  React.useEffect(() => {
    if (isLoadingDone) {
      dispatch(getGifts())
      if (data?.length > 0 || currentGroupPath || currentPage > 1 || !isEmpty(filters)) {
        tracker.trackEvent(VIEW_CONTACT_DASHBOARD)
      } else {
        tracker.trackEvent(VIEW_EMPTY_CONTACT_DASHBOARD)
      }
    }
  }, [isLoadingDone])

  React.useEffect(() => {
    if (selectedGift?.url) {
      if (!contactPanelContent.data) {
        setIsContactPanelOpen(false)
        setIsMarkAsGiftSentModalOpen(false)
      } else if (!contactPanelContent.data.isGiftSent) {
        setTimeout(() => setIsMarkAsGiftSentModalOpen(true), 1000)
      } else {
        setIsContactPanelOpen(false)
      }
    }
  }, [selectedGift?._id, selectedGift?.url])

  const handleUpdateSettings = settings => {
    dispatch(updateContactSettings(settings))
  }

  const handleClickSendMessage = (message, email) => {
    dispatch(
      sendMessage({
        message,
        recipient: email,
      }),
    )
  }

  const handleClickTenantScreening = () => {
    tracker.trackEvent(START_SCREENING, {
      click_from: CONTACTS_FROM_RENTSPREE,
    })
    history.push(SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT)
  }

  const handleClickStarContactOnEmptyPage = () => {
    tracker.trackEvent(GO_BACK_TO_ALL_CONTACTS_FROM_STARRED)
    history.push(CONTACTS)
  }

  const handleOpenCreateContactModal = () => {
    tracker.trackEvent(CLICK_CREATE_CONTACT, {
      click_from: CONTACTS_ALL,
    })
    dispatch(openCreateContactModal())
  }

  const handleOpenCreateContactModalOnFullLanding = () => {
    tracker.trackEvent(CLICK_CREATE_CONTACT, {
      click_from: CONTACTS_LANDING,
    })
    dispatch(openCreateContactModal())
  }

  const handleOpenDeleteTagModal = () => {
    tracker.trackEvent(CLICK_EDIT_TAG)
    dispatch(openDeleteTagModal())
  }

  const handleOpenFilterModal = () => {
    dispatch(openFilterModal())
    tracker.trackEvent(CLICK_OPEN_FILTER)
  }

  const handleStarContact = payload => {
    tracker.trackEvent(payload.starredAt ? UNSTARRED_CONTACT : MARK_STAR_ON_CONTACT, {
      click_from: mapPathToEventProperty(currentGroupPath),
    })
    dispatch(starContact(payload))
  }

  const handleConfirmBulkDelete = () => {
    tracker.trackEvent(BULK_DELETE_CONFIRM_CONFIRMATION_BOX)
    dispatch(bulkDelete(bulkSelected))
  }

  const handleGoToContactDetail = (
    contactId,
    daysTillBirthday,
    daysTillMoveInAnniversary,
    daysTillMovingIn,
  ) => {
    tracker.trackEvent(CLICK_VIEW_CONTACT_PROFILE, {
      click_from: mapPathToEventProperty(currentGroupPath),
      has_special_events:
        isUpcomingDate(daysTillBirthday) ||
        isUpcomingDate(daysTillMoveInAnniversary) ||
        isUpcomingDate(daysTillMovingIn),
    })
    const path = buildPath(CONTACT_DETAIL, { contactId })
    history.push(path)
  }

  const onClickToggleSelectedBulk = contactId => {
    dispatch(toggleSelectedBulk(contactId))
  }

  const onClickAddSelectedAllBulk = () => {
    dispatch(addSelectedAllBulk())
  }

  const handleOpenBulkDeleteSelectMode = () => {
    dispatch(setBulkState(BULK_DELETE_SELECT_STATE))
  }

  const handleOpenBulkScreeningSelectMode = () => {
    tracker.trackEvent(BULK_REQUEST_APPLICATION)
    dispatch(setBulkState(BULK_REQUEST_APPLICATION_SELECT_STATE))
  }

  const handleSendingRentalApplication = () => {
    tracker.trackEvent(SCREEN_FROM_CURRENT_PROPERTY, {
      click_from: CONTACTS_ALL,
      category: PEOPLE_CONCEPT,
    })
    const selectedEmails = data
      .filter(contact =>
        // eslint-disable-next-line no-underscore-dangle
        bulkSelected.includes(contact._id),
      )
      .map(contact => contact.email)
    history.push(SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT, {
      emails: selectedEmails,
    })
  }

  const handleOpenBulkDeleteConfirmMode = () => {
    tracker.trackEvent(BULK_DELETE_SELECTED_CONTACT)
    dispatch(setBulkState(BULK_DELETE_CONFIRM_STATE))
  }

  const handleCloseBulkState = () => {
    if (bulkState === BULK_DELETE_SELECT_STATE) {
      tracker.trackEvent(BULK_DELETE_CANCEL)
    } else if (bulkState === BULK_ADD_TAG_SELECT_STATE) {
      tracker.trackEvent(BULK_ADD_TAG_CANCEL)
    }

    dispatch(setBulkState(null))
    dispatch(deselectAllBulk())
  }

  const handleDeselectAllBulk = () => {
    if (bulkState === BULK_DELETE) tracker.trackEvent(BULK_DELETE_DESELECT_ALL)
    dispatch(deselectAllBulk())
  }

  const handleBulkAddTag = tag => {
    dispatch(bulkAddTag({ contactIds: bulkSelected, tag }))
  }

  const handleCreateAndBulkAddTag = name => {
    dispatch(createAndBulkAddTag({ contactIds: bulkSelected, name }))
  }

  const handleOpenBulkAddTagSelectMode = () => {
    tracker.trackEvent(BULK_ADD_TAG_START)
    dispatch(setBulkState(BULK_ADD_TAG_SELECT_STATE))
  }

  const handleOnFocusAddTagOnBulkAddTag = () => {
    tracker.trackEvent(CLICK_TO_ADD_TAG_TO_CONTACT, {
      click_from: CONTACTS_BULKADDTAG,
      contact_email_list: bulkContactSelected.map(contact => contact.email),
      category: PEOPLE_CONCEPT,
    })
  }

  // rename this fn later once we have modale
  const navigateToImportCsv = () => {
    tracker.trackEvent(IMPORT_CONTACTS_FROM_SPREADSHEET, {
      click_from: isContactEmpty && !isLoadingList && !hasQuery ? CONTACTS_LANDING : CONTACTS_ALL,
    })
    history.push(CONTACT_IMPORT_CSV)
  }

  const handleCreateAndTagContact = ({ contactId, name, email }, options = {}) =>
    dispatch(createAndTagContact({ contactId, name, email }, options))

  const handleTagContact = ({ contactId, tagId, email }) =>
    dispatch(tagContact({ contactId, tag: tagId, email }))

  const handleUntagContact = ({ contactId, tagId, email, isShowMore }) => {
    tracker.trackEvent(REMOVE_SINGLE_TAG_FROM_CONTACT, {
      click_from: isShowMore ? CONTACTS_ALL_MORE : CONTACTS_ALL,
      contact_email: email,
      category: PEOPLE_CONCEPT,
    })
    dispatch(unTagContact(contactId, tagId))
  }

  const handleShowMoreTag = ({ email }) =>
    tracker.trackEvent(CLICK_VIEW_ALL_TAG, {
      contact_email: email,
      category: PEOPLE_CONCEPT,
    })

  const handleOnFocusAddTagBtn = ({ email, isShowMore }) => {
    tracker.trackEvent(CLICK_TO_ADD_TAG_TO_CONTACT, {
      click_from: isShowMore ? CONTACTS_ALL_MORE : CONTACTS_ALL,
      contact_email: email,
      category: PEOPLE_CONCEPT,
    })
  }

  const handleChangePage = page => {
    const newQueryString = updateQueryString(location?.search, { page })
    history.push(`${location.pathname}${newQueryString}`)
  }

  const handleToggleTagFilter = tagId => {
    const isSelectedTag = isTagSelected(tagId, selectedTags)
    const newTagFilter = isSelectedTag
      ? removeArrayValue(filters?.tagIds, tagId)
      : appendArrayValue(filters?.tagIds, tagId)
    const newQueryString = updateQueryString(location?.search, {
      tagIds: newTagFilter,
    })
    const tag = getTagByTagId(allTags, tagId)
    if (!isSelectedTag) {
      tracker.trackEvent(FILTER_CONTACT_TAG, {
        tag_name: tag?.name || '',
      })
    }
    history.push(`${location.pathname}${newQueryString}`)
  }

  const handleSearch = search => {
    const newQueryString = updateQueryString(location?.search, { search })
    history.push(`${location.pathname}${newQueryString}`)
  }

  const handleSortContact = ({ sortedKey, sortedVal }) => {
    tracker.trackEvent(CLICK_SORT_COLUMN, {
      sort_by: `${SORT_VALUE[sortedKey]}_${sortedVal === '-1' ? 'asc' : 'desc'}`,
    })
    dispatch(applySortContact(sortedKey, sortedVal))
  }

  const handleMarkAsGiftSent = ({ contactId, eventType }) => {
    const giftType = UPCOMING_EVENT_TO_GIFT_TYPES[eventType]
    dispatch(markAsGiftSent({ contactId, giftType }))
  }

  const handleUnmarkAsGiftSent = ({ contactId, eventType }) => {
    const giftType = UPCOMING_EVENT_TO_GIFT_TYPES[eventType]
    dispatch(unmarkAsGiftSent({ contactId, giftType }))
  }

  const handleGiftBox = ({ contactId, eventType, gift }) => {
    const path = buildPath(CONTACT_REDIRECT)
    const redirectWindow = window.open(urlJoin(DASHBOARD, path), '_blank')
    redirectWindow.focus()
    dispatch(setSelectedGift(gift))
    dispatch(generateGiftLink(gift, redirectWindow))
    const giftType = UPCOMING_EVENT_TO_GIFT_TYPES[eventType]
    if (contactSettings?.autoMarkAsSentGift) {
      dispatch(markAsGiftSent({ contactId, giftType }))
      handleClosingContactPanel()
    }
    tracker.trackEvent(CHOOSE_GIFT, {
      gift_option: generateTrackingValueByGiftName(gift?.name),
      category: PEOPLE_CONCEPT,
    })
  }

  const handleContactContact = (label, contactId) => {
    dispatch(contactContact(contactId, { label }))
  }

  const handleOpeningContactPanel = panelContent => {
    setContactPanelContent(panelContent)
    setIsContactPanelOpen(true)
  }

  const handleClosingContactPanel = () => {
    setIsClosingContactPanel(true)
    setTimeout(() => {
      setIsContactPanelOpen(false)
      setIsClosingContactPanel(false)
    }, 200)
  }

  const fullLandingActions = {
    openCreateContactModal: handleOpenCreateContactModalOnFullLanding,
    importCsv: navigateToImportCsv,
  }

  const pagination = {
    total: dataPagination?.total,
    page: parseInt(currentPage, 10),
    perPage: 8,
  }

  const isContactEmpty = isEmpty(data)

  const isUploadingCsv =
    createProgress.isOpen && ![PARTIALLY_SUCCESS, SUCCESS].includes(createProgress.status)

  const isMobile = useMobile(MOBILE_THRESHOLD)

  if (isLoadingList && isInitial) return <CenterLoading />
  if (isError) {
    return (
      <FailedToFetch
        noMargin
        noBreadcrumbMobile
        text={ERROR_MESSAGE.MESSAGE}
        title={ERROR_MESSAGE.TITLE}
      />
    )
  }

  if (isContactEmpty && !isLoadingList && !hasQuery) {
    return <FullLandingTemplate actions={fullLandingActions} />
  }

  const ModalContent = styled(B16)`
    font-family: Source Sans Pro;
  `

  return (
    <>
      <Level1Template
        title="Contacts"
        cta={
          <ContactButton
            onClickCreateContact={handleOpenCreateContactModal}
            onClickImportContact={navigateToImportCsv}
            isMobile={isMobile}
          />
        }
        isMobile={isMobile}
      />
      {isBulkLoading && <LoadingOverlay />}
      <FilterModal filters={filters} />
      <ConfirmationModal
        confirmType={CONFIRM_TYPE.DELETE}
        isOpen={bulkState === BULK_DELETE_CONFIRM_STATE}
        isLoading={isBulkLoading}
        onClose={() => {
          tracker.trackEvent(BULK_DELETE_CANCEL_CONFIRM_BOX)
          handleOpenBulkDeleteSelectMode()
        }}
        title={BULK_DELETE_MODEL_TITLE}
        onClickConfirm={handleConfirmBulkDelete}
      >
        <ModalContent fontFamily="Source Sans Pro">{BULK_DELETE_MODEL_DESCRIPTION}</ModalContent>
      </ConfirmationModal>
      <ContactDashboardTemplate
        data={data}
        userSettings={contactSettings}
        gifts={gifts}
        allTags={allTags}
        selectedTags={selectedTags}
        bulkSelected={bulkSelected}
        bulkState={bulkState}
        currentGroupPath={currentGroupPath}
        currentFilterCount={currentFilterCount}
        currentQueryString={currentQueryString}
        currentSearchValue={filters?.search}
        groupCount={groupCount}
        isLoadingList={isLoadingList}
        isLoadingCount={isLoadingCount}
        isLoadingTags={isLoadingTags}
        isLoadingSendMessage={isLoadingSendMessage}
        pagination={pagination}
        isDisableAddNewContactDesktop={isUploadingCsv}
        isDisableAddNewContactMobile={createProgress?.isOpen}
        isMarkAsGiftSentModalOpen={isMarkAsGiftSentModalOpen}
        isContactPanelOpen={isContactPanelOpen}
        isClosingContactPanel={isClosingContactPanel}
        contactPanelContent={contactPanelContent}
        handleGoToContactDetail={handleGoToContactDetail}
        handleOpenFilterModal={handleOpenFilterModal}
        handleChangePage={handleChangePage}
        handleOpenBulkDeleteConfirmMode={handleOpenBulkDeleteConfirmMode}
        handleCloseBulkState={handleCloseBulkState}
        onDeSelectAllBulk={handleDeselectAllBulk}
        onSearch={handleSearch}
        onUpdateSettings={handleUpdateSettings}
        onClickSendMessage={handleClickSendMessage}
        onClickTenantScreening={handleClickTenantScreening}
        onClickStarContactOnEmptyPage={handleClickStarContactOnEmptyPage}
        onClickCreateContact={handleOpenCreateContactModal}
        onClickImportContact={navigateToImportCsv}
        onClickToggleSelectedBulk={onClickToggleSelectedBulk}
        onClickAddSelectedAllBulk={onClickAddSelectedAllBulk}
        onClickDeleteContact={() => {
          tracker.trackEvent(BULK_DELETE_START)
          handleOpenBulkDeleteSelectMode()
        }}
        onClickEditTag={handleOpenDeleteTagModal}
        onCreateAndTagContact={handleCreateAndTagContact}
        onTagContact={handleTagContact}
        onUntagContact={handleUntagContact}
        onShowMoreTag={handleShowMoreTag}
        isLoadingTag={isLoadingTag}
        onToggleTagFilter={handleToggleTagFilter}
        onStarContact={handleStarContact}
        onSortContact={handleSortContact}
        currentSortContactValue={contactSort}
        onFocusAddTag={handleOnFocusAddTagBtn}
        onClickToBulkAddTag={handleOpenBulkAddTagSelectMode}
        onClickRequestApplication={handleOpenBulkScreeningSelectMode}
        onSendRentalApplication={handleSendingRentalApplication}
        onBulkAddTag={handleBulkAddTag}
        onCreateAndBulkAddTag={handleCreateAndBulkAddTag}
        onFocusAddTagOnBulkAddTag={handleOnFocusAddTagOnBulkAddTag}
        onMarkAsGiftSent={handleMarkAsGiftSent}
        onUnmarkGiftSent={handleUnmarkAsGiftSent}
        selectedEvent={selectedEvent}
        onClickGiftBox={handleGiftBox}
        onCopyContactDetail={handleContactContact}
        setIsMarkAsGiftSentModalOpen={setIsMarkAsGiftSentModalOpen}
        onOpeningContactPanel={handleOpeningContactPanel}
        onClosingContactPanel={handleClosingContactPanel}
        setIsContactPanelOpen={setIsContactPanelOpen}
        setContactPanelContent={setContactPanelContent}
      >
        <QuickFilterContact
          setSelectedEvent={setSelectedEvent}
          currentQueryString={currentQueryString}
        />
      </ContactDashboardTemplate>
    </>
  )
}

export default compose(withSaga, withReducer, withGiftSaga, withGiftReducer)(ContactsList)
