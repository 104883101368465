import { BlueGradientButton, Checkbox } from '@rentspree/component-v2'
import TextArea from '@rentspree/component-v2/dist/input/textarea'
import { generateFullName } from '@rentspree/helper'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import ContactPhoto from 'components/contacts/atoms/contact-photo'
import ContactPanel from 'components/contacts/organisms/contact-panel'
import { breakpoints, COLOR } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import {
  TYPE_BIRTHDAY,
  TYPE_MOVE_IN_ANNIVERSARY,
  TYPE_MOVING_IN,
} from '../../templates/contact-dashboard-template/constants'

const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT

const ChatContactPanel = styled(ContactPanel)`
  ${breakpoints.mobile(`
    .chat-panel-container {
      height: 100%; 
    }
  `)};
`
const NameSection = styled.div`
  display: flex;
  align-items: center;
`

const NameWrapper = styled.div`
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  color: ${COLOR.textBlack};
`
const TextAreaWrapper = styled.div`
  margin: 20px 0;
  height: calc(100% - 135px);
  > div {
    height: 100%;
    textarea {
      height: 100%;
      padding: 15px;
      font-size: 16px;
      resize: none;
    }
    > div {
      position: relative;
      top: -4px;
    }
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`

export const SendButton = styled(BlueGradientButton)`
  font-family: 'Source Sans Pro';
  width: 120px;
  font-weight: 600;
  font-size: 16px;
`

const SetDefaultMsgCheckbox = styled(Checkbox)`
  font-size: 16px;
`

const MessageWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const MAX_MESSAGE_LENGTH = 1000
const REQUIRED_ERROR = 'Required'
const EXCEED_MAXIMUM_LENGTH = 'Exceed maximum character length'

const ChatPanel = ({
  isLoading,
  firstName,
  middleName,
  lastName,
  email,
  image,
  imagePlaceholder,
  eventType,
  message: defaultMessage = '',
  setIsContactPanelOpen,
  onClickSendMessage,
  onUpdateDefaultMessage,
}) => {
  const [message, setMessage] = React.useState(defaultMessage)
  const [touched, setTouched] = React.useState(false)
  const [inputErrorMessage, setInputErrorMessage] = React.useState('')
  const [checkSetDefault, setCheckSetDefault] = React.useState(!!defaultMessage)

  const validateMessage = inputMessage => {
    if (!inputMessage) {
      return {
        isError: true,
        errorMessage: REQUIRED_ERROR,
      }
    }
    if (inputMessage.length > MAX_MESSAGE_LENGTH) {
      return {
        isError: true,
        errorMessage: EXCEED_MAXIMUM_LENGTH,
      }
    }
    return {
      isError: false,
      errorMessage: '',
    }
  }

  useEffect(() => {
    if (touched) {
      const { errorMessage } = validateMessage(message)
      setInputErrorMessage(errorMessage)
    }
    setTouched(true)
  }, [message])

  const handleClickSendMessage = () => {
    const { isError, errorMessage } = validateMessage(message)
    if (isError) {
      setInputErrorMessage(errorMessage)
    } else {
      if (checkSetDefault) {
        onUpdateDefaultMessage(message)
      }
      onClickSendMessage(message, email)
    }
    const { BIRTHDAY, MOVE_IN_ANNIVERSARY, MOVING_IN } = EVENT_PROPERTY.EVENT_TYPE
    let eventTypeTrackingProps
    switch (eventType) {
      case TYPE_BIRTHDAY:
        eventTypeTrackingProps = BIRTHDAY
        break
      case TYPE_MOVE_IN_ANNIVERSARY:
        eventTypeTrackingProps = MOVE_IN_ANNIVERSARY
        break
      case TYPE_MOVING_IN:
        eventTypeTrackingProps = MOVING_IN
        break
      default:
        break
    }
    tracker.trackEvent(EVENT_NAME.SEND_CONGRAT_MESSAGE, {
      event_type: eventTypeTrackingProps,
      set_default_message: checkSetDefault,
      category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
    })
  }

  return (
    <ChatContactPanel
      className="chat-panel-container"
      onClosePanel={() => {
        setIsContactPanelOpen(false)
      }}
    >
      <ContactPanel.Header>
        <MessageWrapper>Send message to {firstName}</MessageWrapper>
      </ContactPanel.Header>
      <ContactPanel.Content>
        <NameSection>
          <ContactPhoto image={image} imagePlaceholder={imagePlaceholder} isDashboard />
          <NameWrapper>{generateFullName({ firstName, middleName, lastName })}</NameWrapper>
        </NameSection>
        <TextAreaWrapper>
          <TextArea
            name="description"
            id="description"
            error={inputErrorMessage}
            characterLimit={1000}
            value={message}
            onChange={event => {
              setMessage(event.target.value)
            }}
          />
        </TextAreaWrapper>
        <Footer>
          <SetDefaultMsgCheckbox
            simple
            bgSimpleTransparent
            id="chat-panel-checkbox"
            label="Set as default message"
            isSelected={checkSetDefault}
            onClick={() => setCheckSetDefault(!checkSetDefault)}
          />
          <SendButton
            small
            text="Send Now"
            type="button"
            loading={isLoading}
            onClick={handleClickSendMessage}
          />
        </Footer>
      </ContactPanel.Content>
    </ChatContactPanel>
  )
}

ChatPanel.defaultProps = {
  isLoading: false,
  firstName: '',
  middleName: '',
  lastName: '',
  image: '',
  email: '',
  imagePlaceholder: '',
  message: '',
}

ChatPanel.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.string,
  imagePlaceholder: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
  onClickSendMessage: PropTypes.func.isRequired,
  onUpdateDefaultMessage: PropTypes.func.isRequired,
}

export default ChatPanel
