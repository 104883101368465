import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import React from 'react'
import styled from 'styled-components'

import ContactTagCell from 'components/contacts/molecules/contact-tag-cell'
import { breakpoints, COLOR } from 'styles/settings'

import ContactIdentityCell from '../../molecules/contact-identity-cell'

const Container = styled.div`
  width: 100%;
  display: none;
  padding: 0 15px 15px 10px;
  border-bottom: 1px solid ${COLOR.lineGrey};
  &:last-child {
    border-bottom: none;
  }
  background: ${({ background }) => background};
  ${breakpoints.mobile(`
    display: inline-flex;
    &:last-child {
      border-bottom: 1px solid ${COLOR.lineGrey};
    }
  `)};
`

const ContactContainer = styled.div`
  display: flex;
  height: 45px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 72px;
`

const EmailContainer = styled.span`
  padding-right: 10px;
  color: ${COLOR.textBlack};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PhoneContainer = styled.span`
  width: 125px;
  height: 20px;
  color: ${COLOR.textBlack};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-left: 1px solid ${COLOR.borderGrey};
  flex-shrink: 0;
  padding-left: 15px;
  text-align: center;
`

const CheckBoxContainer = styled.div`
  width: 40px;
  padding-top: 26px;
  padding-left: 4px;
`

const DataContainer = styled.div`
  width: ${({ bulkState }) => (bulkState ? 'calc(100% - 35px);' : '100%;')};
`

const ContactDataMobile = ({
  _id,
  email,
  firstName,
  lastName,
  imagePlaceholder,
  mobileNumber,
  role,
  onClickContactRow,
  moveInDate,
  birthDate,
  daysTillMoveInAnniversary,
  daysTillMovingIn,
  daysTillBirthday,
  tags,
  allTags,
  onCreateAndTagContact,
  onTagContact,
  onUntagContact,
  onShowMoreTag,
  starredAt,
  onStarContact,
  bulkSelected,
  onToggleSelectedBulk,
  bulkState,
  onFocusAddTag,
  isUnseen,
}) => (
  <Container onClick={onClickContactRow} background={isUnseen ? COLOR.blueLv7 : COLOR.white}>
    {bulkState && (
      <CheckBoxContainer>
        <Checkbox
          id="contact_checkbox"
          simple
          isSelected={bulkSelected.includes(_id)}
          bgSimpleTransparent
          onClick={onToggleSelectedBulk}
          margin="0"
        />
      </CheckBoxContainer>
    )}
    <DataContainer bulkState={bulkState}>
      <ContactIdentityCell
        imagePlaceholder={imagePlaceholder}
        firstName={firstName}
        lastName={lastName}
        role={role}
        moveInDate={moveInDate}
        birthDate={birthDate}
        daysTillMoveInAnniversary={daysTillMoveInAnniversary}
        daysTillMovingIn={daysTillMovingIn}
        daysTillBirthday={daysTillBirthday}
        starredAt={starredAt}
        onStarContact={onStarContact}
      />
      <ContactContainer>
        <EmailContainer>{email}</EmailContainer>
        <PhoneContainer>{mobileNumber}</PhoneContainer>
      </ContactContainer>
      <ContactTagCell
        tags={tags}
        allTags={allTags}
        onCreateAndTagContact={onCreateAndTagContact}
        onTagContact={onTagContact}
        onUntagContact={onUntagContact}
        onShowMoreTag={onShowMoreTag}
        onFocusAddTag={onFocusAddTag}

        // isLoadingTag={isLoadingTag} TODO: Correct multiple loading
      />
    </DataContainer>
  </Container>
)

export default ContactDataMobile
