import { Grid } from '@mui/material'
import React from 'react'

import { BackButton, NextButton } from '../buttons/footer-buttons'

export const buttonLayoutChoices = Object.freeze({
  // an enum to sync button layout options between createButtons & the footer's parent renderers
  NEXT: 'next',
  NEXT_BACK: 'next-back',
})

export const createButtons = (
  isMobile,
  pageIndexes,
  progressionHandler,
  buttonLayout,
  textOverrides = {},
  isNextDisabled = false,
) => {
  /* A dynamic handler for all of the Rental Payment page button configurations we might need. */

  const { back: backstepText = 'Back', next: forwardText = 'Next' } = textOverrides

  // default error state
  let renderedButtons = (
    <Grid item xs={1}>
      <p>Button configuration error?</p>
    </Grid>
  )

  // quick & bulky definition for now, we can refactor later
  if (buttonLayout === buttonLayoutChoices.NEXT) {
    renderedButtons = (
      <>
        {/* This grid offset only helps for the desktop view */}
        <Grid item xs={isMobile ? 1 : 9} />
        <Grid item xs={isMobile ? 10 : 2}>
          <NextButton
            onClick={() => progressionHandler({ increment: true })}
            variant="contained"
            disabled={isNextDisabled}
            sx={{
              margin: isMobile ? '0 auto' : 0,
              width: isMobile ? '100%' : 'auto',
            }}
          >
            {forwardText}
          </NextButton>
        </Grid>
        <Grid item xs={1} />
      </>
    )
  } else if (buttonLayout === buttonLayoutChoices.NEXT_BACK) {
    // while this section defines the the layout style, the user might want different text
    renderedButtons = (
      <>
        {/* right: 0 isn't working for BackButton, so an offset Grid
         * is pushing the button away from the edge
         */}
        <Grid item xs={1} />
        <Grid item xs={8}>
          <BackButton onClick={() => progressionHandler({ decrement: true })} variant="text">
            {backstepText || 'Back'}
          </BackButton>
        </Grid>
        <Grid item xs={3}>
          <NextButton
            onClick={() => progressionHandler({ increment: true })}
            variant="contained"
            disabled={isNextDisabled}
          >
            {forwardText || 'Next'}
          </NextButton>
        </Grid>
      </>
    )
  } else {
    console.error(`No button configuration for this state! Layout choice '${buttonLayout}'?`)
  }

  return renderedButtons
}
