import { buildPath } from '@rentspree/path'
import { takeLatest, all, put, call } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'
import { apiInstance } from 'utils/api-interceptor'

import { EVENT_MESSAGE_TYPE } from '../constants'

import { getGiftsApiState, generateGiftLinkApiState } from './actions'
import {
  GET_GIFTS_CALL,
  GET_GIFTS_API,
  GENERATE_GIFT_LINK_CALL,
  GENERATE_GIFT_LINK_API,
  TOAST_BODY_MESSAGE,
} from './constants'

export const getGiftsApi = () => apiInstance.get(buildPath(GET_GIFTS_API))

export function* getGiftsSaga() {
  yield put(getGiftsApiState.request())
  try {
    const response = yield call(getGiftsApi)
    yield put(getGiftsApiState.success(response.data))
  } catch (err) {
    yield put(
      addToast({
        bodyMessage: TOAST_BODY_MESSAGE,
        status: 'error',
        width: '350px',
      }),
    )
    yield put(getGiftsApiState.failure(err))
  }
}

export function* watchGetGifts() {
  yield takeLatest(GET_GIFTS_CALL, getGiftsSaga)
}

export const generateGiftLinkApi = giftId =>
  apiInstance.post(buildPath(GENERATE_GIFT_LINK_API), { giftId })

export function* generateGiftLinkSaga({ payload: { gift, window } }) {
  yield put(generateGiftLinkApiState.request())
  try {
    const response = yield call(generateGiftLinkApi, gift?._id)
    yield put(generateGiftLinkApiState.success(response))
    // eslint-disable-next-line no-param-reassign
    window.data = response
    yield call(window.postMessage, {
      data: response,
      type: EVENT_MESSAGE_TYPE.SEND_GIFT_URL,
    })
  } catch (err) {
    yield put(
      addToast({
        bodyMessage: TOAST_BODY_MESSAGE,
        status: 'error',
        width: '350px',
      }),
    )
    yield put(generateGiftLinkApiState.failure(err))
    // eslint-disable-next-line no-param-reassign
    window.error = err
    yield call(window.postMessage, {
      error: err,
      type: EVENT_MESSAGE_TYPE.SEND_GIFT_URL,
    })
  }
}

export function* watchGenerateGiftLink() {
  yield takeLatest(GENERATE_GIFT_LINK_CALL, generateGiftLinkSaga)
}

export function* rootSaga() {
  yield all([watchGetGifts(), watchGenerateGiftLink()])
}

export default rootSaga
