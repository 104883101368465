import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const Button = styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  color: ${props => props.color};
  padding: 4px 10px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  i {
    font-size: 12px;
  }
`

const StarredButton = ({
  buttonText,
  buttonAction,
  color,
  backgroundColor,
  width,
  height,
  isButtonClicked,
}) => (
  <Button
    width={width}
    height={height}
    onClick={buttonAction}
    color={color}
    borderColor={isButtonClicked ? COLOR.newDeepBlueGradientMid : COLOR.grayLv3}
    backgroundColor={isButtonClicked ? COLOR.lightBlueHEX : backgroundColor}
  >
    <div>
      <i className="fas fa-star" /> {buttonText}
    </div>
  </Button>
)
StarredButton.defaultProps = {
  width: 'max-content',
  color: COLOR.blueLv2,
  backgroundColor: COLOR.grayLv7,
  buttonText: 'Starred',
  buttonAction: () => {},
  isButtonClicked: false,
}
StarredButton.propTypes = {
  isButtonClicked: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
}
export default StarredButton
