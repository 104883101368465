import React from "react"
import isEmpty from "lodash/isEmpty"
import numeral from "numeral"
import { FlexBox } from "../layout/section"
import { H3, P, Text, Hr } from "../typography"
import Input from "../inputs/text-input"
import { Button, ButtonAlike } from "../buttons/buttons"
import * as PATH from "../../constants/route-consts"
import {
  StyledRedeemCouponBox,
  StyledRedeemSuccessBox,
} from "./redeem-coupon-box-style"

export default class RedeemCouponBox extends React.Component {
  constructor() {
    super()
    this.state = {
      couponCode: "",
      errorMessage: null,
    }
  }

  componentWillUnmount() {
    this.props.actions.clearCouponError()
    this.props.actions.clearRedeemCouponData()
  }

  handleChange = (e, value) => {
    this.setState({ couponCode: value })
  }

  clickRedeemCoupon = () => {
    if (isEmpty(this.state.couponCode)) {
      this.setState({ errorMessage: "Coupon cannot be empty." })
    } else {
      this.setState({ errorMessage: null })
      this.props.redeemCoupon(this.state.couponCode)
    }
  }

  render() {
    const { subscription, isLoading, error } = this.props
    const errorMessage = error || this.state.errorMessage
    const isSubscribed = !isEmpty(subscription)
    const { plan } = subscription
    const displayPrice = numeral(
      subscription.unit_amount_in_cents / 100,
    ).format("$0.00")

    return (
      <StyledRedeemCouponBox>
        <H3 size="1.4em" strong>
          Have a coupon?
        </H3>
        <FlexBox align="flex-start" wrap="nowrap" className="coupon-action">
          <Input
            height={45}
            flexGrow={1}
            placeholder="Enter coupon code"
            loading={isLoading}
            disabled={isLoading || isSubscribed}
            error={errorMessage}
            success={isSubscribed}
            maxLength={50}
            value={this.state.coupon}
            onChange={this.handleChange}
          />
          {!isSubscribed ? (
            <Button
              id="redeemCouponBtn"
              className="coupon-btn"
              primary={!isLoading}
              disabled={isLoading}
              text={isLoading ? "Verifying" : "Redeem"}
              leftSpace="20px"
              height="45px"
              minWidth="120px"
              onClick={this.clickRedeemCoupon}
            />
          ) : (
            <ButtonAlike
              className="coupon-btn"
              transparentDarkGreen
              leftSpace="20px"
              height="45px"
              minWidth="120px">
              <Text strong>Success</Text>
            </ButtonAlike>
          )}
        </FlexBox>

        <StyledRedeemSuccessBox isSubscribed={isSubscribed}>
          <H3 strong align="center">
            Congratulations!
          </H3>
          <P align="center">Your RentSpree PRO subscription is now active.</P>
          <Hr margin="20px -10px" />
          <FlexBox justify="space-between">
            <P strong>Subscription plan:</P>
            <P>{plan && plan.name}</P>
          </FlexBox>
          <FlexBox justify="space-between">
            <P strong>Subscription price:</P>
            <P>
              <Text strong color="textGrey" lineThrough>
                {displayPrice}
              </Text>
              <Text strong color="darkGreen" leftSpace="7px">
                $0.00
              </Text>
            </P>
          </FlexBox>
          <br />
          <P strong>Important note:</P>
          <FlexBox justify="space-between" align="center" mCol>
            <P color="textGrey" maxWidth="360px" margin="0" mMargin="0 0 15px">
              When your RentSpree PRO trial ends, your access to RentSpree PRO
              features and to any uploaded documents will be suspended until
              your reactivate your subscription.
            </P>
            <Button
              onClick={() => this.props.history.push(PATH.PRO_PAGE)}
              id="continueCouponLink"
              primary
              text="Continue"
              height="45px"
              minWidth="120px"
            />
          </FlexBox>
        </StyledRedeemSuccessBox>
      </StyledRedeemCouponBox>
    )
  }
}
