import styled from "styled-components"

export const RequestWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 70px);
  overflow-x: hidden;
  position: relative;
`

export const RequestContainer = styled.div`
  width: ${props => (props.width ? props.width : "500px")};
`
