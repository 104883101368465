import { APPLY_FILTER, OPEN_FILTER_MODAL, CLOSE_FILTER_MODAL } from './constants'

export const applyFilter = values => ({
  type: APPLY_FILTER,
  payload: values,
})

export const openFilterModal = () => ({
  type: OPEN_FILTER_MODAL,
})

export const closeFilterModal = () => ({
  type: CLOSE_FILTER_MODAL,
})
