import React from 'react'

import {
  APP_DOCUMENT_STATUS,
  APP_EXPIRED_STATUS,
  APP_SUBMITTED_STATUS,
} from 'containers/application/const'

export const CREATE_PROPERTY = '+ Create/select a property'
export const NO_SPECIFIC_PROPERTY = 'No specific property'

export const TABLE_HEADER = [
  { text: 'Applicant', value: 'applicant', class: 'text-left' },
  { text: 'Property', value: 'property', class: 'text-left' },
  { text: 'Submission date', value: 'submitted_at', class: 'text-left' },
  { text: 'Application progress', value: 'status', class: 'text-left' },
  { text: 'Action', value: '', class: 'text-center' },
]

export const APPLICATION_PROGRESS_TEXT = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  SUBMITTED: 'Submitted',
  SUBMITTED_WITH_DOCS: 'Submitted with docs',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  NOT_INTERESTED: 'Not interested',
  EXPIRED: 'Report expired',
}

export const STATUS_WITH_HIGHLIGHTED_RENTER_NAME = [
  ...APP_SUBMITTED_STATUS,
  ...APP_EXPIRED_STATUS,
  // TODO: consider migrate and remove document_status field from rental submission collection
  APP_DOCUMENT_STATUS.requested_doc.value,
  APP_DOCUMENT_STATUS.partially_uploaded.value,
  APP_DOCUMENT_STATUS.uploaded.value,
]

export const ONE_TIME_POPPER_FOR_INVITED_USER_SEEN_GROUP = 'share_report_one_time_popper_on_ts_page_for_invited_landlord'

export const POPPER_CONTENT = {
  TITLE: (<>Here are your new<br />applications</>),
  SUBTITLE: "Select the applicant's name to review."
}
