import Hr from '@rentspree/component-v2/dist/hr'
import { Icon } from '@rentspree/component-v2/dist/icon'
import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import S16 from '@rentspree/component-v2/dist/typography/s16'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import styled from 'styled-components'

import { GradientOverlay } from 'components/atoms/gradient-overlay-for-mobile-menu'
import GiftBoxIcon from 'components/contacts/atoms/gift-box-icon'
import Tag from 'components/contacts/atoms/tag'
import { Menu as MenuItem } from 'components/molecules/menu-link-with-active-state'
import { CONTACTS as CONTACTS_PATH, CONTACT_GROUP } from 'constants/route'
import { isTagSelected, mapPathToEventProperty } from 'containers/contacts/list/utils'
import { breakpoints, COLOR } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import { CONTACT_GROUP_MENU_LIST } from './constants'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  flex-shrink: 0;
  height: 100%;
  border-right: 1px solid ${COLOR.lineGrey};
  background-color: ${COLOR.white};
  ${breakpoints.mobile(`
    flex-direction: column-reverse;
    width: 100%;
    border-right: 0px;
    border-bottom: 1px solid ${COLOR.borderGrey};
    background-color: transparent;
  `)};
`
const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 253px;
  ${breakpoints.mobile(`
    background-color: ${COLOR.white};
    overflow: auto;
    flex-direction: row;
    height: 43px;
    min-height: 43px;
    padding-left: 15px;
    padding-right: 15px;
  `)};
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  ${breakpoints.mobile(`
    display: none;
  `)};
`

const LineContainer = styled.div`
  padding: 10px 25px 0px 25px;
  ${breakpoints.mobile(`
    display: none;
  `)};
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  margin: 0px 2px;
  padding: 0px 20px;
  ${breakpoints.mobile(`
    margin: 10px 0 0 0;
    padding: 0px;
    flex-direction: row;
    align-items: center;
    width: 100%;
  `)};
`

const CheckBoxContainer = styled.div`
  display: none;
  ${breakpoints.mobile(`
    display: flex;
    padding-top: 6px
  `)};
`

const DeleteTagIcon = styled(Icon)`
  cursor: pointer;
  ${breakpoints.mobile(`
    margin: 0 10px;
  `)};
`

export const DeleteTagIconHorizontal = styled.div`
  display: none;
  ${breakpoints.mobile(`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    margin: 0px;
  `)};
`

const TagPanelContainer = styled.div`
  overflow: auto;
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ bulkState }) => bulkState && 'display: none;'};
  }
`

export const ContactGroupMenu = ({
  data,
  currentGroupPath,
  currentQueryString = '',
  groupCount = {},
  allTags = [],
  selectedTags,
  isLoadingCount,
  isLoadingTags,
  onToggleTagFilter,
  bulkSelected,
  onClickAddSelectedAllBulk,
  bulkState,
  onClickEditTag,
}) => (
  <MainContainer>
    <GroupContainer>
      <GradientOverlay />
      <GradientOverlay right />
      <HeaderContainer>
        <S16 size="12px" color={COLOR.placeholderGrey}>
          Group of Contacts
        </S16>
        {isLoadingCount && <Icon name="loading" spin size="sm" color={COLOR.textBlack} />}
      </HeaderContainer>
      {bulkState && (
        <CheckBoxContainer>
          <Checkbox
            id="header_checkbox"
            simple
            isSelected={data.every(contact => bulkSelected.includes(contact?._id))}
            bgSimpleTransparent
            margin="0"
            onClick={onClickAddSelectedAllBulk}
          />
        </CheckBoxContainer>
      )}
      {CONTACT_GROUP_MENU_LIST.map(menu => (
        <MenuItem
          key={menu.key}
          id={`contact-group-menu-${menu.key}`}
          to={`${CONTACTS_PATH}/${menu.path}${currentQueryString}`}
          onClick={() =>
            tracker.trackEvent(EVENT_PEOPLE_CONCEPT.EVENT_NAME.CLICK_VIEW_CONTACT_GROUPS, {
              group: mapPathToEventProperty(menu.path),
            })
          }
          active={currentGroupPath === menu.path}
          isSubMenu={menu.isSubMenu}
        >
          {menu.label} ({groupCount[menu.key] || 0})
          {menu.key === CONTACT_GROUP.UPCOMING_EVENT && <GiftBoxIcon isShadow={false} />}
        </MenuItem>
      ))}
    </GroupContainer>
    {!isEmpty(allTags) && (
      <>
        <LineContainer>
          <Hr margin="0px" />
        </LineContainer>
        <HeaderContainer>
          <S16 size="12px" color={COLOR.placeholderGrey} margin="0">
            Tags
          </S16>
          {isLoadingTags ? (
            <Icon name="loading" spin size="sm" color={COLOR.textBlack} />
          ) : (
            <DeleteTagIcon name="edit" size="md" color={COLOR.textBlack} onClick={onClickEditTag} />
          )}
        </HeaderContainer>
        <TagPanelContainer bulkState={bulkState}>
          <TagContainer>
            {allTags.map(tag => (
              <Tag
                {...tag}
                key={tag?._id}
                maxWidth="165px"
                margin="0 0 10px 0"
                mMargin="0 5px 10px 0"
                isSelected={isTagSelected(tag?._id, selectedTags)}
                onClick={() => {
                  onToggleTagFilter(tag?._id)
                }}
              />
            ))}
          </TagContainer>
          <DeleteTagIconHorizontal>
            <DeleteTagIcon name="edit" size="md" color={COLOR.textBlack} onClick={onClickEditTag} />
          </DeleteTagIconHorizontal>
        </TagPanelContainer>
      </>
    )}
  </MainContainer>
)
