import get from 'lodash/get'
import { createSelector } from 'reselect'

import { initialState } from './reducer'

export const getReducer = state => get(state, 'contacts.gifts', initialState)

export const selectIsLoadingGiftsFetching = createSelector(getReducer, gifts =>
  get(gifts, 'isLoadingGiftsFetching', initialState.isLoadingGiftsFetching),
)

export const selectGifts = createSelector(getReducer, gifts =>
  get(gifts, 'data', initialState.data),
)

export const selectSelectedGift = createSelector(getReducer, gifts =>
  get(gifts, 'selectedGift', initialState.selectedGift),
)
