import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@rentspree/component-2023.components.atoms.radio'
import RadioGroup from '@rentspree/component-2023.components.atoms.radio-group'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { PaymentsDrawer } from 'v3/containers/overhaul-rent-payment/components/drawer/drawer'
import { TitleText } from 'v3/containers/overhaul-rent-payment/components/text/title-text'
import { PageIndexesContext, PaymentInfoContext } from 'v3/containers/overhaul-rent-payment/context'
import { ClientDetailsDrawer } from 'v3/containers/overhaul-rent-payment/pages/recipient-details/drawer-states/client-details'
import { SelfDetailsDrawer } from 'v3/containers/overhaul-rent-payment/pages/recipient-details/drawer-states/self-details'
import { isEmptyOrNotObject, isObject } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { RECIPIENT_DETAILS } from 'v3/containers/overhaul-rent-payment/text-constants'

export const WideButtonFormControlLabel = styled(FormControlLabel)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 20px',

  border: '2px solid #2222221f',
  borderRadius: '10px',
  backgroundColor: 'white',

  width: '50%',
  maxWidth: '500px',
  minWidth: '350px',
  height: '6rem',

  '&:hover': {
    border: '2px solid #222222',
  },
}))

export const recipientDetailsOptions = Object.freeze({
  ME: 'me',
  CLIENT: 'client',
})

export class RecipientDetails {
  // TODO: simplify, a class/OOP is overkill here
  static recipientInfoValues = {
    requiredKeys: ['firstName', 'lastName'],
    allowedKeys: ['firstName', 'lastName', 'email'],
  }

  static recipientField = 'recipient'

  static recipientInfoField = 'recipient-info'

  constructor({ recipient, recipientInfo }) {
    this.setRecipient(recipient)
    this.setRecipientInfo(recipientInfo)
  }

  static validateRecipient(recipient, allowMissing = true) {
    // short circuit variable instantiation below if we can help it
    if (
      recipient === null ||
      recipient === undefined ||
      (typeof recipient === 'string' && recipient.length === 0)
    ) {
      return allowMissing
    }
    const recipientIsValid = Object.values(recipientDetailsOptions).includes(recipient)

    return recipientIsValid
  }

  static validateRecipientInfo(recipientInfo, allowMissing = true) {
    // short circuit variable instantiation for  below if we can help it
    if (isEmptyOrNotObject(recipientInfo)) {
      return allowMissing
    }
    if (!isObject(recipientInfo)) {
      // if this isn't an object, and it isn't null/undefined, it's an invalid value
      return false
    }

    const infoKeys = Object.keys(recipientInfo)
    const allKeysAllowed = infoKeys.every(val =>
      RecipientDetails.recipientInfoValues.allowedKeys.includes(val),
    )
    const requiredKeysPresent = RecipientDetails.recipientInfoValues.requiredKeys.every(val =>
      infoKeys.includes(val),
    )

    // todo: first/last/email validation can be added here as well

    return allKeysAllowed && requiredKeysPresent
  }

  static validateObject(recipientDetailsObj, allowMissing = true) {
    // short circuit variable instantiation for  below if we can help it
    if (isEmptyOrNotObject(recipientDetailsObj)) {
      return allowMissing
    }
    if (!isObject(recipientDetailsObj)) {
      // if this isn't an object, and it isn't null/undefined, it's an invalid value
      return false
    }

    // validate only expected keys are present, and if 'allowMissing' is false, all keys are present
    const keys = isObject(recipientDetailsObj) ? Object.keys(recipientDetailsObj) : []
    const expectedKeys = [RecipientDetails.recipientField, RecipientDetails.recipientInfoField]
    const keysValid =
      keys.every(val => expectedKeys.includes(val)) &&
      (allowMissing || expectedKeys.every(val => keys.includes(val)))

    // validate recipientData is valid, and if 'allowMissing' is false, all keys are present
    const recipientData = recipientDetailsObj[RecipientDetails.recipientField]
    const recipientValid =
      RecipientDetails.validateRecipient(recipientData) &&
      (allowMissing || recipientData !== undefined)

    const recipientInfoData = recipientDetailsObj[RecipientDetails.recipientInfoField]
    const recipientInfoValid =
      RecipientDetails.validateRecipientInfo(recipientInfoData) &&
      (allowMissing || recipientInfoData !== undefined)

    return keysValid && recipientValid && recipientInfoValid
  }

  setRecipient(recipient) {
    const isValid = RecipientDetails.validateRecipient(recipient)

    if (!isValid) {
      console.error(`Recipient set error, unexpected value '${recipient}'`)
      return
    }

    this.recipient = recipient
  }

  setRecipientInfo(recipientInfo) {
    /* Might be a good candidate for TypeScript interfaces */
    const isValid = RecipientDetails.validateRecipientInfo(recipientInfo)

    if (!isValid) {
      // fallback to empty object if recipientInfo is null/undefined, to avoid errors
      const infoKeys = Object.keys(recipientInfo || {})
      console.error(`Recipient set error, unexpected inputs: '${infoKeys.toString()}'`)
      return
    }

    this.recipientInfo = recipientInfo
  }

  toObject() {
    const representation = {}
    if (this.recipient !== undefined) {
      representation[RecipientDetails.recipientField] = this.recipient
    }
    if (this.recipientInfo !== undefined) {
      representation[RecipientDetails.recipientInfoField] = this.recipientInfo
    }

    return representation
  }
}

export const handleRecipientDetailsProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  /* Returns the page index changes expected for an increment/decrement, based on the current page & data states */

  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }

  // shortcut invalid scenarios
  if (!(decrement || increment) || (increment && decrement)) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Payment Details page progression request')
  } else {
    if (increment) {
      const pageFieldPresent = Object.keys(curPaymentInfo).includes(pageField)
      const recipientDataEntered = RecipientDetails.validateRecipient(
        curPaymentInfo[pageField]?.recipient,
        false,
      )
      const allDataEnteredAndValid = RecipientDetails.validateObject(
        curPaymentInfo[pageField],
        false,
      )
      if (curPageIndexes.drawerOpen && pageFieldPresent && allDataEnteredAndValid) {
        // if the drawer is open & all info is filled out, we can progress to the next page
        indexChanges.pageL1Index += 1
        // drawer resetting should be handled by the smart index progression method, but add safety in redundancy
        indexChanges.drawerOpen = false
      } else if (recipientDataEntered) {
        // if the recipient is selected, and the drawer isn't open, open the drawer
        indexChanges.drawerOpen = true
      }
    }

    if (decrement) {
      // we have no prior page to go back to in this flow, so only the drawer can be closed
      indexChanges.drawerOpen = false
    }
  }
  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  setPageIndexes(indexChanges)
}

export const RecipientDetailsPage = ({ pageField, updateDataHandler }) => {
  const [pageIndexes] = useContext(PageIndexesContext)
  const [paymentInfo] = useContext(PaymentInfoContext)
  // const progressionHandler = useContext(PageProgressionContext)

  const pageInfo = paymentInfo?.[pageField] || {}
  const renterRecipient = pageInfo?.[RecipientDetails.recipientField]
  const renterInfo = pageInfo?.[RecipientDetails.recipientInfoField] || {}

  // don't spam state variable changes & page re-rendering while someone's typing in the drawers
  const setRenterInfoPiece = newRenterInfoPiece => {
    // allow new or existing email to be passed in, but only if the recipient isn't self/ME
    let email = null
    if (renterRecipient === recipientDetailsOptions.CLIENT) {
      const { email: incomingEmail } = newRenterInfoPiece
      email = incomingEmail || renterInfo?.email || null
    }
    updateDataHandler({
      // leave the other non-recipient-info sub-fields for the page's other info alone
      ...pageInfo,
      // change only what we care about
      [RecipientDetails.recipientInfoField]: { ...renterInfo, ...newRenterInfoPiece, email },
    })
  }

  const drawerSelectionObj = {
    [recipientDetailsOptions.CLIENT]: (
      <ClientDetailsDrawer renterInfo={renterInfo} setRenterInfoPiece={setRenterInfoPiece} />
    ),
    [recipientDetailsOptions.ME]: (
      <SelfDetailsDrawer renterInfo={renterInfo} setRenterInfoPiece={setRenterInfoPiece} />
    ),
  }
  const getDrawerContents = () => {
    if (drawerSelectionObj?.[renterRecipient] === undefined) {
      return <Typography level="h3">{RECIPIENT_DETAILS.SELECTION_ERROR}</Typography>
    }
    return drawerSelectionObj[renterRecipient]
  }

  return (
    <>
      <TitleText>{RECIPIENT_DETAILS.TITLE}</TitleText>
      <RadioGroup
        sx={{ alignContent: 'center', padding: 0 }}
        onChange={event => {
          updateDataHandler({ ...pageInfo, recipient: event.target.value })
        }}
      >
        <WideButtonFormControlLabel
          sx={{ width: '100%', height: '5rem', margin: '0px 0px 16px 0px' }}
          control={<Radio checked={renterRecipient === recipientDetailsOptions.ME} />}
          labelPlacement="start"
          label="Me or my business"
          value={recipientDetailsOptions.ME}
        />
        <WideButtonFormControlLabel
          sx={{ width: '100%', height: '5rem', margin: '0px 0px 16px 0px' }}
          control={<Radio checked={renterRecipient === recipientDetailsOptions.CLIENT} />}
          labelPlacement="start"
          label="My client"
          value={recipientDetailsOptions.CLIENT}
        />
      </RadioGroup>
      {/* TODO: ask Jackie if we should be pulling User data & skipping this drawer if possible */}
      <PaymentsDrawer drawerOpen={pageIndexes.drawerOpen}>{getDrawerContents()}</PaymentsDrawer>
    </>
  )
}
