import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Grid, IconButton } from '@mui/material'
import React from 'react'

export const CloseBar = () => {
  return (
    <Grid container spacing={2} padding="2vh 2vw" marginTop="0">
      {/* Something was blocking right: 0/etc. type styling, so we're using grids for 'x' right-alignment */}
      <Grid item xs={10} />
      <Grid item xs={2}>
        {/* TODO: resize -- size="large" and/or fontSize isn't changing the icon's size from default? */}
        <IconButton aria-label="close" size="large">
          <CloseRoundedIcon fontSize="70" />
        </IconButton>
      </Grid>
    </Grid>
  )
}
