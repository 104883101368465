import moment from 'moment'
import {
  APP_STATUS,
  APP_NOT_SUBMIT_STATUS,
  APP_SUBMITTED_STATUS,
  APP_EXPIRED_STATUS,
} from 'containers/application/const'
import { ACCEPT_DENY_TYPE } from 'containers/accept-deny/constants'
import { APPLICATION_PROGRESS_TEXT } from './constants'

export const generateApplicationProgress = (status, screeningResult) => {
  if (status === APP_STATUS.requested.value) return APPLICATION_PROGRESS_TEXT.NOT_STARTED
  if (status === APP_STATUS.not_interested.value) return APPLICATION_PROGRESS_TEXT.NOT_INTERESTED
  if (APP_NOT_SUBMIT_STATUS.includes(status)) return APPLICATION_PROGRESS_TEXT.IN_PROGRESS
  if (screeningResult === ACCEPT_DENY_TYPE.ACCEPT || screeningResult === ACCEPT_DENY_TYPE.ACCEPT_ON_CONDITION) return APPLICATION_PROGRESS_TEXT.ACCEPTED
  if (screeningResult === ACCEPT_DENY_TYPE.DENY) return APPLICATION_PROGRESS_TEXT.DECLINED
  // TODO: document status still not populated to rental, so this status is not available. should improve on backend part
  if (status === APP_STATUS.uploaded.value) return APPLICATION_PROGRESS_TEXT.SUBMITTED_WITH_DOCS
  if (APP_SUBMITTED_STATUS.includes(status)) return APPLICATION_PROGRESS_TEXT.SUBMITTED
  if (APP_EXPIRED_STATUS.includes(status)) return APPLICATION_PROGRESS_TEXT.EXPIRED
  return ''
}

export const generateSubmissionDate = (submittedAt, generatedAt, verifiedIdmaAt) => {
  if (!submittedAt) return ''
  const expiresFrom = generatedAt || verifiedIdmaAt
  if (expiresFrom) {
    const expiresIn = moment(expiresFrom).add(30, 'days').diff(moment(), 'days', true)
    if (expiresIn >= 0 && expiresIn < 1) {
      return 'Report expires today'
    }
    if (expiresIn < 11 && expiresIn >= 1) {
      return `Report expires in ${Math.floor(expiresIn)} days`
    }
  }
  return moment(submittedAt).format('MMMM, D, YYYY')
}

export const isApplicationActionable = (status, screeningResult) => {
  if (status === APP_STATUS.not_interested.value) return false
  if (screeningResult === ACCEPT_DENY_TYPE.DENY) return false

  return true
}
