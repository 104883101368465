import { Box } from '@mui/material'
import Step from '@rentspree/component-2023.components.organisms.step'
import StepLabel from '@rentspree/component-2023.components.organisms.step-label'
import Stepper from '@rentspree/component-2023.components.organisms.stepper'
import React from 'react'

export const RentPaymentStepper = ({ stepTitles, pageIndex, handleClick }) => {
  /*
   * Generates a Rent Payment flow specific stepper, letting the caller specify the titles so that as pages
   * might change, this component can react dynamically.
   *
   * The parent page's state needs to be coordinated between this Stepper & the footer's next/back/etc. buttons,
   * if the stepper is going to allow for navigation at all. Leaving the state variable & setter to the parent scope.
   */

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'calc(100% - 50px)',
        margin: '25px',
        overflowX: 'auto',

        display: 'flex',
        justifyContent: 'flex-start',

        /* removing scrollbar */
        // Internet Explorer 10+
        msOverflowStyle: 'none',
        // Firefox
        scrollbarWidth: 'none',
        // Safari & Chrome
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Stepper
        activeStep={pageIndex}
        /*
         * Stepper sets width to 100% internally, reflecting that here to be transparent.
         * Just note that if we change it here, it will be overridden to 100% w/out an !important marker
         */
        sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '700px', width: '100%' }}
      >
        {stepTitles.map((step, i) => (
          <Step key={step}>
            <StepLabel
              /*
               * 'icon' prop can be customized(?), but not set blank atm:
               *  - https://rentspree.slack.com/archives/C05KNFHSJ5C/p1730098500508989?thread_ts=1729893340.804839&cid=C05KNFHSJ5C
               */
              onClick={e => {
                // classList is a DOMTokenList, which uses .contains() vs an Array.includes()
                const isDisabled = e.currentTarget.classList.contains('Mui-disabled')
                if (handleClick !== undefined && !isDisabled) {
                  // stepper should always be changing the top-level/L1 index
                  handleClick(i)
                }
              }}
            >
              {step}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
