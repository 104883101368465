import generateApiCall from "utils/generate-api-call"
import {
  GET_TENANT_SCREENING_CALL,
  GET_TENANT_SCREENING_REQUEST,
  GET_TENANT_SCREENING_SUCCESS,
  GET_TENANT_SCREENING_FAILURE,
  CLEAR_TENANT_SCREENING,
} from "./constants"

export const getSubmissionParticipantList = (page, filter, search) => ({
  type: GET_TENANT_SCREENING_CALL,
  payload: {
    options: {
      page,
      filter,
      search,
    },
  },
})

export const getTenantScreeningApiState = generateApiCall([
  GET_TENANT_SCREENING_REQUEST,
  GET_TENANT_SCREENING_SUCCESS,
  GET_TENANT_SCREENING_FAILURE,
])

export const clearTenantScreening = () => ({
  type: CLEAR_TENANT_SCREENING,
})
