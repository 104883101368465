import { Icon } from '@rentspree/component-v2/dist/icon'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const Button = styled.button`
  transition: background 0.3s;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 45px;
  left: 33px;
  z-index: 14;
  border-radius: 100%;
  border: none;
  background: ${({ isActive }) =>
    isActive
      ? COLOR.white
      : `linear-gradient(
    90deg,
    ${COLOR.newDeepBlueGradient} 0%,
    ${COLOR.newDeepBlue} 100%
  )`};
  box-shadow: 0px 2px 15px 0px #00000040;
  > div {
    transition: transform 0.3s, color 0.3s;
    transform: ${({ isActive }) => (isActive ? '' : 'rotate(45deg)')};
    color: ${({ isActive }) => (isActive ? COLOR.placeholderGrey : COLOR.white)};
  }
`

const ActionButton = ({ isActive, onClick }) => (
  <Button isActive={isActive} onClick={onClick}>
    <Icon name="close" size="lg" />
  </Button>
)

export default ActionButton
