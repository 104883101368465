import React from "react"
import { Icon } from "@rentspree/component-v2/dist/icon"
import B14 from "@rentspree/component-v2/dist/typography/b14"

import Row from "components/atoms/row"
import { TitleWithSubtitle } from "components/molecules/title-with-subtitle"
import { ERROR_PANEL_MESSAGE } from "constants/error-messages"
import SubtitleWithHr from "components/molecules/subtitle-with-hr"
import CenterWithError from "components/templates/center-with-error-template"
import { RequestWrapper, RequestContainer } from "./create-property-styled"
import { CreatePropertyForm } from "./form"

const errorDetail = (
  <Row flexStart>
    <Icon name="error" size="lg" />
    <B14 weight="600" color="inherit" margin="0 0 0 10px" lineHeight="20px">
      {ERROR_PANEL_MESSAGE.SAVING}
    </B14>
  </Row>
)

export const CreateNewProperty = ({
  title,
  subtitle,
  isError,
  handleCloseError,
  ...props
}) => (
  <CenterWithError
    isError={isError}
    error={isError}
    errorDetail={isError && errorDetail}
    margin="0px"
    widthAlert="100%"
    handleCloseError={handleCloseError}>
    <RequestWrapper>
      <RequestContainer>
        <TitleWithSubtitle title={title} mb="30" mLineHeight="32px" />
        {subtitle && <SubtitleWithHr text={subtitle} />}
        <CreatePropertyForm fullSizeForm {...props} />
      </RequestContainer>
    </RequestWrapper>
  </CenterWithError>
)
