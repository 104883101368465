import DropdownMenuWithCircleHover from '@rentspree/component-v2/dist/menu-dropdown/dropdown-menu-with-circle-hover'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const DotsContainer = styled.div`
  span {
    display: block;
    width: 4px;
    height: 4px;
    margin: 2px auto;
    background-color: ${COLOR.textBlack};
    border-radius: 50%;
  }

  .dropdown-menu {
    position: absolute;
    border-radius: 5px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);

    // triangle
    &:after {
      position: absolute;
      /* top: -11px; */
      right: 6px;
      z-index: 0;
      content: '';
      width: 0;
      height: 0;
      border-bottom: 12px solid ${COLOR.white};
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      filter: drop-shadow(0px -4px 2px rgba(175, 175, 175, 0.5));
    }
  }
`

const Dots = () => (
  <DotsContainer>
    <span />
    <span />
    <span />
  </DotsContainer>
)

const DropdownContainer = styled.div`
  .dropdown-menu > li > a[role='menuitem'] {
    font-size: 16px;
  }
`

const Dropdown = ({ menuItems, disabled }) => (
  <DropdownContainer>
    <DropdownMenuWithCircleHover ToggleComponent={Dots} menuItems={menuItems} disabled={disabled} />
  </DropdownContainer>
)

PropTypes.Dropdown = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
}

export default Dropdown
