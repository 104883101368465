import { buildPath } from "@rentspree/path"
import checkStatus from "./handlers/check-status"
import fetch from "../helpers/fetch-utils"
import {
  GET_CREDIT_REPORT_API_V2,
  PAYMENT_API_V2,
  OPEN_CREDIT_REPORT_API_V2,
} from "../constants/route-consts"

export const getCreditReport = (rentalId = "noId", probing) => bearer => {
  const queryString = probing ? { probing: true } : {}
  const uri = buildPath(GET_CREDIT_REPORT_API_V2, { rentalId }, queryString)
  return fetch(uri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)
}

export const runCreditReport = (rentalId = "") => bearer =>
  fetch(buildPath(OPEN_CREDIT_REPORT_API_V2, { rentalId }), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)

export const getPayment = (rentalId = null, provider) => bearer =>
  fetch(buildPath(PAYMENT_API_V2, { rentalId }, { provider }), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
    },
  }).then(checkStatus)

export const submitPayment = (creditPayment, rentalId = null) => bearer =>
  fetch(buildPath(PAYMENT_API_V2, { rentalId }), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${bearer.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creditPayment),
  }).then(checkStatus)
