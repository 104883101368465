import React from 'react'

import { GiftListPanel, GiftListPanelHeader } from 'components/contacts/molecules/gift-list-panel'
import {
  SuggestGiftFormPanel,
  SuggestGiftFormPanelHeader,
} from 'components/contacts/molecules/suggest-gift-form-panel'
import {
  SuggestGiftSuccessPanel,
  SuggestGiftSuccessPanelHeader,
} from 'components/contacts/molecules/suggest-gift-success-panel'
import ChatPanel from 'components/contacts/organisms/chat-panel'
import ContactPanel from 'components/contacts/organisms/contact-panel'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import {
  CHOOSE_GIFT_PANEL,
  SUGGEST_GIFT_PANEL,
  SUGGEST_GIFT_SUCCESS_PANEL,
  CHAT_PANEL,
} from './constants'

const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT

const ContactPanelContent = ({
  messageField,
  eventType,
  defaultMessage,
  isLoadingSendMessage,
  onClickSendMessage,
  onUpdateSettings,
  setIsContactPanelOpen,
  gifts,
  isClosingContactPanel,
  onClosingContactPanel,
  onClickGiftBox,
  contactPanelContent,
  setContactPanelContent,
}) => {
  const contentType = contactPanelContent?.contentType || ''
  const contentData = contactPanelContent?.data || {}
  switch (contentType) {
    case CHAT_PANEL:
      return (
        <ChatPanel
          isLoading={isLoadingSendMessage}
          firstName={contentData.firstName}
          email={contentData.email}
          imagePlaceHolder={contentData.imagePlaceHolder}
          setIsContactPanelOpen={setIsContactPanelOpen}
          onClickSendMessage={onClickSendMessage}
          onUpdateDefaultMessage={text => {
            onUpdateSettings({ [messageField]: text })
          }}
          eventType={eventType}
          message={defaultMessage}
          lastName={contentData.lastName}
        />
      )
    case CHOOSE_GIFT_PANEL:
      return (
        <ContactPanel
          isClosingPanel={isClosingContactPanel}
          onClosePanel={() => {
            onClosingContactPanel()
            tracker.trackEvent(EVENT_NAME.CLOSE_GIFT_SELECTED_POP_UP, {
              category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
            })
          }}
        >
          <ContactPanel.Header>
            <GiftListPanelHeader contactPanelContent={contentData} />
          </ContactPanel.Header>
          <ContactPanel.Content>
            <GiftListPanel
              gifts={gifts}
              onClickGiftBox={gift => onClickGiftBox({ ...contactPanelContent?.data, gift })}
              onClickSuggestGift={() => {
                setContactPanelContent({
                  ...contactPanelContent,
                  contentType: SUGGEST_GIFT_PANEL,
                })
                tracker.trackEvent(EVENT_NAME.CLICK_SUGGEST_GIFT, {
                  category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
                })
              }}
            />
          </ContactPanel.Content>
        </ContactPanel>
      )
    case SUGGEST_GIFT_PANEL:
      return (
        <ContactPanel
          isClosingPanel={isClosingContactPanel}
          onClosePanel={() => {
            onClosingContactPanel()
            tracker.trackEvent(EVENT_NAME.CANCEL_GIFT_SUGGESTION, {
              category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
            })
          }}
        >
          <ContactPanel.Header>
            <SuggestGiftFormPanelHeader />
          </ContactPanel.Header>
          <ContactPanel.Content>
            <SuggestGiftFormPanel
              onFormSubmitted={() => {
                setContactPanelContent({
                  ...contactPanelContent,
                  contentType: SUGGEST_GIFT_SUCCESS_PANEL,
                })
                tracker.trackEvent(EVENT_NAME.SUBMIT_GIFT_SUGGESTION, {
                  category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
                })
              }}
            />
          </ContactPanel.Content>
        </ContactPanel>
      )
    case SUGGEST_GIFT_SUCCESS_PANEL:
      return (
        <ContactPanel isClosingPanel={isClosingContactPanel} onClosePanel={onClosingContactPanel}>
          <ContactPanel.Header>
            <SuggestGiftSuccessPanelHeader />
          </ContactPanel.Header>
          <ContactPanel.Content>
            <SuggestGiftSuccessPanel
              onBackToGiftOption={() => {
                setContactPanelContent({
                  ...contactPanelContent,
                  contentType: CHOOSE_GIFT_PANEL,
                })
                tracker.trackEvent(EVENT_NAME.BACK_TO_GIFT_OPTIONS, {
                  category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
                })
              }}
            />
          </ContactPanel.Content>
        </ContactPanel>
      )
    default:
      return null
  }
}

export default ContactPanelContent
