import { takeLatest, all, put, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { buildPath } from '@rentspree/path'
import { apiInstance } from 'utils/api-interceptor'
import { SCREENING_NEW_TENANT_ACTION } from 'constants/route'
import { openSweetAlertBaseError } from 'utils/sweet-alert-actions'
import {
  getScreeningPlansApiState,
  getUserPreferenceApiState,
  createScreeningRequestApiState,
} from './actions'
import {
  selectCreateScreeningRequestPayload,
  selectSelectedScreeningOption,
  selectPayerType,
  selectIsAcceptedBackgroundDisclaimer,
} from './selectors'
import {
  GET_SCREENING_PLANS_API,
  GET_SCREENING_PLANS_CALL,
  GET_USER_PREFERENCE_API,
  GET_USER_PREFERENCE_CALL,
  CREATE_SCREENING_REQUEST_API,
  CREATE_SCREENING_REQUEST_CALL,
} from './constants'

export const getScreeningPlansApi = () => apiInstance.get(GET_SCREENING_PLANS_API)

export const getUserPreferenceApi = () => apiInstance.get(GET_USER_PREFERENCE_API)

export const updateUserPreferenceApi = preference =>
  apiInstance.put(GET_USER_PREFERENCE_API, preference)

export const createScreeningRequestApi = body =>
  apiInstance.post(CREATE_SCREENING_REQUEST_API, body)

export function* getScreeningPlansSaga() {
  yield put(getScreeningPlansApiState.request())
  try {
    const response = yield call(getScreeningPlansApi)
    yield put(getScreeningPlansApiState.success(response))
  } catch (err) {
    yield put(getScreeningPlansApiState.failure(err))
  }
}

export function* getUserPreferenceSaga() {
  yield put(getUserPreferenceApiState.request())
  try {
    const response = yield call(getUserPreferenceApi)
    yield put(getUserPreferenceApiState.success(response.result))
  } catch (err) {
    yield put(getUserPreferenceApiState.failure(err))
  }
}

export function* createScreeningRequestSaga({ payload = {} }) {
  yield put(createScreeningRequestApiState.request())
  const screeningRequestPayload = yield select(selectCreateScreeningRequestPayload)
  const selectedScreeningOption = yield select(selectSelectedScreeningOption)
  const payerType = yield select(selectPayerType)
  const isAcceptedBackgroundDisclaimer = yield select(selectIsAcceptedBackgroundDisclaimer)
  try {
    const screeningRequest = yield call(createScreeningRequestApi, {
      ...screeningRequestPayload,
      agentType: payload?.agentType,
    })
    const userPreference = yield call(updateUserPreferenceApi, {
      defaultScreeningOption: selectedScreeningOption,
      payerType,
      isAcceptedBackgroundDisclaimer,
    })
    yield put(createScreeningRequestApiState.success(userPreference.result))
    yield put(
      push(
        buildPath(SCREENING_NEW_TENANT_ACTION, {}, { screeningRequestId: screeningRequest.id }),
        { emails: payload?.emails },
      ),
    )
  } catch (err) {
    yield put(openSweetAlertBaseError(err.status))
    yield put(createScreeningRequestApiState.failure(err))
  }
}

export function* watchGetScreeningPlans() {
  yield takeLatest(GET_SCREENING_PLANS_CALL, getScreeningPlansSaga)
}

export function* watchGetUserPreference() {
  yield takeLatest(GET_USER_PREFERENCE_CALL, getUserPreferenceSaga)
}

export function* watchCreateScreeningRequest() {
  yield takeLatest(CREATE_SCREENING_REQUEST_CALL, createScreeningRequestSaga)
}

export function* rootSaga() {
  yield all([watchGetScreeningPlans(), watchGetUserPreference(), watchCreateScreeningRequest()])
}

export default rootSaga
