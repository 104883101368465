import { all } from 'redux-saga/effects'

import {
  watchCreateAccountSession,
  watchFetchAccountInformation,
  watchUpdateAccountInformation,
} from './services'

export function* identityVerificationRootSaga() {
  yield all([
    watchCreateAccountSession(),
    watchFetchAccountInformation(),
    watchUpdateAccountInformation(),
  ])
}
