import { useExperiment } from "@statsig/react-bindings"
import { useToAgreementPath, useToFullMailingPath } from "hooks"
import { TENANT_SCREENING } from "constants/route"
import { SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT } from "utils/feature-flag/constants"

export const useToAgreementOrMailing = () => {
  const agreementPath = useToAgreementPath()
  const fullMailingPath = useToFullMailingPath()
  const experiment = useExperiment(SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT.VALUE)

  const shouldBeAbleToExperiment = agreementPath || fullMailingPath
  const isSkip = shouldBeAbleToExperiment && experiment.get(SKIP_TU_AGREEMENT_ADDRESS_EXPERIMENT.KEYS.IS_SKIP)
  if(isSkip) return null
  if (agreementPath) {
    return {
      path: agreementPath?.isRedirect ? agreementPath.path : TENANT_SCREENING,
    }
  }
  if (fullMailingPath) {
    return {
      path: fullMailingPath?.isRedirect
        ? fullMailingPath.path
        : TENANT_SCREENING,
    }
  }
  return null
}
