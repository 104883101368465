import React from "react"
import { S14 } from "components/typography"

import tracker from "tracker"
import { getSubscriptionPeriodFromPriceName } from "tracker/tracker-mapper"
import { RENTSPREE_PRO_EVENT } from "tracker/const"
import {
  BillingPeriodButtonSubtitle,
  SwitchToggleButtonWrapper,
  ToggleButton,
  ToggleInput,
} from "./buttons-styled"

const BillingPeriodButton = ({ priceName, subtitle, color }) => {
  const margin = subtitle ? "0" : "auto"
  return (
    <>
      <S14 margin={margin} color={color}>
        {priceName}
      </S14>
      {subtitle && (
        <BillingPeriodButtonSubtitle margin={margin} color={color}>
          {subtitle}
        </BillingPeriodButtonSubtitle>
      )}
    </>
  )
}

export const SwitchToggleButton = ({
  selectedButton,
  setSelectedButton,
  inputName,
  options,
}) => {
  const handleChange = (value, setIsSelectedButton) => {
    tracker.trackEvent(RENTSPREE_PRO_EVENT.BILLING_PERIOD_TOGGLE_BUTTON, {
      page: "rentspree_pro",
      subscription_period: getSubscriptionPeriodFromPriceName(
        value.toLowerCase(),
      ),
    })
    setIsSelectedButton(value)
  }

  return (
    <SwitchToggleButtonWrapper>
      {options.map(option => {
        const { priceName, subtitle, value, className } = option
        const Button = (
          <BillingPeriodButton priceName={priceName} subtitle={subtitle} />
        )
        return (
          <>
            <ToggleInput
              id={className}
              className={className}
              name={inputName || "toggle"}
              value={value}
              type="radio"
              checked={selectedButton === value}
              onChange={event =>
                handleChange(event.target.value, setSelectedButton)
              }
            />
            <ToggleButton for={className}>{Button}</ToggleButton>
          </>
        )
      })}
    </SwitchToggleButtonWrapper>
  )
}
