import React from 'react'
import { compose } from 'redux'
import FullPageModal from '@rentspree/component-v2/dist/full-page-modal'
import BackButton from '@rentspree/component-v2/dist/button/back'
import { TOPBAR_HEIGHT } from 'styles/settings'

import Hr from '@rentspree/component-v2/dist/hr'

import { withRouter } from 'react-router-dom'
import { CreateNewProperty } from './create-property'
import {
  CONTINUE_NEXT_BUTTON_TEXT,
  TITLE,
  TITLE_V2,
  CONTINUE_NEXT_BUTTON_TEXT_V2,
  BACK_BUTTON_TEXT_V2,
  BACK_BUTTON_TEXT,
} from './const'
import { StickyStyled, BackButtonContainer } from './modal-styled'

// TODO: [TECH-15139] Property Address Redesign clean-up remove feature flag and unused component
export const FullModalCreateProperty = ({
  isOpen,
  handleCloseModal,
  handleSubmit,
  isError,
  handleCloseError,
  isCreatingProperty,
  isCreatePropertySuccess,
  isScreeningWithoutProperty = false,
  isVerifyingPropertyAddress,
  onHandleAutoFilledUsedTracker,
  sessionToken,
  setSessionToken,
}) => (
  <FullPageModal
    rootId="#app"
    isOpen={isOpen}
    closeButton={false}
    top={TOPBAR_HEIGHT}
    translateYValue="100%"
    zIndex="4"
  >
    <>
      <StickyStyled>
        <BackButtonContainer>
          <BackButton
            text={isScreeningWithoutProperty ? BACK_BUTTON_TEXT_V2 : BACK_BUTTON_TEXT}
            grayDisabled={false}
            margin="0"
            padding="0"
            downIcon
            semiBold
            size="16px"
            id="BackToDashBoardBtn"
            onClick={handleCloseModal}
          />
        </BackButtonContainer>
        <Hr />
      </StickyStyled>
      <CreateNewProperty
        title={isScreeningWithoutProperty ? TITLE_V2 : TITLE}
        continueText={
          isScreeningWithoutProperty ? CONTINUE_NEXT_BUTTON_TEXT_V2 : CONTINUE_NEXT_BUTTON_TEXT
        }
        hideIconNextButton={!isScreeningWithoutProperty}
        hideBackButton
        handleSubmit={handleSubmit}
        isError={isError}
        handleCloseError={handleCloseError}
        isCreatingProperty={isCreatingProperty || isVerifyingPropertyAddress}
        isCreatePropertySuccess={isCreatePropertySuccess}
        onHandleAutoFilledUsedTracker={onHandleAutoFilledUsedTracker}
        sessionToken={sessionToken}
        setSessionToken={setSessionToken}
      />
    </>
  </FullPageModal>
)

export default compose(withRouter, React.memo)(FullModalCreateProperty)
