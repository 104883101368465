import { Icon } from '@rentspree/component-v2/dist/icon'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { COLOR, breakpoints } from 'styles/settings'

const GlobalStyle = createGlobalStyle`
  ${breakpoints.mobile(`
    html,
    body {
      position: relative;
      overflow: hidden !important;
      height: 100%;
    }
  `)}
`

const Wrapper = styled.div`
  ${breakpoints.mobile(`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      background-color: ${COLOR.black};
      opacity: 0.5;
    }
  `)};
`

const Container = styled.div`
  margin-top: -6px;
  position: absolute;
  right: 0;
  z-index: 2;
  width: 454px;
  height: calc(100vh - 115px);
  background-color: ${COLOR.white};
  box-shadow: 0px 10px 30px 4px rgba(0, 37, 112, 0.15);
  animation-name: ${({ slideOut }) => (!slideOut ? 'slideIn' : 'slideOut')};
  animation-duration: 0.3s;
  border: 1px solid ${COLOR.grayLv4};
  z-index: 12;

  @keyframes slideIn {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }

  @keyframes slideOut {
    from {
      right: 0;
    }
    to {
      right: -100%;
    }
  }

  ${breakpoints.mobile(`
    margin-top: 0px;
    top: 70px;
    width: 100%;
    height: calc(100% - 70px);
    overflow: auto;

    @keyframes slideIn {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }

    @keyframes slideOut {
      from {
        bottom: 0;
      }
      to {
        bottom: -100%;
      }
    }
  `)};
`

const HeaderContainer = styled.div`
  padding: 15px 30px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding-right: 20px;
  background: ${COLOR.white};
  color: ${COLOR.grayLv1};
  font-size: 24px;
  font-weight: 600;
  border-style: none none solid;
  border-width: 1px;
  border-color: ${COLOR.grayLv4};
  ${breakpoints.mobile(`padding: 15px 20px 15px;`)};
`

export const CloseButton = styled.button`
  padding: 0;
  border: none;
  background: transparent;
`

const ContentContainer = styled.div`
  padding: 15px 32px 0px;
  height: calc(100vh - 180px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.mobile(`
    padding: 15px 20px 0px;
    height: calc(100% - 65px);
    overflow-y: visible;
  `)};
`
const CHILD_HEADER_NAME = 'Header'
const CHILD_CONTENT_NAME = 'Content'

const ContactPanel = ({ children, isClosingPanel, className, onClosePanel }) => {
  const ContactPanelRef = React.useRef(null)

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [])

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (ContactPanelRef.current && !ContactPanelRef.current.contains(event.target)) {
        onClosePanel()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClosePanel])

  const header = React.Children.map(children, child =>
    child.type.displayName === CHILD_HEADER_NAME ? child : null,
  )
  const content = React.Children.map(children, child =>
    child.type.displayName === CHILD_CONTENT_NAME ? child : null,
  )

  return (
    <Wrapper>
      <GlobalStyle />
      <Container ref={ContactPanelRef} slideOut={isClosingPanel} className={className}>
        <HeaderContainer>
          {header}
          <CloseButton type="button" onClick={onClosePanel}>
            <Icon size="md" name="close" color={COLOR.grayLv3} />
          </CloseButton>
        </HeaderContainer>
        <ContentContainer>{content}</ContentContainer>
      </Container>
    </Wrapper>
  )
}

const Header = ({ children }) => children
Header.displayName = CHILD_HEADER_NAME
ContactPanel.Header = Header

const Content = ({ children }) => children
Content.displayName = CHILD_CONTENT_NAME
ContactPanel.Content = Content

export default ContactPanel
