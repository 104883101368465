import Button from '@rentspree/component-v2/dist/button'
import { Icon } from '@rentspree/component-v2/dist/icon'
import B14 from '@rentspree/component-v2/dist/typography/b14'
import S24 from '@rentspree/component-v2/dist/typography/s24'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

export const EmptyContactContainer = styled.div`
  background-color: ${COLOR.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const StyledIcon = styled(Icon)`
  margin-right: 5px;
`

export const EmptyContact = ({ title, description, imgSrc, buttonName, buttonIcon, onClick }) => (
  <EmptyContactContainer>
    {imgSrc && <img src={imgSrc} alt="Landing IMG" id="landing-img" />}
    <S24 mt="30">{title}</S24>
    <B14 mb="23">{description}</B14>
    {buttonName && onClick && (
      <Button google small semiBold size="16px" type="submit" onClick={onClick}>
        {buttonIcon && <StyledIcon icon={buttonIcon} size="sm" />}
        {buttonName}
      </Button>
    )}
  </EmptyContactContainer>
)
