import styled from "styled-components"
import { COLOR } from "../../styles/settings"

export const AccordionWrapper = styled.div`
  max-width: ${props => props.maxWidth || '1130px'};
  @media (max-width: 991px) {
    width: 100%;
    padding: 0px 25px;
  }
  margin: auto;
  > .panel-group {
    > .panel {
      margin-top: 15px;
      padding: 0 10px;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      > .panel-heading {
        background: ${COLOR.white};
        padding: 0;

        a {
          &[aria-expanded="true"] {
            i {
              transform: rotate(180deg);
            }
          }
        }
      }
      > .panel-collapse {
        > .panel-body {
          border-top: none;
          padding: 0px 75px 10px 15px;
          @media (max-width: 991px) {
            padding: 12px 40px 12px 5px;
          }
        }
      }
    }
  }
  b,
  strong {
    font-weight: 600;
  }
`

export const PanelHeader = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 25px 75px 25px 15px;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 991px) {
    padding: 20px 40px 20px 5px;
  }
  .active {
    color: #007adf;
  }
  img {
    position: absolute;
    right: 10px;
    top: 25px;
    width: 20px;
    @media (max-width: 991px) {
      right: 15px;
      top: 20px;
    }
  }
`
