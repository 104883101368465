import { SimpleTooltip } from '@rentspree/component-v2/dist'
import Tooltip from '@rentspree/component-v2/dist/tooltip'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import ContactPhoto from 'components/contacts/atoms/contact-photo'
import EnterDoorIcon from 'components/contacts/atoms/enter-door-icon'
import Star from 'components/contacts/atoms/star'
import { ROLE_OPTIONS } from 'containers/contacts/constants'
import { isUpcomingDate } from 'containers/contacts/list/utils'
import { convert } from 'helpers/convert'
import { breakpoints, COLOR } from 'styles/settings'

const Container = styled.div`
  max-width: 100%;
  min-width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 15px 25px 15px 5px;
  box-sizing: border-box;
  ${breakpoints.mobile(`
    padding: 15px 10px 0px 5px;
    border-bottom: none;
  `)};
`

const AvatarContainer = styled.div`
  padding: 0 10px;
`

const NameContainer = styled.div`
  width: 0;
  flex: 1;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  ${breakpoints.mobile(`
    font-size: 18px;
`)};
`

const NameText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ToolTipContainer = styled(Tooltip)`
  text-align: center;
  .tooltiptext {
    width: auto;
    padding: 5px 10px;
    border-radius: 7px;
    background: ${COLOR.textBlack};
    &::before {
      background: ${COLOR.textBlack};
    }
    top: -10px;
    left: calc(50% + 7px);
    p {
      color: ${COLOR.white};
      font-family: Source Sans Pro;
      font-size: 14px;
      font-weight: 600;
      margin: 0px;
    }
  }
  i {
    margin: 0;
    margin-left: 5px;
  }
  .fa-home {
    &::before {
      background: ${COLOR.grayLv1};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .fa-gift {
    &::before {
      background: ${COLOR.grayLv1};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  #tooltipBox {
    padding: 0;
    i {
      margin: 0;
      margin-left: 5px;
      width: auto;
    }
    .fa-home {
      &::before {
        background: ${COLOR.grayLv1};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .fa-gift {
      &::before {
        background: ${COLOR.grayLv1};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
`

const IconContainer = styled.div`
  width: 26px;
  padding: 0;
  padding-left: 5px;
`

const RoleContainer = styled.span`
  display: none;
  margin-left: 10px;
  color: ${COLOR.placeholderGrey};
  font-size: 12px;
  flex-shrink: 0;
  ${breakpoints.mobileContact(`
    display: inline;
  `)};
`

const SimpleTooltipContainer = styled.div`
  #simple-tooltip {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    inset: auto auto 6px 0px !important;
  }
`

const ContactIdentityCell = ({
  imagePlaceholder,
  image,
  firstName,
  middleName,
  lastName,
  role,
  moveInDate,
  birthDate,
  daysTillMoveInAnniversary,
  daysTillMovingIn,
  daysTillBirthday,
  onStarContact,
  starredAt,
}) => {
  const displayName = firstName.length > 20 ? `${firstName.substr(0, 20)}...` : firstName
  const isUpcomingMoveInDate = isUpcomingDate(daysTillMoveInAnniversary)
  const isUpcomingMovingInDate = isUpcomingDate(daysTillMovingIn)
  const isUpcomingBirthDate = isUpcomingDate(daysTillBirthday)

  return (
    <Container>
      <Star isActive={!!starredAt} onClick={onStarContact} />
      <AvatarContainer>
        <ContactPhoto image={image} imagePlaceholder={imagePlaceholder} isDashboard />
      </AvatarContainer>
      <NameContainer>
        <NameText>
          {firstName} {middleName} {lastName}
        </NameText>
        {isUpcomingMoveInDate && (
          <ToolTipContainer
            className="moveInDateTooltip"
            title=""
            iconName="fa fa-home"
            position="TOP"
            bubbleWidth="100px"
          >
            <p>
              {convert(moveInDate, 'dateOfUTC', 'MMM D')} is {displayName}
              ’s move-in anniversary
            </p>
          </ToolTipContainer>
        )}
        {isUpcomingBirthDate && (
          <ToolTipContainer
            className="birthDateTooltip"
            title=""
            iconName="fa fa-gift"
            position="TOP"
            bubbleWidth="100px"
          >
            <p>
              {convert(birthDate, 'dateOfUTC', 'MMM D')} is {displayName}
              ’s Birthday
            </p>
          </ToolTipContainer>
        )}
        {isUpcomingMovingInDate && (
          <SimpleTooltipContainer>
            <SimpleTooltip
              className="movingInDateTooltip"
              position="TOP"
              bubbleWidth="100%"
              color={COLOR.white}
              background={COLOR.grayLv1}
              componentTitle={
                <IconContainer>
                  <EnterDoorIcon />
                </IconContainer>
              }
            >
              <div>
                {convert(moveInDate, 'dateOfUTC', 'MMM D')}, {displayName}{' '}moves-in
              </div>
            </SimpleTooltip>
          </SimpleTooltipContainer>
        )}
      </NameContainer>
      <RoleContainer>
        {role === get(ROLE_OPTIONS.find(({ value }) => value === 'none'), 'label') ? '-' : role}
      </RoleContainer>
    </Container>
  )
}

ContactIdentityCell.propTypes = {
  imagePlaceholder: PropTypes.string,
  image: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
}

export default ContactIdentityCell
