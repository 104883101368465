import { Box, Grid, Link } from '@mui/material'
import Button from '@rentspree/component-2023.components.atoms.button'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { AddPaymentMethodForm } from '@rentspree/payment-component'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import { selectProfile } from 'containers/user/selectors'
import { STRIPE_PUBLIC_KEY } from 'env'
import useMobile from 'hooks/use-mobile'

import {
  usePayee,
  usePayeeProviderAccountInformation,
  usePayoutMethodList,
} from '../../../rent-payment/shared/hooks'
import { API_STATUS } from '../../../rent-payment/shared/redux/constants'
import {
  makeSelectFailedPayoutMethod,
  makeSelectPendingPayoutMethod,
  makeSelectVerifiedPayoutMethod,
} from '../../../rent-payment/shared/redux/selectors/payment-account.selector'

const mobileGridSize = 12
const desktopGridSize = 6

export const STATUS_TAG = {
  PENDING: {
    LABEL: 'Pending verification',
    COLOR: 'yellow',
  },
  FAILED: {
    LABEL: 'Verification failed',
    COLOR: 'red',
  },
  VERIFIED: {
    LABEL: 'Verified',
    COLOR: 'green',
  },
}

export const handleBankAccountInfoProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  _pageField,
  _curPaymentInfo,
) => {
  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }

  // eslint-disable-next-line no-console
  console.log('Progression handler is handling progression...')

  // shortcut invalid scenarios
  if (increment === decrement) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Recipient Details page progression request')
  } else {
    if (increment) {
      indexChanges.pageL1Index += 1
    }
    if (decrement) {
      indexChanges.pageL1Index -= 1
    }
  }

  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  setPageIndexes(indexChanges)
}

export const BankAccountInfoPage = () => {
  const [showReceivingAccountModal, setShowReceivingAccountModal] = useState(false)
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const gridSize = isMobile ? mobileGridSize : desktopGridSize

  const { status: fetchingPayeeStatus } = usePayee()
  const { information: payeeProviderAccount } = usePayeeProviderAccountInformation(
    fetchingPayeeStatus === API_STATUS.SUCCESS,
  )

  const handleAddReceivingAccountError = () => {
    setShowReceivingAccountModal(false)
  }

  const handleAddReceivingAccountComplete = () => {
    setShowReceivingAccountModal(false)
  }

  const handleAddReceivingAccountClose = () => {
    setShowReceivingAccountModal(false)
  }

  const buttonStlyle = {
    width: isMobile ? '100%' : 'auto',
  }

  const textStyle = {
    textAlign: 'left',
  }

  const PaymentMethodBox = ({ paymentMethod, status }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        border: 'thin solid #0000001f',
        borderRadius: '1rem',
        padding: '15px',
        textAlign: 'left',
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '1.6rem',
        }}
      >
        {paymentMethod.bankName} {paymentMethod.lastFourAccountNumber}
      </Typography>
      <Typography
        sx={{
          color: STATUS_TAG[status].COLOR,
          fontSize: '1.4rem',
        }}
      >
        {STATUS_TAG[status].LABEL}
      </Typography>
    </Box>
  )

  const { fetchData: fetchPayoutMethodList } = usePayoutMethodList()

  useEffect(() => {
    fetchPayoutMethodList()
  }, [])

  const userProfile = useSelector(selectProfile)

  const payeeDetail = {
    firstName: payeeProviderAccount?.fullName || userProfile?.fullName,
    email: payeeProviderAccount?.email || userProfile.email,
    phone: userProfile?.phone,
  }

  const payoutMethod = {
    pending: useSelector(makeSelectPendingPayoutMethod()),
    verified: useSelector(makeSelectVerifiedPayoutMethod()),
    failed: useSelector(makeSelectFailedPayoutMethod()),
  }

  const isBankAccountConnected =
    payoutMethod.pending !== undefined ||
    payoutMethod.verified !== undefined ||
    payoutMethod.failed !== undefined

  return (
    <Grid item xs={gridSize}>
      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
        <Typography variant="title" sx={textStyle}>
          Let&#39;s first get your bank info
        </Typography>
        <p style={Object.assign(textStyle, { paddingBottom: '10px' })}>
          Connect the bank account you want payments to be deposited into. You’ll be redirected to
          Stripe.
        </p>
      </Box>
      {isBankAccountConnected ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1vh',
            }}
          >
            <Typography variant="title">Deposit to</Typography>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => setShowReceivingAccountModal(true)}
              sx={{
                color: 'inherit',
              }}
            >
              Change
            </Link>
          </Box>
          {payoutMethod.verified && (
            <PaymentMethodBox paymentMethod={payoutMethod.verified} status="VERIFIED" />
          )}
          {payoutMethod.pending && (
            <PaymentMethodBox paymentMethod={payoutMethod.pending} status="PENDING" />
          )}
          {payoutMethod.failed && (
            <PaymentMethodBox paymentMethod={payoutMethod.failed} status="FAILED" />
          )}
        </Box>
      ) : (
        <Button
          onClick={() => setShowReceivingAccountModal(true)}
          size="small"
          style={buttonStlyle}
          variant="contained"
          color="primary"
          data-testid="connect-bank-account-cta-click"
          id="connect-bank-account-cta-click"
        >
          Connect bank account
        </Button>
      )}

      {showReceivingAccountModal && (
        <AddPaymentMethodForm
          publicKey={STRIPE_PUBLIC_KEY}
          accountType="payee"
          type="bank"
          provider="stripe"
          userDetail={payeeDetail}
          onComplete={handleAddReceivingAccountComplete}
          onError={handleAddReceivingAccountError}
          onClose={handleAddReceivingAccountClose}
        />
      )}
    </Grid>
  )
}
