import { T24 } from "@rentspree/component-v2"
import { SOURCE_SANS_PRO_FONT_FAMILY } from "components/typography"
import styled from "styled-components"

export const StyledT24WithGradientColored = styled(T24)`
  max-width: 231px;
  /* linear new blue */

  background: linear-gradient(90deg, #004bb0 0%, #007adf 100%);
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`

export const ProContentImage = styled.img`
  width: 540px;
  height: 280px;
  margin: 0 0 0 21px;

  @media (max-width: 991px) {
    margin: 0 32px 0 23px;
  }
`