import React from 'react'
import styled from 'styled-components'

import enterDoorFill from 'images/icons/contacts/enter-door-fill.svg'

const Icon = styled.img`
  margin: ${({ margin }) => margin};
`

const EnterDoorIcon = ({ margin }) => <Icon src={enterDoorFill} margin={margin} />

export default EnterDoorIcon
