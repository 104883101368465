import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import FormattedInput from '@rentspree/component-v2/dist/input/formatted'
import TextInput from '@rentspree/component-v2/dist/input/text'

import { Div } from 'components/layout/main'
import ScrollToError from 'components/scroll/scroll-to-error'
import { InputWrapper } from 'components/shared/form'
import { FlexBox } from 'legacy/components/layout/section'
import { TwoInputGroup } from './styled-index'

const PhoneInputForm = ({ mobileLabel, width, values, handleBlur, touched, errors, setFieldValue, gap }) => (
  <InputWrapper width={`${width}px`} ml={gap} bgTransparent>
    <FormattedInput
      formatType="phone"
      id="landlordInformationMobileNumber"
      name="mobileNumber"
      label={mobileLabel}
      value={values?.mobileNumber}
      onValueChange={e => setFieldValue('mobileNumber', e.value)}
      onBlur={handleBlur}
      error={touched?.mobileNumber && errors?.mobileNumber}
    />
  </InputWrapper>
)

const LastNameInputForm = ({ width, values, handleBlur, touched, errors, setFieldValue, gap, handleChange }) => (
  <InputWrapper width={`${width}px`} ml={gap} bgTransparent>
    <TextInput
      name="lastName"
      id="landlordInformationLastName"
      label="Owner Last Name"
      value={values?.lastName}
      onChange={handleChange}
      onBlur={e => {
        e.target.value = e.target.value.trim()
        setFieldValue("lastName", e.target.value)
        handleBlur(e)
      }}
      error={touched?.lastName && errors?.lastName}
    />
  </InputWrapper>
)

const EmailInputForm = ({ width, values, handleBlur, touched, errors, handleChange }) => (
  <InputWrapper width={`${width}px`} bgTransparent>
    <TextInput
      id="landlordInformationEmail"
      name="email"
      label="Email"
      value={values?.email}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched?.email && errors?.email}
    />
  </InputWrapper>
)

const LandlordBasicInfoForm = ({
  margin,
  mMargin,
  width = 480,
  gap = 20,
  ...props
}) => {
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } = props
  const halfWidth = useMemo(() => (Number(width) / 2) - (gap / 2))

  return (
    <Div margin={margin} mMargin={mMargin}>
      <ScrollToError {...props} />
      <FlexBox>
        <TwoInputGroup >
          <InputWrapper width={`${halfWidth}px`} bgTransparent>
            <TextInput
              name="firstName"
              id="landlordInformationFirstName"
              label="Owner First Name"
              value={values?.firstName}
              onChange={handleChange}
              onBlur={e => {
                e.target.value = e.target.value.trim()
                setFieldValue("firstName", e.target.value.trim())
                handleBlur(e)
              }}
              error={touched?.firstName && errors?.firstName}
            />
          </InputWrapper>
          <LastNameInputForm
            width={halfWidth}
            values={values}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            gap={gap}
          />
        </TwoInputGroup>
          <EmailInputForm
            width={width}
            values={values}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
          />
          <PhoneInputForm
            mobileLabel="Phone"
            width={width}
            halfWidth={halfWidth}
            values={values}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
          />
      </FlexBox>
    </Div>
  )
}

LandlordBasicInfoForm.defaultProps = {
  margin: '0 10px',
  mMargin: '0',
}

LandlordBasicInfoForm.propTypes = {
  margin: PropTypes.string,
  mMargin: PropTypes.string,
}

export default LandlordBasicInfoForm
