import { pageIndexesTemplate } from '../context'

export const isObject = obj => typeof obj === 'object' && !Array.isArray(obj)
export const isEmptyOrNotObject = obj => {
  return (
    obj === null ||
    obj === undefined ||
    (isObject(obj) && Object.getOwnPropertyNames(obj).length === 0)
  )
}

export const smartNextIndexesCalc = (
  // nextPageIndexes
  { pageL1Index, pageL2Index, drawerOpen },
  prevPageIndexes,
) => {
  /*
   * start from a default state, and add desired states top-down, so sub-page states are fresh if a parent-page progresses
   *  - i.e. if the top L1 page goes from index 1 to 2, and the L2 sub-page was at index 3, set pageL2Index to 0 so L1 page 2 doesn't start with an offset.
   *  - drawer state is considered "the sub-est sub-page" conceptually, for this method's logic
   *
   *   @param pageL1Index: the top-level (Level 1) page's index, optional if no change is desired
   *   @param pageL2Index: the second level (Level 2) page's index, optional if no change is desired
   *   @param drawerOpen: the Drawer's open/closed state flag, optional if no change is desired
   *   @param curPageIndexes: the complete & current object of all page indexes; required
   *   @return: the desired index states to change to
   */

  // if the caller passes nothing in, catch & set values to the blank-slate template
  const currentPageIndexes = prevPageIndexes || pageIndexesTemplate

  const nextPageIndexes = {
    pageL1Index: 0,
    pageL2Index: 0,
    drawerOpen: false,
  }

  // separate for debugging + line length
  const loopEntries = Array.from(
    // we want this "object" to be ordered, leveraging a quick Map
    new Map([
      ['pageL1Index', pageL1Index],
      ['pageL2Index', pageL2Index],
      ['drawerOpen', drawerOpen],
    ]).entries(),
  )
  // some unhelpful linting here; refactoring this loop would be a good idea later
  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const [key, desiredNextVal] of loopEntries) {
    if (desiredNextVal === undefined) {
      nextPageIndexes[key] = currentPageIndexes[key]
    } else {
      nextPageIndexes[key] = desiredNextVal
      if (desiredNextVal !== currentPageIndexes[key]) {
        // everything "lower level" than this should be reset, so that page level can start fresh; break out of the loop now
        break
      }
    }
  }

  return nextPageIndexes
}
