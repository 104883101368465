import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import get from 'lodash/get'
import React from 'react'
import styled from 'styled-components'

import { ROLE_OPTIONS } from 'containers/contacts/constants'
import { breakpoints, COLOR } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import ContactCell from '../../molecules/contact-cell'
import ContactCellWithCopyText from '../../molecules/contact-cell-with-copy-text'
import ContactIdentityCell from '../../molecules/contact-identity-cell'
import ContactTagCell from '../../molecules/contact-tag-cell'

const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT

const DataContainer = styled.tr`
  height: 60px;
  cursor: pointer;
  &:hover {
    background-color: ${COLOR.bgGrey};
    transition: 0.2s;
  }

  background: ${({ background }) => background};
  ${breakpoints.mobile(`
    display: none;
  `)};
`

const Td = styled.td`
  border-bottom: 1px solid ${COLOR.lineGrey};
`

const CheckboxContainer = styled.td`
  padding-left: 5px;
`

const ContactDataDesktop = ({
  _id,
  email,
  firstName,
  lastName,
  imagePlaceholder,
  mobileNumber,
  lastContactedAt,
  role,
  onClickContactRow,
  moveInDate,
  birthDate,
  daysTillMoveInAnniversary,
  daysTillMovingIn,
  daysTillBirthday,
  tags,
  allTags,
  bulkSelected,
  onCreateAndTagContact,
  contactId,
  onToggleSelectedBulk,
  onTagContact,
  onUntagContact,
  onShowMoreTag,
  onStarContact,
  starredAt,
  bulkState,
  onFocusAddTag,
  isUnseen,
  onCopyContactDetail,
}) => (
  <DataContainer onClick={onClickContactRow} background={isUnseen ? COLOR.blueLv7 : COLOR.white}>
    {bulkState && (
      <CheckboxContainer>
        <Checkbox
          id="contact_checkbox"
          simple
          isSelected={bulkSelected.includes(_id)}
          bgSimpleTransparent
          onClick={onToggleSelectedBulk}
        />
      </CheckboxContainer>
    )}
    <Td>
      <ContactIdentityCell
        imagePlaceholder={imagePlaceholder}
        firstName={firstName}
        lastName={lastName}
        moveInDate={moveInDate}
        birthDate={birthDate}
        daysTillMoveInAnniversary={daysTillMoveInAnniversary}
        daysTillMovingIn={daysTillMovingIn}
        daysTillBirthday={daysTillBirthday}
        onStarContact={onStarContact}
        starredAt={starredAt}
      />
    </Td>

    <ContactCellWithCopyText
      text={email}
      type="Email"
      onCopyContactDetail={e => {
        tracker.trackEvent(EVENT_NAME.COPY_CONTACT_EMAIL, {
          email_address: email,
          category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
        })
        onCopyContactDetail(e)
      }}
    />
    <ContactCellWithCopyText
      text={mobileNumber}
      type="Phone number"
      onCopyContactDetail={e => {
        tracker.trackEvent(EVENT_NAME.COPY_CONTACT_PHONE_NUMBER, {
          category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
        })
        onCopyContactDetail(e)
      }}
    />

    <ContactCell text={lastContactedAt} />
    <ContactCell
      text={
        role ===
        get(
          ROLE_OPTIONS.find(({ value }) => value === 'none'),
          'label',
        )
          ? '-'
          : role
      }
    />
    <ContactTagCell
      contactId={contactId}
      tags={tags}
      allTags={allTags}
      onCreateAndTagContact={onCreateAndTagContact}
      onTagContact={onTagContact}
      onUntagContact={onUntagContact}
      onShowMoreTag={onShowMoreTag}
      onFocusAddTag={onFocusAddTag}
    />
  </DataContainer>
)

export default ContactDataDesktop
