import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CopySvg from 'images/icons/contacts/copy-v2.svg'
import { breakpoints, COLOR } from 'styles/settings'

const Cell = styled.td`
  padding-right: 25px;
  border-bottom: 1px solid ${COLOR.lineGrey};
  box-sizing: border-box;
  ${breakpoints.mobile(`
     border-bottom: none;
  `)};
`

const Container = styled.span`
  margin-left: 15px;
`

const CopyableContainer = styled.div`
  margin-left: 15px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 110%;
  &:hover {
    margin-left: 0px;
    border-radius: 14.5px;
    background-color: ${COLOR.grayLv4};
    padding: 5px 30px 5px 15px;
    .copy-icon {
      display: inline;
    }
  }
`

const TextContainer = styled.span`
  text-overflow: ellipsis;
`

const IconContainer = styled.span`
  position: absolute;
  right: 15px;
`

const CopyIcon = styled.img`
  display: none;
  width: 12px;
  height: 12px;
  margin-top: -2px;
  margin-left: 5px;
  cursor: pointer;
  color: ${COLOR.activeBlue};
`

const ContactCellWithCopyText = ({ text, type, onCopyContactDetail }) => {
  const EMPTY_VALUE = '-'
  const handleCopyContact = e => {
    if (text !== EMPTY_VALUE) {
      e.stopPropagation()
      navigator.clipboard.writeText(text)
      onCopyContactDetail(type)
    }
  }

  return (
    <Cell>
      {text !== EMPTY_VALUE ? (
        <CopyableContainer onClick={handleCopyContact}>
          <TextContainer>{text}</TextContainer>
          <IconContainer>
            {text !== EMPTY_VALUE && <CopyIcon src={CopySvg} className="copy-icon" />}
          </IconContainer>
        </CopyableContainer>
      ) : (
        <Container>{text}</Container>
      )}
    </Cell>
  )
}

ContactCellWithCopyText.prototype = {
  text: PropTypes.string,
}

export default ContactCellWithCopyText
