/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { MainContent } from "components/layout/main"
import * as PATH from "../../../constants/route-consts"
import {
  Section,
  SectionContainer,
  SectionHeader,
  SectionLogo,
  FlexCol,
  FlexBox,
  IconContentRow,
  IconContentCol,
} from "../../../components/layout/section"
import { H1, H3, H4, Text, P } from "../../../components/typography"
import { Link } from "../../../components/buttons/buttons"
import { TrackerInstance as Tracker } from "../../../helpers/tracker"
import { trackAndRedirect } from "../../../helpers/track-and-redirect"
import FaqAccordion from "../../../components/panel/faq-accordion"
import ModalConnect from "../../static-page/getting-started-connect"
import { INFO_PAGE } from "./wording"

@ModalConnect
export default class ArcInfoPage extends React.Component {
  openRestrictionsModal = () => {
    Tracker.trackEvent("clickArcsProRestrictionsModal")
    this.props.actions.toggleProRestrictionsModal(true)
  }

  render() {
    const { history } = this.props
    const { authorize } = this.props.subscriptions
    return (
      <MainContent className="-w100">
        {/* -- SPLASH -- */}
        <Section className="splash refChecks">
          <SectionLogo />
          {authorize.isExpires ? (
            <FlexCol className="splash-content" col="2" noMargin mCenter>
              <H1 size="2.6em" margin="0 0 15px" strong>
                We&apos;re sad to see you go
              </H1>
              <H3 size="1.6em" light margin="0" mCenter>
                You have lost access to all RentSpree PRO features.
              </H3>
              <Link
                id="arcsReactivateLink"
                onClick={() =>
                  trackAndRedirect(
                    "clickArcReactivate",
                    PATH.SUBSCRIPTION_PAYMENT,
                    history,
                  )
                }
                primary
                width="230px"
                padding="10px"
                margin="20px 0 10px">
                <Text size="1.2em">Reactivate Now</Text>
              </Link>
              <H4 mCenter mMargin="15px auto">
                Only $9.99/month
              </H4>
            </FlexCol>
          ) : (
            <FlexCol className="splash-content" col={2} noMargin mCenter>
              <H1 size="2.6em" mSize="2.2em" margin="0 0 15px" strong>
                {INFO_PAGE.splash.title}
              </H1>
              <H3 size="1.6em" light margin="0" mCenter>
                {INFO_PAGE.splash.subtitle}
              </H3>
              <Link
                id="arcsTryFreeLink"
                onClick={() =>
                  trackAndRedirect(
                    "clickArcsTryFreeLink",
                    PATH.SUBSCRIPTION_PAYMENT,
                    history,
                  )
                }
                primary
                width="230px"
                padding="10px"
                margin="20px 0 10px">
                <Text size="1.2em">{INFO_PAGE.splash.button}</Text>
              </Link>
              <H4 mCenter mMargin="15px auto">
                {INFO_PAGE.splash.promotion}
                <Link
                  id="arcsHaveCouponCodeLink"
                  onClick={() =>
                    trackAndRedirect(
                      "clickArcsHaveCouponCodeLink",
                      PATH.SUBSCRIPTION_PAYMENT,
                      history,
                    )
                  }
                  hoverWhiteText
                  underlineTextLink
                  leftSpace="7px">
                  {INFO_PAGE.splash.coupon}
                </Link>
              </H4>
              <P size="15px">
                <Link
                  id="proOpenRestrictionsModalLink"
                  onClick={this.openRestrictionsModal}
                  inlineLink
                  text={INFO_PAGE.splash.restriction}
                />
              </P>
            </FlexCol>
          )}
        </Section>
        {/* -- FEATURES -- */}
        <Section padding="40px 15px 60px" className="diamondBg">
          <SectionContainer>
            <SectionHeader
              title={INFO_PAGE.feature.header.title}
              subtitle={INFO_PAGE.feature.header.subtitle}
            />
            <FlexBox mCol justify="space-between">
              <IconContentCol
                col={2}
                icon={{
                  src: INFO_PAGE.feature.one.icon,
                  alt: INFO_PAGE.feature.one.title,
                }}
                title={INFO_PAGE.feature.one.title}
                content={INFO_PAGE.feature.one.content}
              />
              <IconContentCol
                col={2}
                icon={{
                  src: INFO_PAGE.feature.two.icon,
                  alt: INFO_PAGE.feature.two.title,
                }}
                title={INFO_PAGE.feature.two.title}
                content={INFO_PAGE.feature.two.content}
              />
            </FlexBox>
          </SectionContainer>
        </Section>
        {/* -- REFERENCES -- */}
        <Section padding="40px 15px">
          <SectionContainer>
            <SectionHeader
              maxWidth="100%"
              title={INFO_PAGE.reference.header.title}
            />
            <FlexBox>
              {INFO_PAGE.reference.refs.map(ref => (
                <IconContentRow
                  col={2}
                  icon={{ src: ref.icon, alt: ref.title }}
                  title={ref.title}
                  titleSize="1.4em"
                  titleStrong
                  content={ref.subtitle}
                  className="references"
                />
              ))}
            </FlexBox>
          </SectionContainer>
        </Section>
        {/* -- FAQS -- */}
        <Section padding="40px 15px 30px">
          <SectionContainer>
            <SectionHeader title={INFO_PAGE.faq.header.title} />
            <FaqAccordion name="refChecksFaq" />
          </SectionContainer>
        </Section>
      </MainContent>
    )
  }
}
