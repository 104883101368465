import { createBrowserHistory } from "history"
import get from "lodash/get"

import { DASHBOARD } from "../legacy/constants/route-consts"

const history = createBrowserHistory({ basename: DASHBOARD })

export const refreshHistory = createBrowserHistory({
  basename: DASHBOARD,
  forceRefresh: true,
})

export const handleGoBackByDefault = (lastLocation = null, _history = {}) => {
  const from = get(_history, "location.state.from", undefined)
  if (from) {
    history.push({ ...lastLocation, state: { from } })
  } else if (lastLocation) {
    history.goBack()
  } else {
    history.push("/")
  }
}

export default history
