import styled from "styled-components"

export const UserRoleForPropertyFormWrapper = styled.div`
    width: ${props => (props.width ? props.width : "100%")};
    @media (max-width: 991px) {
        width: 100%;
    }
`

export const FormWrapper = styled.div`
    width: ${props => (props.width ? props.width : "100%")};
    margin: ${props => props.margin};
    @media (max-width: 991px) {
        width: 100%;
        margin: 0 0 30px 0;
    }
`
