import B16 from '@rentspree/component-v2/dist/typography/b16'
import React from 'react'
import styled from 'styled-components'

import GiftBox from 'components/contacts/atoms/gift-box'
import Arrow from 'images/icons/left-menu/arrow.svg'
import { COLOR } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

const { EVENT_NAME } = EVENT_PEOPLE_CONCEPT

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const GiftTopic = styled.div`
  margin-bottom: 2.5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLOR.grayLv1};
`

const GiftBoxContainer = styled.div`
  margin: 0px -7.5px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const SuggestGiftContainer = styled(B16)`
  color: ${COLOR.activeBlue};
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 7.5px 0px;
`

const SuggestGiftButton = styled.span`
  cursor: pointer;
`

const FooterContainer = styled(B16)`
  padding-bottom: 20px;
  line-height: 16px;
`

export const LinkContainer = styled.span`
  cursor: pointer;
  color: ${COLOR.activeBlue};
  display: flex;
  padding-left: 3px;
`

const HeaderText = styled.span`
  color: ${COLOR.grayLv2};
  font-weight: bold;
`
const ArrowContainer = styled.div`
  transform: ${props => (props.rotate ? `rotate(270deg)` : `rotate(90deg)`)};
  transition-duration: 0.2s;
`
const ReadMoreText = styled.div`
  padding-right: 10px;
  font-weight: bold;
`
const WrapFooter = styled.div`
  display: flex;
`
const Content = ({ gifts, onClickGiftBox, onClickSuggestGift }) => {
  const [isSendGiftFootnoteOpen, setIsSendGiftFootnoteOpen] = React.useState(false)
  const messagesEndRef = React.useRef(null)
  const scrollToBottom = () => {
    if (isSendGiftFootnoteOpen) {
      messagesEndRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }
  React.useEffect(scrollToBottom, [isSendGiftFootnoteOpen])

  const handleOpeningSendGiftFootnote = () => {
    if (!isSendGiftFootnoteOpen) {
      tracker.trackEvent(EVENT_NAME.READ_MORE_AFFILIATE_DISCLOSURE)
    } else {
      tracker.trackEvent(EVENT_NAME.HIDE_AFFILIATE_DISCLOSURE)
    }
    setIsSendGiftFootnoteOpen(!isSendGiftFootnoteOpen)
  }
  return (
    <ContentContainer>
      <div>
        <GiftTopic>Popular gifts</GiftTopic>
        <GiftBoxContainer>
          {gifts.map(gift => (
            <GiftBox
              key={gift?._id}
              name={gift?.name}
              imageUrl={gift?.image}
              onClick={() => onClickGiftBox(gift)}
            />
          ))}
        </GiftBoxContainer>
        <SuggestGiftContainer onClick={onClickSuggestGift}>
          <SuggestGiftButton>Suggest gift options</SuggestGiftButton>
        </SuggestGiftContainer>
      </div>
      <FooterContainer size="12px">
        <WrapFooter>
          <HeaderText>Affiliate Disclosure </HeaderText>
          <LinkContainer onClick={handleOpeningSendGiftFootnote}>
            <ReadMoreText>{!isSendGiftFootnoteOpen ? 'Read More' : 'Hide'}</ReadMoreText>

            <ArrowContainer rotate={isSendGiftFootnoteOpen}>
              <img src={Arrow} alt="" />
            </ArrowContainer>
          </LinkContainer>
        </WrapFooter>
        <div ref={messagesEndRef} id="scroll-to-bottom">
          {isSendGiftFootnoteOpen &&
            'This affiliate disclosure details affiliate relationships that RentSpree has with other companies and products. rentspree.com is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. Pages on this site may include affiliate links to Amazon and its affiliate sites on which the owners of this website will make a referral commission.'}
        </div>
      </FooterContainer>
    </ContentContainer>
  )
}

export default Content
