import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import S18 from '@rentspree/component-v2/dist/typography/s18'
import React from 'react'
import styled from 'styled-components'

import SuggestGiftImg from '../../../../images/contacts/suggest-gift.svg'

const Image = styled.img`
  margin-top: 35px;
  max-width: 156px;
  height: auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BackButton = styled(BlueGradientButton)`
  width: 189px;
  border-radius: 20px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  font-family: Source Sans Pro, sans-serif;
`

const Content = ({ onBackToGiftOption }) => (
  <ContentWrapper>
    <Image src={SuggestGiftImg} alt="suggest-gift-success" />
    <S18>Thank you for your suggestion!</S18>
    <BackButton onClick={onBackToGiftOption}>Back To Gift Options</BackButton>
  </ContentWrapper>
)

export default Content
