import { query } from '@rentspree/path'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import Filter from 'components/contacts/atoms/filter'
import StarredButton from 'components/contacts/atoms/starred-button'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import { withSaga } from './connect'
import {
  UPCOMING_DATE_OPTION,
  UPCOMING_EVENT_OPTION,
  DATE_TYPE_KEYS,
  EVENT_TYPE_KEYS,
  APPLY_QUICK_FILTER,
} from './constants'

const {
  CLICK_APPLY_UPCOMING_FILTER_EVENT_TYPE,
  CLICK_APPLY_UPCOMING_FILTER_DATE,
  CLICK_APPLY_UPCOMING_FILTER_EVENT_STARRED,
} = EVENT_PEOPLE_CONCEPT.EVENT_NAME
const { QUICK_FILTER, CATEGORY } = EVENT_PEOPLE_CONCEPT.EVENT_PROPERTY

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0;
  justify-content: start;
  > * {
    margin: 0 8px;
  }
`
export const QuickFilterContact = ({ setSelectedEvent, currentQueryString }) => {
  const filter = query.parse(currentQueryString)
  const dispatch = useDispatch()
  const [starSelected, setStarSelected] = React.useState(filter?.starred === String(true) || false)
  const onStarClick = () => {
    triggerApplyQuickFilter({
      starred: String(!starSelected),
    })
    setStarSelected(!starSelected)
    tracker.trackEvent(CLICK_APPLY_UPCOMING_FILTER_EVENT_STARRED, {
      filter_name: QUICK_FILTER.STARRED,
      category: CATEGORY.PEOPLE_CONCEPT,
    })
  }

  const triggerApplyQuickFilter = values => dispatch({ type: APPLY_QUICK_FILTER, payloads: values })

  const onChangeEventType = values => {
    setSelectedEvent(values?.label)
    triggerApplyQuickFilter({ eventType: values?.value })
    tracker.trackEvent(CLICK_APPLY_UPCOMING_FILTER_EVENT_TYPE, {
      filter_name: values?.trackName,
      category: CATEGORY.PEOPLE_CONCEPT,
    })
  }

  const onChangeDateType = values => {
    triggerApplyQuickFilter({ dateType: values?.value })
    tracker.trackEvent(CLICK_APPLY_UPCOMING_FILTER_DATE, {
      filter_name: values?.trackName,
      category: CATEGORY.PEOPLE_CONCEPT,
    })
  }

  return (
    <FilterContainer>
      <Filter
        width="106px"
        callback={onChangeEventType}
        options={UPCOMING_EVENT_OPTION}
        defaultOption={find(UPCOMING_EVENT_OPTION, item => item.value === EVENT_TYPE_KEYS.ALL)}
        previousSelected={filter?.eventType}
      />
      <Filter
        width="106px"
        callback={onChangeDateType}
        options={UPCOMING_DATE_OPTION}
        defaultOption={find(
          UPCOMING_DATE_OPTION,
          item => item.value === DATE_TYPE_KEYS.THIRTY_DAYS,
        )}
        previousSelected={filter?.dateType}
      />
      <StarredButton width="106px" isButtonClicked={starSelected} buttonAction={onStarClick} />
    </FilterContainer>
  )
}
QuickFilterContact.propTypes = {
  setSelectedEvent: PropTypes.func.isRequired,
  currentQueryString: PropTypes.string,
}
export default compose(withSaga)(QuickFilterContact)
