import { useDispatch, useSelector } from 'react-redux'

import { useInjectReducer } from 'utils/inject-reducer'
import { useInjectSaga } from 'utils/inject-saga'
import {
  createAccountSession,
  resetAccountSession,
} from 'v3/containers/identity-verification/actions'
import { KEY, identityVerificationReducer } from 'v3/containers/identity-verification/reducers'
import { identityVerificationRootSaga } from 'v3/containers/identity-verification/sagas'
import {
  makeSelectAccountSessionData,
  makeSelectAccountSessionErrors,
  makeSelectAccountSessionStatus,
} from 'v3/containers/identity-verification/selectors'

export const useIdentityVerificationAccountSession = () => {
  const dispatch = useDispatch()

  useInjectSaga({ key: KEY.IDENTITY_VERIFICATION, saga: identityVerificationRootSaga })
  useInjectReducer({ key: KEY.IDENTITY_VERIFICATION, reducer: identityVerificationReducer })

  const data = useSelector(makeSelectAccountSessionData())
  const errors = useSelector(makeSelectAccountSessionErrors())
  const status = useSelector(makeSelectAccountSessionStatus())

  return {
    createAccountSession: payload => dispatch(createAccountSession(payload)),
    data,
    errors,
    status,
    resetAccountSession: () => dispatch(resetAccountSession()),
  }
}
