import Button from '@rentspree/component-v2/dist/button'
import { BlueGradientButton } from '@rentspree/component-v2/dist/button/gradient'
import React from 'react'
import styled from 'styled-components'

import Dropdown from 'components/contacts/atoms/dropdown'
import FilterButton from 'components/contacts/molecules/filter-button'
import MobileFloatingAction from 'components/contacts/molecules/mobile-floating-action'
import Search from 'components/contacts/molecules/search'
import { breakpoints, COLOR } from 'styles/settings'

const showOnMobileStyle = `
display: none;
 ${breakpoints.mobile(`
  display: flex;
 `)}
`

const showOnDesktopStyle = `
display: flex;
 ${breakpoints.mobile(`
  display: none;
 `)}
`

const Container = styled.div`
  flex-grow: 0;
  align-items: center;
  margin-bottom: 10px;
  ${({ mobile }) => mobile && showOnMobileStyle};
  ${({ desktop }) => desktop && showOnDesktopStyle};
  ${breakpoints.mobile(`
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    background-color: ${COLOR.bgDarkGrey};
    border-bottom: 1px solid ${COLOR.newGrey};
  `)};
`

const StyledDropdownWrapper = styled.div`
  display: inline;
  > div {
    > .dropdown-toggle {
      background-color: transparent;
      > div {
        > span {
          width: 4px;
          height: 4px;
          background-color: ${COLOR.textBlack};
        }
      }
    }
  }
`

const StyledGradientButtonWrapper = styled.div`
  display: inline-flex;
  > button {
    padding: 0 23.5px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    &:first-child {
      margin-right: 10px;
    }
  }
  ${breakpoints.mobile(`
    display: none;
  `)};
`

const ContactDashboardTopPanel = ({
  mobile,
  desktop,
  currentFilterCount,
  currentSearchValue,
  isDisableAddNewContact,
  isDisableAllFeature,
  hideContactButton,
  handleOpenFilterModal,
  onSearch,
  onClickCreateContact,
  onClickImportContact,
  onClickDeleteContact,
  onClickToBulkAddTag,
  onClickRequestApplication,
}) => {
  const addTagsMenu = {
    label: 'Add tags',
    onClick: () => {
      onClickToBulkAddTag()
    },
  }
  const deleteContactsMenu = {
    label: 'Delete',
    onClick: () => {
      onClickDeleteContact()
    },
  }
  const requestApplicationMenu = {
    label: 'Request Application',
    onClick: () => {
      onClickRequestApplication()
    },
  }
  const menuItems = [requestApplicationMenu, addTagsMenu, deleteContactsMenu]

  const handleDisplayContactMobile = () => {
    if (hideContactButton) {
      return <></>
    }
    if (!isDisableAddNewContact && !isDisableAllFeature) {
      return (
        <MobileFloatingAction
          onClickCreateContact={onClickCreateContact}
          onClickImportContact={onClickImportContact}
        />
      )
    }
    return <></>
  }

  return (
    <Container mobile={mobile} desktop={desktop}>
      <Search
        disabled={isDisableAllFeature}
        initialValue={currentSearchValue}
        onSearch={onSearch}
      />
      <FilterButton
        disabled={isDisableAllFeature}
        onClick={handleOpenFilterModal}
        currentFilterCount={currentFilterCount}
      />
      <StyledDropdownWrapper>
        <Dropdown disabled={isDisableAllFeature} menuItems={menuItems} />
      </StyledDropdownWrapper>
      {handleDisplayContactMobile()}
    </Container>
  )
}

export default ContactDashboardTopPanel
