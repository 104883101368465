import { BlueGradientButton } from '@rentspree/component-v2'
import { Icon } from '@rentspree/component-v2/dist/icon'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const StyledGradientButtonWrapper = styled.div`
  vertical-align: middle;
  > button {
    width: ${props => (props.width ? props.width : '86px')};
    height: ${props => (props.height ? props.height : '24px')};
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }
`

export const LoadingContainer = styled(StyledGradientButtonWrapper)`
  cursor: default;
`

const SmallButton = ({ gradient, isLoading, text, width, onClick, disabledButton }) => {
  if (isLoading) {
    return (
      <LoadingContainer google>
        <Icon name="loading" color={COLOR.textBlack} size="md" spin />
      </LoadingContainer>
    )
  }

  return (
    <StyledGradientButtonWrapper width={width} gradient={gradient}>
      <BlueGradientButton
        id="smallButton"
        gradient={gradient}
        onClick={onClick}
        disabled={disabledButton}
      >
        {text}
      </BlueGradientButton>
    </StyledGradientButtonWrapper>
  )
}

SmallButton.defaultProps = {
  disabledButton: false,
}

SmallButton.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  disabledButton: PropTypes.bool,
  gradient: PropTypes.oneOf(['blue', 'red', 'yellow', 'secondary']),
}

export default SmallButton
