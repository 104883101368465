import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const CounterBadgeWrapper = styled.div`
  background: ${({ backgroundColor }) => backgroundColor || COLOR.steelBluePale};
  color: ${COLOR.cyanBlue};
  border-radius: 50%;
  min-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  font-family: Source Sans Pro;
`

const CounterBadge = ({ backgroundColor, number, onClick }) => (
  <CounterBadgeWrapper backgroundColor={backgroundColor} onClick={onClick}>
    {`+${number}`}
  </CounterBadgeWrapper>
)

export default CounterBadge

CounterBadge.propTypes = {
  number: PropTypes.number,
}
