import get from "lodash/get"

export const getTotalPrice = priceScheme =>
  Object.keys(priceScheme)
    .map(
      reportType =>
        get(priceScheme, [reportType, "price"], 0) *
        get(priceScheme, [reportType, "isSelected"], false),
    )
    .reduce((prev, curr) => prev + curr, 0)
