import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import giftsReducer from '../gifts/reducer'
import giftsSaga from '../gifts/saga'

import reducer from './reducer'
import saga from './saga'

export const withSaga = injectSaga({ key: 'contacts.list', saga })

export const withReducer = injectReducer({
  key: 'contacts.list',
  reducer,
})

export const withGiftSaga = injectSaga({
  key: 'contacts.gifts',
  saga: giftsSaga,
})

export const withGiftReducer = injectReducer({
  key: 'contacts.gifts',
  reducer: giftsReducer,
})
