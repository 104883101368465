import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import tracker from 'tracker'
import { useQueryStrings } from 'hooks'

import { TenantScreeningRequestStepOptionTemplate } from 'components/tenant-screening-request-step-option'
import CriminalDisclaimerModal from 'components/modal/criminal-disclaimer-modal'
import { TENANT_SCREENING } from 'constants/route'
import { EVENT_ENTER_SELECT_OPTION_PAGE, EVENT_REQUEST_SCREENING } from 'legacy/tracker/const'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { APP_TYPES } from 'containers/application/const'
import { useToAgreementOrMailing } from './use-to-agreement-or-mailing'

export const RequestStepOptions = ({
  actions,
  priceScheme,
  payerType,
  isFetching,
  isSubmitting,
  isAcceptedBackgroundDisclaimer,
  subdomain,
  documentProOption,
  refCheckProOption,
  history,
  userPlan,
  createScreeningRequestPayload,
  isFirstTimeUser,
}) => {
  const [showModal, setShowModal] = useState(false)
  const agreementOrMailing = useToAgreementOrMailing()

  if (agreementOrMailing) {
    const pathValue = isString(agreementOrMailing.path)
      ? {
          pathname: agreementOrMailing.path,
        }
      : agreementOrMailing.path
    return <Redirect push to={{ ...pathValue, state: history.location?.state }} />
  }

  const { agentType } = useQueryStrings() || {}

  useEffect(() => {
    actions.getScreeningPlans()
    actions.getUserPreference()
  }, [])

  useEffect(() => {
    if (isBoolean(isFirstTimeUser)) {
      const isPro = userPlan === SUBSCRIPTION_PLAN.PRO
    const { eventName, properties } = EVENT_ENTER_SELECT_OPTION_PAGE
    tracker.trackEvent(eventName, properties({ isPro, isFirstTimeUser }))
    }
  }, [isFirstTimeUser])

  const clickDoNotAskCheckBox = () => {
    actions.acceptedBackgroundDisclaimer(!isAcceptedBackgroundDisclaimer)
  }

  const callCreateScreeningRequestTracking = () => {
    tracker.trackEvent(EVENT_REQUEST_SCREENING.selectScreening, {
      click_from: EVENT_REQUEST_SCREENING.clickFromCreateScreeningRequest,
      has_rental_app: priceScheme?.application?.isSelected,
      // eslint-disable-next-line camelcase
      has_credit_report: priceScheme?.credit_report?.isSelected,
      has_criminal: priceScheme?.criminal?.isSelected,
      has_eviction: priceScheme?.eviction?.isSelected,
      template: priceScheme?.application?.isSelected ? APP_TYPES.RENTSPREE : 'N/A',
      has_doc_upload: !isEmpty(createScreeningRequestPayload?.proOption?.documents),
      has_ref_check: !isEmpty(createScreeningRequestPayload?.proOption?.references),
    })
  }

  const confirmModal = () => {
    setShowModal(false)
    actions.createScreeningRequest({ emails: history.location?.state?.emails, agentType })
    callCreateScreeningRequestTracking()
  }

  const onSaveAndNext = () => {
    if (
      priceScheme?.criminal?.disabled ||
      isAcceptedBackgroundDisclaimer ||
      !priceScheme?.criminal?.isSelected
    ) {
      actions.createScreeningRequest({ emails: history.location?.state?.emails, agentType })
      callCreateScreeningRequestTracking()
    } else {
      setShowModal(true)
    }
  }

  const onClickPrevBtn = () => {
    history.goBack()
  }

  return (
    <>
      <TenantScreeningRequestStepOptionTemplate
        priceScheme={priceScheme}
        selectReport={actions.selectReport}
        selectPayer={actions.selectPayer}
        payer={payerType}
        subdomain={subdomain}
        documentProOption={documentProOption}
        refCheckProOption={refCheckProOption}
        isFetching={isFetching}
        isSubmitting={isSubmitting}
        backToDashBoard={() => history.push(TENANT_SCREENING)}
        onSaveAndNext={onSaveAndNext}
        onClickPrevBtn={onClickPrevBtn}
        updateDocumentProOption={actions.updateDocumentProOption}
        updateRefCheckProOption={actions.updateRefCheckProOption}
        agentType={agentType}
      />

      <CriminalDisclaimerModal
        show={showModal}
        backdrop="static"
        isSelected={isAcceptedBackgroundDisclaimer}
        onCheckBoxClick={clickDoNotAskCheckBox}
        closeButton
        closeModal={() => setShowModal(false)}
        onConfirmClick={confirmModal}
      />
    </>
  )
}