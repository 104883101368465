import { buttonLayoutChoices } from './components/footer/utils'
import {
  BankAccountInfoPage,
  handleBankAccountInfoProgression,
} from './pages/bank-account-info/page'
import { BusinessInfoPage, handleBusinessInfoProgression } from './pages/business-info/page'
import {
  PersonalDetailsPage,
  handlePersonalDetailsProgression,
} from './pages/personal-details/page'
/*
 * import {
 *   handlePropertyAndTenantProgression,
 *   PropertyAndTenantPage,
 * } from './pages/bank-account-info/page'
 * import { PaymentDetailsPage, handlePaymentDetailsProgression } from './pages/business-info/page'
 */

export const pageOptions = Object.freeze({
  // an enum to sync button layout options between createButtons & the footer's parent renderers
  BANK_ACCOUNT_INFO: 'bank-account-info',
  BUSINESS_INFO: 'business-info',
  PERSONAL_DETAILS: 'personal-details',
})

// Maps are order-dependent, so we can "map" this to the Stepper's page index concept dependably
export const pages = new Map([
  [
    'Bank account info',
    {
      Page: BankAccountInfoPage,
      progressionHandler: handleBankAccountInfoProgression,
      pageField: pageOptions.PAYMENT_DETAILS,
      buttonLayout: buttonLayoutChoices.NEXT,
    },
  ],
  [
    'Business info',
    {
      Page: BusinessInfoPage,
      progressionHandler: handleBusinessInfoProgression,
      pageField: pageOptions.PROPERTY_AND_TENANT,
      buttonLayout: buttonLayoutChoices.NEXT,
    },
  ],
  [
    'Personal Details',
    {
      Page: PersonalDetailsPage,
      progressionHandler: handlePersonalDetailsProgression,
      pageField: pageOptions.RECIPIENT_DETAILS,
      buttonLayout: buttonLayoutChoices.NEXT,
      textOverrides: { next: 'Finish' },
    },
  ],
])

export const pageNames = new Array(...pages.keys())
