import React from "react"
import { COLOR } from "styles/settings"
import { SourceSansProS16 } from "components/typography"
import {
  FeatureLineWrapper,
  Description,
  PlanTitle,
  PriceLineWrapper,
  PriceTag,
  FeatureLineMobileWrapper,
  FeatureWrapper,
  StrikeThroughText,
  PriceCardWrapper,
  B16Wrapper,
} from "./styled-index"

const getPriceText = price => {
  if (price === 0) {
    return `$${price}`
  }

  return `$${price.toFixed(2)}`
}

const Price = ({ price, monthlyPrice, hasButton, isYearly }) => {
  const perMonthText = "/month"
  const isFree = price === 0
  const isShowStrikeThrough = isYearly ? !isFree : !isFree && price !== monthlyPrice
  return (
    <PriceCardWrapper hasButton={hasButton} isYearly={isYearly}>
      {isShowStrikeThrough && (
          <StrikeThroughText>{getPriceText(monthlyPrice)}</StrikeThroughText>
      )}
      <PriceLineWrapper>
        <PriceTag price={price}>
          {getPriceText(isYearly ? price / 12 : price)}
        </PriceTag>
        <B16Wrapper price={price} margin="0">
          {perMonthText}
        </B16Wrapper>
      </PriceLineWrapper>
    </PriceCardWrapper>
  )
}

const PlanTitleWithDescription = ({
  isActive,
  isRecommended,
  title,
  description,
}) => (
  <>
    <PlanTitle
      color={COLOR.textBlack}
      isActive={isActive}
      isRecommended={isRecommended}>
      {title}
    </PlanTitle>
    <Description color={COLOR.grayLv6}>{description}</Description>
  </>
)

const Feature = ({ text, textColor, icon, isFeatureColumn }) => (
  <FeatureLineWrapper isFeatureColumn={isFeatureColumn}>
    {icon}
    <SourceSansProS16 margin="0" lineHeight="22px" color={textColor} center>
      {text}
    </SourceSansProS16>
  </FeatureLineWrapper>
)

const FeatureMobile = ({
  featureName,
  featureNameColor,
  text,
  textColor,
  icon,
}) => (
  <FeatureLineMobileWrapper>
    <FeatureWrapper isFeatureColumn>
      <SourceSansProS16 margin="0" lineHeight="22px" color={featureNameColor}>
        {featureName}
      </SourceSansProS16>
    </FeatureWrapper>
    <FeatureWrapper>
      {icon}
      <SourceSansProS16 margin="0" lineHeight="22px" color={textColor} center>
        {text}
      </SourceSansProS16>
    </FeatureWrapper>
  </FeatureLineMobileWrapper>
)

export { Price, PlanTitleWithDescription, Feature, FeatureMobile }
