import produce from 'immer'

import { UPCOMING_EVENT_TO_GIFT_TYPES } from 'components/contacts/molecules/upcoming-event-type-cell/constants'

import {
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
  GET_CONTACT_LIST_FAILURE,
  GET_CONTACT_SETTINGS_SUCCESS,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  CONTACT_LIST_CHANGE_PAGE,
  TAG_CONTACT_DASHBOARD,
  UNTAG_CONTACT_DASHBOARD,
  TAG_CONTACT_DASHBOARD_SUCCESS,
  UNTAG_CONTACT_DASHBOARD_SUCCESS,
  STAR_CONTACT_DASHBOARD,
  GET_CONTACT_COUNT_REQUEST,
  GET_CONTACT_COUNT_SUCCESS,
  GET_CONTACT_COUNT_FAILURE,
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAILURE,
  BULK_DELETE_SELECT_STATE,
  BULK_ADD_TAG_REQUEST,
  BULK_ADD_TAG_SUCCESS,
  BULK_ADD_TAG_FAILURE,
  BULK_ADD_TAG_SELECT_STATE,
  SET_BULK_STATE,
  TOGGLE_SELECTED_BULK,
  ADD_SELECTED_ALL_BULK,
  DESELECTED_ALL_BULK,
  UNSET_IS_LOADING_DONE,
  UPDATE_CONTACT_SETTINGS_SUCCESS,
  MARK_AS_GIFT_SENT_REQUEST,
  MARK_AS_GIFT_SENT_SUCCESS,
  MARK_AS_GIFT_SENT_FAILURE,
  UNMARK_AS_GIFT_SENT_REQUEST,
  UNMARK_AS_GIFT_SENT_SUCCESS,
  UNMARK_AS_GIFT_SENT_FAILURE,
} from './constants'
import { updateBulkSelectedAll } from './utils'

export const initialState = {
  data: [],
  isLoading: false,
  isLoadingDone: false,
  isError: false,
  isInitial: true,

  // TODO: should keep loading state as contact id.
  isLoadingTag: false,
  isLoadingSendMessage: false,
  counts: {
    all: 0,
    starred: 0,
    'rentspree-application': 0,
    'recent-activity': 0,
    'upcoming-event': 0,
    isLoading: false,
  },
  bulkState: null,
  bulkSelected: [],
  isBulkLoading: false,
  settings: {},
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_CONTACT_LIST_REQUEST:
        draft.isLoading = true
        break
      case GET_CONTACT_LIST_SUCCESS:
        draft.data = payload?.data || []
        draft.pagination = payload?.pagination || {}
        draft.isLoading = false
        draft.isError = false
        draft.isInitial = false
        draft.isLoadingDone = true
        break
      case GET_CONTACT_LIST_FAILURE:
        draft.isLoading = false
        draft.isError = true
        draft.isInitial = false
        draft.isLoadingDone = true
        break

      /*
       * TODO: Adjust loading state to support multiple tag loading
       * case TAG_CONTACT_DASHBOARD_REQUEST:
       * case UNTAG_CONTACT_DASHBOARD_REQUEST:
       *   draft.isLoadingTag = true
       *   break
       */
      case GET_CONTACT_SETTINGS_SUCCESS:
        draft.settings = { ...payload }
        break
      case UPDATE_CONTACT_SETTINGS_SUCCESS:
        draft.settings = { ...payload }
        break
      case TAG_CONTACT_DASHBOARD_SUCCESS:
      case UNTAG_CONTACT_DASHBOARD_SUCCESS: {
        draft.data = draft.data.map(contact => {
          if (payload?._id === contact?._id) {
            return {
              ...contact,
              tags: contact.tags.filter(tag => contact.tags.includes(tag)),
            }
          }
          return contact
        })
        break
      }

      /*
       * TODO: Adjust loading state to support multiple tag loading
       * case TAG_CONTACT_DASHBOARD_FAILURE:
       * case UNTAG_CONTACT_DASHBOARD_FAILURE:
       *   break
       * TODO: write test for tag
       */
      case GET_CONTACT_COUNT_REQUEST:
        draft.counts.isLoading = true
        break
      case GET_CONTACT_COUNT_SUCCESS:
        draft.counts = payload?.data ? { ...payload?.data, isLoading: false } : initialState.counts
        break
      case GET_CONTACT_COUNT_FAILURE:
        draft.counts.isLoading = false
        break
      case CONTACT_LIST_CHANGE_PAGE:
        draft.currentPage = payload?.page || initialState.currentPage
        break
      case UNTAG_CONTACT_DASHBOARD:
        draft.data = draft.data.map(data => {
          if (data?._id === payload?.contactId) {
            const updatedTag = data.tags.filter(tag => tag?._id !== payload?.tagId)
            return {
              ...data,
              tags: updatedTag,
            }
          }
          return data
        })
        break

      // TODO: should call reducer after tag contact success (payload should be tagId)
      case TAG_CONTACT_DASHBOARD:
        draft.data = draft.data.map(contact => {
          if (contact?._id === payload?.contactId) {
            return {
              ...contact,
              tags: [...contact.tags, payload.tag],
            }
          }
          return contact
        })
        break
      case STAR_CONTACT_DASHBOARD: {
        const contactId = payload?.contactId
        draft.data = draft.data.map(val => {
          if (val?._id === contactId) {
            return {
              ...val,
              starredAt: !val.starredAt,
            }
          }
          return val
        })
        break
      }
      case TOGGLE_SELECTED_BULK: {
        // Can be improved to hashmap
        const index = draft.bulkSelected.indexOf(payload)
        if (index === -1) {
          draft.bulkSelected.push(payload)
        } else {
          draft.bulkSelected.splice(index, 1)
        }
        break
      }
      case ADD_SELECTED_ALL_BULK:
        draft.bulkSelected = updateBulkSelectedAll(draft.bulkSelected, draft.data)
        break
      case SET_BULK_STATE:
        draft.bulkState = payload
        break
      case BULK_DELETE_REQUEST:
        draft.isBulkLoading = true
        break
      case BULK_DELETE_SUCCESS:
        draft.isBulkLoading = false
        draft.bulkSelected = []
        draft.bulkState = null
        break
      case BULK_DELETE_FAILURE:
        draft.isBulkLoading = false
        draft.bulkState = BULK_DELETE_SELECT_STATE
        break
      case DESELECTED_ALL_BULK:
        draft.bulkSelected = []
        break
      case BULK_ADD_TAG_REQUEST:
        draft.isBulkLoading = true
        break
      case BULK_ADD_TAG_SUCCESS:
        draft.isBulkLoading = false
        draft.bulkSelected = []
        draft.bulkState = null
        break
      case BULK_ADD_TAG_FAILURE:
        draft.isBulkLoading = false
        draft.bulkState = BULK_ADD_TAG_SELECT_STATE
        break
      case UNSET_IS_LOADING_DONE:
        draft.isLoadingDone = false
        break
      case MARK_AS_GIFT_SENT_REQUEST:
        draft.isLoading = true
        break
      case MARK_AS_GIFT_SENT_SUCCESS:
        draft.isLoading = false
        draft.data = draft.data.map(data => {
          if (
            data?._id === payload?._id &&
            UPCOMING_EVENT_TO_GIFT_TYPES[data.event] === payload?.giftType
          ) {
            return {
              ...data,
              isGiftSent: true,
            }
          }
          return data
        })
        break
      case MARK_AS_GIFT_SENT_FAILURE:
        draft.isLoading = false
        break
      case UNMARK_AS_GIFT_SENT_REQUEST:
        draft.isLoading = true
        break
      case UNMARK_AS_GIFT_SENT_SUCCESS:
        draft.isLoading = false
        draft.data = draft.data.map(data => {
          if (
            data?._id === payload?._id &&
            UPCOMING_EVENT_TO_GIFT_TYPES[data.event] === payload?.giftType
          ) {
            return {
              ...data,
              isGiftSent: false,
            }
          }
          return data
        })
        break
      case UNMARK_AS_GIFT_SENT_FAILURE:
        draft.isLoading = false
        break
      case SEND_MESSAGE_REQUEST:
        draft.isLoadingSendMessage = true
        break
      case SEND_MESSAGE_SUCCESS:
        draft.isLoadingSendMessage = false
        break
      case SEND_MESSAGE_FAILURE:
        draft.isLoadingSendMessage = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
