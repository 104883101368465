import { CDN_URL } from 'env'

const PREFIX = `${CDN_URL}/static-files`
const ONBOARDING = `${PREFIX}/onboarding`
const SHARED_ASSET = `${PREFIX}/shared-assets`

export const IMG_GIRL_RAISE_HAND = `${ONBOARDING}/girl-raise-hand.svg`
export const IMG_PLANE = `${ONBOARDING}/plane.svg`
export const RELAX_ON_MONEY = `${ONBOARDING}/relax-on-money.svg`

export const MOBILE_VIEW_PAYMENT_ON_MOBILE = `${SHARED_ASSET}/mobile-view-payment-on-mobile.svg`
export const HAND_MONEY_BAG = `${SHARED_ASSET}/hand_money_bag.svg`
export const HAND_GIVING = `${SHARED_ASSET}/hand_giving.svg`
export const IMG_LADY_MONEY_GROW = `${SHARED_ASSET}/lady-money-grow.svg`

export const CONFETTI_ANIMATION = `${SHARED_ASSET}/animations/confetti.lottie`
