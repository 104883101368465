import React from "react"
import styled from "styled-components"
import { COLOR } from "../../styles/settings"

import notiSuccessIcon from "../../images/icons/notification/icon-success.png"

const rgbaHexBackground = `${COLOR.green}26`

// language=SCSS prefix=dummy{ suffix=}
export const NotiSuccess = styled(({ className, message }) => (
  <div className={className}>
    <img src={notiSuccessIcon} alt="icon-success" />
    {message}
  </div>
))`
  display: flex;
  align-items: center;
  padding: 10px 17px;
  border-radius: 5px;
  background-color: ${rgbaHexBackground};
  margin-bottom: 20px;
  color: ${COLOR.darkGreen};
  ${props => (props.hidden ? "display: none;" : "")}
  &> img {
    margin-right: 15px;
  }
`
