import React from 'react'
import styled from 'styled-components'

import EnterDoorIcon from 'components/contacts/atoms/enter-door-icon'
import { breakpoints, COLOR } from 'styles/settings'

import { UPCOMING_EVENT_TYPES } from './constants'

const TypeCell = styled.td`
  border-bottom: 1px solid ${COLOR.grayLv6};
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  box-sizing: border-box;
  ${breakpoints.mobile(`
    border-bottom: none;
  `)}
  color: ${COLOR.grayLv1};
  font-weight: 600;
  > span {
    margin-left: 10px;
  }
`

const renderEventIcon = type => {
  switch (type) {
    case UPCOMING_EVENT_TYPES.BIRTHDAY:
      return <i className="fa fa-gift" />
    case UPCOMING_EVENT_TYPES.MOVE_IN_ANNIVERSARY:
      return <i className="fa fa-home" />
    case UPCOMING_EVENT_TYPES.MOVING_IN:
      return <EnterDoorIcon />
    default:
      return <></>
  }
}

const renderEventName = type => {
  switch (type) {
    case UPCOMING_EVENT_TYPES.BIRTHDAY:
      return <span>Birthday</span>
    case UPCOMING_EVENT_TYPES.MOVE_IN_ANNIVERSARY:
      return <span>Move-in Ani.</span>
    case UPCOMING_EVENT_TYPES.MOVING_IN:
      return <span>Move-in</span>
    default:
      return <></>
  }
}

export const UpcomingEventTypeCell = ({ className, type = UPCOMING_EVENT_TYPES.BIRTHDAY }) => (
  <TypeCell className={className} type={type}>
    {renderEventIcon(type)}
    {renderEventName(type)}
  </TypeCell>
)
