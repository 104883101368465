import styled from "styled-components"
import { COLOR } from "styles/settings"
import { B14, B16, S20 } from "@rentspree/component-v2"

export const WhiteBackground = styled.div`
  flex: 1;
  background: white;
  box-sizing: border-box;
  flex-direction: column;
  border: 2px solid
    ${props => (props.isRecommended ? COLOR.textBlue : COLOR.borderGrey)};
  border-style: solid;
  border-style: ${props => props.isFeatureColumn && `none solid solid none`};
  border-style: ${props => props.isRecommended && `none solid solid solid`};
  ${props =>
    !props.isRecommended && !props.isFeatureColumn && "padding-top: 23px"};
  ${props =>
    !props.isRecommended && props.isFeatureColumn && "padding-top: 25px"};
  ${props => props.isFeatureColumn && "width: 322px"};
  @media (max-width: 991px) {
    padding: 0;
  }
`
export const BorderWrapper = styled.div`
  ${props => !props.isFeatureColumn && `flex-grow: 1`};
  ${props => !props.isFeatureColumn && `flex-basis: 0`};
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.isRecommended
      ? `linear-gradient(to right, ${COLOR.newDeepBlueGradient}, ${
          COLOR.newDeepBlue
        })`
      : `white`};
  :not(:first-of-type) {
    margin-left: -2px;
    @media (max-width: 991px) {
      margin-left: 0;
      margin-bottom: ${props => (props.isRecommended ? "10px" : "50px")};
    }
  }
  @media (max-width: 991px) {
    ${props => props.isFeatureColumn && `display: none`};
    width: 100%;
    max-width: none;
    margin-bottom: 30px;
  }
`

export const PlanTitle = styled(S20)`
  margin: 0;
  line-height: 30px;
`

export const PriceTag = styled.div`
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 36px;
  background: ${props =>
    props.price !== 0
      ? `linear-gradient(
    90deg,
    ${COLOR.newDeepBlueGradient} -0.73%,
    ${COLOR.deepBlue} 100.02%
  )`
      : COLOR.titleGrey};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
// temporary fix for safari < 15.5 see more
// https://developer.apple.com/documentation/safari-release-notes/safari-15_5-release-notes#:~:text=Fixed%20background%2Dclip%3A%20text%20to%20work%20with%20display%3A%20flex.
export const B16Wrapper = styled(B16)`
  background: ${props =>
    props.price !== 0
      ? `linear-gradient(
    90deg,
    ${COLOR.newDeepBlueGradient} -0.73%,
    ${COLOR.deepBlue} 100.02%
  )`
      : COLOR.titleGrey};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Image = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin: ${props => props.margin || "0px"};
  fill: grey;
`
export const FeatureLineWrapper = styled.div`
  display: flex;
  justify-content: ${props =>
    props.isFeatureColumn ? `space-between` : `center`};
  border: 2px ${COLOR.borderGrey};
  border-style: solid none none none;
  margin: -2px 0 0 0;
  padding: ${props => (props.isFeatureColumn ? `20px 24px` : `20px`)};
  align-items: center;
  width: 100%;
  height: 62px;
  @media (max-width: 991px) {
    display: none;
  }
`
export const FeatureLineMobileWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    border: 2px ${COLOR.borderGrey};
    border-style: solid none none none;
    margin: -2px 0 0 0;
    align-items: center;
    width: 100%;
    height: 61px;
    justify-content: space-between;
    padding: 20px 0 20px 22px;
  }
`

export const FeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.isFeatureColumn ? `80%` : `20%`)};
  justify-content: ${props =>
    props.isFeatureColumn ? `flex-start` : `center`};
`

export const FeatureNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px ${COLOR.borderGrey};
  border-style: solid none none none;
  margin: -2px 0 0 0;
  text-align: center;
  width: 100%;
`
export const PriceLineWrapper = styled.div`
  display: flex;
  align-items: baseline;
  color: transparent;
  height: 36px;
`

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: inline;
  }
`

export const DesktopWrapper = styled.div`
  display: inline;
  @media (max-width: 991px) {
    display: none;
  }
`

export const ButtonMobileWrapper = styled.div`
  display: none;
  margin: 30px 0 0 0;
  @media (max-width: 991px) {
    display: block;
  }
`

export const ButtonDesktopWrapper = styled.div`
  display: block;
  width: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`

export const Description = styled(B14)`
  line-height: 20px;
  margin: 0 0 20px 0;
  @media (max-width: 991px) {
    margin: 0 0 10px 0;
  }
`

export const HighLightedTag = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  height: 25px;
  @media (max-width: 991px) {
    height: 30px;
  }
`
export const PlanHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 173px;
  padding: ${props =>
    props.hasButton || props.showCurrentPlanTag
      ? "20px 36px 0"
      : "38px 36px 0"};
  transition: height 0.5s;
  @media (max-width: 991px) {
    height: auto;
    padding: ${props =>
      props.hasButton ? "15px 48px 20px" : "25px 48px 20px"};
  }
`
export const FeatureHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 173px;
  padding: 35px 36px 0;
  transition: height 0.5s;
  @media (max-width: 991px) {
    height: auto;
  }
`
export const FeatureHeaderMobileWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 20px 0;
  }
`
export const DetailCollapse = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const StyledImg = styled.img`
  transform: ${props => (props.showingFeature ? "rotate(180deg)" : "")};
  transition: transform 150ms ease;
`

export const StyledFeatureIcon = styled.img`
  width: ${props => props.size};
  height: ${props => props.size};
  margin-right: 7.5px;
`

export const StrikeThroughText = styled.span`
  display: block;
  position: relative;
  color: ${props => props.color || COLOR.black};
  margin-right: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${props => (props.color ? props.color : COLOR.black)};
    transform: rotate(-7deg);
  }
`

export const PriceCardWrapper = styled.span`
  display: flex;
  align-items: baseline;
  height: 36px;
  margin-bottom: ${props => (props.hasButton ? `20px` : `30px`)};
  @media (max-width: 991px) {
    margin-bottom: ${props => (props.hasButton ? `20px` : `10px`)};
  }
`
