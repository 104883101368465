import React from 'react'
import { buildPath } from '@rentspree/path'
import { generateAddress } from '@rentspree/helper'
import { Badge } from '@rentspree/component-v2'
import { Icon } from '@rentspree/component-v2/dist/icon'
import styled from 'styled-components'

import { Tr, Th, Td, IconColumn, IconColumnText } from 'legacy/components/table/table-style'
import * as Styled from 'legacy/components/table/property-table-style'
import { PROPERTY_OVERVIEW_PAGE } from 'constants/route'
import { Button } from 'legacy/components/buttons/buttons'
import { CenterContent } from 'components/layout/main'
import { MODAL_STATUS } from 'containers/property/toggle-archive-property/constants'
import { ACTIVE_PROPERTY, ARCHIVED_PROPERTY } from 'styles/settings'
import { NoData } from './no-property'
import { DropdownWithUseDisableFeature } from './dropdown-with-use-disable-feature'

export const PropertyIcon = styled(Icon)`
  margin-right: 10px;
  top: 3px;
`
export default class PropertyTable extends React.Component {
  clickArchiveProperty = propertyId => {
    this.props.actions.openArchiveModal({
      type: MODAL_STATUS.ARCHIVE,
      propertyId,
    })
  }

  clickUnarchiveProperty = propertyId => {
    this.props.actions.openArchiveModal({
      type: MODAL_STATUS.UNARCHIVE,
      propertyId,
    })
  }

  render() {
    const {
      propertyList,
      isFetching,
      history,
      hasNoQueryOption,
      isHighlightScreenTenant = false,
      setIsHighlightScreenTenant = () => {},
    } = this.props
    if (!isFetching && propertyList.length === 0 && !hasNoQueryOption) {
      return (
        <CenterContent withBreadcrumb withFilter>
          <NoData text="No properties match your search criteria." />
        </CenterContent>
      )
    }

    return (
      <Styled.PropertyTable id="propertyTable" className="table">
        <thead>
          <Tr id="propertyTableHeader">
            <Th />
            <Th>Property Address</Th>
            <Th minWidth="100px" className="hidden-xs">
              Requested
            </Th>
            <Th minWidth="100px">Completed</Th>
            <Th minWidth="100px">Shared</Th>
            <Th />
          </Tr>
        </thead>
        <tbody>
          {propertyList.map((p, index) => {
            const { _id: propertyId, archived } = p
            const isArchived = !!archived
            return (
              <Tr
                id={`propertyTableRow${index}`}
                key={propertyId}
                className={isArchived && 'inactive'}
                onClick={() => {
                  const url = buildPath(PROPERTY_OVERVIEW_PAGE, {
                    propertyId,
                  })
                  history.push(url)
                }}
              >
                <Td />
                <AddressColumn property={p} index={index} />
                <Td id="total-requested-col" minWidth="100px" className="hidden-xs">
                  {p.total_requested}
                </Td>
                <Td id="total-submitted-col" minWidth="100px">
                  {p.total_submitted}
                </Td>
                <Td id="total-shared-col" minWidth="100px">
                  {p.total_shared}
                </Td>
                <Td minWidth="120px" padding="7px">
                  {isArchived ? (
                    <Button
                      green
                      text="Unarchive"
                      id={`UnarchiveBtn${index}`}
                      fontSize="12"
                      weight="normal"
                      height="auto"
                      width="auto"
                      padding="5px 12px 6px 12px"
                      onClick={e => {
                        e.stopPropagation() // TODO: add type to button to prevent redirect and check the impacts
                        this.clickUnarchiveProperty(propertyId)
                      }}
                    />
                  ) : (
                    <DropdownWithUseDisableFeature
                      id={`DropdownAction${index}`}
                      property={p}
                      clickArchiveProperty={this.clickArchiveProperty}
                      history={history}
                      isRedesignSelectTemplateEnabled={this.props.isRedesignSelectTemplateEnabled}
                      isHighlightScreenTenant={isHighlightScreenTenant && index === 0}
                      setIsHighlightScreenTenant={setIsHighlightScreenTenant}
                    />
                  )}
                </Td>
              </Tr>
            )
          })}
        </tbody>
      </Styled.PropertyTable>
    )
  }
}

export const AddressColumn = ({ property, index }) => {
  const address = generateAddress(property)
  const isArchived = !!property.archived
  return (
    <IconColumn id={`propertyItem${index}`}>
      <div>
        <IconColumnText id={`propertyItem${index}Text`} className="ellipsis-text">
          <PropertyIcon
            name="listing"
            id={`propertyItem${index}Icon`}
            color={isArchived ? ARCHIVED_PROPERTY : ACTIVE_PROPERTY}
            size="1.2em"
          />
          {address}
        </IconColumnText>
        {property.total_unopen > 0 && (
          <Badge styles="margin-left: 10px;" id={`propertyItem${index}Noti`}>
            {property.total_unopen}
          </Badge>
        )}
      </div>
    </IconColumn>
  )
}
