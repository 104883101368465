import React from 'react'
import styled from 'styled-components'

import { breakpoints } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import ActionButton from './action-button'
import ActionMenus from './action-menus'
import Overlay from './floating-overlay'

const Container = styled.div`
  display: none;
  ${breakpoints.mobile(`
    display: block;
  `)};
`

const MobileFloatingAction = ({ onClickCreateContact, onClickImportContact }) => {
  const [isActive, setIsActive] = React.useState(false)

  const toggleActive = () => {
    if (!isActive) {
      tracker.trackEvent(EVENT_PEOPLE_CONCEPT.EVENT_NAME.START_GETTING_NEW_CONTACT)
    }
    setIsActive(!isActive)
  }
  const closeActive = () => setIsActive(false)

  return (
    <Container>
      <Overlay onClick={closeActive} isActive={isActive} />
      <ActionButton isActive={isActive} onClick={toggleActive} />
      {isActive && (
        <ActionMenus
          onClickCreateContact={onClickCreateContact}
          onClickImportContact={onClickImportContact}
        />
      )}
    </Container>
  )
}

export default MobileFloatingAction
