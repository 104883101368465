import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import ChatIcon from 'components/contacts/atoms/chat-icon'
import ContactPhoto from 'components/contacts/atoms/contact-photo'
import Dropdown from 'components/contacts/atoms/dropdown-v2'
import SmallButton from 'components/contacts/atoms/small-button'
import Star from 'components/contacts/atoms/star'
import {
  CHAT_PANEL,
  CHOOSE_GIFT_PANEL,
} from 'components/contacts/templates/contact-dashboard-template/constants'
import { ROLE_OPTIONS } from 'containers/contacts/constants'
import { convert } from 'helpers/convert'
import CheckIcon from 'images/icons/contacts/check.svg'
import { breakpoints, COLOR } from 'styles/settings'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import LabelWithImage from '../label-with-image'
import { UpcomingEventTypeCell } from '../upcoming-event-type-cell'
import { UPCOMING_EVENT_TYPES } from '../upcoming-event-type-cell/constants'

const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT
const { CATEGORY } = EVENT_PEOPLE_CONCEPT.EVENT_PROPERTY

const Container = styled.div`
  display: none;
  padding: 15px 0px 15px 15px;
  border-bottom: 1px solid ${COLOR.grayLv7};

  ${breakpoints.mobile(`
    display: block;
  `)};
`

const FirstLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const ContentContainer = styled.div`
  display: inline-flex;
  flex: 1;
  margin-right: 10px;
  overflow: hidden;
`

const StyledContactPhotoWrapper = styled(ContactPhoto)`
  min-width: 30px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const NameLabel = styled.span`
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const RoleContainer = styled.span`
  display: none;
  color: ${COLOR.textBlack};
  font-size: 12px;

  ${breakpoints.mobile(`
    display: inline;
  `)};
`

const ActionsContainer = styled.span`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: -15px;
  margin-right: 4px;
`

const StyledDropdownWrapper = styled(Dropdown)`
  position: initial;

  .dropdown-menu {
    min-width: initial;
    width: initial;
    margin-top: 5px;
  }

  a[role='menuitem'] {
    padding: 10px 20px;
  }
`

const SecondLineContainer = styled.div`
  display: flex;
  margin-top: 10px;
  padding-left: 65px;
`

const UpcomingEventTypeCellStyledWrapper = styled(UpcomingEventTypeCell)`
  display: flex;
  align-items: center;
`

export const DateContainer = styled.div`
  margin-left: 10px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${COLOR.grayLv1};
`

const ContactCard = ({
  contactId,
  imagePlaceholder,
  image,
  firstName,
  middleName,
  lastName,
  email,
  role,
  onStarContact,
  starredAt,
  birthDate,
  moveInDate,
  type,
  isGiftSent,
  onMarkAsGiftSent,
  onUnmarkGiftSent,
  onOpenContactPanel,
  setSelectedContact,
}) => {
  const handleOpenGiftPanel = e => {
    e.stopPropagation()
    tracker.trackEvent(EVENT_NAME.CLICK_SEND_ANOTHER_GIFT, {
      category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
    })
    onOpenContactPanel({
      contentType: CHOOSE_GIFT_PANEL,
      data: {
        contactId,
        eventType: type,
        firstName,
        middleName,
        lastName,
        email,
        isGiftSent,
      },
    })
  }

  const menuItems = isGiftSent
    ? [
        {
          label: 'Unmark As Sent',
          onClick: onUnmarkGiftSent,
        },
        {
          label: 'Send Another',
          onClick: handleOpenGiftPanel,
        },
      ]
    : [
        {
          label: 'Mark As Sent',
          onClick: onMarkAsGiftSent,
        },
      ]

  return (
    <Container>
      <FirstLineContainer>
        <ContentContainer>
          <Star margin="5px 0 0 0" isActive={!!starredAt} onClick={onStarContact} />
          <StyledContactPhotoWrapper
            margin="0 10px"
            image={image}
            imagePlaceholder={imagePlaceholder}
            isDashboard
          />
          <Col>
            <NameLabel>
              {firstName} {middleName} {lastName}
            </NameLabel>
            <RoleContainer>
              {role ===
              get(
                ROLE_OPTIONS.find(({ value }) => value === 'none'),
                'label',
              )
                ? '-'
                : role}
            </RoleContainer>
          </Col>
        </ContentContainer>
        <ActionsContainer>
          {isGiftSent ? (
            <LabelWithImage icon={CheckIcon} color={COLOR.cyanGreen} label="Gift Sent" />
          ) : (
            <SmallButton text="Send Gift" onClick={handleOpenGiftPanel} />
          )}
          <ChatIcon
            margin="0 2px 0 20px"
            onClick={e => {
              e.stopPropagation()
              setSelectedContact()
              tracker.trackEvent(EVENT_NAME.START_SENDING_MESSAGE, {
                click_from: EVENT_PROPERTY.CLICK_FROM.UPCOMING_EVENTS,
                category: CATEGORY.PEOPLE_CONCEPT,
              })
              onOpenContactPanel({
                contentType: CHAT_PANEL,
                data: {
                  firstName,
                  lastName,
                  middleName,
                  email,
                  imagePlaceholder,
                },
              })
            }}
          />
          <StyledDropdownWrapper menuItems={menuItems} />
        </ActionsContainer>
      </FirstLineContainer>
      <SecondLineContainer>
        <UpcomingEventTypeCellStyledWrapper type={type} />
        <DateContainer>
          {type === UPCOMING_EVENT_TYPES.BIRTHDAY
            ? convert(birthDate, 'dateOfUTC', 'DD MMM')
            : convert(moveInDate, 'dateOfUTC', 'DD MMM')}
        </DateContainer>
      </SecondLineContainer>
    </Container>
  )
}

ContactCard.propTypes = {
  imagePlaceholder: PropTypes.string,
  image: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
}

export default ContactCard
