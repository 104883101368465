import styled, { css } from "styled-components"

import DIAMOND_BG from "images/backgrounds/gplay-diamond.png"
import {
  ANIMATE,
  COLOR,
  FLEX_CENTER,
  MOBILE_SIDEBAR_HEIGHT,
  TOPBAR_HEIGHT,
} from "../../styles/settings"

import PRO_LANDING_SPLASH from "../../images/splash/rentspree-pro-splash.png"
import REQ_DOCS_SPLASH from "../../images/splash/request-doc-splash.png"
import REF_CHECKS_BG from "../../images/splash/ref-check-soon.png"
import REF_CHECKS_SPLASH from "../../images/splash/ref-check-splash.png"
// language=SCSS prefix=dummy{ suffix=}
export const section = styled.section`
  position: relative;
  padding: ${props => (props.padding ? props.padding : "30px 15px")};
  width: 100%;
  ${props => props.blueBg && blueSection};
  ${props =>
    props.flexCenter &&
    css`
      ${FLEX_CENTER};
      flex-direction: column;
      flex-grow: 1;
    `};
  ${props =>
    props.flex &&
    css`
      display: flex;
      flex-grow: 1;
      @media (max-width: 991px) {
        flex-direction: column;
      }
    `};

  &.splash {
    height: 560px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 60px;
    background-size: cover !important;
    &.proLanding {
      color: ${COLOR.white};
      background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
        url(${PRO_LANDING_SPLASH}) no-repeat 60% 0;
      @media (max-width: 991px) {
        background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
          url(${PRO_LANDING_SPLASH}) no-repeat 60% 0;
      }
    }
    &.requestDocs {
      color: ${COLOR.white};
      background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        url(${REQ_DOCS_SPLASH}) no-repeat 60% 0;
      background-position-x: right;
      @media (max-width: 1199px) {
        background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
          url(${REQ_DOCS_SPLASH}) no-repeat 60% 0;
      }
    }
    &.refChecks {
      color: ${COLOR.white};
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
        url(${REF_CHECKS_SPLASH}) no-repeat 60% 0;
      background-position-x: 60%;
      @media (max-width: 1199px) {
        background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
          url(${REF_CHECKS_SPLASH}) no-repeat 60% 0;
        background-position-x: 40%;
      }
    }
    &.smallHeader {
      height: 60px;
      padding: 20px;
      .section-logo {
        top: 18px;
        left: 18px;
        width: 160px;
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
    .section-logo {
      img {
        max-height: 35px;
      }
    }
    @media (max-width: 991px) {
      padding: 30px;
      h1 {
        text-align: center;
      }
    }
  }

  &.full-img {
    height: calc(100vh - ${TOPBAR_HEIGHT});
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 991px) {
      height: calc(100vh - ${TOPBAR_HEIGHT} - ${MOBILE_SIDEBAR_HEIGHT});
    }
    &.reference-checks {
      color: ${COLOR.white};
      background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.61)),
        url(${REF_CHECKS_BG}) no-repeat 60% 0;
      background-size: cover;
      @media (max-width: 991px) {
        background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
          url(${REF_CHECKS_BG}) no-repeat 60% 0;
        background-size: cover;
      }
    }
  }

  &.diamondBg {
    background: linear-gradient(hsla(0, 0%, 100%, 0), rgba(255, 255, 255, 1)),
      url(${DIAMOND_BG});
    background-color: white;
  }
  &.diamondGreyBg {
    background: linear-gradient(hsla(0, 0%, 100%, 0), rgba(238, 238, 238, 1)),
      url(${DIAMOND_BG});
  }
  &.whiteBg {
    background-color: ${COLOR.white};
  }
  &.blackBg {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
  }
  &.videoSection {
    .embed-responsive {
      border-radius: 10px;
    }
    @media (max-width: 991px) {
      .embed-responsive {
        border-radius: 0;
      }
    }
  }

  @media (max-width: 991px) {
    padding: ${props => (props.mPadding ? props.mPadding : "15px")};
    //font-size: 12px;
    &.-overHide {
      overflow: hidden;
    }
  }
`
export const sectionContainer = styled.div`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}` : "750px")};
  margin: auto;
  display: ${props => (props.display ? `${props.display}` : "flex")};
  flex-direction: ${props => (props.flexRow ? "row" : "column")};
  flex-wrap: wrap;
`
// language=SCSS prefix=dummy{ suffix=}
export const flexCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  ${props => props.col && `width: calc(100% / ${props.col})`};
  ${props => props.width && `width: ${props.width}`};
  ${props => props.center && "text-align: center; justify-content: center;"};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.noMargin && "margin: 0"};
  @media (max-width: 991px) {
    ${props =>
      props.mCol ? `width: calc(100% / ${props.mCol})` : "width: 100%"};
    ${props => props.mCenter && "align-items: center"};
  }
  ${props =>
    props.panelCol &&
    css`
      padding: 20px 10px 0;
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
      @media (max-width: 991px) {
        padding: 0px !important;
        margin-bottom: 0;
      }
    `}
`
// language=SCSS prefix=dummy{ suffix=}
export const flexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${props => props.col && "flex-direction: column"};
  ${props => props.center && "justify-content: center; align-items: center"};
  ${props => props.justify && `justify-content: ${props.justify}`};
  ${props => props.align && `align-items: ${props.align}`};
  ${props => props.wrap && `flex-wrap: ${props.wrap}`};
  @media (max-width: 991px) {
    ${props => props.mCol && "flex-direction: column"};
  }
`

export const blueSection = css`
  background: ${COLOR.deepBlue};
  color: ${COLOR.white};
  p {
    color: ${COLOR.white} !important;
  }
`

// language=SCSS prefix=dummy{ suffix=}
export const title = styled.div`
  margin-bottom: 15px;
  .title {
    margin: 0 0 10px;
    font-size: 2.4em;
    line-height: 1.2;
    font-weight: 300;

    @media (max-width: 991px) {
      margin: 0 0 8px;
      font-size: 1.8em;
    }
  }

  .subtitle {
    font-size: 1.3em;
    color: ${COLOR.textGrey};

    @media (max-width: 991px) {
      font-size: 1.1em;
    }
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const leftHeadingDiv = styled.div`
  padding: 0 15%;
  @media (max-width: 991px) {
    padding: 0px;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const imageBanner = styled.div`
  img {
    width: 100%;
  }
  @media (max-width: 991px) {
    height: 180px;
    overflow: hidden;
    img {
      height: auto;
      width: 100%;
      max-width: none;
    }
  }
`

export const bottomLinkDiv = styled.div`
  font-size: 1.2em;
`
export const sectionLogo = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
`
export const sectionHeader = styled.div`
  max-width: ${props => (props.maxWidth ? props.maxWidth : "540px")};
  width: 100%;
  margin: 0px auto 40px;
  > h2 {
    text-align: center;
    font-weight: bold;
    font-size: 2.2em;
    line-height: 1.3;
    margin-bottom: 5px;
  }
  > h4 {
    margin: 5px 0;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const iconContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 40px;
  max-width: 700px;
  ${props => props.col && `width: calc(100% / ${props.col})`};
  > .icon {
    width: ${props => (props.iconWidth ? props.iconWidth : "23%")};
    text-align: center;
  }
  > .content {
    width: ${props => (props.contentWidth ? props.contentWidth : "70%")};
  }
  @media (max-width: 991px) {
    flex-direction: column;
    > .icon {
      width: 50%;
      margin: auto;
    }
    > .content {
      width: 100%;
      text-align: center;
    }
  }
  &.references {
    width: 300px;
    justify-content: center;
    > .icon {
      width: 50px;
      margin-right: 30px;
    }
    > .content {
      > h3 {
        margin: 0 0 5px;
      }
      > p {
        margin-bottom: 0;
      }
    }
    @media (max-width: 991px) {
      flex-direction: row;
      align-items: center;
      > .icon {
        width: 75px;
        margin-right: 30px;
      }
      > .content {
        text-align: left;
      }
    }
  }
  &.arcs-basic {
    align-items: center;
    > .content {
      > h3 {
        margin-top: 0px;
      }
      > p {
        padding-left: 22px;
      }
    }
  }
`
export const iconContentCol = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  > .icon {
    width: auto;
    height: 150px;
    text-align: center;
    > img {
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
    padding: 0 5px;
  }
`
// language=SCSS prefix=dummy{ suffix=}
export const notification = styled.div`
  display: block;
  padding: 10px 25px;
  opacity: 1;
  margin: -15px -25px 15px;
  max-height: none;
  ${props => props.bgColor && `background: ${COLOR[props.bgColor]}`};
  ${props =>
    props.hide &&
    css`
      transition: all ease ${ANIMATE.fast};
      opacity: 0;
      max-height: 0;
      margin: 0;
      padding: 0;
    `};
`
