import styled from "styled-components"

export const GradientOverlay = styled.div`
  display: none;

  @media (max-width: 991px) {
    position: absolute;
    display: block;
    width: 28px;
    height: 43px;
    ${props => (props.right ? "right: 5px;" : "left: 5px;")}
    background-image: linear-gradient(
      to ${props => (props.right ? "left" : "right")},
      rgba(255, 255, 255, 100) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`
