import { BUSINESS_TYPE } from 'v3/containers/identity-verification/constants'

export const BUSINESS_TYPE_OPTIONS = [
  {
    value: BUSINESS_TYPE.INDIVIDUAL,
    label: 'Individual',
    tooltipText: `Your rental property is a personal investment and you don’t 
separate property-related income from your other personal income.`,
  },
  {
    value: BUSINESS_TYPE.COMPANY,
    label: 'Business',
    tooltipText: `Your rental property is owned by a separate business entity and the
rental income is kept separate from your personal income.`,
  },
]

export const BUSINESS_STRUCTURE = {
  MULTIPLE_LLC: 'multi_member_llc',
  SINGLE_LLC: 'single_member_llc',
  PRIVATE_CORPORATION: 'private_corporation',
  PRIVATE_PARTNERSHIP: 'private_partnership',
  SOLE_PROPRIETORSHIP: 'sole_proprietorship',
}

export const BUSINESS_STRUCTURE_OPTIONS = [
  {
    value: BUSINESS_STRUCTURE.SOLE_PROPRIETORSHIP,
    label: 'Sole proprietorship',
  },
  {
    value: BUSINESS_STRUCTURE.SINGLE_LLC,
    label: 'Single-member LLC',
  },
  {
    value: BUSINESS_STRUCTURE.MULTIPLE_LLC,
    label: 'Multi-member LLC',
  },
  {
    value: BUSINESS_STRUCTURE.PRIVATE_PARTNERSHIP,
    label: 'Private partnership',
  },
  {
    value: BUSINESS_STRUCTURE.PRIVATE_CORPORATION,
    label: 'Private corporation',
  },
]
