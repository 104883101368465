import { call } from 'redux-saga-test-plan/matchers'
import { put, takeLatest } from 'redux-saga/effects'

import {
  createAccountSessionAPI,
  CREATE_ACCOUNT_SESSION_CALL,
} from 'v3/containers/identity-verification/actions'
import { createAccountSessionInstance } from 'v3/containers/identity-verification/api-instances'

export function* createAccountSessionSaga() {
  yield put(createAccountSessionAPI.request())

  try {
    const data = yield call(createAccountSessionInstance)
    yield put(createAccountSessionAPI.success(data))
  } catch (err) {
    yield put(createAccountSessionAPI.failure(err.data))
  }
}

export function* watchCreateAccountSession() {
  yield takeLatest(CREATE_ACCOUNT_SESSION_CALL, createAccountSessionSaga)
}
