export const feeField = 'fees'
export const rentField = 'rent'

export const PaymentCategories = Object.freeze({
  RENT: 'Rent',
  LATE_FEE: 'Late fee',
  SECURITY_DEPOSIT: 'Security deposit',
  UTILITIES: 'Utilities',
  PRORATED_RENT: 'Prorated rent',
  PET_DEPOSIT: 'Pet deposit',
  OTHER: 'Other',
})
