import React from "react"
import { MainContent, WhiteWrapper } from "components/layout/main"
import { Section, Title } from "../../components/layout/section"
import {
  IconCard,
  IconCardWrap,
  IconCardSwitch,
} from "../../components/getting-started/getting-started"
import {
  BEFORE_START_LIST,
  URL_SIMPLE_APPLICATION,
  URL_SIMPLE_APPLICATION_FOR_CAA,
} from "./getting-started-const"
import { RouterLink } from "../../components/buttons/buttons"
import * as PATH from "../../constants/route-consts"
import GettingStartedConnect from "./getting-started-connect"
import { TrackerInstance as Tracker } from "../../helpers/tracker"
import tracker from "../../tracker"
import { EVENT_REQUEST_SCREENING } from "../../tracker/const"

@GettingStartedConnect
export default class GettingStarted extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 100,
    }
  }

  setTransition(transition) {
    this.setState({ current: transition })
  }

  downloadSampleApplication() {
    try {
      const filePath =
        this.props.subdomain === "caa"
          ? URL_SIMPLE_APPLICATION_FOR_CAA
          : URL_SIMPLE_APPLICATION
      const a = document.createElement("A")
      a.href = filePath
      a.download = filePath.substr(filePath.lastIndexOf("/") + 1)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (e) {
      console.error("error download simple application=")
    }
  }

  cardButtonActions(btnAction) {
    switch (btnAction) {
      case BEFORE_START_LIST[0].id:
        Tracker.trackEvent("clickOpenWatchTutorialVideo")
        return this.props.actions.toggleVideoTutorialModal(true)
      case BEFORE_START_LIST[1].id:
        Tracker.trackEvent("clickDownloadSampleFile")
        return this.downloadSampleApplication()
      case BEFORE_START_LIST[2].id:
        Tracker.trackEvent("clickViewProcessInGettingStartedPage")
        return this.props.actions.toggleVideoViewProcessModal(true)
      default:
        return false
    }
  }

  handleGetStartedNow() {
    const { clickStartScreening } = EVENT_REQUEST_SCREENING
    tracker.trackEvent(
      clickStartScreening.name,
      clickStartScreening.properties.howToPage,
    )
  }

  render() {
    return (
      <MainContent className="-w100">
        <WhiteWrapper noMargin flexCenter>
          <Section
            id="sectionStartHeader"
            padding="40px 0 0"
            mPadding="25px 0 0">
            <Title
              align="center"
              title="Need help screening?"
              subtitle="RentSpree has all the answers"
            />
          </Section>
          <IconCardSwitch
            current={this.state.current}
            handleFirst={() => this.setTransition(100)}
            handleSecond={() => this.setTransition(0)}
            handleThird={() => this.setTransition(-100)}
          />
          <Section
            flexCenter
            id="sectionBeforeStart"
            padding="25px 0 40px"
            mPadding="40px 25px"
            className="-overHide">
            <IconCardWrap current={this.state.current}>
              {BEFORE_START_LIST.map(card => (
                <IconCard
                  key={card.id}
                  id={card.id}
                  icon={card.icon}
                  title={card.title}
                  content={card.content}
                  button={card.button}
                  onBtnClick={() => this.cardButtonActions(card.id)}
                />
              ))}
            </IconCardWrap>
          </Section>
          <Section
            flexCenter
            id="sectionStartScreening"
            blueBg
            mPadding="40px 15px">
            <Title align="center" title="Let’s get going!" />
            <div className="text-center">
              <RouterLink
                id="startRequestBtn"
                className="button-lg"
                text="Start screening now"
                white
                minWidth="250px"
                to={PATH.REQUEST}
                onClick={() => this.handleGetStartedNow()}
              />
            </div>
          </Section>
        </WhiteWrapper>
      </MainContent>
    )
  }
}
