import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { COLOR } from 'styles/settings'

const activeState = css`
  color: ${COLOR.newDeepBlue};
  border-image: linear-gradient(to bottom, ${COLOR.newDeepBlueGradient}, ${COLOR.newDeepBlue}) 1
    100%;
  @media (max-width: 991px) {
    border-image: none;
    background: linear-gradient(to right, ${COLOR.newDeepBlueGradient}, ${COLOR.newDeepBlue});
    background-size: calc(100% - 20px) 3px;
    background-position: bottom 0 left 10px, bottom 0 left 0;
    background-repeat: no-repeat;
  }
`

export const Menu = styled(NavLink)`
  align-items: center;
  display: flex;
  width: 100%;
  padding-left: ${props => (props.isSubMenu ? '30px' : '20px')};
  color: ${COLOR.textBlack};
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  transition-property: border-left, color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  border-left: 5px solid ${COLOR.white};
  cursor: pointer;
  line-height: 40px;
  i {
    margin-left: 2px;
  }
  ${props => props.active && activeState}
  :hover {
    color: ${COLOR.newDeepBlue};
  }
  @media (max-width: 991px) {
    height: 100%;
    width: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0px 10px;
    flex-shrink: 0;
    border-left: 0px;
    :hover {
      ${activeState}
      border-left: 0px;
    }
  }
`
