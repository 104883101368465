import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import saga from './saga'
import {
  getScreeningPlans,
  getUserPreference,
  selectReport,
  selectPayer,
  createScreeningRequest,
  acceptedBackgroundDisclaimer,
  updateDocumentProOption,
  updateRefCheckProOption,
} from './actions'
import reducer from './reducer'
import {
  selectPriceScheme,
  selectPayerType,
  selectIsFetching,
  selectCreateScreeningRequestPayload,
  selectIsAcceptedBackgroundDisclaimer,
  selectIsSubmitting,
  selectSubdomain,
  selectDocumentProOption,
  selectRefCheckProOption,
  selectIsFirstTimeUser,
} from './selectors'

const mapStateToProps = createStructuredSelector({
  priceScheme: selectPriceScheme,
  payerType: selectPayerType,
  isFetching: selectIsFetching,
  isSubmitting: selectIsSubmitting,
  createScreeningRequestPayload: selectCreateScreeningRequestPayload,
  isAcceptedBackgroundDisclaimer: selectIsAcceptedBackgroundDisclaimer,
  subdomain: selectSubdomain,
  documentProOption: selectDocumentProOption,
  refCheckProOption: selectRefCheckProOption,
  isFirstTimeUser: selectIsFirstTimeUser,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getScreeningPlans,
      getUserPreference,
      selectReport,
      selectPayer,
      createScreeningRequest,
      acceptedBackgroundDisclaimer,
      updateDocumentProOption,
      updateRefCheckProOption,
    },
    dispatch,
  ),
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withSaga = injectSaga({ key: 'tenantScreeningRequest', saga })
export const withReducer = injectReducer({
  key: 'tenantScreeningRequest',
  reducer,
})
