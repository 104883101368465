import React, { useState } from "react"
import { PanelGroup, Panel } from "react-bootstrap"
import { RENTSPREE_PRO_EVENT } from "tracker/const"
import { P } from "../typography"
import { PanelHeading } from "./panel-box"
import { SUBSCRIPTION_FAQ } from "./constants"

export const SubscriptionFaq = ({ clickPanel, headerWithIcon }) => {
  const [activePanel, setActivePanel] = useState()
  const onClickPanel = content => () =>
    clickPanel(RENTSPREE_PRO_EVENT.CLICK_SHOW_FAQ, {
      action:
        activePanel === content.id
          ? RENTSPREE_PRO_EVENT.ACTION_HIDE_FAQ
          : RENTSPREE_PRO_EVENT.ACTION_SHOW_FAQ,
      section: content.event.name,
    })

  return (
    <PanelGroup
      accordion
      onSelect={setActivePanel}
      activeKey={activePanel}
      data-testid="faq">
      {SUBSCRIPTION_FAQ.map((content, id) => (
        <Panel
          id={content.id}
          eventKey={content.id}
          key={content.id}
          onClick={onClickPanel(content)}>
          <PanelHeading>
            {headerWithIcon({
              text: content.header,
              collapsed: activePanel === SUBSCRIPTION_FAQ[id].id,
            })}
          </PanelHeading>
          <Panel.Body collapsible>
            <P fontWeight="600">{content.description}</P>
          </Panel.Body>
        </Panel>
      ))}
    </PanelGroup>
  )
}
