import { S20, B14, BlueGradientButton } from '@rentspree/component-v2'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const BenefitCardContainer = styled.div`
  border-radius: 5px;
  background: ${COLOR.white};
  text-align: center;
  box-shadow: 0 2px 7px 0 rgb(0 0 0 / 10%);
  padding: 40px 20px;
  width: 290px;
  height: 343px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-bottom: 10px;
    margin-right: 0px;
    width: 345px;
  }
`
const Image = styled.img`
  max-height: 110px;
`
const Subtitle = styled(B14)`
  flex: 5;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

const ActionButton = styled(BlueGradientButton)`
  border-radius: 25px;
  width: 140px;
  min-height: 40px;
  font-weight: bold;
`

const BenefitCard = ({ image, title, subTitle, buttonText, buttonAction }) => (
  <BenefitCardContainer>
    <Image src={image} alt={title} />
    <S20>{title}</S20>
    <Subtitle>{subTitle}</Subtitle>
    {buttonText && (
      <ButtonContainer>
        <ActionButton primary center text={buttonText} fontSize="16px" onClick={buttonAction} />
      </ButtonContainer>
    )}
  </BenefitCardContainer>
)

export default BenefitCard

BenefitCard.defaultProps = {
  subTitle: '',
  buttonText: '',
  buttonAction: () => {},
}
BenefitCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
}
