import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import * as PATH from 'constants/route'
import { COLOR } from 'styles/settings'
import { MainContent } from 'components/layout/main'
import tracker from 'tracker'
import { ONBOARDING_PARTNER_AGENT_EVENT } from 'tracker/const'

import { PATH_HOME_PAGE } from 'v3/routers/path'
import { useDashboard } from 'v3/hooks/use-dashboard'
import { CenterLoading } from 'v3/components/molecules/center-loading/center-loading'
import { useOnboardingPartnerAgent } from 'v3/hooks/use-onboarding-partner-agent'
import ListingRouteContainer from './containers/listing'

export const RedirectDefault = props => {
  const { isNewHome, isLoading: isLoadingUseDashboard } = useDashboard()
  const { isOnboardingVisible, isLoading: isLoadingOnboardingPartnerAgent } =
    useOnboardingPartnerAgent()

  if (isLoadingUseDashboard || isLoadingOnboardingPartnerAgent) {
    return <CenterLoading color="black" height="full" />
  }

  if (isOnboardingVisible) {
    tracker.trackEvent(ONBOARDING_PARTNER_AGENT_EVENT.EVENT_NAME.REDIRECT_TO_ONBOARDING_AGENT)
    return <Redirect to={PATH.PROPERTY} />
  }
  /**
   * If the user supposed get the new dashboard not the explore page as the home page, redirect to home
   */
  if (isNewHome) {
    return <Redirect to={PATH_HOME_PAGE} />
  }

  return (
    <Redirect
      to={{
        pathname: PATH.TENANT_SCREENING,
        search: props?.location?.search || '',
      }}
    />
  )
}

export const MainContentRoute = () => (
  <MainContent bgColor={COLOR.whiteContainer}>
    <Switch>
      <Route exact path="/" render={props => <RedirectDefault {...props} />} />
      <Route path={PATH.PROPERTY} component={ListingRouteContainer} />
      <Route
        render={() => {
          window.location.href = '/not-found'
        }}
      />
    </Switch>
  </MainContent>
)
