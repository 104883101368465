import Checkbox from '@rentspree/component-v2/dist/input/checkbox'
import Pagination from '@rentspree/component-v2/dist/pagination'
import React from 'react'
import styled from 'styled-components'

import { SortableHeader } from 'components/atoms/sortable-header'
import { breakpoints, COLOR } from 'styles/settings'

import { TABLE_HEADER } from './constants'
import ContactDataDesktop from './contact-data-desktop'
import ContactDataMobile from './contact-data-mobile'
import { contactDataMapper, contactSortMapper } from './helpers'

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  ${breakpoints.mobile(`
    table-layout: fixed;
  `)};
`

const HeaderContainer = styled.tr`
  font-family: Inter;
  height: 40px;
  font-size: 14px;
  border-bottom: 2px solid ${COLOR.grayFade};
  & th {
    font-weight: bold;
  }
  ${breakpoints.mobile(`
    display: none;
  `)};
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const TableWrapper = styled.div`
  flex-grow: 1;
`

const PaginationWrapper = styled.div`
  margin: 20px 0;
  padding-left: 5px;
  ${breakpoints.mobile(`
    margin: 30px 0 120px;
    padding-left: 0;
  `)};
`

export const PaginationContainer = styled(Pagination)`
  @media (max-width: 1199px) {
    height: auto;
    flex-direction: column;
    align-items: center;

    > div:nth-child(1) {
      position: relative;
      margin-bottom: 10px;
    }

    > div:nth-child(2) {
      height: 40px;
    }

    > div:nth-child(3) {
      height: 40px;
      margin-left: 0;
      margin-top: 10px;
    }
  }
`

const copyableFieldList = ['email', 'phoneNumber']

const Th = styled.th`
  width: ${({ width }) => width || 'initial'};
  padding-left: ${({ name, isShowCopyableCell }) =>
    copyableFieldList.includes(name) ? isShowCopyableCell && '15px' : '0px'};
`

const NameTh = styled.th`
  width: ${({ width }) => width || 'initial'};
  padding-left: 32px;
`

const CheckboxTh = styled.th`
  padding-left: 5px;
`

export const ContactListComponent = ({
  allTags = [],
  bulkSelected = [],
  data = [],
  pagination,
  isLoadingTag,
  handleGoToContactDetail,
  handleChangePage,
  onCreateAndTagContact,
  onClickToggleSelectedBulk,
  onClickAddSelectedAllBulk,
  onTagContact,
  onUntagContact,
  onShowMoreTag,
  onStarContact,
  bulkState,
  onSortContact,
  currentSortContactValue,
  onFocusAddTag,
  onCopyContactDetail,
}) => (
  <FlexWrapper>
    <TableWrapper>
      <Table>
        <HeaderContainer>
          {bulkState && (
            <CheckboxTh width="39px">
              <Checkbox
                id="header_checkbox"
                simple
                isSelected={
                  data.every(contact => bulkSelected.includes(contact?._id)) && data.length > 0
                }
                bgSimpleTransparent
                onClick={onClickAddSelectedAllBulk}
              />
            </CheckboxTh>
          )}
          {TABLE_HEADER.map((header, index) => {
            const sortedVal = currentSortContactValue[header.name]
            return index === 0 ? (
              <NameTh key={header.name} width={header.width}>
                <SortableHeader
                  id={`sort-${header.name}-btn`}
                  text={header.text}
                  sorting={contactSortMapper(sortedVal)}
                  onClick={() =>
                    header.isSortable &&
                    onSortContact({
                      sortedKey: header.name,
                      sortedVal,
                    })
                  }
                />
              </NameTh>
            ) : (
              <Th key={header.name} width={header.width} name={header.name} isShowCopyableCell>
                <SortableHeader
                  id={`sort-${header.name}-btn`}
                  text={header.text}
                  sorting={contactSortMapper(sortedVal)}
                  onClick={() =>
                    header.isSortable &&
                    onSortContact({
                      sortedKey: header.name,
                      sortedVal,
                    })
                  }
                />
              </Th>
            )
          })}
        </HeaderContainer>
        {data.map(contact => (
          <>
            <ContactDataDesktop
              {...contactDataMapper(contact)}
              contactId={contact?._id}
              allTags={allTags}
              bulkSelected={bulkSelected}
              onClickContactRow={() =>
                handleGoToContactDetail(
                  contact?._id,
                  contact?.daysTillBirthday,
                  contact?.daysTillMoveInAnniversary,
                  contact?.daysTillMovingIn,
                )
              }
              onCreateAndTagContact={(name, options) =>
                onCreateAndTagContact(
                  {
                    contactId: contact?._id,
                    name,
                  },
                  options,
                )
              }
              onTagContact={tagId =>
                onTagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                })
              }
              onUntagContact={(tagId, isShowMore) =>
                onUntagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                  isShowMore,
                })
              }
              onShowMoreTag={() =>
                onShowMoreTag({
                  email: contact?.email,
                })
              }
              isLoadingTag={isLoadingTag}
              onStarContact={event => {
                onStarContact({
                  contactId: contact?._id,
                  starredAt: contact?.starredAt,
                })
                event.stopPropagation()
              }}
              onToggleSelectedBulk={event => {
                onClickToggleSelectedBulk(contact?._id)
                event.stopPropagation()
              }}
              bulkState={bulkState}
              onFocusAddTag={isShowMore => onFocusAddTag({ email: contact?.email, isShowMore })}
              onCopyContactDetail={label => onCopyContactDetail(label, contact?._id)}
            />
            <ContactDataMobile
              {...contactDataMapper(contact)}
              contactId={contact?._id}
              allTags={allTags}
              onClickContactRow={() =>
                handleGoToContactDetail(
                  contact?._id,
                  contact?.daysTillBirthday,
                  contact?.daysTillMoveInAnniversary,
                  contact?.daysTillMovingIn,
                )
              }
              onCreateAndTagContact={(name, options) =>
                onCreateAndTagContact(
                  {
                    contactId: contact?._id,
                    name,
                  },
                  options,
                )
              }
              onTagContact={tagId =>
                onTagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                })
              }
              onUntagContact={(tagId, isShowMore) =>
                onUntagContact({
                  contactId: contact?._id,
                  tagId,
                  email: contact?.email,
                  isShowMore,
                })
              }
              onShowMoreTag={() =>
                onShowMoreTag({
                  email: contact?.email,
                })
              }
              isLoadingTag={isLoadingTag}
              onStarContact={event => {
                onStarContact({
                  contactId: contact?._id,
                  starredAt: contact?.starredAt,
                })
                event.stopPropagation()
              }}
              onToggleSelectedBulk={event => {
                onClickToggleSelectedBulk(contact?._id)
                event.stopPropagation()
              }}
              bulkSelected={bulkSelected}
              bulkState={bulkState}
              onFocusAddTag={isShowMore => onFocusAddTag({ email: contact?.email, isShowMore })}
            />
          </>
        ))}
      </Table>
    </TableWrapper>
    <PaginationWrapper>
      <PaginationContainer
        isShowStatus
        isShowInput
        total={pagination?.total}
        page={pagination?.page}
        perPage={pagination?.perPage}
        onChangePage={handleChangePage}
      />
    </PaginationWrapper>
  </FlexWrapper>
)

ContactListComponent.defaultProps = {
  currentSortContactValue: {},
}

export default ContactListComponent
