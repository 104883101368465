import { loadConnectAndInitialize } from '@stripe/connect-js'
import { ConnectAccountOnboarding, ConnectComponentsProvider } from '@stripe/react-connect-js'
import React, { useEffect, useState } from 'react'

import { STRIPE_PUBLIC_KEY } from 'env'
import { TAX_INFORMATION_STEPS } from 'v3/containers/identity-verification/constants'
import { useIdentityVerificationAccountSession } from 'v3/containers/identity-verification/hooks'

import { EmbeddedFormWrapper } from './styled'

export const PersonalDetailsStep = ({
  continuePath,
  history,
  location,
  onExit = () => {},
  setCurrentStep,
  setHiddenBottomBar,
  setWelcomeModalOnContinue = true,
}) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null)

  const {
    data: accountSession = {},
    createAccountSession,
    resetAccountSession,
  } = useIdentityVerificationAccountSession()

  useEffect(() => {
    createAccountSession()
    setCurrentStep(TAX_INFORMATION_STEPS.PERSONAL_DETAILS)
    setHiddenBottomBar(true)

    return () => {
      resetAccountSession()
      setHiddenBottomBar(false)
    }
  }, [])

  useEffect(() => {
    if (accountSession.clientSecret) {
      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: STRIPE_PUBLIC_KEY,
          fetchClientSecret: async () => accountSession.clientSecret,
          appearance: {
            variables: {
              colorPrimary: '#1F15E1',
              colorText: '#222222',
              colorBackground: '#FEFEFE',
              colorDanger: '#D22E2C',
              /*
               * NOTED: stripe embedded error when passing #222222 code to badge*ColorText
               * badgeNeutralColorText:"#222222",
               * badgeSuccessColorText:"#222222",
               * badgeWarningColorText:"#222222",
               * badgeDangerColorText:"#222222",
               */
              badgeSuccessColorBorder: '#1A962F',
              badgeWarningColorBorder: '#D8BB00',
              badgeDangerColorBorder: '#D22E2C',
              offsetBackgroundColor: '#F8F8F8',
              formBackgroundColor: '#FEFEFE',
              fontFamily: 'Inter',
            },
          },
        }),
      )
    }
  }, [accountSession.clientSecret])

  const onSubmit = () => {
    onExit()
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('showWelcomeModal', setWelcomeModalOnContinue ? 'true' : 'false')
    history.replace({
      pathname: continuePath,
      search: queryParams.toString(),
      state: { ...location?.state },
    })
  }

  return (
    <EmbeddedFormWrapper>
      {stripeConnectInstance && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectAccountOnboarding
            onExit={onSubmit}
            collectionOptions={{
              fields: 'eventually_due',
            }}
          />
        </ConnectComponentsProvider>
      )}
    </EmbeddedFormWrapper>
  )
}

export default PersonalDetailsStep
