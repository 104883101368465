import { PRO_PLAN_VARIANTS } from "legacy/containers/pro/landing/constants"
import React from "react"
import { TOGGLE_BUTTON_YEARLY_SUBTITLE } from "env"
import { SwitchToggleButton } from "./button-switch-toggle"

export const BillingPeriodToggleButton = ({
  selectedPriceName,
  setSelectedPriceName,
  inputName,
}) => (
  <SwitchToggleButton
    options={[
      {
        priceName: PRO_PLAN_VARIANTS.MONTHLY,
        value: PRO_PLAN_VARIANTS.MONTHLY,
        className: "first-toggle",
      },
      {
        priceName: PRO_PLAN_VARIANTS.YEARLY,
        subtitle: TOGGLE_BUTTON_YEARLY_SUBTITLE,
        value: PRO_PLAN_VARIANTS.YEARLY,
        className: "second-toggle",
      },
    ]}
    selectedButton={selectedPriceName}
    setSelectedButton={setSelectedPriceName}
    inputName={inputName}
  />
)
