import { buildPath } from '@rentspree/path'
import { push } from 'connected-react-router'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'
import { takeLatest, all, put, call, select } from 'redux-saga/effects'

import { addToast } from 'containers/toast/actions'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'
import { apiInstance } from 'utils/api-interceptor'
import history from 'utils/history'

import { CONTACTS, MESSAGING_PAGE } from '../../../constants/route'
import { setSeenContacts } from '../actions'
import { updateQueryString } from '../helpers'
import { selectLocation } from '../selectors'
import { createTagSaga, tagContactApi, untagContactApi } from '../tags/saga'

import {
  getContactListApiState,
  tagContactDashboardApiState,
  untagContactDashboardApiState,
  getContactSettingsApiState,
  updateContactSettingsApiState,
  sendMessageApiState,
  tagContact,
  starContact,
  getContactCountApiState,
  bulkDeleteApiState,
  bulkAddTagApiState,
  getContactList,
  getContactCount,
  bulkAddTag,
  markAsGiftSentApiState,
  unmarkAsGiftSentApiState,
} from './actions'
import {
  GET_CONTACT_LIST_CALL,
  GET_CONTACT_LIST_API,
  GET_CONTACT_SETTINGS_CALL,
  UPDATE_CONTACT_SETTINGS_CALL,
  CONTACT_MATCHING_FILTER_TOAST_CONFIG,
  CREATE_AND_TAG_CONTACT_DASHBOARD,
  TAG_CONTACT_DASHBOARD,
  UNTAG_CONTACT_DASHBOARD,
  CREATE_TAG_SUCCESS_TOAST_CONFIG,
  CONTACT_STAR_API,
  STAR_CONTACT_DASHBOARD,
  ACTION_TOAST_FAILURE,
  GET_CONTACT_COUNT_API,
  GET_CONTACT_COUNT_CALL,
  BULK_DELETE,
  BULK_DELETE_API,
  BULK_DELETE_SUCCESS_TOAST_CONFIG,
  BULK_DELETE_FAILURE_TOAST_CONFIG,
  BULK_ADD_TAG,
  BULK_ADD_TAG_API,
  BULK_ADD_TAG_SUCCESS_TOAST_CONFIG,
  BULK_ADD_TAG_FAILURE_TOAST_CONFIG,
  APPLY_SORT_CONTACT,
  CREATE_AND_BULK_ADD_TAG,
  MARK_AS_GIFT_SENT,
  UNMARK_AS_GIFT_SENT,
  MARK_AS_GIFT_SENT_API,
  USER_SETTINGS_API,
  GET_SETTINGS_FAILURE_TOAST_CONFIG,
  UPDATE_SETTINGS_FAILURE_TOAST_CONFIG,
  SEND_MESSAGE,
  SEND_MESSAGE_API,
  SEND_MESSAGE_SUCCESS_TOAST_CONFIG,
  SEND_MESSAGE_FAILED_TOAST_CONFIG,
  MARK_AS_GIFT_SENT_SUCCESS_TOAST_CONFIG,
  UNMARK_AS_GIFT_SENT_SUCCESS_TOAST_CONFIG,
  MARK_AS_GIFT_SENT_FAILURE_TOAST_CONFIG,
  UNMARK_AS_GIFT_SENT_FAILURE_TOAST_CONFIG,
} from './constants'
import { countFilter } from './utils'

const {
  EVENT_NAME: { ADDED_TAG_SUCCESS, BULK_ADDED_TAG_SUCCESS },
  EVENT_PROPERTY: {
    SUCCESS_TAG_BY,
    CATEGORY: { PEOPLE_CONCEPT },
  },
} = EVENT_PEOPLE_CONCEPT

export const getContactListApi = query =>
  apiInstance.get(buildPath(GET_CONTACT_LIST_API, {}, query))

export const bulkDeleteApi = contactIds =>
  apiInstance.delete(BULK_DELETE_API, { data: { contactIds } })

export const bulkAddTagApi = ({ tagId, contactIds }) =>
  apiInstance.post(
    buildPath(BULK_ADD_TAG_API, {
      tagId,
    }),
    { contactIds },
  )

export const sendMessageApi = body => apiInstance.put(SEND_MESSAGE_API, body)

export const starContactApi = (contactId, isStar) =>
  isStar
    ? apiInstance.post(
        buildPath(CONTACT_STAR_API, {
          contactId,
        }),
      )
    : apiInstance.delete(
        buildPath(CONTACT_STAR_API, {
          contactId,
        }),
      )

export const getContactCountApi = query =>
  apiInstance.get(buildPath(GET_CONTACT_COUNT_API, {}, query))

export const markAsGiftSentApi = ({ contactId, giftType }) =>
  apiInstance.patch(buildPath(MARK_AS_GIFT_SENT_API, { contactId, giftType }))

export const unmarkAsGiftSentApi = ({ contactId, giftType }) =>
  apiInstance.delete(buildPath(MARK_AS_GIFT_SENT_API, { contactId, giftType }))

export const getContactSettingsApi = () => apiInstance.get(buildPath(USER_SETTINGS_API))

export const updateContactSettingsApi = settings =>
  apiInstance.patch(buildPath(USER_SETTINGS_API), settings)

export function* getContactListSaga({ payload }) {
  yield put(getContactListApiState.request())
  try {
    const response = yield call(getContactListApi, payload)
    yield put(getContactListApiState.success(response))

    const unSeenContactIds = response?.data
      .filter(contact => contact.isUnseen)
      .map(contact => contact?._id)
    yield put(setSeenContacts(unSeenContactIds))

    const totalContacts = response?.pagination?.total
    const totalPage = Math.ceil(totalContacts / 8)
    if (payload.page > totalPage && totalPage !== 0) {
      const currentLocation = yield select(selectLocation)
      const newSearch = updateQueryString(currentLocation.search, {
        page: totalPage,
      })
      yield put(push({ pathname: currentLocation.pathname, search: newSearch }))
    } else if (countFilter(payload) > 0) {
      const toastConfig = CONTACT_MATCHING_FILTER_TOAST_CONFIG(totalContacts)
      yield put(addToast(toastConfig))
    }
    if (payload.search) {
      // TODO: please use yield and call
      tracker.trackEvent(EVENT_PEOPLE_CONCEPT.EVENT_NAME.SEARCH_SUCCESS, {
        keyword: payload.search,
      })
    }
  } catch (err) {
    yield put(getContactListApiState.failure())
  }
}

export function* createAndTagContactSaga({ payload }) {
  yield put(tagContactDashboardApiState.request())
  try {
    const { successCallback } = payload
    const tag = yield call(createTagSaga, { payload })
    // eslint-disable-next-line no-underscore-dangle
    const updatedContact = yield call(tagContactApi, payload.contactId, tag._id)
    yield put(
      tagContact({
        tag,
        contactId: payload.contactId,
        isNewTag: true,
      }),
    )
    yield put(tagContactDashboardApiState.success(updatedContact))
    yield put(addToast(CREATE_TAG_SUCCESS_TOAST_CONFIG()))
    if (successCallback) yield call(successCallback)
  } catch (error) {
    yield put(tagContactDashboardApiState.failure())
  }
}

export function* sendMessageToContactSaga({ payload }) {
  yield put(sendMessageApiState.request())
  try {
    yield call(sendMessageApi, {
      recipient: payload?.recipient,
      message: payload?.message,
    })
    yield put(sendMessageApiState.success())
    yield put(addToast(SEND_MESSAGE_SUCCESS_TOAST_CONFIG()))
    const emailUri = encodeURIComponent(payload?.recipient)
    history.push(`${MESSAGING_PAGE}?emailFromContactDetail=${emailUri}`)
  } catch (error) {
    yield put(sendMessageApiState.failure())
    yield put(addToast(SEND_MESSAGE_FAILED_TOAST_CONFIG()))
  }
}

export function* tagContactSaga({ payload }) {
  yield put(tagContactDashboardApiState.request())
  try {
    const updatedContact = yield call(tagContactApi, payload.contactId, payload.tag?._id)
    yield put(tagContactDashboardApiState.success(updatedContact))
    yield call([tracker, 'trackEvent'], ADDED_TAG_SUCCESS, {
      tag_name: get(payload, 'tag.name', ''),
      success_tag_by: payload.isNewTag
        ? SUCCESS_TAG_BY.CREATE_NEW_TAG
        : SUCCESS_TAG_BY.CHOOSE_EXISTING_TAG,
      contact_email: payload.email,
      category: PEOPLE_CONCEPT,
    })
  } catch (error) {
    yield put(tagContactDashboardApiState.failure())
  }
}

export function* untagContactSaga({ payload }) {
  yield put(untagContactDashboardApiState.request())
  try {
    const updatedContact = yield call(untagContactApi, payload.contactId, payload.tagId)
    yield put(untagContactDashboardApiState.success(updatedContact))
  } catch (error) {
    yield put(untagContactDashboardApiState.failure())
  }
}

export function* starContactSaga({ payload }) {
  if (payload?.isFallBack) return
  try {
    const isStar = !payload?.starredAt
    yield call(starContactApi, payload?.contactId, isStar)
  } catch (err) {
    yield put(
      addToast({
        bodyMessage: ACTION_TOAST_FAILURE,
        status: 'error',
      }),
    )
    yield put(
      starContact({
        contactId: payload?.contactId,
        starredAt: payload?.starredAt,
        isFallBack: true,
      }),
    )
  }
}

export function* getContactCountSaga({ payload }) {
  yield put(getContactCountApiState.request())
  try {
    const response = yield call(getContactCountApi, payload)
    yield put(getContactCountApiState.success(response))
  } catch (err) {
    yield put(getContactCountApiState.failure())
  }
}

export function* getContactSettingsSaga() {
  yield put(getContactSettingsApiState.request())
  try {
    const response = yield call(getContactSettingsApi)
    yield put(getContactSettingsApiState.success(response))
  } catch (err) {
    yield put(getContactSettingsApiState.failure())
    yield put(addToast(GET_SETTINGS_FAILURE_TOAST_CONFIG()))
  }
}

export function* updateContactSettingsSaga({ payload }) {
  yield put(updateContactSettingsApiState.request())
  try {
    const response = yield call(updateContactSettingsApi, payload)
    yield put(updateContactSettingsApiState.success(response))
  } catch (err) {
    yield put(updateContactSettingsApiState.failure())
    yield put(addToast(UPDATE_SETTINGS_FAILURE_TOAST_CONFIG()))
  }
}

export function* bulkDeleteSaga({ payload }) {
  yield put(bulkDeleteApiState.request())
  try {
    const response = yield call(bulkDeleteApi, payload)
    yield put(bulkDeleteApiState.success(response))
    const currentLocation = yield select(selectLocation)
    if (currentLocation.pathname === CONTACTS && isEmpty(currentLocation.search)) {
      yield put(
        getContactList({
          group: 'all',
          page: 1,
          sort: { lastContactedAt: -1 },
        }),
      )
      yield put(getContactCount({}))
    } else {
      yield put(push({ pathname: CONTACTS }))
    }
    yield put(addToast(BULK_DELETE_SUCCESS_TOAST_CONFIG()))
    yield call([tracker, 'trackEvent'], EVENT_PEOPLE_CONCEPT.EVENT_NAME.BULK_DELETE_SUCCESS)
  } catch (err) {
    yield put(addToast(BULK_DELETE_FAILURE_TOAST_CONFIG()))
    yield put(bulkDeleteApiState.failure())
  }
}

export function* bulkAddTagSaga({ payload }) {
  yield put(bulkAddTagApiState.request())
  try {
    const response = yield call(bulkAddTagApi, {
      contactIds: payload?.contactIds,
      tagId: payload.tag?._id,
    })
    yield put(bulkAddTagApiState.success(response))
    const currentLocation = yield select(selectLocation)
    if (currentLocation.pathname === CONTACTS && isEmpty(currentLocation.search)) {
      yield put(
        getContactList({
          group: 'all',
          page: 1,
          sort: { lastContactedAt: -1 },
        }),
      )
      yield put(getContactCount({}))
    } else {
      yield put(push({ pathname: CONTACTS }))
    }
    yield put(addToast(BULK_ADD_TAG_SUCCESS_TOAST_CONFIG()))
    yield call([tracker, 'trackEvent'], BULK_ADDED_TAG_SUCCESS, {
      tag_name: get(payload, 'tag.name', ''),
      success_tag_by: payload.isNewTag
        ? SUCCESS_TAG_BY.CREATE_NEW_TAG
        : SUCCESS_TAG_BY.CHOOSE_EXISTING_TAG,
      number_of_contact: get(payload, 'contactIds.length', ''),
    })
  } catch (err) {
    yield put(addToast(BULK_ADD_TAG_FAILURE_TOAST_CONFIG()))
    yield put(bulkAddTagApiState.failure())
  }
}

export function* createAndBulkAddTagSaga({ payload }) {
  const tag = yield call(createTagSaga, { payload })
  yield put(
    bulkAddTag({
      tag,
      contactIds: payload.contactIds,
      isNewTag: true,
    }),
  )
}

export function* applySortContactSaga({ payload }) {
  const { sortedKey, sortedVal } = payload
  const currentLocation = yield select(selectLocation)
  const sortObj = {
    sort: { [sortedKey]: toNumber(sortedVal) === -1 ? 1 : -1 },
  }
  const newSearch = updateQueryString(currentLocation.search, sortObj)
  yield put(push({ pathname: currentLocation.pathname, search: newSearch }))
}

export function* markAsGiftSentSaga({ payload }) {
  const giftType = payload?.giftType
  yield put(markAsGiftSentApiState.request())
  try {
    const response = yield call(markAsGiftSentApi, payload)
    yield put(addToast(MARK_AS_GIFT_SENT_SUCCESS_TOAST_CONFIG()))
    yield put(markAsGiftSentApiState.success({ ...response, giftType }))
  } catch (err) {
    yield put(addToast(MARK_AS_GIFT_SENT_FAILURE_TOAST_CONFIG()))
    yield put(markAsGiftSentApiState.failure())
  }
}

export function* unmarkAsGiftSentSaga({ payload }) {
  const giftType = payload?.giftType
  yield put(unmarkAsGiftSentApiState.request())
  try {
    const response = yield call(unmarkAsGiftSentApi, payload)
    yield put(addToast(UNMARK_AS_GIFT_SENT_SUCCESS_TOAST_CONFIG()))
    yield put(unmarkAsGiftSentApiState.success({ ...response, giftType }))
  } catch (err) {
    yield put(addToast(UNMARK_AS_GIFT_SENT_FAILURE_TOAST_CONFIG()))
    yield put(unmarkAsGiftSentApiState.failure())
  }
}

export function* watchGetContactList() {
  yield takeLatest(GET_CONTACT_LIST_CALL, getContactListSaga)
}

export function* watchGetContactSettings() {
  yield takeLatest(GET_CONTACT_SETTINGS_CALL, getContactSettingsSaga)
}

export function* watchUpdateContactSettings() {
  yield takeLatest(UPDATE_CONTACT_SETTINGS_CALL, updateContactSettingsSaga)
}

export function* watchCreateAndTagContact() {
  yield takeLatest(CREATE_AND_TAG_CONTACT_DASHBOARD, createAndTagContactSaga)
}

export function* watchTagContact() {
  yield takeLatest(TAG_CONTACT_DASHBOARD, tagContactSaga)
}

export function* watchUntagContact() {
  yield takeLatest(UNTAG_CONTACT_DASHBOARD, untagContactSaga)
}

export function* watchStarContact() {
  yield takeLatest(STAR_CONTACT_DASHBOARD, starContactSaga)
}

export function* watchGetContactCount() {
  yield takeLatest(GET_CONTACT_COUNT_CALL, getContactCountSaga)
}

export function* watchBulkDelete() {
  yield takeLatest(BULK_DELETE, bulkDeleteSaga)
}

export function* watchBulkAddTag() {
  yield takeLatest(BULK_ADD_TAG, bulkAddTagSaga)
}

export function* watchCreateAndBulkAddTag() {
  yield takeLatest(CREATE_AND_BULK_ADD_TAG, createAndBulkAddTagSaga)
}

export function* watchApplySort() {
  yield takeLatest(APPLY_SORT_CONTACT, applySortContactSaga)
}

export function* watchMarkAsGiftSent() {
  yield takeLatest(MARK_AS_GIFT_SENT, markAsGiftSentSaga)
}

export function* watchUnmarkAsGiftSent() {
  yield takeLatest(UNMARK_AS_GIFT_SENT, unmarkAsGiftSentSaga)
}

export function* watchSendMessage() {
  yield takeLatest(SEND_MESSAGE, sendMessageToContactSaga)
}

export function* rootSaga() {
  yield all([
    watchGetContactList(),
    watchGetContactSettings(),
    watchUpdateContactSettings(),
    watchCreateAndTagContact(),
    watchTagContact(),
    watchUntagContact(),
    watchStarContact(),
    watchGetContactCount(),
    watchBulkDelete(),
    watchBulkAddTag(),
    watchApplySort(),
    watchSendMessage(),
    watchCreateAndBulkAddTag(),
    watchMarkAsGiftSent(),
    watchUnmarkAsGiftSent(),
  ])
}

export default rootSaga
