import { push } from 'connected-react-router'
import { takeLatest, all, put, select } from 'redux-saga/effects'

import {
  MIN_MONTHLY_INCOME_RANGE,
  MAX_MONTHLY_INCOME_RANGE,
} from 'components/contacts/organisms/filter-form/constants'

import { updateQueryString } from '../helpers'
import { selectLocation } from '../selectors'

import { closeFilterModal } from './actions'
import { APPLY_FILTER } from './constants'

export const parseFilterValues = values => {
  const selectedRoles = Object.keys(values?.role || {}).filter(key => values.role[key])
  const selectedSources = Object.keys(values?.source || {}).filter(key => values.source[key])
  return {
    role: selectedRoles.join('_'),
    source: selectedSources.join('_'),
    startBirthDate: values?.dateOfBirthRange?.start,
    endBirthDate: values?.dateOfBirthRange?.end,
    state: values?.location,
    minIncome:
      values?.monthlyIncomeRange?.min > MIN_MONTHLY_INCOME_RANGE
        ? values?.monthlyIncomeRange?.min
        : undefined,
    maxIncome:
      values?.monthlyIncomeRange?.max < MAX_MONTHLY_INCOME_RANGE
        ? values?.monthlyIncomeRange?.max
        : undefined,
    lastContact: values?.recentContact,
  }
}

export function* applyFilterSaga({ payload }) {
  const applyingFilter = parseFilterValues(payload)

  const currentLocation = yield select(selectLocation)
  const newSearch = updateQueryString(currentLocation.search, applyingFilter)
  yield put(push({ pathname: currentLocation.pathname, search: newSearch }))

  yield put(closeFilterModal())
}

export function* watchApplyFilter() {
  yield takeLatest(APPLY_FILTER, applyFilterSaga)
}

export function* rootSaga() {
  yield all([watchApplyFilter()])
}

export default rootSaga
