import styled from "styled-components"
import { COLOR } from "styles/settings"
import { Link } from "legacy/components/buttons/buttons"
import { Div } from "components/layout/main"

import { ActionButton } from "legacy/components/pro/landing/price-plan-card/button"
import { SOURCE_SANS_PRO_FONT_FAMILY } from "components/typography"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 5px solid ${COLOR.lineGrey};
`

export const BlueRadialGradientBackground = styled.div`
  position: relative;
  background: radial-gradient(
    39.24% 76.84% at 69.62% 0%,
    ${COLOR.blueLV6} 0.91%,
    ${COLOR.white} 100%
  );
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 100%;
  top: 0px;
  @media (max-width: 991px) {
    transform: unset;
    background: linear-gradient(
      180deg,
      ${COLOR.white} -87.25%,
      ${COLOR.blueLV6} 1.46%,
      ${COLOR.white} 63.48%
    );
  }
`

export const BackgroundDecoratorLeft = styled.img`
  pointer-events: none;
  position: absolute;
  width: 95.59px;
  height: 282.05px;
  display: block;
  left: 0px;
  top: 0px;
  @media (max-width: 991px) {
    display: none;
  }
`

export const BackgroundDecoratorRight = styled.img`
  pointer-events: none;
  position: absolute;
  width: 63.25px;
  height: 282.05px;
  display: block;
  bottom: 329px;
  right: 0px;
  @media (max-width: 991px) {
    display: none;
  }
`

export const SupportLink = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    padding-left: 25px;
  }
`

export const Wrapper = styled(Div)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
  @media (max-width: 991px) {
    width: 335px;
  }
`

export const PlanCardsWrapper = styled.div`
  display: flex;
  width: 930px;
  margin: 0 85px 12px 85px;
  @media (max-width: 991px) {
    margin: 0;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    padding: 0 25px;
  }
`

export const SourceSansProActionButton = styled(ActionButton)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`

export const FinePrintWrapper = styled.div`
  padding: 0 0 85px 5px;
  width: 930px;
  @media (max-width: 991px) {
    width: 100%;
    padding: 0 0 15px 0;
  }
`
