import React from "react"
import classnames from "classnames/bind"
import styled from "styled-components"
import styles from "../../legacy/components/forms/hubspot-form.scss"
import { isIOS } from "../../legacy/components/helper/ua-parser-js"

const c = classnames.bind(styles)

export const FormWrapper = styled.div`
  min-height: 100px;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: -30px;
  background: white;
`

export const Loading = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  :after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #3b99fc;
    border-color: #3b99fc transparent #3b99fc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default class HubspotForm extends React.Component {
  state = { loading: false }

  componentDidMount() {
    this.setState({ loading: true })
    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are all methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }))
    this.addFunctionForWindow()
    const { formId, portalId, id, css, inlineMessage } = this.props
    this.timerInterval = setInterval(this.checkLoaded, 100)
    const script = document.createElement("script")
    const timeout = this.props.submitDelay || 5000
    script.innerHTML = `hbspt.forms.create({  
      css: "${css}", 
      portalId: "${portalId}", 
      formId: "${formId}",
      target: "#${id}",
      onFormSubmit: function(form){ 
        setTimeout(function() {
           window.handleOnSubmitHubspot()
        }, ${timeout})
      },
      ${
        isIOS() && this.props.redirectPath
          ? `redirectUrl: "${this.props.redirectPath}?to=oldDashboard",`
          : ""
      }
      onFormReady: function($form) {
        console.log("form", $form); 
        window.handleOnFormReadyHubspot() 
      },
      onFormSubmitted: function() {
        window.handleOnFormSubmittedHubspot() 
      },
      ${
        isIOS && this.props.redirectPath
          ? `redirectUrl: "${this.props.redirectPath}?to=oldDashboard",`
          : ""
      }
      ${inlineMessage ? `inlineMessage: "${inlineMessage}"` : ""}
    });`

    document.body.appendChild(script)
  }

  addFunctionForWindow() {
    window.handleOnSubmitHubspot = this.props.onFormSubmit
      ? this.props.onFormSubmit.bind(this)
      : Function
    window.handleOnFormReadyHubspot = this.props.onFormReady
      ? this.props.onFormReady.bind(this)
      : Function
    window.handleOnFormSubmittedHubspot = this.props.onFormSubmitted
      ? this.props.onFormSubmitted.bind(this)
      : Function
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval)
  }

  checkLoaded = () => {
    const element = document.getElementsByClassName("hbspt-form")
    if (element) {
      this.setState({ loading: false })
      clearInterval(this.timerInterval)
    }
  }

  render() {
    const { id, classForm, classContent } = this.props
    const { loading } = this.state
    return (
      <FormWrapper>
        {loading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        <div className={c("hubspot-content", classContent)}>
          <div className={c("form", classForm)}>
            <div id={id} className="form-content-hubspot" />
          </div>
        </div>
      </FormWrapper>
    )
  }
}
