import produce from 'immer'

import {
  GET_GIFTS_REQUEST,
  GET_GIFTS_SUCCESS,
  GET_GIFTS_FAILURE,
  GENERATE_GIFT_LINK_REQUEST,
  GENERATE_GIFT_LINK_SUCCESS,
  GENERATE_GIFT_LINK_FAILURE,
  SET_SELECTED_GIFT,
} from './constants'

export const initialState = {
  data: [],
  isLoadingGiftsFetching: false,
  isLoadingGiftLinkGenerating: false,
  selectedGift: null,
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    switch (type) {
      case GET_GIFTS_REQUEST:
        draft.isLoadingGiftsFetching = true
        break
      case GET_GIFTS_SUCCESS:
        draft.data = payload
        draft.isLoadingGiftsFetching = false
        break
      case GET_GIFTS_FAILURE:
        draft.isLoadingGiftsFetching = false
        break
      case GENERATE_GIFT_LINK_REQUEST:
        draft.isLoadingGiftLinkGenerating = true
        break
      case GENERATE_GIFT_LINK_SUCCESS:
        if (state.selectedGift?._id === payload?.giftId) {
          draft.selectedGift = {
            ...state.selectedGift,
            url: payload?.url,
          }
        }
        draft.isLoadingGiftLinkGenerating = false
        break
      case GENERATE_GIFT_LINK_FAILURE:
        draft.isLoadingGiftLinkGenerating = false
        break
      case SET_SELECTED_GIFT:
        if (payload.gift) {
          draft.selectedGift = {
            _id: payload.gift?._id,
          }
        } else {
          draft.selectedGift = null
        }
        break
      default:
        break
    }
  })

export default reducer
