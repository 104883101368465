import { createSelector } from 'reselect'
import get from 'lodash/get'

import { STATUS } from 'legacy/constants/credit-report-consts'
import { PRINT_REPORTS_PAGE } from 'legacy/constants/route-consts'
import { PRINT_REPORTS_RENTER_PAGE } from 'constants/route'
import { selectIsFetchingCreditReport } from './screening-reports/selectors'
import { PARTICIPANT_TYPE } from './constants'

const getApplication = state => get(state, 'application', {})
const getPdfReport = state => get(state, 'pdfReport', {})
const getSubmissionParticipant = state => get(state, 'submissionParticipant', {})
const getSubscriptions = state => get(state, 'subscriptions', {})
const getUser = state => state?.user || {}

export const selectApplication = createSelector(getApplication, state => state)

export const selectLraFormAvailable = createSelector(getApplication, state =>
  get(state, ['formAvailable', 'lra'], {}),
)

export const selectApplicationDetail = createSelector(selectApplication, state =>
  get(state, 'applicationDetail', {}),
)

export const selectErrorApplication = createSelector(selectApplication, state =>
  get(state, 'errorApplication', {}),
)

export const selectRentalDetail = createSelector(selectApplication, state =>
  get(state, 'rentalDetail', {}),
)

export const selectScreeningOption = createSelector(selectRentalDetail, state =>
  get(state, 'screeningOption', {}),
)

export const selectRentalDocuments = createSelector(selectRentalDetail, state =>
  get(state, 'documents'),
)

export const selectErrorRental = createSelector(selectApplication, state =>
  get(state, 'errorRental', {}),
)

export const selectRentalCreditReport = createSelector(selectRentalDetail, state =>
  get(state, 'credit_report', {}),
)

export const selectIsFetchingRentalDetail = createSelector(selectApplication, state =>
  get(state, 'isFetchingRentalDetail', true),
)

export const selectIsFetchingAppDetail = createSelector(selectApplication, state =>
  get(state, 'isFetchingAppDetail', true),
)

export const selectIsFetchingFormAvailable = createSelector(selectApplication, state =>
  get(state, 'isFetchingFormAvailable', true),
)

export const selectPdfReport = createSelector(getPdfReport, state => state)

export const selectReportData = createSelector(
  selectPdfReport,
  ({ reportDownloadData, reportFileName }) => ({
    fileData: reportDownloadData,
    fileName: reportFileName,
  }),
)

export const selectLraData = createSelector(
  selectPdfReport,
  ({ LRADownloadData, LRAFileName }) => ({
    fileData: LRADownloadData,
    fileName: LRAFileName,
  }),
)

export const selectSubscriptions = createSelector(getSubscriptions, state => state)

export const selectSubscriptionIsFetching = createSelector(selectSubscriptions, state =>
  get(state, 'isFetching', false),
)

export const selectIsAppAndCreditFetching = createSelector(
  [selectIsFetchingAppDetail, selectIsFetchingCreditReport],
  (isFetchingAppDetail, isFetchingCreditReport) => isFetchingAppDetail || isFetchingCreditReport,
)

// screening report expired selector
export const selectIsReportExpired = createSelector(selectRentalCreditReport, rentalCreditReport =>
  get(rentalCreditReport, 'isExpired'),
)
export const selectIsSubmissionExpired = createSelector(
  selectRentalCreditReport,
  rentalCreditReport =>
    get(rentalCreditReport, 'isIDMAExpired') && get(rentalCreditReport, 'status') !== STATUS.OPENED,
)
export const selectIsExpired = createSelector(
  [selectIsReportExpired, selectIsSubmissionExpired],
  (isReportExpired, isSubmissionExpired) => isReportExpired || isSubmissionExpired,
)

// used for ref-check report only
export const selectParticipantProperty = createSelector(getSubmissionParticipant, participant =>
  get(participant, 'participantData.property'),
)

export const selectParticipantType = createSelector(getSubmissionParticipant, participant =>
  get(participant, 'participantData.type'),
)

export const selectParticipantId = createSelector(getSubmissionParticipant, participant =>
  get(participant, 'participantData._id'),
)

export const selectIsSharedParticipant = createSelector(getSubmissionParticipant, participant =>
  get(participant, 'isSharedParticipant'),
)

export const selectIsSharedReport = createSelector(
  selectParticipantType,
  type => type === PARTICIPANT_TYPE.PARTICIPANT,
)

export const selectIsOwnReport = createSelector(
  selectParticipantType,
  type => type === PARTICIPANT_TYPE.OWNER,
)

export const selectShareOption = createSelector(getSubmissionParticipant, participant =>
  get(participant, 'participantData.shareOption', {}),
)

export const selectIsFetchingParticipant = createSelector(getSubmissionParticipant, participant =>
  get(participant, 'isFetching'),
)

export const selectAuthorize = createSelector(getSubscriptions, ({ authorize }) => authorize)

// TODO: this should instead return props.isPrintPage which got from ReportPageContext Context Consumer at Parent level.
export const selectIsPrintPage = (_, props) =>
  get(props, 'match.path', '').includes(`${PRINT_REPORTS_PAGE}/:type`)

export const selectIsPrintRenterPage = (_, props) =>
  get(props, 'match.path', '').includes(`${PRINT_REPORTS_RENTER_PAGE}/:type`)

export const makeSelectIsShareAvailable = () =>
  createSelector(
    [selectIsPrintPage, selectIsPrintRenterPage, selectIsOwnReport],
    (isPrintPage, isPrintRenterPage, isOwnReport) =>
      !isPrintPage && !isPrintRenterPage && isOwnReport,
  )

export const selectUser = createSelector(getUser, ({ profile }) => profile)

export const selectIsRenter = createSelector(
  [selectUser, selectRentalDetail],
  ({ _id: userId }, { renter_id: renterId }) => {
    if (userId && renterId && userId.toString() === renterId.toString()) return true
    return false
  },
)
