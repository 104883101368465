import { Icon } from '@rentspree/component-v2/dist/icon'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const StarContainer = styled.div`
  display: flex;
  ${({ margin }) => margin && `margin: ${margin};`} cursor: pointer;
`

const Star = ({ className, margin, isActive, onClick }) => (
  <StarContainer className={className} margin={margin}>
    {isActive ? (
      <Icon name="filled-star" size="md" color={COLOR.yellowLight} onClick={onClick} />
    ) : (
      <Icon name="empty-star" size="md" color={COLOR.placeholderGrey} onClick={onClick} />
    )}
  </StarContainer>
)

Star.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Star
