import { SOURCE_SANS_PRO_FONT_FAMILY } from "components/typography"
import styled from "styled-components"
import {Container} from "legacy/containers/pro/landing/landing-styled"
import { COLOR } from "styles/settings"
import { Div } from "components/layout/main"

export const SourceSansProContainer = styled(Container)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`

export const BillingPeriodToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: height 0.5s;
  margin: 0 0 30px 0;
`

export const PlanFeatureTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 86px 12px 86px;
  @media (max-width: 991px) {
    margin: 0;
    flex-direction: column-reverse;
    align-items: center;
    width
  }
`

export const PlanFeatureTableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const PlanCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  justify-content: center;
  margin: 0;
  padding: 0 86px 0 86px;
  @media (max-width: 991px) {
    margin: 0;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    padding: 0 25px;
  }
`

export const FeatureCellWrapper = styled.div`
  flex: 1;
`

export const FeatureRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1300px;
  margin: 0;
  padding: 0 86px 0 86px;
  justify-content: center;
  @media (max-width: 991px) {
    display: none;
  }
  ${FeatureCellWrapper} {
    border-left: 1px solid ${COLOR.placeholderGrey};
    border-bottom: 1px solid ${COLOR.placeholderGrey};
  }
  ${FeatureCellWrapper}:last-child {
    border-right: 1px solid ${COLOR.placeholderGrey};
  }
`

export const Wrapper = styled(Div)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
  @media (max-width: 991px) {
    width: 100%;
  }
`