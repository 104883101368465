import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const BackButton = styled(MuiButton)(() => ({
  color: 'black',
  '&:hover': {
    color: '#606060',
    textDecoration: 'underline',
  },
  textDecoration: 'underline',
}))

export const NextButton = styled(MuiButton)(() => ({
  color: 'white',
  backgroundColor: 'black',
  '&:hover': {
    color: 'white',
    backgroundColor: '#606060',
  },
}))
