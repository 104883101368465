import Hr from '@rentspree/component-v2/dist/hr'
import lowerCase from 'lodash/lowerCase'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CounterBadge from 'components/contacts/atoms/counter-badge'
import Tag from 'components/contacts/atoms/tag'
import { useOnClickOutside } from 'containers/contacts/hooks'
import { usePrevious } from 'hooks/use-previous-add-tag'
import { breakpoints, COLOR } from 'styles/settings'

import AddTagSection from './add-tag-section'

const Container = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;

  ${breakpoints.mobile(`
    position: unset;
  `)} @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .fade-in {
    animation-name: fade-in;
    animation-duration: 0.3s;
  }

  .fade-out {
    animation-name: fade-out;
    animation-duration: 0.3s;
  }
`

export const AddingTagContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 124px;
  ${({ scrollEnabled }) => scrollEnabled && 'overflow-y: scroll'};
`

const MultipleTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 200px;
  max-height: 355px;
  top: 31px;
  right: 0;
  z-index: 1;

  background-color: ${COLOR.white};
  border: 1px solid ${COLOR.grayLv4};
  border-radius: 6px;
  box-shadow: 0px 10px 30px 4px rgba(0, 37, 112, 0.15);
  ${breakpoints.mobile(`
    right: unset;
    left: 0;
  `)} transition: 0.3s;
  opacity: ${({ isShowingDropdown }) => (isShowingDropdown ? '1' : '0')};
  visibility: ${({ isShowingDropdown }) => (isShowingDropdown ? 'visible' : 'hidden')};
`

export const MultipleTagDropdown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 170px;
  ${({ scrollEnabled }) => scrollEnabled && 'overflow-y: scroll'};
  padding: 15px;
`

const TagContainer = styled(Tag)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const MultipleTag = ({
  number,
  tags,
  onUntagContact,
  isLoading,
  allTags = [],
  onTagContact,
  onShowMoreTag,
  onFocus,
  onCreateAndTagContact,
  disabledButton,
}) => {
  const [isShowingDropdown, setIsShowingDropdown] = React.useState(false)
  const [animationName, setAnimationName] = React.useState('fade-in')
  const [displayedTags, setDisplayedTags] = React.useState([])
  const prevTags = usePrevious(displayedTags)
  const bottomDisplaysTagRef = React.useRef(null)

  const multipleTagDropdownRef = React.useRef(null)

  const onToggleDisplay = e => {
    onShowMoreTag()
    e.stopPropagation()
    if (!isShowingDropdown) setIsShowingDropdown(!isShowingDropdown)
    else {
      setTimeout(() => {
        setIsShowingDropdown(!isShowingDropdown)
      }, 300)
    }
  }

  const outsideClickHandler = () => {
    setAnimationName('fade-out')
    setTimeout(() => {
      setIsShowingDropdown(false)
      setAnimationName('fade-in')
    }, 300)
  }
  useOnClickOutside(multipleTagDropdownRef, outsideClickHandler)

  const handleTagContact = tag => {
    onTagContact(tag)
  }

  React.useEffect(
    () =>
      bottomDisplaysTagRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      }),
    [prevTags],
  )

  return (
    <Container
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <TagContainer
        id={tags[0]?._id}
        key={tags[0]?._id}
        name={tags[0]?.name}
        color={tags[0]?.color}
        maxWidth="fit-content"
        margin="0 5px 0 0"
        onClick={tags.length === 1 ? onToggleDisplay : undefined}
        onRemove={() => onUntagContact(tags[0]?._id)}
      />
      {tags.length > 1 && (
        <CounterBadge
          number={number}
          backgroundColor={isShowingDropdown && COLOR.blueLV6}
          onClick={onToggleDisplay}
        />
      )}
      {isShowingDropdown && (
        <MultipleTagContainer
          ref={multipleTagDropdownRef}
          isShowingDropdown={isShowingDropdown}
          scrollEnabled={tags.length > 4}
          className={animationName}
        >
          <MultipleTagDropdown scrollEnabled={tags.length > 4}>
            {sortBy(tags, [tag => lowerCase(tag?.name)]).map(tag => (
              <Tag
                id={tag?._id}
                key={tag?._id}
                name={tag?.name}
                color={tag?.color}
                maxWidth="fit-content"
                margin="0 0 15px 0"
                iconName="close"
                iconMargin="0 0 0 5px"
                onRemove={() => onUntagContact(tag?._id, true)}
              />
            ))}
            <div ref={bottomDisplaysTagRef} />
          </MultipleTagDropdown>

          <Hr margin="0 0 15px 0" />

          <AddTagSection
            tags={tags}
            allTags={allTags}
            isLoading={isLoading}
            onCreateAndTagContact={onCreateAndTagContact}
            handleTagContact={handleTagContact}
            onFocus={() => onFocus(true)}
            disabledButton={disabledButton}
            setDisplayedTags={setDisplayedTags}
          />
        </MultipleTagContainer>
      )}
    </Container>
  )
}

export default MultipleTag

MultipleTag.propTypes = {
  number: PropTypes.number,
  tags: PropTypes.array,
  onUntagContact: PropTypes.func,
}
