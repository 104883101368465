import React from 'react'
import isEmpty from 'lodash/isEmpty'
import isFinite from 'lodash/isFinite'
import { Redirect } from 'react-router-dom'

import { ButtonSetLeaseAgreement } from 'components/buttons/button-set'
import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import BooleanCard from 'components/molecules/boolean-card'
import { ScreeningRequestForm } from 'components/organisms/screening-request-form'
import CheckboxImage from 'components/atoms/checkbox-image'
import RadioImage from 'components/atoms/radio-image'
import ComponentList from 'components/atoms/component-list'
import S16 from '@rentspree/component-v2/dist/typography/s16'

import { PARTNER } from 'legacy/constants/partner-consts'
import { RequestWrapper, RequestContainer } from 'legacy/components/request/request-main-style'
import { LoadingOverlay } from 'legacy/components/request/left-content/index'
import { SectionHeader, Section } from 'legacy/components/request/heading'
import { PayerBoxWrapper, PayerBox } from 'legacy/components/request/select-payer'
import { PAYER } from 'legacy/constants/request-const'
import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import * as ERRORS from 'legacy/constants/error-messages'
import {
  SELECT_APPLICATION,
  SELECT_CREDIT_REPORT,
  SELECT_CRIMINAL,
  SELECT_EVICTION,
} from 'containers/tenant-screening/request/constants'
import { PRICING_SCHEME } from 'legacy/constants/screening-plan-consts'
import LegalRestrictionText from 'components/molecules/legal-restriction-text'
import { useQuery } from '@tanstack/react-query'
import { getUserPlan } from 'utils/get-user-plan'
import { ProOptionForm } from 'components/organisms/pro-option-form'
import { SUBSCRIPTION_PLAN } from 'constants/subscription'
import { COLOR } from 'components/constants'
import { shouldRedirectToSelectAgentTypePage } from 'containers/tenant-screening/request/utils'
import { getTotalPrice } from './helper'

export const TenantScreeningRequestStepOptionTemplate = ({
  priceScheme,
  selectReport,
  isGetScreeningPlanError,
  selectPayer,
  payer,
  backToDashBoard,
  onSaveAndNext,
  onClickPrevBtn,
  isFetching,
  isSubmitting,
  subdomain,
  documentProOption,
  refCheckProOption,
  updateDocumentProOption,
  updateRefCheckProOption,
  agentType,
}) => {
  const { application, credit_report: creditReport, criminal, eviction } = priceScheme
  const totalPriceScheme = getTotalPrice(priceScheme)
  const isSubdomainCaa = subdomain === PARTNER.CAA

  const canCreateScreeningRequest = application?.isSelected || creditReport?.isSelected
  const [validationError, setValidationError] = React.useState(false)
  const { data: subscriptionData, isFetched: isSubscriptionDataFetched } = useQuery({
    queryKey: ['subscription_plan_v2'],
    queryFn: getUserPlan,
  })

  const isProUser =
    isSubscriptionDataFetched && subscriptionData?.userPlan === SUBSCRIPTION_PLAN.PRO

  if (isFetching) {
    return <LoadingOverlay />
  }

  const { shouldRedirect, redirectPath } = shouldRedirectToSelectAgentTypePage({
    agentType,
  })
  if (shouldRedirect) {
    return <Redirect push to={redirectPath} />
  }

  if (isGetScreeningPlanError) {
    return (
      <FailedToFetch
        withBreadcrumb
        withReportTabs
        noMargin
        title={ERRORS.REQUEST.GET_SCREENING_PLAN.TITLE}
        text={ERRORS.REQUEST.GET_SCREENING_PLAN.MESSAGE}
      />
    )
  }

  const convertToPrice = value => {
    const parsedValue = parseInt(value, 10)
    const priceText = !isFinite(parsedValue) && 0
    return priceText === 0 || parsedValue === 0 ? 'Free' : `$${value}`
  }

  const handleNextButton = () => {
    if (!canCreateScreeningRequest) {
      setValidationError(true)
    } else {
      onSaveAndNext()
    }
  }

  return (
    <RequestWrapper>
      <RequestContainer>
        <TitleWithSubtitle title="Select Application Options" />
        <Section>
          <SectionHeader title="Who will pay the application fee?" />
          <PayerBoxWrapper>
            <PayerBox
              label="Applicants"
              isSelected={payer === PAYER.RENTER}
              onClick={() => {
                if (isEmpty(payer)) {
                  selectReport(SELECT_APPLICATION, true)
                }
                selectPayer(PAYER.RENTER)
              }}
              dataTestId="screening-option-applicant-pay-radio"
            />
            <PayerBox
              label="Me"
              isSelected={payer === PAYER.AGENT}
              onClick={() => {
                if (isEmpty(payer)) {
                  selectReport(SELECT_APPLICATION, true)
                }
                selectPayer(PAYER.AGENT)
              }}
              dataTestId="screening-option-agent-pay-radio"
            />
          </PayerBoxWrapper>
        </Section>
        {!isEmpty(priceScheme) && (
          <>
            <Section mb={30}>
              <SectionHeader title="Select Application" />
              <ComponentList bottomGap={10}>
                {!application?.disabled && !isSubdomainCaa && (
                  <BooleanCard
                    id="RentSpreeApplicationBooleanCard"
                    title={PRICING_SCHEME.application.text}
                    ascent={convertToPrice(application.price)}
                    icon={RadioImage}
                    checked={application.isSelected}
                    onClick={() => {
                      selectReport(SELECT_APPLICATION, true)
                      setValidationError(false)
                    }}
                    dataTestId="screening-option-rentspree-application-radio"
                  />
                )}
                <BooleanCard
                  id="NoApplicationBooleanCard"
                  title={PRICING_SCHEME.no_application.text}
                  ascent={null}
                  icon={RadioImage}
                  checked={!application.isSelected}
                  onClick={() => {
                    selectReport(SELECT_APPLICATION, false)
                    if (!creditReport.isSelected) {
                      selectReport(SELECT_CREDIT_REPORT, true)
                      selectReport(SELECT_CRIMINAL, !criminal?.disabled && true)
                      selectReport(SELECT_EVICTION, !eviction?.disabled && true)
                    }
                  }}
                  dataTestId="screening-option-no-application-radio"
                />
              </ComponentList>
            </Section>
            <Section mb={10}>
              <SectionHeader title="Customize the screening reports" />
              <ScreeningRequestForm totalPrice={totalPriceScheme} isShowPrice>
                <ComponentList bottomGap={10}>
                  {!creditReport?.disabled && (
                    <BooleanCard
                      title={PRICING_SCHEME.credit_report.text}
                      subtitle={PRICING_SCHEME.credit_report.subtext}
                      ascent={convertToPrice(creditReport.price)}
                      checked={creditReport.isSelected}
                      icon={CheckboxImage}
                      alt="credit_report"
                      onClick={() => {
                        selectReport(SELECT_CREDIT_REPORT, !creditReport.isSelected)
                        selectReport(
                          SELECT_CRIMINAL,
                          !criminal?.disabled && !creditReport.isSelected,
                        )
                        selectReport(
                          SELECT_EVICTION,
                          !eviction?.disabled && !creditReport.isSelected,
                        )
                        setValidationError(false)
                      }}
                      dataTestId="screening-option-credit-report-checkbox"
                      validationError={validationError}
                    />
                  )}
                  {!criminal?.disabled && (
                    <BooleanCard
                      title={PRICING_SCHEME.criminal.text}
                      subtitle={PRICING_SCHEME.criminal.subtext}
                      ascent={convertToPrice(criminal.price)}
                      checked={criminal.isSelected}
                      disabled={!creditReport.isSelected}
                      icon={CheckboxImage}
                      onClick={() => {
                        if (creditReport.isSelected)
                          selectReport(SELECT_CRIMINAL, !criminal.isSelected)
                      }}
                      alt="criminal"
                      dataTestId="screening-option-criminal-checkbox"
                    />
                  )}
                  {!eviction?.disabled && (
                    <BooleanCard
                      title={PRICING_SCHEME.eviction.text}
                      subtitle={PRICING_SCHEME.eviction.subtext}
                      ascent={convertToPrice(eviction.price)}
                      checked={eviction.isSelected}
                      disabled={!creditReport.isSelected}
                      icon={CheckboxImage}
                      alt="eviction"
                      onClick={() => {
                        if (creditReport.isSelected)
                          selectReport(SELECT_EVICTION, !eviction.isSelected)
                      }}
                      dataTestId="screening-option-eviction-checkbox"
                    />
                  )}
                </ComponentList>
                {validationError && (
                  <S16 id="validationErrorText" color={COLOR.negativeRed} weight="400" mt={10}>
                    Please make a selection
                  </S16>
                )}
                <LegalRestrictionText />
                {isProUser && (
                  <ProOptionForm
                    documentOption={documentProOption}
                    refCheckOption={refCheckProOption}
                    onDocumentOptionChange={(documentKey, change) =>
                      updateDocumentProOption({ documentKey, change })
                    }
                    onRefCheckOptionChange={(refCheckKey, isSelected) =>
                      updateRefCheckProOption({ refCheckKey, isSelected })
                    }
                  />
                )}
              </ScreeningRequestForm>
            </Section>
          </>
        )}

        <ButtonSetLeaseAgreement
          isShowPrev
          withSystemLayout
          onBorderDesktop
          onBorder
          prevText="Back"
          hideBackButton
          text="Back to dashboard"
          onClickBackToDashboardBtn={backToDashBoard}
          onClickPrevBtn={onClickPrevBtn}
          hasIconNextBtn
          continueText="Next"
          onSaveAndNext={handleNextButton}
          isLoading={isSubmitting}
          disabledNextButton={isEmpty(payer)}
          margin="0 0 40px 0"
          dataTestKey="screening-options-page"
        />
      </RequestContainer>
    </RequestWrapper>
  )
}
