import { compose } from 'redux'
import React from 'react'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import { getLocalItem, removeLocalItem } from '@rentspree/cookie'
import { query, buildPath } from '@rentspree/path'
import { MainContent } from 'components/layout/main'
import B16 from '@rentspree/component-v2/dist/typography/b16'
import { COLOR } from 'styles/settings'
import { FEATURE_BLOCK_EVENT } from 'tracker/const'
import tracker from 'tracker'
import {
  Section,
  SectionLogo,
  FlexCol,
  FlexBox,
  SectionContainer,
  Notification,
} from '../../components/layout/section'
import { H1, Hr, Text, I, P } from '../../components/typography'
import { PanelBox } from '../../components/panel/panel-box'
import * as PATH from '../../constants/route-consts'
import { Membership, IconContentRow, EmailBox } from '../../components/pro/my-subscription'
import { LoadingOverlay } from '../../components/request/left-content'
import * as ERRORS from '../../constants/error-messages'
import { FailedToFetch } from '../../components/layout/failed-to-fetch'
import { trackAndRedirect } from '../../helpers/track-and-redirect'
import mySubscriptionConnect from './my-subscription-connect'
import { Button } from '../../components/buttons/buttons'
import { STORAGE } from '../../../constants/cookie'
import { RentSpreeProPackage } from './rentspree-pro-package'
import { newFeatures } from './constants'
import { SUBSCRIPTION_PROFILE_CHECKOUT_URL, PRO_PLAN_VARIANTS } from './landing/constants'
import { withConnect } from './landing/connect'

const REQ_DOCS_ICON = require('../../images/icons/feature-request-doc.png')
const REF_CHECKS_ICON = require('../../images/icons/feature-ref-check.png')

@mySubscriptionConnect
export class MySubscription extends React.Component {
  constructor() {
    super()
    this.state = {
      showNoti: false,
    }
  }

  componentWillMount() {
    const { location } = this.props
    const queryString = query.parse(location.search)

    if (queryString.payment) {
      this.checkAndRedirectSubdomain()
    }
    this.setState({ showNoti: !!queryString.payment })
  }

  checkAndRedirectSubdomain() {
    const cookieSubdomain = getLocalItem(STORAGE.SUBDOMAIN)
    removeLocalItem(STORAGE.SUBDOMAIN)
    try {
      const full = window.location.host
      const parts = full.split('.')
      if (cookieSubdomain && cookieSubdomain !== parts[0]) {
        const subdomainUrl = `${window.location.protocol}//${cookieSubdomain}.${parts[1]}.com${window.location.pathname}${window.location.search}`
        window.location.href = subdomainUrl
      }
    } catch (e) {
      console.error(e)
    }
  }

  hideNoti = () => {
    this.setState({ showNoti: false })
  }

  onStartFreeTrial = () => {
    const { location } = this.props
    const { redirect, featureName = 'rentspree_pro_legacy' } = query.parse(location.search)
    tracker.trackEvent(FEATURE_BLOCK_EVENT.CLICK_CHECKOUT, {
      action_needed: 'trial',
      click_from: featureName,
      subscription_period: 'monthly',
    })
    window.location.href = buildPath(
      SUBSCRIPTION_PROFILE_CHECKOUT_URL,
      {},
      {
        redirect,
        featureName,
      },
    )
  }

  render() {
    const error = ''
    const { mySubscription, isFetchingMySubscription } = this.props.subscriptions
    const { profile, isFetching } = this.props.profile
    const { showNoti } = this.state
    const plans = flatten(this.props.products?.data?.map(product => product.variants)) || []
    const price = plans.find(plan => plan.name === PRO_PLAN_VARIANTS.MONTHLY)?.price || 0
    const monthlyPrice = plans.find(plan => plan.name === PRO_PLAN_VARIANTS.MONTHLY)?.price || 0

    return (
      <MainContent className="-w100" relative>
        <Section className="splash proLanding smallHeader">
          <SectionLogo />
          <FlexCol className="splash-content" noMargin mCenter>
            <H1 align="center" size="1.4em" strong margin="0">
              My Subscription
            </H1>
          </FlexCol>
        </Section>
        {isEmpty(error) ? (
          <Section
            padding="0"
            mPadding="0"
            style={{
              background: COLOR.white,
            }}
          >
            <SectionContainer maxWidth="1200px" flexRow>
              <FlexCol col={2} panelCol>
                <PanelBox header="My Subscription">
                  <Notification hide={!showNoti} bgColor="green">
                    <FlexBox justify="space-between" align="center">
                      <Text color="white">
                        <I color="white" className="fas fa-check" rightSpace="10px" />
                        You have successfully subscribed to RentSpree PRO
                      </Text>
                      <Button className="noti-close-btn" inlineLink onClick={this.hideNoti}>
                        <I color="white" className="fas fa-times" />
                      </Button>
                    </FlexBox>
                  </Notification>

                  {isFetchingMySubscription && <LoadingOverlay className="full" />}
                  <Membership subscription={mySubscription} />
                </PanelBox>

                <PanelBox header="Have a coupon?">
                  {isFetching && <LoadingOverlay className="full" />}
                  <P mb={15}>
                    We can help you apply it to your account. Please call us at (323) 515-7757.
                  </P>
                  <EmailBox text="Your email" email={profile.email} />
                </PanelBox>
              </FlexCol>
              <FlexCol col={2} panelCol>
                <PanelBox header="Try New RentSpree Pro Package">
                  <RentSpreeProPackage
                    textButton="Start 30-Day Free Trial"
                    fullPrice={monthlyPrice.toFixed(2)}
                    totalPrice={price.toFixed(2)}
                    featureTitle="New features for you"
                    features={newFeatures}
                    content={
                      <B16 color={COLOR.cyanBlue} margin="0">
                        <strong>Document Uploads</strong> and
                        <strong> Auto-Reference Checks </strong>
                        are included with RentSpree PRO
                      </B16>
                    }
                    onClick={this.onStartFreeTrial}
                  />
                </PanelBox>
                <PanelBox header="My Benefits">
                  <IconContentRow
                    icon={{
                      src: REQ_DOCS_ICON,
                      alt: 'Document Uploads',
                    }}
                    title="Document Uploads"
                    content="RentSpree can collect supplemental documents from applicants so you don’t have to. Receive proof of income, bank statements, government-issued ID, and more without having to lift a finger."
                    link={{
                      id: 'goToReqDocsLink',
                      text: 'Go to request document',
                    }}
                    onClick={() =>
                      trackAndRedirect(
                        'clickGoToReqDocsFromMySubscription',
                        PATH.REQUEST_DOCS_PAGE,
                        this.props.history,
                      )
                    }
                  />
                  <Hr margin="10px 0 20px" />
                  <IconContentRow
                    icon={{
                      src: REF_CHECKS_ICON,
                      alt: 'auto reference checks',
                    }}
                    title="Auto Reference Checks"
                    content="Want to hear from applicants' previous landlords, employers, and references? We will reach out for you to gather crucial information on prospective tenants."
                    link={{
                      id: 'goToRefChecksLink',
                      text: 'Go to reference check',
                    }}
                    onClick={() =>
                      trackAndRedirect(
                        'clickGoToRefChecksFromMySubscription',
                        PATH.REF_CHECKS_PAGE,
                        this.props.history,
                      )
                    }
                  />
                </PanelBox>
              </FlexCol>
            </SectionContainer>
          </Section>
        ) : (
          <div>
            <FailedToFetch
              noMargin
              withSmallHeader
              text={ERRORS.SUBSCRIPTIONS.UNKNOWN_ERROR.MESSAGE}
              title={ERRORS.SUBSCRIPTIONS.UNKNOWN_ERROR.TITLE}
            />
          </div>
        )}
      </MainContent>
    )
  }
}

export default compose(withConnect)(MySubscription)
