import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex !important;
  align-items: center;
  gap: ${({ gap }) => gap};
`

export const LabelContainer = styled.span`
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  ${({ color }) => `color: ${color}`};
`

export const LabelWithImage = ({ label, icon, color, gap = '6px', alt = '' }) => (
  <Container gap={gap}>
    <img src={icon} alt={alt} />
    <LabelContainer color={color}>{label}</LabelContainer>
  </Container>
)

LabelWithImage.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  gap: PropTypes.string,
}

export default LabelWithImage
