import isEmpty from 'lodash/isEmpty'
import React from 'react'
import styled from 'styled-components'

import MarkAsGiftSentModal from 'components/contacts/organisms/mark-as-gift-sent-modal'
import { CONTACT_GROUP } from 'constants/route'
import { getContactName } from 'containers/contacts/helpers'
import { UPCOMING_EVENT_TYPES, SETTING_KEYS } from 'containers/contacts/list/constants'
import { breakpoints, COLOR } from 'styles/settings'
import tracker from 'tracker'

import { EVENT_PEOPLE_CONCEPT } from '../../../../tracker/const'
import LoadingOverlay from '../../molecules/loading-overlay'
import ContactDashboardActionPanel from '../../organisms/contact-dashboard-action-panel'
import ContactDashboardTopPanel from '../../organisms/contact-dashboard-top-panel'
import { ContactGroupMenu } from '../../organisms/contact-group-menu'
import ContactPanelTemplate from '../contact-panel-template'
import UpcomingEventTemplate from '../upcoming-event-template'

import {
  SYSTEM_DEFAULT_MESSAGE,
  TYPE_BIRTHDAY,
  TYPE_MOVE_IN_ANNIVERSARY,
  TYPE_MOVING_IN,
} from './constants'
import EmptyContactComponent from './contact-empty'
import ContactListComponent from './contact-list'
import { generateDisplayingTags } from './helpers'

const { EVENT_NAME, EVENT_PROPERTY } = EVENT_PEOPLE_CONCEPT

const ContactListWithMenuContainer = styled.div`
  font-family: Inter;
  display: flex;
  height: calc(100vh - 70px - 10px - 45px);
  flex-grow: 1;
  margin: 5px;
  ${breakpoints.mobile(`
    height: auto;
    flex-direction: column;
    margin-top: 0;
  `)};
`

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 10px;
  background-color: ${COLOR.white};
  overflow-y: scroll;
  height: 100%;
  overflow: auto;
  ${breakpoints.mobile(`
    width: 100%;
    margin-right: 0;
    padding: 0;
  `)};
`

const DesktopPanelContainer = styled.div`
  ${breakpoints.mobile(`
    display: none;
  `)};
`

const MobilePanelContainer = styled.div`
  display: none;
  ${breakpoints.mobile(`
    display: flex;
  `)};
`

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
export const ContactDashboardContent = ({
  currentGroupPath,
  data = [],
  pagination,
  handleGoToContactDetail,
  handleChangePage,
  allTags,
  onClickTenantScreening,
  onClickStarContactOnEmptyPage,
  bulkSelected,
  onCreateAndTagContact,
  onClickToggleSelectedBulk,
  onClickAddSelectedAllBulk,
  onTagContact,
  onUntagContact,
  onShowMoreTag,
  isLoadingTag,
  onStarContact,
  bulkState,
  onSortContact,
  currentSortContactValue,
  onFocusAddTag,
  isUpcomingEventPath,
  upcomingFilter,
  onMarkAsGiftSent,
  onUnmarkGiftSent,
  onOpenContactPanel,
  setSelectedContact,
  onCopyContactDetail,
}) => {
  if (isEmpty(data)) {
    return (
      <EmptyContactComponent
        currentGroupPath={currentGroupPath}
        filter={upcomingFilter}
        onClickActions={{
          onClickTenantScreening,
          onClickStarContactOnEmptyPage,
        }}
      />
    )
  }
  return isUpcomingEventPath ? (
    <UpcomingEventTemplate
      allTags={allTags}
      bulkSelected={bulkSelected}
      data={data}
      handleGoToContactDetail={handleGoToContactDetail}
      pagination={pagination}
      isLoadingTag={isLoadingTag}
      handleChangePage={handleChangePage}
      onCreateAndTagContact={onCreateAndTagContact}
      onClickToggleSelectedBulk={onClickToggleSelectedBulk}
      onClickAddSelectedAllBulk={onClickAddSelectedAllBulk}
      onTagContact={onTagContact}
      onUntagContact={onUntagContact}
      onStarContact={onStarContact}
      bulkState={bulkState}
      onSortContact={onSortContact}
      currentSortContactValue={currentSortContactValue}
      onFocusAddTag={onFocusAddTag}
      onMarkAsGiftSent={onMarkAsGiftSent}
      onUnmarkGiftSent={onUnmarkGiftSent}
      onOpenContactPanel={onOpenContactPanel}
      setSelectedContact={setSelectedContact}
    />
  ) : (
    <ContactListComponent
      allTags={allTags}
      bulkSelected={bulkSelected}
      data={data}
      handleGoToContactDetail={handleGoToContactDetail}
      pagination={pagination}
      isLoadingTag={isLoadingTag}
      handleChangePage={handleChangePage}
      onCreateAndTagContact={onCreateAndTagContact}
      onClickToggleSelectedBulk={onClickToggleSelectedBulk}
      onClickAddSelectedAllBulk={onClickAddSelectedAllBulk}
      onTagContact={onTagContact}
      onUntagContact={onUntagContact}
      onShowMoreTag={onShowMoreTag}
      onStarContact={onStarContact}
      bulkState={bulkState}
      onSortContact={onSortContact}
      currentSortContactValue={currentSortContactValue}
      onFocusAddTag={onFocusAddTag}
      onCopyContactDetail={onCopyContactDetail}
    />
  )
}

const ContactDashboardTemplate = ({
  currentGroupPath,
  currentFilterCount,
  currentQueryString,
  currentSearchValue,
  data = [],
  userSettings = {},
  gifts,
  allTags,
  selectedTags,
  bulkSelected,
  bulkState,
  groupCount = {},
  idvStatus,
  isLoadingList,
  isLoadingCount,
  isLoadingTags,
  isLoadingSendMessage,
  isDisableAddNewContactDesktop,
  isMarkAsGiftSentModalOpen,
  isContactPanelOpen,
  isClosingContactPanel,
  contactPanelContent,
  selectedEvent,
  handleOpenFilterModal,
  onSearch,
  handleGoToContactDetail,
  pagination,
  handleChangePage,
  onClickSendMessage,
  onClickTenantScreening,
  onClickStarContactOnEmptyPage,
  handleOpenBulkDeleteConfirmMode,
  handleCloseBulkState,
  onDeSelectAllBulk,
  onClickCreateContact,
  onClickImportContact,
  onClickDeleteContact,
  onCreateAndTagContact,
  onClickToggleSelectedBulk,
  onClickAddSelectedAllBulk,
  onTagContact,
  onUntagContact,
  onShowMoreTag,
  isLoadingTag,
  onToggleTagFilter,
  onStarContact,
  onSortContact,
  currentSortContactValue,
  onFocusAddTag,
  onClickEditTag,
  onClickToBulkAddTag,
  onClickRequestApplication,
  onSendRentalApplication,
  onBulkAddTag,
  onCreateAndBulkAddTag,
  onFocusAddTagOnBulkAddTag,
  onMarkAsGiftSent,
  onUpdateSettings,
  onClickGiftBox,
  onCopyContactDetail,
  setIsMarkAsGiftSentModalOpen,
  onOpeningContactPanel,
  onClosingContactPanel,
  setIsContactPanelOpen,
  setContactPanelContent,
  onUnmarkGiftSent,
  children,
}) => {
  const [selectedContact, setSelectedContact] = React.useState(data[0])
  const messageField = (() => {
    switch (selectedContact?.event) {
      case UPCOMING_EVENT_TYPES.BIRTHDAY:
        return SETTING_KEYS.DEFAULT_BIRTHDAY_MESSAGE
      case UPCOMING_EVENT_TYPES.MOVE_IN_ANNIVERSARY:
        return SETTING_KEYS.DEFAULT_MOVE_IN_MESSAGE
      case UPCOMING_EVENT_TYPES.MOVING_IN:
        return SETTING_KEYS.DEFAULT_MOVING_IN_MESSAGE
      default:
        return SETTING_KEYS.DEFAULT_MOVE_IN_MESSAGE
    }
  })()
  let eventType
  switch (selectedContact?.event) {
    case UPCOMING_EVENT_TYPES.BIRTHDAY:
      eventType = TYPE_BIRTHDAY
      break
    case UPCOMING_EVENT_TYPES.MOVE_IN_ANNIVERSARY:
      eventType = TYPE_MOVE_IN_ANNIVERSARY
      break
    case UPCOMING_EVENT_TYPES.MOVING_IN:
      eventType = TYPE_MOVING_IN
      break
    default:
      break
  }
  const defaultMessage = userSettings[messageField] || SYSTEM_DEFAULT_MESSAGE[eventType]

  const isUpcomingEventPath = currentGroupPath === CONTACT_GROUP.UPCOMING_EVENT

  return (
    <ContactListWithMenuContainer>
      {isContactPanelOpen && (
        <ContactPanelTemplate
          messageField={messageField}
          eventType={eventType}
          defaultMessage={defaultMessage}
          isLoadingSendMessage={isLoadingSendMessage}
          onClickSendMessage={onClickSendMessage}
          onUpdateSettings={onUpdateSettings}
          setIsContactPanelOpen={setIsContactPanelOpen}
          gifts={gifts}
          isClosingContactPanel={isClosingContactPanel}
          onClosingContactPanel={onClosingContactPanel}
          onClickGiftBox={onClickGiftBox}
          contactPanelContent={contactPanelContent}
          setContactPanelContent={setContactPanelContent}
        />
      )}
      <MarkAsGiftSentModal
        isOpen={isMarkAsGiftSentModalOpen}
        onClose={() => setIsMarkAsGiftSentModalOpen(false)}
        onMarkAsGiftSent={() => {
          tracker.trackEvent(EVENT_NAME.MARK_AS_SENT, {
            contact_fullname: getContactName(contactPanelContent?.data),
            click_from: EVENT_PROPERTY.CLICK_FROM.GIFT_SELECTED_POPUP,
            always_mark_sent: false,
            category: EVENT_PROPERTY.CATEGORY.PEOPLE_CONCEPT,
          })
          onMarkAsGiftSent(contactPanelContent?.data)
          setIsContactPanelOpen(false)
        }}
        onSetAutoMarkAsGiftSent={() => onUpdateSettings({ autoMarkAsSentGift: true })}
      />
      <MobilePanelContainer>
        {bulkState && (
          <ContactDashboardActionPanel
            selectedNumber={bulkSelected.length}
            onClose={handleCloseBulkState}
            onSubmit={handleOpenBulkDeleteConfirmMode}
            onDeselect={onDeSelectAllBulk}
            bulkState={bulkState}
            allTags={generateDisplayingTags(allTags)}
            onBulkAddTag={onBulkAddTag}
            onCreateAndBulkAddTag={onCreateAndBulkAddTag}
            onFocusAddTagOnBulkAddTag={onFocusAddTagOnBulkAddTag}
            onSendRentalApplication={onSendRentalApplication}
          />
        )}
      </MobilePanelContainer>
      <ContactGroupMenu
        data={data}
        currentGroupPath={currentGroupPath}
        currentQueryString={currentQueryString}
        groupCount={groupCount}
        allTags={generateDisplayingTags(allTags)}
        selectedTags={selectedTags}
        isLoadingCount={isLoadingCount}
        isLoadingTags={isLoadingTags}
        onToggleTagFilter={onToggleTagFilter}
        bulkSelected={bulkSelected}
        onClickAddSelectedAllBulk={onClickAddSelectedAllBulk}
        onClickEditTag={onClickEditTag}
        bulkState={bulkState}
        handleOpenBulkDeleteConfirmMode={handleOpenBulkDeleteConfirmMode}
        handleDeselectAllBulkDelete={onDeSelectAllBulk}
      />
      <DashboardContainer>
        <DesktopPanelContainer>
          {bulkState && (
            <ContactDashboardActionPanel
              selectedNumber={bulkSelected.length}
              onClose={handleCloseBulkState}
              onSubmit={handleOpenBulkDeleteConfirmMode}
              onDeselect={onDeSelectAllBulk}
              bulkState={bulkState}
              allTags={generateDisplayingTags(allTags)}
              onBulkAddTag={onBulkAddTag}
              onCreateAndBulkAddTag={onCreateAndBulkAddTag}
              onFocusAddTagOnBulkAddTag={onFocusAddTagOnBulkAddTag}
              onSendRentalApplication={onSendRentalApplication}
            />
          )}
        </DesktopPanelContainer>
        {!bulkState && (
          <ContactDashboardTopPanel
            desktop
            currentFilterCount={currentFilterCount}
            currentSearchValue={currentSearchValue}
            isDisableAddNewContact={isDisableAddNewContactDesktop}
            hideContactButton={isUpcomingEventPath}
            handleOpenFilterModal={handleOpenFilterModal}
            onSearch={onSearch}
            onClickCreateContact={onClickCreateContact}
            onClickImportContact={onClickImportContact}
            onClickDeleteContact={onClickDeleteContact}
            onClickToBulkAddTag={onClickToBulkAddTag}
            onClickRequestApplication={onClickRequestApplication}
          />
        )}
        {isUpcomingEventPath && children}
        <ContentContainer>
          {isLoadingList && <LoadingOverlay />}
          <ContactDashboardContent
            allTags={allTags}
            bulkSelected={bulkSelected}
            idvStatus={idvStatus}
            groupCount={groupCount}
            currentGroupPath={currentGroupPath}
            data={data}
            pagination={pagination}
            isLoadingTag={isLoadingTag}
            handleGoToContactDetail={handleGoToContactDetail}
            handleChangePage={handleChangePage}
            onClickToggleSelectedBulk={onClickToggleSelectedBulk}
            onClickAddSelectedAllBulk={onClickAddSelectedAllBulk}
            onClickTenantScreening={onClickTenantScreening}
            onClickStarContactOnEmptyPage={onClickStarContactOnEmptyPage}
            onCreateAndTagContact={onCreateAndTagContact}
            onTagContact={onTagContact}
            onUntagContact={onUntagContact}
            onShowMoreTag={onShowMoreTag}
            onStarContact={onStarContact}
            onOpenContactPanel={panelContent => onOpeningContactPanel(panelContent)}
            bulkState={bulkState}
            onSortContact={onSortContact}
            currentSortContactValue={currentSortContactValue}
            onFocusAddTag={onFocusAddTag}
            isUpcomingEventPath={isUpcomingEventPath}
            onClickGiftBox={onClickGiftBox}
            onMarkAsGiftSent={onMarkAsGiftSent}
            onUnmarkGiftSent={onUnmarkGiftSent}
            upcomingFilter={selectedEvent}
            setSelectedContact={setSelectedContact}
            onCopyContactDetail={onCopyContactDetail}
          />
        </ContentContainer>
      </DashboardContainer>
    </ContactListWithMenuContainer>
  )
}

export default ContactDashboardTemplate
