import RentSpreeBaseModal from '@rentspree/component-v2/dist/modal'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'

import FilterModalFormTemplate from 'components/contacts/templates/filter-form-template'
import tracker from 'tracker'
import { EVENT_PEOPLE_CONCEPT } from 'tracker/const'

import { closeFilterModal, applyFilter } from './actions'
import { withReducer, withSaga } from './connect'
import { selectIsOpen } from './selectors'

const { CLICK_CLOSE_FILTER, CLICK_APPLY_FILTER } = EVENT_PEOPLE_CONCEPT.EVENT_NAME

export const FilterModal = ({ filters }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsOpen)

  const handleCloseModal = () => {
    dispatch(closeFilterModal())
    tracker.trackEvent(CLICK_CLOSE_FILTER)
  }

  const handleApplyFilter = values => {
    dispatch(applyFilter(values))
    tracker.trackEvent(CLICK_APPLY_FILTER, { filter_value: values })
  }

  return (
    <RentSpreeBaseModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      modalSize="large"
      padding="20px 0"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <FilterModalFormTemplate
        filters={filters}
        handleCloseModal={handleCloseModal}
        handleApplyFilter={handleApplyFilter}
      />
    </RentSpreeBaseModal>
  )
}
export default compose(withReducer, withSaga)(FilterModal)
