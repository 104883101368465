import { getLocalItem, setLocalItem, setOption } from '@rentspree/cookie'
import { buildPath } from '@rentspree/path'
import * as sentry from '@sentry/browser'
import dayjs from 'dayjs'

import { STORAGE } from 'constants/cookie'
import { apiInstance } from 'utils/api-interceptor'

export const GET_MY_SEEN_ANNOUNCEMENT_PATH = '/api/v2/announcement/seen-announcement/me'
export const UPDATE_MY_SEEN_ANNOUNCEMENT_PATH = '/api/v2/announcement/feature/:announcementType'
export const GET_CLEAN_USER_METADATA_API_PATH = '/api/v2/announcement/agent-dashboard-itr1'

/**
 * getMyUserInvitation get user seen announcement resource.
 *
 * @returns {Promise<{seen_groups: { [key: string]: Date}}>}
 */
export const getMySeenAnnouncement = async () => {
  try {
    return await apiInstance.get(GET_MY_SEEN_ANNOUNCEMENT_PATH)
  } catch (e) {
    sentry.captureException(e)
    throw e
  }
}

/**
 * getMyUserInvitation get user seen announcement resource.
 *
 * @returns {Promise<{result: {seenGroups: { [key: string]: Date}}}>}
 */
export const updateMySeenAnnouncement = async (announcementType, groups) => {
  try {
    return await apiInstance.post(
      buildPath(UPDATE_MY_SEEN_ANNOUNCEMENT_PATH, { announcementType }),
      groups,
    )
  } catch (e) {
    sentry.captureException(e)
    throw e
  }
}

/**
 * (Temporary API) getCleanUserMetadataAPI get if the user is invited and if has a property or screening.
 * @returns {Promise<{isInvitedUser: boolean, hasPropertyOrScreening: boolean}}>}
 */
export const getCleanUserMetadataAPI = async () => {
  try {
    const result = await apiInstance.get(buildPath(GET_CLEAN_USER_METADATA_API_PATH))
    return result
  } catch (error) {
    sentry.captureException(error)
    return {}
  }
}

/**
 * Check local storage before fetching from API
 * @returns {Promise<{isInvitedUser: boolean, hasPropertyOrScreening: boolean}}>}
 */
export const getCleanUserMetadata = async () => {
  const agentDashboardItr1Cookie = getLocalItem(STORAGE.AGENT_DASHBOARD_ITR1)
  const isInvitedUser = agentDashboardItr1Cookie?.isInvitedUser
  const hasPropertyOrScreening = agentDashboardItr1Cookie?.hasPropertyOrScreening

  if (
    agentDashboardItr1Cookie?.isInvitedUser !== undefined &&
    agentDashboardItr1Cookie?.hasPropertyOrScreening !== undefined
  ) {
    return { isInvitedUser, hasPropertyOrScreening }
  }

  const result = await getCleanUserMetadataAPI()
  setOption({ expires: dayjs().add(14, 'day').toDate() })
  setLocalItem(STORAGE.AGENT_DASHBOARD_ITR1, result)

  // reset the options for any future localStorage setting
  setOption({ expires: undefined })

  return result
}
