import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const Wrapper = styled.div`
  transition: opacity 0.3s;
  opacity: ${({ isActive }) => (isActive ? '0.7' : '0')};
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  background-color: ${COLOR.black};
`
const Overlay = ({ isActive, onClick }) => <Wrapper isActive={isActive} onClick={onClick} />

export default Overlay
