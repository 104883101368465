/* eslint-disable no-underscore-dangle */
import React from 'react'
import { withRouter, NavLink, Redirect } from 'react-router-dom'
import { CONTAINERS, PARTICIPANT_TYPE, CLICK_REPORT_FROM } from 'containers/reports/constants'
import { CLICK_FROM } from 'containers/accept-deny/constants'
import { SingleAppWrapper } from 'components/layout/main'
import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import RouteReports from 'containers/reports'

import { EVENT_REPORT, EVENT_RENTSPREE_PRO, INCOME_VERIFICATION_EVENT } from 'tracker/const'
import { MobileReportList, MobileTabContent } from 'components/reports'
import { NEXT_ACTION_TYPES } from 'containers/tenant-screening/assign-property/constants'
import { ProBadge } from 'components/badge'
import {
  DropdownContent,
  DropdownItem,
  proBadgeStyle,
} from 'legacy/components/application/application-detail-style'
import { SINGLE_APPLICATION } from 'constants/route'
import { buildPath } from '@rentspree/path'
import { compose } from 'redux'
import ReportBottomBar from 'containers/reports/bottom-bar'
import Level2Template from '@rentspree/component-2023.components.templates.level-2-template'
import debounce from 'lodash/debounce'
import { MOBILE_THRESHOLD } from 'utils/constants'
import Breadcrumb2023 from 'containers/breadcrumb-2023'
import PropertyInnerTabs from 'containers/property-inner-tabs'
import { ApplicationDetailWrapper } from 'components/layout/main-style'
import { hasInLieuOfCredit } from 'containers/reports/helper'
import { TabWrapper, Tabs, TabItem } from '../../components/layout/tabs'
import {
  MobileTabsWrapper,
  MobileTabs,
  MobileTabItem,
  MobileReport,
  MobileReportItem,
  MobileReportFooter,
} from '../../components/application/application-detail'
import withReportConnect from '../report/report-connect'
import { MReportPanel, TabText } from '../../components/credit-report/mobile-panel'

import * as PATH from '../../constants/route-consts'
import {
  getReportTabs,
  credit,
  personal,
  contact,
  MOBILE_TABS,
  INCOME_VERIFICATION_TAB,
} from './application-detail-const'
import { Routable } from '../utils/routable'
import { convert } from '../../components/helper/convert'
import { getDate } from '../../helpers/expired-credit-report'
import { LraLearnMoreModal } from '../../components/modal/lra-learn-more-modal'
import { TrackerInstance as Tracker } from '../../helpers/tracker'
import tracker from '../../tracker'
import { REPORT_STEP } from '../../constants/report-consts'
import { STATUS } from '../../constants/credit-report-consts'
import { withTracker } from '../with-tracker'
import Popper from '../../../containers/popper/popper'
import { PAGE, PROJECT } from '../../../containers/popper/const'
import { isReportAvailable, getReportNameByTabId } from './utils'
import { AbilityContext } from '../../../containers/reports/with-pro-report-available/ability-context'
import {
  PRO_CAN_ACTION,
  PRO_CAN_SUBJECT,
} from '../../../containers/reports/with-pro-report-available/constants'
import { REPORT_TAB_ID_CREDIT } from './const'

const { HEADER } = CLICK_REPORT_FROM

export class ApplicationDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showPanel: true,
      showMoreDropdown: false,
      isMobile: false,
    }
  }

  componentDidMount() {
    this.checkScreenSize()
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.checkScreenSize()
  }

  checkScreenSize = debounce(() => {
    const isMobile = window.innerWidth <= MOBILE_THRESHOLD
    this.setState({ isMobile })
  }, 200)

  componentWillMount() {
    const {
      actions: { changeMobileActiveTab },
    } = this.props
    const pathArr = this.props.location.pathname.split('/')
    const route = pathArr[pathArr.length - 1]
    switch (route) {
      case 'reference-checks':
        changeMobileActiveTab(MOBILE_TABS.REF_CHECKS)
        break
      case 'documents':
        changeMobileActiveTab(MOBILE_TABS.OTHER_DOCS)
        break
      case 'income-verification':
        changeMobileActiveTab(MOBILE_TABS.INCOME_VERIFICATION)
        break
      case 'all':
      default:
        changeMobileActiveTab(MOBILE_TABS.REPORT)
        break
    }
    const { isFetchingAppDetail, applicationDetail } = get(this.props, 'application', {})
    if (isFetchingAppDetail || isEmpty(applicationDetail)) {
      personal[1].value = 'N/A'
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.application.rentalDetail.landlord_id !==
      prevProps.application.rentalDetail.landlord_id
    ) {
      if (isEmpty(this.props.incomeVerification?._id)) {
        if (
          this.props.profile.id === this.props.application.rentalDetail.landlord_id &&
          this.props.application.rentalDetail.screeningOption?.income
        ) {
          this.props.actions.getIncomeVerificationByRentalSubmission(
            this.props.match.params.rentalAppId,
          )
        }
      }
    }
  }

  onChangeTab = tab => {
    const {
      match,
      history,
      actions: { changeMobileActiveTab },
    } = this.props
    const reportTabs = getReportTabs(!!this.props.incomeVerification?.id)
    switch (tab) {
      case MOBILE_TABS.REPORT:
        this.trackClickReportTab(reportTabs[0])
        changeMobileActiveTab(MOBILE_TABS.REPORT)
        history.push(`${match.url}/all`)
        this.setState({ showMoreDropdown: false })
        break
      case MOBILE_TABS.REF_CHECKS:
        this.trackClickReportTab(reportTabs[5])
        changeMobileActiveTab(MOBILE_TABS.REF_CHECKS)
        history.push(`${match.url}/reference-checks`)
        this.setState({ showMoreDropdown: false })
        break
      case MOBILE_TABS.OTHER_DOCS:
        this.trackClickReportTab(reportTabs[6])
        changeMobileActiveTab(MOBILE_TABS.OTHER_DOCS)
        history.push(`${match.url}/documents`)
        this.setState({ showMoreDropdown: false })
        break
      case MOBILE_TABS.INCOME_VERIFICATION:
        // this.trackClickReportTab(reportTabs[7])
        changeMobileActiveTab(MOBILE_TABS.INCOME_VERIFICATION)
        history.push(`${match.url}/income`)
        this.setState({ showMoreDropdown: false })
        break
      case MOBILE_TABS.MORE:
        this.setState(prevState => ({ showMoreDropdown: !prevState.showMoreDropdown }))
        break
      default:
        break
    }
  }

  isFetchingData() {
    const { application, property } = this.props
    return (
      application.isFetchingAppDetail ||
      property.isFetching ||
      application.isFetchingFormAvailable ||
      application.isFetchingRentalDetail
    )
  }

  handlePrintMobile = (type, autoPrint, tab) => event => {
    event.preventDefault()
    event.stopPropagation()
    const { actions, property } = this.props
    const propertyId = get(property, '_id')
    actions.printReport({
      type,
      autoPrint,
      isPrintPage: false,
      clickFrom: HEADER,
      propertyId,
    })
    if (tab && tab.id) this.trackClickReportTab(tab)
  }

  handleCloseLRAModal = () => {
    Tracker.trackEvent('clickCancelLRAModal')
    const { actions } = this.props
    actions.toggleLRALearnMoreModal(false)
  }

  handleLearnMoreLRA = () => {
    Tracker.trackEvent('clickLearnMoreLRAModal')
    window.open(PATH.SUPPORT_LINK_ZIPFORM_ACCOUNT_URL)
  }

  trackReport(event, property = {}) {
    const rental = get(this.props, 'application.rentalDetail')
    tracker.trackEvent(event, {
      rental_id: get(rental, '_id'),
      applicant: get(rental, 'email'),
      ...property,
    })
  }

  trackClickReportTab = type => {
    if (type.path === INCOME_VERIFICATION_TAB.path) {
      this.trackReport(EVENT_REPORT.switchReportView, {
        switch_to: INCOME_VERIFICATION_EVENT.SWITCH_TO_TAB,
      })
    } else if (type.path !== 'reference-checks' && type.path !== 'documents') {
      this.trackReport(EVENT_REPORT.switchReportView, {
        switch_to: type.trackTitle,
      })
    } else if (type.path === 'reference-checks') {
      const isPro = this.context.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
      this.trackReport(EVENT_RENTSPREE_PRO.REF_CHECK_TAB, {
        is_pro: isPro,
      })
    } else if (type.path === 'documents') {
      const isPro = this.context.can(PRO_CAN_ACTION, PRO_CAN_SUBJECT)
      this.trackReport(EVENT_RENTSPREE_PRO.OTHER_DOCS_TAB, {
        is_pro: isPro,
      })
    }
  }

  isRenderReportTab = () => {
    const { currentReportStep } = this.props
    const renderStatus = [REPORT_STEP.NONE, REPORT_STEP.OPENED, REPORT_STEP.EXPIRED]
    return includes(renderStatus, currentReportStep)
  }

  handleClickShare = clickFrom => () => {
    const {
      actions: { toggleShareReportModal, openAssignPropertyModal },
      application,
      submissionParticipantDetail,
    } = this.props
    const property = get(application, 'rentalDetail.property')
    const participantId = get(submissionParticipantDetail, '_id')
    const shareReportProps = {
      toggle: true,
      clickFrom,
      position: HEADER,
    }

    if (property) {
      toggleShareReportModal({ ...shareReportProps })
    } else {
      openAssignPropertyModal({
        participantId,
        nextAction: NEXT_ACTION_TYPES.SHARE,
        nextActionProps: shareReportProps,
      })
    }
  }

  isShowPopper = currentReportStep => {
    const renderStatus = [REPORT_STEP.PAYMENT, REPORT_STEP.READY, REPORT_STEP.NONE]
    return !includes(renderStatus, currentReportStep)
  }

  isPrintPage = () => {
    const { match } = this.props
    return get(match, 'path', '').includes(PATH.PRINT_REPORTS_PAGE)
  }

  handleSavePDFMobile = type => () => {
    const { match, actions } = this.props
    const isPrintPage = this.isPrintPage()
    const propertyId = get(match, 'params.propertyId')
    actions.savePDF({
      type,
      device: 'mobile',
      isPrintPage,
      clickFrom: HEADER,
      propertyId,
    })
  }

  handleOpenAcceptDenyModal = (acceptDenyType, dropdownButtonEventName) => {
    const {
      actions,
      acceptDenyPayload,
      submissionParticipantDetail = {},
      match,
      application,
    } = this.props
    const property = get(application, 'rentalDetail.property')
    const propertyId = get(match, 'params.propertyId')
    const { _id: participantId, type: participantType } = submissionParticipantDetail
    const acceptDenyProps = {
      rentalSubmission: acceptDenyPayload,
      acceptDenyType,
      propertyId,
      propertyAddress: property,
      property,
      isSharedReport: participantType === PARTICIPANT_TYPE.PARTICIPANT,
      clickFrom: CLICK_FROM.APPLICATION_DETAIL,
      dropdownButtonEventName,
      screeningResult: application?.rentalDetail?.screeningResult?.result,
    }

    if (property) {
      actions.openAcceptDenyModal({ ...acceptDenyProps })
    } else {
      actions.openAssignPropertyModal({
        participantId,
        nextAction: NEXT_ACTION_TYPES.ACCEPT_DENY,
        nextActionProps: acceptDenyProps,
      })
    }
  }

  render() {
    const {
      match,
      application,
      creditReportDetail,
      pdfReport,
      modals,
      currentReportStep,
      submissionParticipantDetail = {},
      isShowBtnLra,
      location,
      history,
      incomeVerification,
    } = this.props
    const { aboutMe, applicationDetail, rentalDetail, mobileActiveTab } = application
    const shouldDisableCreditReportTab = hasInLieuOfCredit(applicationDetail)
    const reportTabs = getReportTabs(!!incomeVerification?._id)
    if (shouldDisableCreditReportTab) {
      const creditReportIndex = reportTabs.findIndex(tab => tab.id === REPORT_TAB_ID_CREDIT)
      set(reportTabs, `[${creditReportIndex}].forceDisabled`, true)
    }
    const isFetchingIncomeVerification = this.props.incomeVerification?.isFetchingIncomeVerification
    const pathArr = location.pathname.split('/')
    const route = pathArr[pathArr.length - 1]
    const isAllPage = route === 'all'

    const { showPanel, isMobile } = this.state
    const { email, property, _id: rentalAppId, isScreeningWithoutProperty } = rentalDetail

    const isAssignedPropertyButWrongPath =
      !!isScreeningWithoutProperty && !!get(property, '_id') && !get(match, 'params.propertyId')
    if (isAssignedPropertyButWrongPath) {
      const appDetailPath = buildPath(`${SINGLE_APPLICATION}/${route}`, {
        rentalAppId,
        propertyId: get(property, '_id'),
      })
      return <Redirect to={appDetailPath} />
    }

    const creditReportStatus = get(rentalDetail, 'credit_report.status')
    const reportExpired =
      creditReportStatus === STATUS.OPENED
        ? get(rentalDetail, 'credit_report.isExpired')
        : get(rentalDetail, 'credit_report.isIDMAExpired')
    const expiredOn =
      creditReportStatus === STATUS.OPENED
        ? getDate(get(rentalDetail, 'credit_report.generated_at'))
        : getDate(get(rentalDetail, 'credit_report.verified_idma_at'))
    if (!isEmpty(applicationDetail)) {
      const { monthlyIncome } = applicationDetail
      personal[0].value = convert(aboutMe.dateOfBirth, 'dateOfUTC', 'MM/DD/YYYY')
      personal[1].value = convert(monthlyIncome, '$')
      contact[0].value = email
      contact[1].value = convert(aboutMe.phoneNumber, 'phoneNumber')
      contact[2].value = isEmpty(aboutMe.driverLicenseNo) ? '-' : aboutMe.driverLicenseNo
    }
    if (!isEmpty(creditReportDetail) && !isEmpty(creditReportDetail.score)) {
      const { creditScore, criminalRecords, evictionRecords } = creditReportDetail.score
      if (reportExpired) {
        credit.map(() => 'Expired')
      } else {
        credit[0].value = creditScore
        credit[1].value = criminalRecords
        credit[2].value = evictionRecords
      }
    }
    const score = creditReportDetail.score || {}
    const isShareAvailable = submissionParticipantDetail.type === PARTICIPANT_TYPE.OWNER
    const isShowLRAButton = !this.isPrintPage() && isShowBtnLra

    const isDisplayMobileReportTabsWithIncome =
      this.isRenderReportTab() && reportTabs.includes(INCOME_VERIFICATION_TAB)
    const isDisplayMobileReportTabsWithoutIncome =
      this.isRenderReportTab() && !reportTabs.includes(INCOME_VERIFICATION_TAB)

    const shouldShowProBadge = !get(rentalDetail, 'screeningOption.premium')

    const firstName = get(applicationDetail, 'firstName', '')
    const lastName = get(applicationDetail, 'lastName', '')
    const displayName = `${firstName} ${lastName}`
    const hasPropertyStreet = !!property?.street

    return (
      <ApplicationDetailWrapper isMobile={isMobile}>
        <Level2Template
          title={property?.street ?? displayName}
          breadcrumb={<Breadcrumb2023 location={location} />}
          isMobile={isMobile}
          onClickBack={history.goBack}
          tabs={
            hasPropertyStreet && <PropertyInnerTabs defaultTab="propertyInnerTabTenantScreening" />
          }
        />

        <SingleAppWrapper id="appDetailWrapper" isMobile={isMobile} hasTab={hasPropertyStreet}>
          <TabWrapper showPanel={showPanel} id="appDetailTabsWrapper">
            {/* MOBILE TAB AND CONTENT */}
            {
              <div className="mContent">
                <MobileTabsWrapper id="mTabsWrapper" shouldDisplay={!isFetchingIncomeVerification}>
                  {isDisplayMobileReportTabsWithIncome && (
                    <>
                      <MobileTabs>
                        <MobileTabItem
                          id="appDetailMobileTabReport"
                          text="Reports"
                          onClick={() => this.onChangeTab(MOBILE_TABS.REPORT)}
                          active={
                            mobileActiveTab === MOBILE_TABS.REPORT && !this.state.showMoreDropdown
                          }
                        />
                        {reportTabs.includes(INCOME_VERIFICATION_TAB) && (
                          <MobileTabItem
                            id="appDetailMobileTabIncomeVerification"
                            text="Income Verification"
                            onClick={() => this.onChangeTab(MOBILE_TABS.INCOME_VERIFICATION)}
                            active={
                              mobileActiveTab === MOBILE_TABS.INCOME_VERIFICATION &&
                              !this.state.showMoreDropdown
                            }
                          >
                            {shouldShowProBadge && <ProBadge {...proBadgeStyle}>PRO</ProBadge>}
                          </MobileTabItem>
                        )}
                        <MobileTabItem
                          id="appDetailMobileTabMore"
                          onClick={() => this.onChangeTab(MOBILE_TABS.MORE)}
                          active={
                            this.state.showMoreDropdown ||
                            mobileActiveTab === MOBILE_TABS.REF_CHECKS ||
                            mobileActiveTab === MOBILE_TABS.OTHER_DOCS
                          }
                          text="More"
                        ></MobileTabItem>
                      </MobileTabs>
                      {this.state.showMoreDropdown && (
                        <DropdownContent>
                          <DropdownItem onClick={() => this.onChangeTab(MOBILE_TABS.REF_CHECKS)}>
                            <span>Ref Checks</span>
                            {shouldShowProBadge && <ProBadge {...proBadgeStyle}>PRO</ProBadge>}
                          </DropdownItem>
                          <DropdownItem onClick={() => this.onChangeTab(MOBILE_TABS.OTHER_DOCS)}>
                            <span>Other Docs</span>
                            {shouldShowProBadge && <ProBadge {...proBadgeStyle}>PRO</ProBadge>}
                          </DropdownItem>
                        </DropdownContent>
                      )}
                    </>
                  )}
                  {isDisplayMobileReportTabsWithoutIncome && (
                    <MobileTabs>
                      <MobileTabItem
                        id="appDetailMobileTabReport"
                        text="Reports"
                        onClick={() => this.onChangeTab(MOBILE_TABS.REPORT)}
                        active={mobileActiveTab === MOBILE_TABS.REPORT}
                      />
                      <MobileTabItem
                        id="appDetailMobileTabArc"
                        text="Ref Checks"
                        onClick={() => this.onChangeTab(MOBILE_TABS.REF_CHECKS)}
                        active={mobileActiveTab === MOBILE_TABS.REF_CHECKS}
                      >
                        {shouldShowProBadge && <ProBadge {...proBadgeStyle}>PRO</ProBadge>}
                      </MobileTabItem>
                      <MobileTabItem
                        id="appDetailMobileTabDoc"
                        text="Other Docs"
                        onClick={() => this.onChangeTab(MOBILE_TABS.OTHER_DOCS)}
                        active={mobileActiveTab === MOBILE_TABS.OTHER_DOCS}
                      >
                        {shouldShowProBadge && <ProBadge {...proBadgeStyle}>PRO</ProBadge>}
                      </MobileTabItem>
                    </MobileTabs>
                  )}
                </MobileTabsWrapper>
                {this.isRenderReportTab() && (
                  <MobileTabContent
                    id="appDetailMobileReport"
                    hidden={mobileActiveTab !== MOBILE_TABS.REPORT}
                    padding="0 0 70px;"
                  >
                    <MobileReport>
                      <MobileReportList id="appDetailMobileReportList" className="add-bottom-width">
                        {reportTabs.map(tab => (
                          <MobileReportItem
                            key={tab.id}
                            color={tab.color}
                            id={tab.id}
                            hidden={tab.mHidden}
                            reportExpired={tab.expirable && reportExpired}
                            result={rentalDetail?.screeningResult?.result}
                            reportNotAvailable={
                              tab.forceDisabled ||
                              (tab.missable &&
                                (this.isFetchingData() ||
                                  !isReportAvailable(
                                    rentalDetail,
                                    submissionParticipantDetail,
                                    getReportNameByTabId(tab.id),
                                  )))
                            }
                            className={tab.class}
                          >
                            <NavLink activeClassName="active" to={`${match.url}/${tab.path}`}>
                              <MReportPanel
                                reportExpired={tab.expirable && reportExpired}
                                reportNotAvailable={
                                  tab.forceDisabled ||
                                  (tab.missable &&
                                    (this.isFetchingData() ||
                                      !isReportAvailable(
                                        rentalDetail,
                                        submissionParticipantDetail,
                                        getReportNameByTabId(tab.id),
                                      )))
                                }
                                expiredOn={`Expired on ${expiredOn}`}
                                text={tab.mText}
                                onClick={this.handlePrintMobile(tab.path, false, tab)}
                                onPrint={this.handlePrintMobile(tab.path, true, false)}
                                monthlyIncome={personal[1].value || 0}
                                score={score || {}}
                                value={tab.value}
                                download={{
                                  onMobileClick: this.handleSavePDFMobile(tab.path),
                                  ...pdfReport,
                                }}
                                isShowLRAButton={isShowLRAButton}
                                onClickShare={this.handleClickShare(tab.path)}
                                isShareAvailable={isShareAvailable}
                              />
                            </NavLink>
                          </MobileReportItem>
                        ))}
                      </MobileReportList>
                    </MobileReport>
                  </MobileTabContent>
                )}
              </div>
            }
            {/* DESKTOP TAB */}
            {this.isRenderReportTab() && (
              <Tabs id="appDetailTabs">
                {reportTabs.map(tab => (
                  <TabItem
                    key={tab.id}
                    color={tab.color}
                    id={`${tab.id}-desktop`}
                    reportExpired={tab.expirable && reportExpired}
                    reportNotAvailable={
                      tab.forceDisabled ||
                      (tab.missable &&
                        !isReportAvailable(
                          rentalDetail,
                          submissionParticipantDetail,
                          getReportNameByTabId(tab.id),
                        ))
                    }
                  >
                    <NavLink
                      activeClassName="active"
                      to={`${match.url}/${tab.path}`}
                      onClick={() => this.trackClickReportTab(tab)}
                    >
                      <TabText text={tab.text} isPro={tab.isPro && shouldShowProBadge} />
                    </NavLink>
                  </TabItem>
                ))}
              </Tabs>
            )}
            {/* DESKTOP CONTENT */}
            <RouteReports {...this.props} match={match} history={history} container={CONTAINERS.APPLICATION_DETAIL} />
          </TabWrapper>
          {/* LRA MODAL */}
          <LraLearnMoreModal
            id="lraLearnMoreModal"
            showModal={modals.showLRALearnMoreModal}
            closeModal={this.handleCloseLRAModal}
            clickLearnHow={this.handleLearnMoreLRA}
          />
          {/* ACCEPT/DENY MOBILE DROPDOWN */}
          {!this.isRenderReportTab() || !isAllPage ? (
            <div />
          ) : (
            isMobile && (
              <MobileReportFooter zIndex={1000}>
                <ReportBottomBar isMobile />
              </MobileReportFooter>
            )
          )}
          {this.isShowPopper(currentReportStep) && (
            <Popper page={PAGE.APP_DETAILS_TAB} project={PROJECT} />
          )}
        </SingleAppWrapper>
      </ApplicationDetailWrapper>
    )
  }
}
ApplicationDetail.contextType = AbilityContext

export default compose(withTracker, withRouter, Routable, withReportConnect)(ApplicationDetail)
