import GradientTick from "images/gradient-tick.svg"
import GreyTick from "images/border-grey-tick.svg"
import CircleWithTick from "images/circle-with-tick-inside.svg"
import GreyCircleWithTick from "images/grey-circle-with-tick-inside.svg"

export const CURRENT_PLAN = "Current Plan"
export const BUTTON_TEXTS = {
  MANAGE_PLAN: "Manage Subscription",
  CHOOSE_PLAN: "Choose Plan",
  UPGRADE_PLAN: "Upgrade to PRO",
  TRY_TRIAL: "Start 30-Day Free Trial",
  CONTACT_SALES: "Contact Sales",
  RENEW_PLAN: "Renew Plan",
}

export const TICK_ICONS = {
  GradientTick,
  GreyTick,
  CircleWithTick,
  GreyCircleWithTick,
}
