import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Subscription from 'containers/subscription'
import ProLandingPageV2 from 'containers/pro/landing'
import MySubscription from './my-subscription'
import * as PATH from '../../constants/route-consts'
import { FEATURE_PRO } from '../../constants/feature-pro-consts'
import ProPaymentContainer from './payment'
import ReferenceCheckDetail from './arcs/authen-route'
import RequestDocsDetail from '../static-page/request-docs-route'
import { withTracker } from '../with-tracker'

const ProSubscriptionPage = props => (
    <Subscription
      feature={FEATURE_PRO.SUBSCRIPTION}
      render={({ isSubscriber }) => {
        if (isSubscriber) return <MySubscription {...props} />
        return <ProLandingPageV2 {...props} />
      }}
    />
  )

const ProPaymentPage = props => (
  <Subscription
    feature={FEATURE_PRO.PAYMENT}
    render={({ isSubscriber }) => {
      if (isSubscriber) return <Redirect to={PATH.PRO_PAGE} />
      return <ProPaymentContainer {...props} />
    }}
  />
)

const RoutePro = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={ProSubscriptionPage} />
    <Route exact path={`${match.path}${PATH.PAYMENT_PAGE}`} component={ProPaymentPage} />
    <Route exact path={PATH.REQUEST_DOCS_PAGE} component={withTracker(RequestDocsDetail)} />
    <Route exact path={PATH.REF_CHECKS_PAGE} component={withTracker(ReferenceCheckDetail)} />
    <Route
      render={() => {
        window.location.href = PATH.NOT_FOUND
      }}
    />
  </Switch>
)

export default RoutePro
