import generateApiCall from 'utils/generate-api-call'

import {
  GET_GIFTS_CALL,
  GET_GIFTS_REQUEST,
  GET_GIFTS_SUCCESS,
  GET_GIFTS_FAILURE,
  GENERATE_GIFT_LINK_CALL,
  GENERATE_GIFT_LINK_REQUEST,
  GENERATE_GIFT_LINK_SUCCESS,
  GENERATE_GIFT_LINK_FAILURE,
  SET_SELECTED_GIFT,
} from './constants'

export const getGiftsApiState = generateApiCall([
  GET_GIFTS_REQUEST,
  GET_GIFTS_SUCCESS,
  GET_GIFTS_FAILURE,
])

export const getGifts = () => ({
  type: GET_GIFTS_CALL,
})

export const generateGiftLinkApiState = generateApiCall([
  GENERATE_GIFT_LINK_REQUEST,
  GENERATE_GIFT_LINK_SUCCESS,
  GENERATE_GIFT_LINK_FAILURE,
])

export const generateGiftLink = (gift, window) => ({
  type: GENERATE_GIFT_LINK_CALL,
  payload: { gift, window },
})

export const setSelectedGift = gift => ({
  type: SET_SELECTED_GIFT,
  payload: { gift },
})
