import React from "react"
import styled from "styled-components"
import PENCIL_ICON from "../../images/icons/lease-agreement/pencil-icon.svg"
import TEXT_ICON from "../../images/icons/lease-agreement/text-icon.svg"
import CHECKBOX_ICON from "../../images/icons/lease-agreement/checkbox-icon.svg"
import COLLAPSE_UP_ICON from "../../images/icons/lease-agreement/collapse-up-icon.svg"
import COLLAPSE_DOWN_ICON from "../../images/icons/lease-agreement/collapse-down-icon.svg"
import RP_ICON from "../../images/icons/lease-agreement/rp-icon.svg"
import CALENDAR_ICON from "../../images/icons/lease-agreement/calendar-icon.svg"

export const IconSvg = styled.img`
  padding-right: 5px;
`

export const SignIcon = ({ color }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M0.187503 11.2973C0.0839491 11.2973 2.86102e-06 11.3789 2.86102e-06 11.4796C2.86102e-06 11.5804 0.0839491 11.662 0.187503 11.662H5.81244C5.916 11.662 5.99994 11.5804 5.99994 11.4798C5.99994 11.3789 5.916 11.2973 5.81244 11.2973H0.187503ZM0.599771 10.2627C0.58721 10.2627 0.576199 10.2659 0.56797 10.2726L0.2164 10.5587C0.178556 10.5894 0.0922937 10.7759 0.166233 10.859C0.196048 10.8924 0.247089 10.904 0.299841 10.904C0.378749 10.904 0.46199 10.8779 0.484489 10.8596L0.83586 10.5737L0.840194 10.5646C0.867026 10.5287 0.83912 10.4452 0.77015 10.3676C0.715451 10.306 0.652086 10.268 0.607325 10.2642L0.599771 10.2627ZM1.34091 7.90137C1.31452 7.90137 1.27906 7.90497 1.26025 7.92093C1.26006 7.92136 1.25982 7.92178 1.25962 7.92197L1.24155 7.94642C1.22667 7.9764 1.22145 8.02263 1.22631 8.08177L1.11627 8.44001L1.02806 8.72697L0.619846 10.0557C0.768599 10.0658 0.888174 10.1784 0.933572 10.2295C1.02719 10.3345 1.07604 10.4533 1.06762 10.5554C1.06762 10.5563 1.06698 10.5569 1.06698 10.5577L2.49746 10.3942L2.81055 10.3584L3.23109 10.3102C3.25701 10.3145 3.28384 10.3197 3.3061 10.3197C3.33572 10.3197 3.37615 10.3155 3.39928 10.2957C3.4287 10.2707 3.44317 10.2163 3.44015 10.1387C3.43021 9.88958 3.2328 9.39575 2.71539 8.82136C2.17746 8.2239 1.60578 7.90137 1.34091 7.90137ZM9.67537 0L1.55454 7.55214C1.97639 7.65643 2.53054 8.03778 3.01011 8.57073C3.40708 9.01138 3.72124 9.52709 3.8073 9.94589L11.9461 2.37698L11.9547 2.38579C11.9547 2.38579 11.9684 2.37315 11.9807 2.34478L11.9869 2.32862C12.0299 2.19873 12.0157 1.81808 11.1253 0.912832C10.1962 -0.0317752 9.8114 -0.0241987 9.6929 0.0178559L9.67537 0Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="11.662" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const EmailIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8 1H1.2C0.54 1 0.00599999 1.5625 0.00599999 2.25L0 9.75C0 10.4375 0.54 11 1.2 11H10.8C11.46 11 12 10.4375 12 9.75V2.25C12 1.5625 11.46 1 10.8 1ZM10.8 3.5L6 6.625L1.2 3.5V2.25L6 5.375L10.8 2.25V3.5Z"
      fill="#3B99FC"
    />
  </svg>
)

export const Icon = ({ icon, color }) => {
  switch (icon) {
    case "pencil-icon":
      return <IconSvg src={PENCIL_ICON} alt="pencil-icon" />
    case "text-icon":
      return <IconSvg src={TEXT_ICON} alt="text-icon" />
    case "checkbox-icon":
      return <IconSvg src={CHECKBOX_ICON} alt="checkbox-icon" />
    case "collapse-up-icon":
      return <IconSvg src={COLLAPSE_UP_ICON} alt="collapse-up-icon" />
    case "collapse-down-icon":
      return <IconSvg src={COLLAPSE_DOWN_ICON} alt="collapse-down-icon" />
    case "rp-icon":
      return <IconSvg src={RP_ICON} alt="rp-icon" />
    case "calendar-icon":
      return <IconSvg src={CALENDAR_ICON} alt="calendar-icon" />
    case "sign-icon":
      return <SignIcon color={color} />
    case "email-icon":
      return <EmailIcon />
    default:
      break
  }
  return null
}
