export const LANDING_HEADER_TEXTS = {
  TITLE: "From Screening to Signing in No Time",
  DESCRIPTION_LINE1: "RentSpree PRO brings together the tools you need",
  DESCRIPTION_LINE2: "to close more deals faster and",
  DESCRIPTION_LINE3: "grow your business.",
  CTA_BUTTON: "Start 30-Day Free Trial",
}
export const KEY = "stripeSubscription"

export const PLAN_NAMES = {
  FREE: "RentSpree Basic",
  PRO: "RentSpree PRO",
}

export const PLAN_FEATURES = [
  { name: "Online applications and screening", tooltip: "" },
  { name: "Client manager", tooltip: "" },
  { name: "Rental listings page", tooltip: "" },
  { name: "Branding page", tooltip: "" },
  { name: "Agent reviews and custom URL", tooltip: "" },
  { name: "Send and e-sign agreements", tooltip: "" },
  { name: "Secure file sharing" },
  { name: "Auto-reference checks" },
  { name: "Priority phone support" },
]

export const FEATURE_DESCRIPTIONS = {
  "Online applications and screening": "Collect rental applications and securely verify tenants with comprehensive screening reports.",
  "Client manager": "Easily store and organize contact information to nurture and grow your client relationships.",
  "Rental listings page": "Customize your listing page to market your rental properties and generate new leads.",
  "Branding page": "Personalize your profile page to showcase client reviews, expertise, and listings to further grow your business.",
  "Agent reviews and custom URL": "Collect client ratings and reviews, and customize your profile page URL to reflect your business.",
  "Send and e-sign agreements": "Conveniently send and sign rental documents anywhere, anytime, and with anyone.",
  "Secure file sharing": "Request, collect, and securely store documents like bank statements and proof of identity from renters.",
  "Auto-reference checks": "Complete an applicant’s profile with RentSpree’s automated reference checks.",
  "Priority phone support": "Skip the line with our dedicated support team only a phone call away.",
}

export const FREE_QUOTA = {
  "Online applications and screening": true,
  "Client manager": true,
  "Rental listings page": true,
  "Branding page": true,
  "Agent reviews and custom URL": false,
  "Send and e-sign agreements": false,
  "Secure file sharing": false,
  "Auto-reference checks": false,
  "Priority phone support": false,
}

export const PRO_QUOTA = {
  "Online applications and screening": true,
  "Client manager": true,
  "Rental listings page": true,
  "Branding page": true,
  "Agent reviews and custom URL": true,
  "Send and e-sign agreements": true,
  "Secure file sharing": true,
  "Auto-reference checks": true,
  "Priority phone support": true,
}

export const PRO_PLAN_VARIANTS = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  MONTHLY_PRO_EARLY_ADOPTER_DISCOUNT: "Monthly (Early Adopter Discount)",
}

export const RECOMMENDED_PLAN = "RentSpree PRO"

export const PLAN_TITLE_MAP = {
  "RentSpree Basic": {
    title: "Basic",
    subtitle: "Suitable for agent",
    hasFinePrintAbove: true,
  },
  "RentSpree PRO": {
    title: "PRO",
    subtitle: "Perfect for agent",
    hasFinePrintAbove: false,
  },
}

export const ACCOUNT_PROFILE_URL = `/account/profile`
export const SUBSCRIPTION_PROFILE_CHECKOUT_URL = `${ACCOUNT_PROFILE_URL}/subscription/checkout`
export const SUBSCRIPTION_PROFILE_MANAGEMENT_URL = `${ACCOUNT_PROFILE_URL}/subscription/management`

export const GET_SUBSCRIPTION_PRODUCT_CALL = "GET_SUBSCRIPTION_PRODUCT"
export const GET_SUBSCRIPTION_PRODUCT_REQUEST =
  "GET_SUBSCRIPTION_PRODUCT_REQUEST"
export const GET_SUBSCRIPTION_PRODUCT_SUCCESS =
  "GET_SUBSCRIPTION_PRODUCT_SUCCESS"
export const GET_SUBSCRIPTION_PRODUCT_FAILURE =
  "GET_SUBSCRIPTION_PRODUCT_FAILURE"

export const GET_USER_SUBSCRIPTION_CALL = "GET_USER_SUBSCRIPTION_CALL"
export const GET_USER_SUBSCRIPTION_REQUEST = "GET_USER_SUBSCRIPTION_REQUEST"
export const GET_USER_SUBSCRIPTION_SUCCESS = "GET_USER_SUBSCRIPTION_SUCCESS"
export const GET_USER_SUBSCRIPTION_FAILURE = "GET_USER_SUBSCRIPTION_FAILURE"

export const SET_SELECTED_PRICE_NAME = "SET_SELECTED_PRICE_NAME"

export const GET_SUBSCRIPTION_PRODUCT_API_URL = "/api/v2/products"
export const GET_USER_SUBSCRIPTION_API_URL = "/api/v2/subscriptions"

export const FAQ_HEADER = "FAQ"

export const PRO_PAGE_FEATURE_NAME = {
  HEADLINE: "rentspree_pro_headline",
  FEATURE_LIST: "rentspree_pro_feature_list",
}

export const FINE_PRINT_TEXT =
  "*Rent estimates are available on a pay-per-use basis. Tenants may be charged an application fee and, if applicable, monthly rent payments and insurance."

export const CHOOSE_PLAN_ACTION = {
  UPGRADE: "upgrade",
  TRIAL: "trial",
}
