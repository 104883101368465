import { query } from '@rentspree/path'
import { ReportDiv } from 'components/layout/main-style'
import { MobileReportList } from 'components/reports'
import { ReportLoading } from 'components/reports/loading'
import {
  CLICK_REPORT_FROM,
  FULL_PATH_ROUTE_REPORT,
  SHARED_REPORT_TYPES,
} from 'containers/reports/constants'
import { MobileReportItem } from 'legacy/components/application/application-detail'
import { MReportPanel } from 'legacy/components/credit-report/mobile-panel'
import { FailedToFetch } from 'legacy/components/layout/failed-to-fetch'
import { ReferenceCheckReport } from 'legacy/components/reports/ref-check-report'
import * as ERROR from 'legacy/constants/error-messages'
import { refCheckStatus } from 'legacy/constants/ref-check-consts'
import { NOT_FOUND } from 'legacy/constants/route-consts'
import { ARC_MOBILE_TAB } from 'legacy/containers/application/application-detail-const'
import { Routable } from 'legacy/containers/utils/routable'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { ReportPageContext } from '../../context'
import withProReportAvailable from '../../with-pro-report-available'
import { withConnect } from '../connect'
const { HEADER } = CLICK_REPORT_FROM

export class RefChecksReportContainer extends React.Component {
  static contextType = ReportPageContext

  async componentDidMount() {
    const {
      match: { params },
      refCheck,
      getApplicationDetail,
      applicationDetail,
      getReferenceCheck,
      isFetchingAppDetail,
      // Routable
    } = this.props
    const { rentalAppId } = params
    if (!isFetchingAppDetail && isEmpty(applicationDetail)) {
      await getApplicationDetail(rentalAppId)
    }
    if (!refCheck.isFetching) {
      await getReferenceCheck(rentalAppId)
    }
  }

  async componentDidUpdate(prevProp) {
    const {
      match: { params },
      history,
      refCheck,
      getReferenceCheck,
      applicationDetail,
      // Routable
      isAt,
    } = this.props
    const { rentalAppId } = params
    if (!refCheck.isFetching && applicationDetail !== prevProp.applicationDetail) {
      const refChecksData = (await getReferenceCheck(rentalAppId)) || {}
      /**
       * Redirect when the user accessing print page but didn't request reference checks
       * so that the result of reference checks data will be empty array
       */
      if (
        this.context.isPrintPage &&
        !this.isShowReport(refChecksData.result) && // the reference checks have not to requested yet
        isAt(/\/reference-checks/) // redirect only reference checks page
      )
        history.push(NOT_FOUND)
    }
    this.checkPrintPDFMode()
  }

  checkPrintPDFMode() {
    const { location } = this.props
    const queryString = query.parse(location.search)
    if (queryString.print === 'true') {
      window.printMode = true
    }
  }

  isShowReport(refs) {
    const refCheck = refs || this.props.refCheck.list
    return (
      filter(
        refCheck,
        ref => !isEmpty(ref.status) && ref.status !== refCheckStatus.unattainable.value,
      ).length > 0
    )
  }

  handlePrint = (type, autoPrint) => event => {
    event.preventDefault()
    event.stopPropagation()
    const { printReport } = this.props
    const { isPrintPage, propertyId } = this.context

    printReport({
      type,
      autoPrint,
      isPrintPage,
      clickFrom: HEADER,
      propertyId,
    })
  }

  makeHandleSavePDF = device => () => {
    const { savePDF } = this.props
    const { isPrintPage, propertyId } = this.context
    savePDF({
      type: ARC_MOBILE_TAB.path,
      ...(device && { device }),
      isPrintPage,
      clickFrom: HEADER,
      propertyId,
    })
  }

  handleSavePDFDesktop = this.makeHandleSavePDF()

  handleSavePDFMobile = this.makeHandleSavePDF('mobile')

  render() {
    const {
      refCheck,
      isFetchingAppDetail,
      pdfReport,
      applicationDetail,
      match: { path },
    } = this.props
    const { isPrintPage } = this.context
    const isCurrentDetail = !isPrintPage
    // only collapse reference checks page within application detail path
    const isCollapse = path === FULL_PATH_ROUTE_REPORT.REFERENCE_CHECKS

    if (refCheck.errorReference) {
      return (
        <FailedToFetch
          withBreadcrumb
          withFilter
          title={ERROR.APPLICATION_DETAIL.REFERENCE_CHECK.FETCH_REFERENCE.TITLE}
          text={ERROR.APPLICATION_DETAIL.REFERENCE_CHECK.FETCH_REFERENCE.MESSAGE}
        />
      )
    }

    if (!isCollapse && (isFetchingAppDetail || refCheck.isFetching)) {
      return <ReportLoading />
    }

    return (
      <>
        {!isEmpty(applicationDetail) && this.isShowReport() && (
          <div className="report-div">
            <ReportDiv className={`${isCurrentDetail ? 'hideMobile' : ''}`}>
              <ReferenceCheckReport
                references={refCheck.list}
                application={applicationDetail}
                collapsible={isCollapse}
                onPrint={this.handlePrint(ARC_MOBILE_TAB.path, true)}
                download={{
                  onClick: this.handleSavePDFDesktop,
                  onMobileClick: this.handleSavePDFMobile,
                  isFetching: pdfReport.isFetching,
                }}
              />
            </ReportDiv>
            {isCurrentDetail && (
              <MobileReportList className="visible-xs visible-sm" id="mRefChecksTab">
                <MobileReportItem
                  key={ARC_MOBILE_TAB.id}
                  color={ARC_MOBILE_TAB.color}
                  id={ARC_MOBILE_TAB.id}
                  hidden={ARC_MOBILE_TAB.mHidden}
                >
                  <MReportPanel
                    text={ARC_MOBILE_TAB.mText}
                    onClick={this.handlePrint(ARC_MOBILE_TAB.path, false)}
                    onPrint={this.handlePrint(ARC_MOBILE_TAB.path, true)}
                    download={{
                      onClick: this.handleSavePDFDesktop,
                      onMobileClick: this.handleSavePDFMobile,
                      isFetching: pdfReport.isFetching,
                    }}
                  />
                </MobileReportItem>
              </MobileReportList>
            )}
          </div>
        )}
      </>
    )
  }
}

export default compose(
  //  Wrap screening-options HOC to disappear from ALL page when doesn't have application option to resolved call unused API on this page
  withProReportAvailable(SHARED_REPORT_TYPES.REFERENCE_CHECK),
  withRouter,
  Routable,
  withConnect,
)(RefChecksReportContainer)
