import RECENT_ACTIVITY from 'images/icons/contacts/recent-activity.svg'
import RENTSPREE_APPLICATION from 'images/icons/contacts/rentspree-application.svg'
import STARRED from 'images/icons/contacts/starred.svg'
import UPCOMING_EVENT from 'images/icons/contacts/upcoming-event.svg'

export const EMPTY_CONTACT_GROUP_MENU_LIST = (currentGroupPath, filter) => {
  switch (currentGroupPath) {
    case 'starred':
      return {
        title: 'No starred contacts yet',
        description: '',
        imgSrc: STARRED,
        buttonName: 'Go to contacts',
        buttonIcon: '',
        buttonActionName: 'onClickStarContactOnEmptyPage',
      }
    case 'rentspree-application':
      return {
        title: 'No Applications Yet',
        description: 'Screen new tenant to get a new contact shown here',
        imgSrc: RENTSPREE_APPLICATION,
        buttonName: 'Screen New Tenant',
        buttonIcon: 'add',
        buttonActionName: 'onClickTenantScreening',
      }
    case 'recent-activity':
      return {
        title: 'No recent activity yet',
        description: '',
        imgSrc: RECENT_ACTIVITY,
        buttonName: '',
        buttonIcon: '',
      }
    case 'upcoming-event':
      return {
        title: EMPTY_UPCOMING_TITLE[filter] || EMPTY_UPCOMING_TITLE['All Events'],
        description: EMPTY_UPCOMING_SUBTITLE[filter] || EMPTY_UPCOMING_SUBTITLE['All Events'],
        imgSrc: UPCOMING_EVENT,
        buttonName: '',
        buttonIcon: '',
      }
    default:
      return {
        title: '',
        description: 'No contacts match your search criteria.',
      }
  }
}

export const TABLE_HEADER = [
  { name: 'name', text: 'Name', width: '25%', isSortable: true },
  { name: 'email', text: 'Email', width: '20%', isSortable: true },
  { name: 'phoneNumber', text: 'Phone', width: '15%', isSortable: true },
  {
    name: 'lastContactedAt',
    text: 'Latest Contact',
    width: '15%',
    isSortable: true,
  },
  { name: 'role', text: 'Role', width: '10%', isSortable: true },
  { name: 'tag', text: 'Tag', width: '10%' },
]

const EMPTY_UPCOMING_TITLE = {
  'All Events': 'Upcoming Events',
  Birthdays: 'Upcoming Birthdays',
  'Move-in Anniversary': 'Upcoming Anniversaries',
}

const EMPTY_UPCOMING_SUBTITLE = {
  'All Events':
    "Receive reminders for upcoming events by adding important dates to your contact's profiles.",
  Birthdays:
    "Receive reminders for upcoming birthdays by adding important dates to your contact's profiles.",
  'Move-in Anniversary':
    "Receive reminders for upcoming events by adding important dates to your contact's profiles.",
  'Move-in':
    "Receive reminders for upcoming events by adding important dates to your contact's profiles.",
}

export const SYSTEM_DEFAULT_MESSAGE = {
  BIRTHDAY:
    'Happy birthday, Wishing you the best on your birthday and everything good in the year ahead.',
  MOVE_IN_ANNIVERSARY:
    'Happy move-in anniversary, Wishing you happily living in your home and rooting for everything good in the year ahead.',
  MOVING_IN: 'Best wishes as you settle in to your new place!',
}
export const CHOOSE_GIFT_PANEL = 'CHOOSE_GIFT_PANEL'

export const SUGGEST_GIFT_PANEL = 'SUGGEST_GIFT_PANEL'
export const SUGGEST_GIFT_SUCCESS_PANEL = 'SUGGEST_GIFT_SUCCESS_PANEL'

export const CHAT_PANEL = 'CHAT_PANEL'

export const TYPE_BIRTHDAY = 'BIRTHDAY'
export const TYPE_MOVE_IN_ANNIVERSARY = 'MOVE_IN_ANNIVERSARY'
export const TYPE_MOVING_IN = 'MOVING_IN'
