import React from 'react'
import T36 from '@rentspree/component-v2/dist/typography/t36'
import { withFormik } from 'formik'
import { object } from 'yup'

import { Div } from 'components/layout/main'
import {
  DiamondWrapper,
  DiamondContainer,
} from 'components/templates/diamond-wrapper-template-style'
import SelectPropertyAddress from 'components/organisms/select-property-form'
import SelectPropertyAddressV2 from 'components/organisms/select-property-form-v2'

import {
  requiredYup,
  validateLength,
  validatePhone,
} from 'legacy/constants/validations'
import styled from 'styled-components'

import { validateEmail } from 'constants/validations'

const StyledT36 = styled(T36)`
  margin: 0 0 30px;
  ${props => props.isScreeningWithoutProperty && 'line-height: 45px;'}

  @media (max-width: 768px) {
    ${props => props.isScreeningWithoutProperty && 'line-height: 32px;'}
  }
`

export const selectPropertySchema = ({
  selectedPropertyId,
  validateOnMount = false,
  firstName,
  lastName,
  middleName,
  email,
  mobileNumber,
  userRoleForProperty,
}) => ({
  validation: object().shape({
    selectedPropertyId: requiredYup(true),
    firstName: validateLength(false, 1, 50),
    lastName: validateLength(false, 1, 50),
    middleName: validateLength(false, 1, 50),
    email: validateEmail(false),
    mobileNumber: validatePhone(false),
  }),
  touched: {
    selectedPropertyId: validateOnMount,
    firstName: validateOnMount,
    lastName: validateOnMount,
    middleName: validateOnMount,
    email: validateOnMount,
    mobileNumber: validateOnMount,
    userRoleForProperty,
  },
  mapper: {
    selectedPropertyId,
    firstName,
    lastName,
    middleName,
    email,
    mobileNumber,
    userRoleForProperty,
  },
})

export const SelectProperty = ({ isScreeningWithoutProperty = false, ...props }) => {
  const title = isScreeningWithoutProperty
    ? 'Which property are you screening for?'
    : 'Select Property'
  return (
    <DiamondWrapper data-testid="select-property-page-wrapper">
      <DiamondContainer>
        <StyledT36
          data-testid="select-property-page-title-text"
          isScreeningWithoutProperty={isScreeningWithoutProperty}
        >
          {title}
        </StyledT36>
        <Div margin="20px 0">
          {isScreeningWithoutProperty ? (
            <SelectPropertyAddressV2 {...props} />
          ) : (
            <SelectPropertyAddress addPropertyLater {...props} />
          )}
        </Div>
      </DiamondContainer>
    </DiamondWrapper>
  )
}

const SelectPropertyTemplate = withFormik({
  mapPropsToValues: props => selectPropertySchema(props).mapper,
  validationSchema: props => selectPropertySchema(props).validation,
  mapPropsToTouched: props => selectPropertySchema(props).touched,
  handleSubmit: (values, { props }) => {
    props.onSubmitProperty(values)
  },
  displayName: 'SelectPropertyTemplate',
})(SelectProperty)

export default SelectPropertyTemplate
