import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { COLOR } from 'styles/settings'

const Container = styled.div`
  ${({ margin }) => `margin: ${margin};`};
`

const ShadowCircle = styled.i`
  &.fa-stack-2x {
    color: ${props => props.shadowColor};
    font-size: ${props => props.shadowSize};
  }
`

const Icon = styled.i`
  &.fa-stack-1x {
    color: ${props => props.iconColor};
    font-size: ${props => props.iconSize};
  }
`

const DotMessage = styled.i`
  color: ${props => props.iconColor};
  font-size: ${props => props.iconSize};
`

const ChatIcon = ({
  className,
  margin,
  shadowColor,
  shadowSize,
  iconColor,
  iconSize,
  isShadow,
  onClick,
}) => (
  <Container className={className} margin={margin} onClick={onClick}>
    {isShadow ? (
      <span className="fa-stack">
        <ShadowCircle
          className="fas fa-circle fa-stack-2x"
          shadowSize={shadowSize}
          shadowColor={shadowColor}
        />
        <Icon
          className="fas fa-comment-dots fa-stack-1x"
          iconColor={iconColor}
          iconSize={iconSize}
        />
      </span>
    ) : (
      <DotMessage className="fas fa-comment-dots" iconColor={iconColor} iconSize={iconSize} />
    )}
  </Container>
)

ChatIcon.defaultProps = {
  iconColor: COLOR.blueLv3,
  iconSize: '20px',
  shadowColor: COLOR.white,
  shadowSize: '0px',
  isShadow: false,
}

ChatIcon.propTypes = {
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  shadowColor: PropTypes.string,
  shadowSize: PropTypes.string,
  isShadow: PropTypes.bool,
}

export default ChatIcon
