import React from "react"
import {
  CollapseBarArea,
  CollapseButton,
  ArrowCollapse,
  CollapseBar,
  TooltipComponent,
} from "./collapse-style"

import ARROW_ICON from "../../../images/icons/left-menu/arrow.svg"

export const CollapseSideBar = ({ onCollapseSideBar, collapseSideBar }) => (
  <CollapseBarArea onClick={onCollapseSideBar}>
    <CollapseButton onClick={onCollapseSideBar} isCollapse={collapseSideBar}>
      <ArrowCollapse src={ARROW_ICON} />
    </CollapseButton>
    <CollapseBar />
  </CollapseBarArea>
)

export const TooltipOverlay = ({ text, id, placement, width, textAlign }) => (
  <TooltipComponent
    id={id}
    placement={placement}
    width={width}
    textAlign={textAlign}>
    {text}
  </TooltipComponent>
)

export default CollapseSideBar
