import React, { useMemo } from "react"
import CreatePropertyAddressForm from "components/organisms/address-form"
import { ButtonSet } from "components/buttons/button-set"
import { Div } from "components/layout/main"

import isEmpty from "lodash/isEmpty"
import { withFormik } from "formik"
import { createPropertySchema } from "components/templates/create-property/schema"
import { UserRoleForPropertyForm } from "components/organisms/user-role-for-property-form"
import { USER_ROLE_FOR_PROPERTY } from "containers/request/step-create-property/constants"
import { BUTTON_TYPE_SUBMIT, BUTTON_TEXT_CONTINUE } from "./const"

export const InnerForm = ({
  handleSubmit,
  touched,
  errors,
  values,
  dirty,
  isCreatingProperty,
  handleBackButton,
  hideBackButton,
  continueText,
  hideIconNextButton,
  isCreatePropertySuccess,
  ...props
}) => {
  const handleSelectRole = (selectValue) => {
    props.setFieldValue("userRoleForProperty", selectValue)
  }

  const disableSubmit = useMemo(() => {
    const isFormValidationOk = !dirty || !isEmpty(errors)
    const isUserRoleForPropertyEmpty = isEmpty(values?.userRoleForProperty)
    return isFormValidationOk || isUserRoleForPropertyEmpty
  })

  return (
    <form id="createPropertyFormSubmit" onSubmit={handleSubmit}>
      <Div margin="0 0 20px" mMargin="0 0 20px">
        <CreatePropertyAddressForm
          {...props}
          values={values}
          errors={errors}
          touched={touched}
        />
        <UserRoleForPropertyForm
          {...props}
          values={values}
          errors={errors}
          touched={touched}
          onSelectDropdownChange={handleSelectRole}
          dropDownWidth="500px"
          landlordProfileWidth={500}
        />
      </Div>
      <ButtonSet
        id="createPropertyButton"
        dataTestKey="create-property-form"
        margin="0"
        minWidth="118"
        continueBtnType={BUTTON_TYPE_SUBMIT}
        continueText={continueText || BUTTON_TEXT_CONTINUE}
        hideIconNextButton={hideIconNextButton}
        btnHeight="40px"
        hidePrevButton
        hideBackButton={hideBackButton}
        onClickBackToDashboardBtn={handleBackButton}
        disabledNextButton={disableSubmit}
        isLoading={isCreatingProperty}
        isSuccess={isCreatePropertySuccess}
        onBorder
      />
    </form>
  )
}

/**
 * handles the form submission when the form is submitted.
 * It maps the values to the payload format that will be used
 * in the given submit function.
 *
 * @param {object} values - the form values
 * @param {string} values.street - the street
 * @param {string} values.unitNumber - the unit number
 * @param {string} values.city - the city
 * @param {string} values.zipcode - the zipcode
 * @param {string} values.state - the state
 * @param {string} values.email - the email
 * @param {string} values.firstName - the first name of the landlord
 * @param {string} values.lastName - the last name of the landlord
 * @param {string} values.middleName - the middle name of the landlord
 * @param {string} values.mobileNumber - the mobile number of the landlord
 * @param {string} values.userRoleForProperty - the property creator role
 * @param {(property: object) => void} submitFn - submit function
 */
export const handleSubmit = (values, submitFn) => {
  const mappedValue = {
    street: values.street,
    unitNumber: values.unitNumber,
    city: values.city,
    zipcode: values.zipcode,
    state: values.state,
    userRoleForProperty: values.userRoleForProperty,
    ... (values.userRoleForProperty === USER_ROLE_FOR_PROPERTY.LISTING_AGENT && {
      landlordProfile: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        mobileNumber: values.mobileNumber,
      }
    })
  }

  submitFn({ property: mappedValue })
}

export const CreatePropertyForm = withFormik({
  mapPropsToValues: props => createPropertySchema(props).mapper,
  validationSchema: props => createPropertySchema(props).validation,
  mapPropsToTouched: props => createPropertySchema(props).touched,
  displayName: "CreatePropertyTemplateForm",
  handleSubmit: async (values, { props }) => {
    handleSubmit(values, props.handleSubmit)
  },
})(InnerForm)

export default CreatePropertyForm
