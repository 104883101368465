import { T24 } from "@rentspree/component-v2"
import styled from "styled-components"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 70px 86px 50px 86px;
  height: fit-content;

  //because this container is wrapped inside a transformed background div
  transform: matrix(-1, 0, 0, 1, 0, 0);

  @media (max-width: 991px) {
    margin: 0 0 50px 0;
    transform: unset;
    flex-direction: column;
    height: 100%;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 991px) {
    align-items: center;
  }
`

export const ProContentImage = styled.img`
  width: 320px;
  height: 240px;
  margin: 0 0 0 21px;

  @media (max-width: 991px) {
    margin: 0 32px 0 23px;
  }
`

export const DesktopWrapper = styled.div`
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`

export const StyledT24WithGradientColored = styled(T24)`
  max-width: 231px;
  /* linear new blue */

  background: linear-gradient(90deg, #004bb0 0%, #007adf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

export const B18_STYLES = {
  lineHeight: "24px",
  weight: "600",
}

export const B16_STYLES = {
  lineHeight: "22px",
  weight: "400",
  align: "center",
}

export const BigBoldPriceTag = styled.span`
  font-size: 24px;
  line-height: 32px;
`
